// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Institutions.
export const GET_ALL_INSTITUTIONS_START = 'GET_ALL_INSTITUTIONS_START';
export const GET_ALL_INSTITUTIONS_SUCCESS = 'GET_ALL_INSTITUTIONS_SUCCESS';
export const GET_ALL_INSTITUTIONS_FAILED = 'GET_ALL_INSTITUTIONS_FAILED';
export const GET_ALL_INSTITUTIONS_RESET = 'GET_ALL_INSTITUTIONS_RESET';

// Action Types: Get One Institution.
export const GET_ONE_INSTITUTION_START = 'GET_ONE_INSTITUTION_START';
export const GET_ONE_INSTITUTION_SUCCESS = 'GET_ONE_INSTITUTION_SUCCESS';
export const GET_ONE_INSTITUTION_FAILED = 'GET_ONE_INSTITUTION_FAILED';
export const GET_ONE_INSTITUTION_RESET = 'GET_ONE_INSTITUTION_RESET';

// Action Types: Create New Institution.
export const CREATE_INSTITUTION_START = 'CREATE_INSTITUTION_START';
export const CREATE_INSTITUTION_SUCCESS = 'CREATE_INSTITUTION_SUCCESS';
export const CREATE_INSTITUTION_FAILED = 'CREATE_INSTITUTION_FAILED';
export const CREATE_INSTITUTION_RESET = 'CREATE_INSTITUTION_RESET';

// Action Types: Update One Institution.
export const UPDATE_ONE_INSTITUTION_START = 'UPDATE_ONE_INSTITUTION_START';
export const UPDATE_ONE_INSTITUTION_SUCCESS = 'UPDATE_ONE_INSTITUTION_SUCCESS';
export const UPDATE_ONE_INSTITUTION_FAILED = 'UPDATE_ONE_INSTITUTION_FAILED';
export const UPDATE_ONE_INSTITUTION_RESET = 'UPDATE_ONE_INSTITUTION_RESET';

// Action Types: Delete One Institution.
export const DELETE_ONE_INSTITUTION_START = 'DELETE_ONE_INSTITUTION_START';
export const DELETE_ONE_INSTITUTION_SUCCESS = 'DELETE_ONE_INSTITUTION_SUCCESS';
export const DELETE_ONE_INSTITUTION_FAILED = 'DELETE_ONE_INSTITUTION_FAILED';
export const DELETE_ONE_INSTITUTION_RESET = 'DELETE_ONE_INSTITUTION_RESET';

// Action Creators: Get All Institutions.
const getAllInstitutionsStart = (payload) => ({
  type: GET_ALL_INSTITUTIONS_START,
  payload,
});
const getAllInstitutionsSuccess = (payload) => ({
  type: GET_ALL_INSTITUTIONS_SUCCESS,
  payload,
});
const getAllInstitutionsFailed = (payload) => ({
  type: GET_ALL_INSTITUTIONS_FAILED,
  payload,
});
const getAllInstitutionsReset = () => ({ type: GET_ALL_INSTITUTIONS_RESET });

// Action Creators: Get One Institution.
const getOneInstitutionStart = (payload) => ({
  type: GET_ONE_INSTITUTION_START,
  payload,
});
const getOneInstitutionSuccess = (payload) => ({
  type: GET_ONE_INSTITUTION_SUCCESS,
  payload,
});
const getOneInstitutionFailed = (payload) => ({
  type: GET_ONE_INSTITUTION_FAILED,
  payload,
});
const getOneInstitutionReset = () => ({ type: GET_ONE_INSTITUTION_RESET });

// Action Creators: Create New Institution.
const createInstitutionStart = (payload) => ({
  type: CREATE_INSTITUTION_START,
  payload,
});
const createInstitutionSuccess = (payload) => ({
  type: CREATE_INSTITUTION_SUCCESS,
  payload,
});
const createInstitutionFailed = (payload) => ({
  type: CREATE_INSTITUTION_FAILED,
  payload,
});
const createInstitutionReset = () => ({ type: CREATE_INSTITUTION_RESET });

// Action Creators: Update One Institution.
const updateOneInstitutionStart = (payload) => ({
  type: UPDATE_ONE_INSTITUTION_START,
  payload,
});
const updateOneInstitutionSuccess = (payload) => ({
  type: UPDATE_ONE_INSTITUTION_SUCCESS,
  payload,
});
const updateOneInstitutionFailed = (payload) => ({
  type: UPDATE_ONE_INSTITUTION_FAILED,
  payload,
});
const updateOneInstitutionReset = () => ({ type: UPDATE_ONE_INSTITUTION_RESET });

// Action Creators: Delete One Institution.
const deleteOneInstitutionStart = (payload) => ({
  type: DELETE_ONE_INSTITUTION_START,
  payload,
});
const deleteOneInstitutionSuccess = (payload) => ({
  type: DELETE_ONE_INSTITUTION_SUCCESS,
  payload,
});
const deleteOneInstitutionFailed = (payload) => ({
  type: DELETE_ONE_INSTITUTION_FAILED,
  payload,
});
const deleteOneInstitutionReset = () => ({ type: DELETE_ONE_INSTITUTION_RESET });

// Actions: Get All Institutions.
export const getAllInstitutions = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllInstitutionsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, name, municipality, type, language } = payload;
      const result = await ApiClient.get('institutions', {
        params: { page, limit, pagination, name, municipality, type, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { institutions } = result.data.data;
        dispatch(
          getAllInstitutionsSuccess({
            loading: false,
            success: true,
            data: { institutions },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllInstitutionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllInstitutionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Institutions.
export const clearGetAllInstitutions = () => getAllInstitutionsReset();

// Actions: Get One Institution.
export const getOneInstitution = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneInstitutionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { institutionId } = payload;
      const result = await ApiClient.get(`institutions/${institutionId}`);
      if (result.data?.success) {
        const { institution } = result.data.data;
        dispatch(
          getOneInstitutionSuccess({
            loading: false,
            success: true,
            data: { institution },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneInstitutionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneInstitutionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Institution.
export const clearGetOneInstitution = () => getOneInstitutionReset();

// Actions: Create New Institution.
export const createInstitution = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createInstitutionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription, type, municipality } = payload;
    const {
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onInstituionExistsMessage,
    } = options;

    try {
      const result = await ApiClient.post('institutions', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        type,
        municipality,
      });
      if (result.data?.success) {
        const { institution } = result.data.data;
        dispatch(
          createInstitutionSuccess({
            loading: false,
            success: true,
            data: { institution },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createInstitutionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createInstitutionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const institutionExists = 'INSTITUTION_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === institutionExists
      ) {
        toastNotification('error', onInstituionExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Institution.
export const clearCreateNewInstitution = () => createInstitutionReset();

// Actions: Update One Institution.
export const updateOneInstitution = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneInstitutionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { institutionId, enName, enDescription, sqName, sqDescription, srName, srDescription, type, municipality } =
      payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`institutions/${institutionId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        type,
        municipality,
      });
      if (result.data?.success) {
        const { institution } = result.data.data;
        dispatch(
          updateOneInstitutionSuccess({
            loading: false,
            success: true,
            data: { institution },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneInstitutionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneInstitutionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Institution.
export const clearUpdateOneInstitution = () => updateOneInstitutionReset();

// Actions: Delete One Institution.
export const deleteOneInstitution = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneInstitutionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { institutionId } = payload;
      const result = await ApiClient.delete(`institutions/${institutionId}`);
      if (result.data?.success) {
        const { institution } = result.data.data;
        dispatch(
          deleteOneInstitutionSuccess({
            loading: false,
            success: true,
            data: { institution },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneInstitutionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneInstitutionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Institution.
export const cleareDeleteOneInstitution = () => deleteOneInstitutionReset();
