// Imports: local files.
import ApiClient from '../../services/ApiClient';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

// Action Types: Get All Mentorships.
export const GET_ALL_MENTORSHIPS_START = 'GET_ALL_MENTORSHIPS_START';
export const GET_ALL_MENTORSHIPS_SUCCESS = 'GET_ALL_MENTORSHIPS_SUCCESS';
export const GET_ALL_MENTORSHIPS_FAILED = 'GET_ALL_MENTORSHIPS_FAILED';
export const GET_ALL_MENTORSHIPS_RESET = 'GET_ALL_MENTORSHIPS_RESET';

// Action Types: Get One Mentorship.
export const GET_ONE_MENTORSHIP_START = 'GET_ONE_MENTORSHIP_START';
export const GET_ONE_MENTORSHIP_SUCCESS = 'GET_ONE_MENTORSHIP_SUCCESS';
export const GET_ONE_MENTORSHIP_FAILED = 'GET_ONE_MENTORSHIP_FAILED';
export const GET_ONE_MENTORSHIP_RESET = 'GET_ONE_MENTORSHIP_RESET';

// Action Types: Get One Mentorship.
export const GET_MENTORSHIP_MEETINGS_START = 'GET_MENTORSHIP_MEETINGS_START';
export const GET_MENTORSHIP_MEETINGS_SUCCESS = 'GET_MENTORSHIP_MEETINGS_SUCCESS';
export const GET_MENTORSHIP_MEETINGS_FAILED = 'GET_MENTORSHIP_MEETINGS_FAILED';
export const GET_MENTORSHIP_MEETINGS_RESET = 'GET_MENTORSHIP_MEETINGS_RESET';

// Action Types: Create New Mentorship.
export const CREATE_MENTORSHIP_START = 'CREATE_MENTORSHIP_START';
export const CREATE_MENTORSHIP_SUCCESS = 'CREATE_MENTORSHIP_SUCCESS';
export const CREATE_MENTORSHIP_FAILED = 'CREATE_MENTORSHIP_FAILED';
export const CREATE_MENTORSHIP_RESET = 'CREATE_MENTORSHIP_RESET';

// Action Types: Create New Mentorship.
export const CREATE_MENTORSHIP_AUTOMATICALLY_START = 'CREATE_MENTORSHIP_AUTOMATICALLY_START';
export const CREATE_MENTORSHIP_AUTOMATICALLY_SUCCESS = 'CREATE_MENTORSHIP_AUTOMATICALLY_SUCCESS';
export const CREATE_MENTORSHIP_AUTOMATICALLY_FAILED = 'CREATE_MENTORSHIP_AUTOMATICALLY_FAILED';
export const CREATE_MENTORSHIP_AUTOMATICALLY_RESET = 'CREATE_MENTORSHIP_AUTOMATICALLY_RESET';

// Action Types: Update One Mentorship.
export const UPDATE_ONE_MENTORSHIP_START = 'UPDATE_ONE_MENTORSHIP_START';
export const UPDATE_ONE_MENTORSHIP_SUCCESS = 'UPDATE_ONE_MENTORSHIP_SUCCESS';
export const UPDATE_ONE_MENTORSHIP_FAILED = 'UPDATE_ONE_MENTORSHIP_FAILED';
export const UPDATE_ONE_MENTORSHIP_RESET = 'UPDATE_ONE_MENTORSHIP_RESET';

// Action Types: Upload File Mentorship.
export const UPLOAD_FILE_MENTORSHIP_START = 'UPLOAD_FILE_MENTORSHIP_START';
export const UPLOAD_FILE_MENTORSHIP_SUCCESS = 'UPLOAD_FILE_MENTORSHIP_SUCCESS';
export const UPLOAD_FILE_MENTORSHIP_FAILED = 'UPLOAD_FILE_MENTORSHIP_FAILED';
export const UPLOAD_FILE_MENTORSHIP_RESET = 'UPLOAD_FILE_MENTORSHIP_RESET';

// Action Types: Upload Link Mentorship.
export const UPLOAD_LINK_MENTORSHIP_START = 'UPLOAD_LINK_MENTORSHIP_START';
export const UPLOAD_LINK_MENTORSHIP_SUCCESS = 'UPLOAD_LINK_MENTORSHIP_SUCCESS';
export const UPLOAD_LINK_MENTORSHIP_FAILED = 'UPLOAD_LINK_MENTORSHIP_FAILED';
export const UPLOAD_LINK_MENTORSHIP_RESET = 'UPLOAD_LINK_MENTORSHIP_RESET';

// Action Types: Delete File Mentorship.
export const DELETE_FILE_MENTORSHIP_START = 'DELETE_FILE_MENTORSHIP_START';
export const DELETE_FILE_MENTORSHIP_SUCCESS = 'DELETE_FILE_MENTORSHIP_SUCCESS';
export const DELETE_FILE_MENTORSHIP_FAILED = 'DELETE_FILE_MENTORSHIP_FAILED';
export const DELETE_FILE_MENTORSHIP_RESET = 'DELETE_FILE_MENTORSHIP_RESET';

// Action Creators: Get All Mentorships.
const getAllMentorshipsStart = (payload) => ({
  type: GET_ALL_MENTORSHIPS_START,
  payload,
});
const getAllMentorshipsSuccess = (payload) => ({
  type: GET_ALL_MENTORSHIPS_SUCCESS,
  payload,
});
const getAllMentorshipsFailed = (payload) => ({
  type: GET_ALL_MENTORSHIPS_FAILED,
  payload,
});
const getAllMentorshipsReset = () => ({ type: GET_ALL_MENTORSHIPS_RESET });

// Action Creators: Get One Mentorship.
const getOneMentorshipStart = (payload) => ({
  type: GET_ONE_MENTORSHIP_START,
  payload,
});
const getOneMentorshipSuccess = (payload) => ({
  type: GET_ONE_MENTORSHIP_SUCCESS,
  payload,
});
const getOneMentorshipFailed = (payload) => ({
  type: GET_ONE_MENTORSHIP_FAILED,
  payload,
});
const getOneMentorshipReset = () => ({ type: GET_ONE_MENTORSHIP_RESET });

// Action Creators: Get One Mentorship.
const getMentorshipMeetingsStart = (payload) => ({
  type: GET_MENTORSHIP_MEETINGS_START,
  payload,
});
const getMentorshipMeetingsSuccess = (payload) => ({
  type: GET_MENTORSHIP_MEETINGS_SUCCESS,
  payload,
});
const getMentorshipMeetingsFailed = (payload) => ({
  type: GET_MENTORSHIP_MEETINGS_FAILED,
  payload,
});
const getMentorshipMeetingsReset = () => ({ type: GET_MENTORSHIP_MEETINGS_RESET });

// Action Creators: Update One Mentorship.
const updateOneMentorshipStart = (payload) => ({
  type: UPDATE_ONE_MENTORSHIP_SUCCESS,
  payload,
});
const updateOneMentorshipSuccess = (payload) => ({
  type: UPDATE_ONE_MENTORSHIP_START,
  payload,
});
const updateOneMentorshipFailed = (payload) => ({
  type: UPDATE_ONE_MENTORSHIP_FAILED,
  payload,
});
const updateOneMentorshipReset = () => ({ type: UPDATE_ONE_MENTORSHIP_RESET });

// Action Creators: Create New Mentorship.
const createMentorshipStart = (payload) => ({
  type: CREATE_MENTORSHIP_START,
  payload,
});
const createMentorshipSuccess = (payload) => ({
  type: CREATE_MENTORSHIP_SUCCESS,
  payload,
});
const createMentorshipFailed = (payload) => ({
  type: CREATE_MENTORSHIP_FAILED,
  payload,
});
const createMentorshipReset = () => ({ type: CREATE_MENTORSHIP_RESET });

// Action Creators: Create New Mentorship Automatically.
const createMentorshipAutomaticallyStart = (payload) => ({
  type: CREATE_MENTORSHIP_AUTOMATICALLY_START,
  payload,
});
const createMentorshipAutomaticallySuccess = (payload) => ({
  type: CREATE_MENTORSHIP_AUTOMATICALLY_SUCCESS,
  payload,
});
const createMentorshipAutomaticallyFailed = (payload) => ({
  type: CREATE_MENTORSHIP_AUTOMATICALLY_FAILED,
  payload,
});
const createMentorshipAutomaticallyReset = () => ({ type: CREATE_MENTORSHIP_AUTOMATICALLY_RESET });

// Action Creators: Upload Photo Mentor.
const uploadFileMentorshipStart = (payload) => ({
  type: UPLOAD_FILE_MENTORSHIP_START,
  payload,
});
const uploadFileMentorshipSuccess = (payload) => ({
  type: UPLOAD_FILE_MENTORSHIP_SUCCESS,
  payload,
});
const uploadFileMentorshipFailed = (payload) => ({
  type: UPLOAD_FILE_MENTORSHIP_FAILED,
  payload,
});
const uploadFileMentorshipReset = () => ({ type: UPLOAD_FILE_MENTORSHIP_RESET });

// Action Creators: Upload link.
const uploadLinkMentorshipStart = (payload) => ({
  type: UPLOAD_LINK_MENTORSHIP_START,
  payload,
});
const uploadLinkMentorshipSuccess = (payload) => ({
  type: UPLOAD_LINK_MENTORSHIP_SUCCESS,
  payload,
});
const uploadLinkMentorshipFailed = (payload) => ({
  type: UPLOAD_LINK_MENTORSHIP_FAILED,
  payload,
});
const uploadLinkMentorshipReset = () => ({ type: UPLOAD_LINK_MENTORSHIP_RESET });

// Action Creators: Delete File Mentorship.
const deleteFileMentorshipStart = (payload) => ({
  type: DELETE_FILE_MENTORSHIP_START,
  payload,
});
const deleteFileMentorshipSuccess = (payload) => ({
  type: DELETE_FILE_MENTORSHIP_SUCCESS,
  payload,
});
const deleteFileMentorshipFailed = (payload) => ({
  type: DELETE_FILE_MENTORSHIP_FAILED,
  payload,
});
const deleteFileMentorshipReset = () => ({ type: DELETE_FILE_MENTORSHIP_RESET });

// Actions: Get All Mentorships.
export const getAllMentorships = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMentorshipsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { page, limit, pagination, intern, mentor, application, acceptedByMentor, availability } = payload;

    try {
      const result = await ApiClient.get('mentorships', {
        params: { page, limit, pagination, intern, mentor, application, acceptedByMentor, availability },
      });
      if (result.data?.success) {
        const { mentorships } = result.data?.data;
        dispatch(
          getAllMentorshipsSuccess({
            loading: false,
            success: true,
            data: { mentorships },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMentorshipsFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMentorshipsFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All.
export const clearGetAllMentorships = () => getAllMentorshipsReset();

// Actions: Get One Mentorship.
export const getOneMentorship = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { mentorId, internId, applicationId } = payload;

    try {
      const result = await ApiClient.get(`mentorships/${mentorId}/intern/${internId}/application/${applicationId}`);
      if (result.data?.success) {
        const { mentorship } = result.data?.data;
        dispatch(
          getOneMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One.
export const clearGetOneMentorship = () => getOneMentorshipReset();

// Actions: Get One Mentorship.
export const getMentorshipMeetings = (payload) => {
  return async (dispatch) => {
    dispatch(
      getMentorshipMeetingsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { mentorshipId, mentor, intern } = payload;

    try {
      const result = await ApiClient.get(`mentorships/${mentorshipId}/meetings`, { params: { mentor, intern } });
      if (result.data?.success) {
        const { meetings } = result.data?.data;
        dispatch(
          getMentorshipMeetingsSuccess({
            loading: false,
            success: true,
            data: { meetings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getMentorshipMeetingsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getMentorshipMeetingsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One.
export const clearGetMentorshipMeetings = () => getMentorshipMeetingsReset();

// Actions: Create New Mentorship.
export const createMentorship = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, mentorId, emailLanguage } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('mentorships', { applicationId, mentorId, emailLanguage });
      if (result.data?.success) {
        const { mentorship } = result.data.data;
        dispatch(
          createMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Mentorship.
export const clearCreateMentorship = () => createMentorshipReset();

// Actions: Create New Mentorship Automatically.
export const createMentorshipAutomatically = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createMentorshipAutomaticallyStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, languages, emailLanguage } = payload;
    const { toastNotification, history, pathname, showToast, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('mentorships/assignmentor', { applicationId, languages, emailLanguage });
      if (result.data?.success) {
        const { mentorship } = result.data.data;
        dispatch(
          createMentorshipAutomaticallySuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        mentorship !== false && showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createMentorshipAutomaticallyFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createMentorshipAutomaticallyFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New MentorshipAutomatically.
export const clearCreateMentorshipAutomatically = () => createMentorshipAutomaticallyReset();

// Actions: Update One Mentorship.
export const updateOneMentorship = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { mentorshipId, type, emailLanguage } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`mentorships/${mentorshipId}`, {
        type,
        emailLanguage,
      });
      if (result.data?.success) {
        const { mentorship } = result.data.data;
        dispatch(
          updateOneMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'INTERN_HAS_MENTOR'
      ) {
        toastNotification('error', 'Intern already has a mentor!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Update One.
export const clearUpdateOneMentorship = () => updateOneMentorshipReset();

// Actions: Upload File Mentorship.
export const uploadFileMentorship = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadFileMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onUploadTitle,
      onUploadMessage,
    } = options;
    try {
      const { mentorshipId, formData } = payload;
      const result = await ApiClient.put(`mentorships/${mentorshipId}/files`, formData);
      if (result.data?.success) {
        const { mentorship } = result.data?.data;
        dispatch(
          uploadFileMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        // showToast && toastNotification('success', onSuccessMessage);
        // shouldRedirect && history.push(pathname);
        if (showSwal) {
          const swalOptions = {
            title: `${onUploadTitle}`,
            text: `${onUploadMessage}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
        } else {
          showToast && toastNotification('success', onSuccessMessage);
        }
      } else {
        dispatch(
          uploadFileMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadFileMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Upload Link Mentorship.
export const uploadLinkMentorship = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadFileMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onUploadTitle,
      onUploadMessage,
    } = options;
    try {
      const { mentorshipId, link } = payload;
      const result = await ApiClient.put(`mentorships/${mentorshipId}/links`, { link });
      if (result.data?.success) {
        const { mentorship } = result.data?.data;
        dispatch(
          uploadFileMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        // showToast && toastNotification('success', onSuccessMessage);
        // shouldRedirect && history.push(pathname);
        if (showSwal) {
          const swalOptions = {
            title: `${onUploadTitle}`,
            text: `${onUploadMessage}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
        } else {
          showToast && toastNotification('success', onSuccessMessage);
        }
      } else {
        dispatch(
          uploadFileMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadFileMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload File Mentorship.
export const clearUploadFileMentorship = () => uploadFileMentorshipReset();

// Actions: Delete One Mentor.
export const deleteFileMentorship = (payload, options = {}) => {
  return async (dispatch) => {
    dispatch(
      deleteFileMentorshipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, onSuccessMessage, onFailMessage } = options;
    try {
      const { mentorshipId, fileId } = payload;
      const result = await ApiClient.delete(`mentorships/${mentorshipId}/files/${fileId}`);
      if (result.data?.success) {
        const { mentorship } = result.data.data;
        dispatch(
          deleteFileMentorshipSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          deleteFileMentorshipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        deleteFileMentorshipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Delete File Mentorship.
export const clearDeleteFileMentorship = () => deleteFileMentorshipReset();
