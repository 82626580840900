// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Sub Categories.
export const GET_ALL_SUB_CATEGORIES_START = 'GET_ALL_SUB_CATEGORIES_START';
export const GET_ALL_SUB_CATEGORIES_SUCCESS = 'GET_ALL_SUB_CATEGORIES_SUCCESS';
export const GET_ALL_SUB_CATEGORIES_FAILED = 'GET_ALL_SUB_CATEGORIES_FAILED';
export const GET_ALL_SUB_CATEGORIES_RESET = 'GET_ALL_SUB_CATEGORIES_RESET';

// Action Types: Get One Sub Category.
export const GET_ONE_SUB_CATEGORY_START = 'GET_ONE_SUB_CATEGORY_START';
export const GET_ONE_SUB_CATEGORY_SUCCESS = 'GET_ONE_SUB_CATEGORY_SUCCESS';
export const GET_ONE_SUB_CATEGORY_FAILED = 'GET_ONE_SUB_CATEGORY_FAILED';
export const GET_ONE_SUB_CATEGORY_RESET = 'GET_ONE_SUB_CATEGORY_RESET';

// Action Types: Create New Sub Category.
export const CREATE_SUB_CATEGORY_START = 'CREATE_SUB_CATEGORY_START';
export const CREATE_SUB_CATEGORY_SUCCESS = 'CREATE_SUB_CATEGORY_SUCCESS';
export const CREATE_SUB_CATEGORY_FAILED = 'CREATE_SUB_CATEGORY_FAILED';
export const CREATE_SUB_CATEGORY_RESET = 'CREATE_SUB_CATEGORY_RESET';

// Action Types: Update One Sub Category.
export const UPDATE_ONE_SUB_CATEGORY_START = 'UPDATE_ONE_SUB_CATEGORY_START';
export const UPDATE_ONE_SUB_CATEGORY_SUCCESS = 'UPDATE_ONE_SUB_CATEGORY_SUCCESS';
export const UPDATE_ONE_SUB_CATEGORY_FAILED = 'UPDATE_ONE_SUB_CATEGORY_FAILED';
export const UPDATE_ONE_SUB_CATEGORY_RESET = 'UPDATE_ONE_SUB_CATEGORY_RESET';

// Action Types: Delete One Sub Category.
export const DELETE_ONE_SUB_CATEGORY_START = 'DELETE_ONE_SUB_CATEGORY_START';
export const DELETE_ONE_SUB_CATEGORY_SUCCESS = 'DELETE_ONE_SUB_CATEGORY_SUCCESS';
export const DELETE_ONE_SUB_CATEGORY_FAILED = 'DELETE_ONE_SUB_CATEGORY_FAILED';
export const DELETE_ONE_SUB_CATEGORY_RESET = 'DELETE_ONE_SUB_CATEGORY_RESET';

// Action Creators: Get All Sub Categories.
const getAllSubCategoriesStart = (payload) => ({
  type: GET_ALL_SUB_CATEGORIES_START,
  payload,
});
const getAllSubCategoriesSuccess = (payload) => ({
  type: GET_ALL_SUB_CATEGORIES_SUCCESS,
  payload,
});
const getAllSubCategoriesFailed = (payload) => ({
  type: GET_ALL_SUB_CATEGORIES_FAILED,
  payload,
});
const getAllSubCategoriesReset = () => ({ type: GET_ALL_SUB_CATEGORIES_RESET });

// Action Creators: Get One Sub Category.
const getOneSubCategoryStart = (payload) => ({
  type: GET_ONE_SUB_CATEGORY_START,
  payload,
});
const getOneSubCategorySuccess = (payload) => ({
  type: GET_ONE_SUB_CATEGORY_SUCCESS,
  payload,
});
const getOneSubCategoryFailed = (payload) => ({
  type: GET_ONE_SUB_CATEGORY_FAILED,
  payload,
});
const getOneSubCategoryReset = () => ({ type: GET_ONE_SUB_CATEGORY_RESET });

// Action Creators: Create New Sub Category.
const createSubCategoryStart = (payload) => ({
  type: CREATE_SUB_CATEGORY_START,
  payload,
});
const createSubCategorySuccess = (payload) => ({
  type: CREATE_SUB_CATEGORY_SUCCESS,
  payload,
});
const createSubCategoryFailed = (payload) => ({
  type: CREATE_SUB_CATEGORY_FAILED,
  payload,
});
const createSubCategoryReset = () => ({ type: CREATE_SUB_CATEGORY_RESET });

// Action Creators: Update One Sub Category.
const updateOneSubCategoryStart = (payload) => ({
  type: UPDATE_ONE_SUB_CATEGORY_START,
  payload,
});
const updateOneSubCategorySuccess = (payload) => ({
  type: UPDATE_ONE_SUB_CATEGORY_SUCCESS,
  payload,
});
const updateOneSubCategoryFailed = (payload) => ({
  type: UPDATE_ONE_SUB_CATEGORY_FAILED,
  payload,
});
const updateOneSubCategoryReset = () => ({ type: UPDATE_ONE_SUB_CATEGORY_RESET });

// Action Creators: Delete One Sub Category.
const deleteOneSubCategoryStart = (payload) => ({
  type: DELETE_ONE_SUB_CATEGORY_START,
  payload,
});
const deleteOneSubCategorySuccess = (payload) => ({
  type: DELETE_ONE_SUB_CATEGORY_SUCCESS,
  payload,
});
const deleteOneSubCategoryFailed = (payload) => ({
  type: DELETE_ONE_SUB_CATEGORY_FAILED,
  payload,
});
const deleteOneSubCategoryReset = () => ({ type: DELETE_ONE_SUB_CATEGORY_RESET });

// Actions: Get All Sub Categories.
export const getAllSubCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllSubCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, category, language } = payload;
      const result = await ApiClient.get('subcategories', {
        params: { page, limit, pagination, category, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { subcategories } = result.data.data;
        dispatch(
          getAllSubCategoriesSuccess({
            loading: false,
            success: true,
            data: { subcategories },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllSubCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllSubCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Sub Categories.
export const clearGetAllSubCategories = () => getAllSubCategoriesReset();

// Actions: Get One Sub Category.
export const getOneSubCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneSubCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { subcategoryId } = payload;
      const result = await ApiClient.get(`subcategories/${subcategoryId}`);
      if (result.data?.success) {
        const { subcategory } = result.data.data;
        dispatch(
          getOneSubCategorySuccess({
            loading: false,
            success: true,
            data: { subcategory },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneSubCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneSubCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Sub Category.
export const clearGetOneSubCategory = () => getOneSubCategoryReset();

// Actions: Create New Sub Category.
export const createSubCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createSubCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription, category } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onSubCategoryExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('subcategories', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        category,
      });
      if (result.data?.success) {
        const { subcategory } = result.data.data;
        dispatch(
          createSubCategorySuccess({
            loading: false,
            success: true,
            data: { subcategory },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createSubCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createSubCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'SUB_CATEGORY_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onSubCategoryExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Sub Category.
export const clearCreateNewSubCategory = () => createSubCategoryReset();

// Actions: Update One Sub Category.
export const updateOneSubCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneSubCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { subcategoryId, enName, enDescription, sqName, sqDescription, srName, srDescription, category } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`subcategories/${subcategoryId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        category,
      });
      if (result.data?.success) {
        const { subcategory } = result.data.data;
        dispatch(
          updateOneSubCategorySuccess({
            loading: false,
            success: true,
            data: { subcategory },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneSubCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneSubCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Sub Category.
export const clearUpdateOneSubCategory = () => updateOneSubCategoryReset();

// Actions: Delete One Sub Category.
export const deleteOneSubCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneSubCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { subcategoryId } = payload;
      const result = await ApiClient.delete(`subcategories/${subcategoryId}`);
      if (result.data?.success) {
        const { subcategory } = result.data.data;
        dispatch(
          deleteOneSubCategorySuccess({
            loading: false,
            success: true,
            data: { subcategory },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneSubCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneSubCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Sub Category.
export const cleareDeleteOneSubCategory = () => deleteOneSubCategoryReset();
