// Imports: local files.
import {
  GET_ALL_MEETINGS_START,
  GET_ALL_MEETINGS_SUCCESS,
  GET_ALL_MEETINGS_FAILED,
  GET_ALL_MEETINGS_RESET,
  GET_ONE_MEETING_START,
  GET_ONE_MEETING_SUCCESS,
  GET_ONE_MEETING_FAILED,
  GET_ONE_MEETING_RESET,
  CREATE_MEETING_START,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_FAILED,
  CREATE_MEETING_RESET,
  UPDATE_ONE_MEETING_START,
  UPDATE_ONE_MEETING_SUCCESS,
  UPDATE_ONE_MEETING_FAILED,
  UPDATE_ONE_MEETING_RESET,
  CANCEL_ONE_MEETING_START,
  CANCEL_ONE_MEETING_SUCCESS,
  CANCEL_ONE_MEETING_FAILED,
  CANCEL_ONE_MEETING_RESET,
  DELETE_ONE_MEETING_START,
  DELETE_ONE_MEETING_SUCCESS,
  DELETE_ONE_MEETING_FAILED,
  DELETE_ONE_MEETING_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  cancelOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const meetingsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Meetings
     * =======================================================================
     */
    case GET_ALL_MEETINGS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEETINGS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEETINGS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MEETINGS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Meeting
     * =======================================================================
     */
    case GET_ONE_MEETING_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEETING_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEETING_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MEETING_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Meeting
     * =======================================================================
     */
    case CREATE_MEETING_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEETING_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEETING_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_MEETING_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Meeting
     * =======================================================================
     */
    case UPDATE_ONE_MEETING_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MEETING_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MEETING_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MEETING_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Cancel One Meeting
     * =======================================================================
     */
    case CANCEL_ONE_MEETING_START:
      return { ...state, cancelOne: { ...action.payload } };
    case CANCEL_ONE_MEETING_SUCCESS:
      return { ...state, cancelOne: { ...action.payload } };
    case CANCEL_ONE_MEETING_FAILED:
      return { ...state, cancelOne: { ...action.payload } };
    case CANCEL_ONE_MEETING_RESET:
      return { ...state, cancelOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Meeting
     * =======================================================================
     */
    case DELETE_ONE_MEETING_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MEETING_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MEETING_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MEETING_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default meetingsReducer;
