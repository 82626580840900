// Imports: local files.
import {
    GET_ALL_CANCELLATIONS_START,
    GET_ALL_CANCELLATIONS_SUCCESS,
    GET_ALL_CANCELLATIONS_FAILED,
    GET_ALL_CANCELLATIONS_RESET,
    GET_ONE_CANCELLATION_START,
    GET_ONE_CANCELLATION_SUCCESS,
    GET_ONE_CANCELLATION_FAILED,
    GET_ONE_CANCELLATION_RESET,
    CREATE_CANCELLATION_START,
    CREATE_CANCELLATION_SUCCESS,
    CREATE_CANCELLATION_FAILED,
    CREATE_CANCELLATION_RESET,
  } from '../actions/actions';
  
  const initialLoadingState = {
    loading: false,
    success: false,
    data: null,
    error: false,
    errorMessage: null,
  };
  const initialState = {
    getAll: initialLoadingState,
    getOne: initialLoadingState,
    create: initialLoadingState,
  };
  
  const cancellationsReducer = (state = initialState, action) => {
    switch (action.type) {
      /**
       * =======================================================================
       * Get All Cancellations
       * =======================================================================
       */
      case GET_ALL_CANCELLATIONS_START:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_CANCELLATIONS_SUCCESS:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_CANCELLATIONS_FAILED:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_CANCELLATIONS_RESET:
        return { ...state, getAll: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Get One Cancellation
       * =======================================================================
       */
      case GET_ONE_CANCELLATION_START:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_CANCELLATION_SUCCESS:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_CANCELLATION_FAILED:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_CANCELLATION_RESET:
        return { ...state, getOne: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Create New Cancellation
       * =======================================================================
       */
      case CREATE_CANCELLATION_START:
        return { ...state, create: { ...action.payload } };
      case CREATE_CANCELLATION_SUCCESS:
        return { ...state, create: { ...action.payload } };
      case CREATE_CANCELLATION_FAILED:
        return { ...state, create: { ...action.payload } };
      case CREATE_CANCELLATION_RESET:
        return { ...state, create: { ...initialLoadingState } };
      
      default:
        return state;
    }
  };
  
  export default cancellationsReducer;
  