// Imports: local files.
import {
  GET_ALL_BANNER_START,
  GET_ALL_BANNER_SUCCESS,
  GET_ALL_BANNER_FAILED,
  GET_ALL_BANNER_RESET,
  GET_ONE_BANNER_START,
  GET_ONE_BANNER_SUCCESS,
  GET_ONE_BANNER_FAILED,
  GET_ONE_BANNER_RESET,
  CREATE_BANNER_START,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_FAILED,
  CREATE_BANNER_RESET,
  UPDATE_ONE_BANNER_START,
  UPDATE_ONE_BANNER_SUCCESS,
  UPDATE_ONE_BANNER_FAILED,
  UPDATE_ONE_BANNER_RESET,
  UPLOAD_PHOTO_BANNER_START,
  UPLOAD_PHOTO_BANNER_SUCCESS,
  UPLOAD_PHOTO_BANNER_FAILED,
  UPLOAD_PHOTO_BANNER_RESET,
  DELETE_ONE_BANNER_START,
  DELETE_ONE_BANNER_SUCCESS,
  DELETE_ONE_BANNER_FAILED,
  DELETE_ONE_BANNER_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  uploadPhoto: initialLoadingState,
  deleteOne: initialLoadingState,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Bannes
     * =======================================================================
     */
    case GET_ALL_BANNER_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BANNER_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BANNER_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BANNER_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Banner
     * =======================================================================
     */
    case GET_ONE_BANNER_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BANNER_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BANNER_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BANNER_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Banner
     * =======================================================================
     */
    case CREATE_BANNER_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_BANNER_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_BANNER_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_BANNER_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Banner
     * =======================================================================
     */
    case UPDATE_ONE_BANNER_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BANNER_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BANNER_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BANNER_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Photos Banner
     * =======================================================================
     */
    case UPLOAD_PHOTO_BANNER_START:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_BANNER_SUCCESS:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_BANNER_FAILED:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_BANNER_RESET:
      return { ...state, uploadPhoto: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Delete One Article
     * =======================================================================
     */
    case DELETE_ONE_BANNER_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BANNER_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BANNER_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BANNER_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default bannerReducer;
