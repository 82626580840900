// Imports: local files.
import {
  GET_ALL_PUBLIC_MENTORS_START,
  GET_ALL_PUBLIC_MENTORS_SUCCESS,
  GET_ALL_PUBLIC_MENTORS_FAILED,
  GET_ALL_PUBLIC_MENTORS_RESET,
  GET_ONE_PUBLIC_MENTOR_START,
  GET_ONE_PUBLIC_MENTOR_SUCCESS,
  GET_ONE_PUBLIC_MENTOR_FAILED,
  GET_ONE_PUBLIC_MENTOR_RESET,
  CREATE_PUBLIC_MENTOR_START,
  CREATE_PUBLIC_MENTOR_SUCCESS,
  CREATE_PUBLIC_MENTOR_FAILED,
  CREATE_PUBLIC_MENTOR_RESET,
  UPDATE_ONE_PUBLIC_MENTOR_START,
  UPDATE_ONE_PUBLIC_MENTOR_SUCCESS,
  UPDATE_ONE_PUBLIC_MENTOR_FAILED,
  UPDATE_ONE_PUBLIC_MENTOR_RESET,
  DELETE_ONE_PUBLIC_MENTOR_START,
  DELETE_ONE_PUBLIC_MENTOR_SUCCESS,
  DELETE_ONE_PUBLIC_MENTOR_FAILED,
  DELETE_ONE_PUBLIC_MENTOR_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const publicMentorsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Public Mentors
     * =======================================================================
     */
    case GET_ALL_PUBLIC_MENTORS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_PUBLIC_MENTORS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_PUBLIC_MENTORS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_PUBLIC_MENTORS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Public Mentor
     * =======================================================================
     */
    case GET_ONE_PUBLIC_MENTOR_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_PUBLIC_MENTOR_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_PUBLIC_MENTOR_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_PUBLIC_MENTOR_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Public Mentor
     * =======================================================================
     */
    case CREATE_PUBLIC_MENTOR_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_PUBLIC_MENTOR_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_PUBLIC_MENTOR_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_PUBLIC_MENTOR_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Public Mentor
     * =======================================================================
     */
    case UPDATE_ONE_PUBLIC_MENTOR_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_PUBLIC_MENTOR_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_PUBLIC_MENTOR_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_PUBLIC_MENTOR_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Public Mentor
     * =======================================================================
     */
    case DELETE_ONE_PUBLIC_MENTOR_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_PUBLIC_MENTOR_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_PUBLIC_MENTOR_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_PUBLIC_MENTOR_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default publicMentorsReducer;
