// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get all Banner
export const GET_ALL_BANNER_START = 'GET_ALL_BANNER_START';
export const GET_ALL_BANNER_SUCCESS = 'GET_ALL_BANNER_SUCCESS';
export const GET_ALL_BANNER_FAILED = 'GET_ALL_BANNER_FAILED';
export const GET_ALL_BANNER_RESET = 'GET_ALL_BANNER_RESET';

// Action Types: Get One Banner
export const GET_ONE_BANNER_START = 'GET_ONE_BANNER_START';
export const GET_ONE_BANNER_SUCCESS = 'GET_ONE_BANNER_SUCCESS';
export const GET_ONE_BANNER_FAILED = 'GET_ONE_BANNER_FAILED';
export const GET_ONE_BANNER_RESET = 'GET_ONE_BANNER_RESET';

// Action Types: Create New Banner.
export const CREATE_BANNER_START = 'CREATE_BANNER_START';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';
export const CREATE_BANNER_RESET = 'CREATE_BANNER_RESET';

// Action Types: Update One Banner.
export const UPDATE_ONE_BANNER_START = 'UPDATE_ONE_BANNER_START';
export const UPDATE_ONE_BANNER_SUCCESS = 'UPDATE_ONE_BANNER_SUCCESS';
export const UPDATE_ONE_BANNER_FAILED = 'UPDATE_ONE_BANNER_FAILED';
export const UPDATE_ONE_BANNER_RESET = 'UPDATE_ONE_BANNER_RESET';

// Action Types: Upload One Banner.
export const UPLOAD_PHOTO_BANNER_START = 'UPLOAD_PHOTO_BANNER_START';
export const UPLOAD_PHOTO_BANNER_SUCCESS = 'UPLOAD_PHOTO_BANNER_SUCCESS';
export const UPLOAD_PHOTO_BANNER_FAILED = 'UPLOAD_PHOTO_BANNER_FAILED';
export const UPLOAD_PHOTO_BANNER_RESET = 'UPLOAD_PHOTO_BANNER_RESET';

// Action Types: Delete One Banner.
export const DELETE_ONE_BANNER_START = 'DELETE_ONE_BANNER_START';
export const DELETE_ONE_BANNER_SUCCESS = 'DELETE_ONE_BANNER_SUCCESS';
export const DELETE_ONE_BANNER_FAILED = 'DELETE_ONE_BANNER_FAILED';
export const DELETE_ONE_BANNER_RESET = 'DELETE_ONE_BANNER_RESET';

// Action Creators: Get All Banner.
const getAllBannerStart = (payload) => ({
  type: GET_ALL_BANNER_START,
  payload,
});

const getAllBannerSuccess = (payload) => ({
  type: GET_ALL_BANNER_SUCCESS,
  payload,
});
const getAllBannerFailed = (payload) => ({
  type: GET_ALL_BANNER_FAILED,
  payload,
});
const getAllBannerReset = () => ({ type: GET_ALL_BANNER_RESET });

// Action Creators: Get One Banner.
const getOneBannerStart = (payload) => ({
  type: GET_ONE_BANNER_START,
  payload,
});

const getOneBannerSuccess = (payload) => ({
  type: GET_ONE_BANNER_SUCCESS,
  payload,
});

const getOneBannerFailed = (payload) => ({
  type: GET_ONE_BANNER_FAILED,
  payload,
});

const getOneBannerReset = () => ({ type: GET_ONE_BANNER_RESET });

// Action Creators: Create New Article.
const createBannerStart = (payload) => ({
  type: CREATE_BANNER_START,
  payload,
});
const createBannerSuccess = (payload) => ({
  type: CREATE_BANNER_SUCCESS,
  payload,
});
const createBannerFailed = (payload) => ({
  type: CREATE_BANNER_FAILED,
  payload,
});
const createBannerReset = () => ({ type: CREATE_BANNER_RESET });

// Action Creators: Update One Banner.
const updateOneBannerStart = (payload) => ({
  type: UPDATE_ONE_BANNER_START,
  payload,
});
const updateOneBannerSuccess = (payload) => ({
  type: UPDATE_ONE_BANNER_SUCCESS,
  payload,
});
const updateOneBannerFailed = (payload) => ({
  type: UPDATE_ONE_BANNER_FAILED,
  payload,
});
const updateOneBannerReset = () => ({ type: UPDATE_ONE_BANNER_RESET });

// Action Creators: Upload Photo Banner.
const uploadPhotoBannerStart = (payload) => ({
  type: UPLOAD_PHOTO_BANNER_START,
  payload,
});
const uploadPhotoBannerSuccess = (payload) => ({
  type: UPLOAD_PHOTO_BANNER_SUCCESS,
  payload,
});
const uploadPhotoBannerFailed = (payload) => ({
  type: UPLOAD_PHOTO_BANNER_FAILED,
  payload,
});
const uploadPhotoBannerReset = () => ({ type: UPLOAD_PHOTO_BANNER_RESET });

// Action Creators: Delete One Banner.
const deleteOneBannerStart = (payload) => ({
  type: DELETE_ONE_BANNER_START,
  payload,
});
const deleteOneBannerSuccess = (payload) => ({
  type: DELETE_ONE_BANNER_SUCCESS,
  payload,
});
const deleteOneBannerFailed = (payload) => ({
  type: DELETE_ONE_BANNER_FAILED,
  payload,
});
const deleteOneBannerReset = () => ({ type: DELETE_ONE_BANNER_RESET });

// Actions: Get All Banner.
export const getAllBanner = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllBannerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const result = await ApiClient.get('banners');

      if (result.data?.success) {
        const { banners } = result.data.data;
        dispatch(
          getAllBannerSuccess({
            loading: false,
            success: true,
            data: { banners },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Articles.
export const clearGetAllBanners = () => getAllBannerReset();

// Actions: Get One Banner.
export const getOneBanner = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneBannerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { bannerId } = payload;
      const result = await ApiClient.get(`banners/${bannerId}`);
      if (result.data?.success) {
        const { banner } = result.data.data;
        dispatch(
          getOneBannerSuccess({
            loading: false,
            success: true,
            data: { banner },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Banner.
export const clearGetOneBanner = () => getOneBannerReset();

// Actions: Create New Banner.
export const createBanner = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createBannerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enText, sqText, srText } = payload;
    const { showToast, toastNotification, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('banners', {
        enText,
        sqText,
        srText,
      });
      if (result.data?.success) {
        const { banner } = result.data.data;

        dispatch(
          createBannerSuccess({
            loading: false,
            success: true,
            data: { banner },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Banner.
export const clearCreateNewBanner = () => createBannerReset();

// Actions: Update One Banner.
export const updateOneBanner = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneBannerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enText, sqText, srText, bannerId, deletedPhotos } = payload;
    const { showToast, toastNotification, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`banners/${bannerId}`, {
        enText,
        sqText,
        srText,
        deletedPhotos,
      });
      if (result.data?.success) {
        const { banner } = result.data.data;
        dispatch(
          updateOneBannerSuccess({
            loading: false,
            success: true,
            data: { banner },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Banner.
export const clearUpdateOneBanner = () => updateOneBannerReset();

// Actions: Upload Photo Banner.
export const uploadPhotoBanner = (payload, options) => {
  return async (dispatch) => {
    dispatch(uploadPhotoBannerStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

    const { bannerId, formData } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`banners/${bannerId}/photo`, formData);
      if (result.data?.success) {
        const { banner } = result.data?.data;
        dispatch(
          uploadPhotoBannerSuccess({
            loading: false,
            success: true,
            data: { banner },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          uploadPhotoBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadPhotoBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Photo Banner.
export const clearUploadPhotoBanner = () => uploadPhotoBannerReset();

// Actions: Delete One Banner.
export const deleteOneBanner = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneBannerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { bannerId } = payload;
      const result = await ApiClient.delete(`banners/${bannerId}`);
      if (result.data?.success) {
        const { banner } = result.data.data;
        dispatch(
          deleteOneBannerSuccess({
            loading: false,
            success: true,
            data: { banner },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneBannerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneBannerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Banner.
export const clearDeleteOneBanner = () => deleteOneBannerReset();
