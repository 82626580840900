// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Ethnicities.
export const GET_ALL_ETHNICITIES_START = 'GET_ALL_ETHNICITIES_START';
export const GET_ALL_ETHNICITIES_SUCCESS = 'GET_ALL_ETHNICITIES_SUCCESS';
export const GET_ALL_ETHNICITIES_FAILED = 'GET_ALL_ETHNICITIES_FAILED';
export const GET_ALL_ETHNICITIES_RESET = 'GET_ALL_ETHNICITIES_RESET';

// Action Types: Get One Ethnicity.
export const GET_ONE_ETHNICITY_START = 'GET_ONE_ETHNICITY_START';
export const GET_ONE_ETHNICITY_SUCCESS = 'GET_ONE_ETHNICITY_SUCCESS';
export const GET_ONE_ETHNICITY_FAILED = 'GET_ONE_ETHNICITY_FAILED';
export const GET_ONE_ETHNICITY_RESET = 'GET_ONE_ETHNICITY_RESET';

// Action Types: Create New Ethnicity.
export const CREATE_ETHNICITY_START = 'CREATE_ETHNICITY_START';
export const CREATE_ETHNICITY_SUCCESS = 'CREATE_ETHNICITY_SUCCESS';
export const CREATE_ETHNICITY_FAILED = 'CREATE_ETHNICITY_FAILED';
export const CREATE_ETHNICITY_RESET = 'CREATE_ETHNICITY_RESET';

// Action Types: Update One Ethnicity.
export const UPDATE_ONE_ETHNICITY_START = 'UPDATE_ONE_ETHNICITY_START';
export const UPDATE_ONE_ETHNICITY_SUCCESS = 'UPDATE_ONE_ETHNICITY_SUCCESS';
export const UPDATE_ONE_ETHNICITY_FAILED = 'UPDATE_ONE_ETHNICITY_FAILED';
export const UPDATE_ONE_ETHNICITY_RESET = 'UPDATE_ONE_ETHNICITY_RESET';

// Action Types: Delete One Ethnicity.
export const DELETE_ONE_ETHNICITY_START = 'DELETE_ONE_ETHNICITY_START';
export const DELETE_ONE_ETHNICITY_SUCCESS = 'DELETE_ONE_ETHNICITY_SUCCESS';
export const DELETE_ONE_ETHNICITY_FAILED = 'DELETE_ONE_ETHNICITY_FAILED';
export const DELETE_ONE_ETHNICITY_RESET = 'DELETE_ONE_ETHNICITY_RESET';

// Action Creators: Get All Ethnicities.
const getAllEthnicitiesStart = (payload) => ({
  type: GET_ALL_ETHNICITIES_START,
  payload,
});
const getAllEthnicitiesSuccess = (payload) => ({
  type: GET_ALL_ETHNICITIES_SUCCESS,
  payload,
});
const getAllEthnicitiesFailed = (payload) => ({
  type: GET_ALL_ETHNICITIES_FAILED,
  payload,
});
const getAllEthnicitiesReset = () => ({ type: GET_ALL_ETHNICITIES_RESET });

// Action Creators: Get One Ethnicity.
const getOneEthnicityStart = (payload) => ({
  type: GET_ONE_ETHNICITY_START,
  payload,
});
const getOneEthnicitySuccess = (payload) => ({
  type: GET_ONE_ETHNICITY_SUCCESS,
  payload,
});
const getOneEthnicityFailed = (payload) => ({
  type: GET_ONE_ETHNICITY_FAILED,
  payload,
});
const getOneEthnicityReset = () => ({ type: GET_ONE_ETHNICITY_RESET });

// Action Creators: Create New Ethnicity.
const createEthnicityStart = (payload) => ({
  type: CREATE_ETHNICITY_START,
  payload,
});
const createEthnicitySuccess = (payload) => ({
  type: CREATE_ETHNICITY_SUCCESS,
  payload,
});
const createEthnicityFailed = (payload) => ({
  type: CREATE_ETHNICITY_FAILED,
  payload,
});
const createEthnicityReset = () => ({ type: CREATE_ETHNICITY_RESET });

// Action Creators: Update One Ethnicity.
const updateOneEthnicityStart = (payload) => ({
  type: UPDATE_ONE_ETHNICITY_START,
  payload,
});
const updateOneEthnicitySuccess = (payload) => ({
  type: UPDATE_ONE_ETHNICITY_SUCCESS,
  payload,
});
const updateOneEthnicityFailed = (payload) => ({
  type: UPDATE_ONE_ETHNICITY_FAILED,
  payload,
});
const updateOneEthnicityReset = () => ({ type: UPDATE_ONE_ETHNICITY_RESET });

// Action Creators: Delete One Ethnicity.
const deleteOneEthnicityStart = (payload) => ({
  type: DELETE_ONE_ETHNICITY_START,
  payload,
});
const deleteOneEthnicitySuccess = (payload) => ({
  type: DELETE_ONE_ETHNICITY_SUCCESS,
  payload,
});
const deleteOneEthnicityFailed = (payload) => ({
  type: DELETE_ONE_ETHNICITY_FAILED,
  payload,
});
const deleteOneEthnicityReset = () => ({ type: DELETE_ONE_ETHNICITY_RESET });

// Actions: Get All Ethnicities.
export const getAllEthnicities = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllEthnicitiesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('ethnicities', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { ethnicities } = result.data.data;
        dispatch(
          getAllEthnicitiesSuccess({
            loading: false,
            success: true,
            data: { ethnicities },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllEthnicitiesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllEthnicitiesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Ethnicities.
export const clearGetAllEthnicities = () => getAllEthnicitiesReset();

// Actions: Get One Ethnicity.
export const getOneEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { ethnicityId } = payload;
      const result = await ApiClient.get(`ethnicities/${ethnicityId}`);
      if (result.data?.success) {
        const { ethnicity } = result.data.data;
        dispatch(
          getOneEthnicitySuccess({
            loading: false,
            success: true,
            data: { ethnicity },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Ethnicity.
export const clearGetOneEthnicity = () => getOneEthnicityReset();

// Actions: Create New Ethnicity.
export const createEthnicity = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onEthnicityExistsMessage } = options;

    try {
      const result = await ApiClient.post('ethnicities', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { ethnicity } = result.data.data;
        dispatch(
          createEthnicitySuccess({
            loading: false,
            success: true,
            data: { ethnicity },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const ethnicityExists = 'ETHNICITY_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === ethnicityExists
      ) {
        toastNotification('error', onEthnicityExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Ethnicity.
export const clearCreateNewEthnicity = () => createEthnicityReset();

// Actions: Update One Ethnicity.
export const updateOneEthnicity = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { ethnicityId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`ethnicities/${ethnicityId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { ethnicity } = result.data.data;
        dispatch(
          updateOneEthnicitySuccess({
            loading: false,
            success: true,
            data: { ethnicity },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Ethnicity.
export const clearUpdateOneEthnicity = () => updateOneEthnicityReset();

// Actions: Delete One Ethnicity.
export const deleteOneEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { ethnicityId } = payload;
      const result = await ApiClient.delete(`ethnicities/${ethnicityId}`);
      if (result.data?.success) {
        const { ethnicity } = result.data.data;
        dispatch(
          deleteOneEthnicitySuccess({
            loading: false,
            success: true,
            data: { ethnicity },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Ethnicity.
export const cleareDeleteOneEthnicity = () => deleteOneEthnicityReset();
