// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Jobs.
export const GET_ALL_JOBS_START = 'GET_ALL_JOBS_START';
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAILED = 'GET_ALL_JOBS_FAILED';
export const GET_ALL_JOBS_RESET = 'GET_ALL_JOBS_RESET';

// Action Types: Get One Job.
export const GET_ONE_JOB_START = 'GET_ONE_JOB_START';
export const GET_ONE_JOB_SUCCESS = 'GET_ONE_JOB_SUCCESS';
export const GET_ONE_JOB_FAILED = 'GET_ONE_JOB_FAILED';
export const GET_ONE_JOB_RESET = 'GET_ONE_JOB_RESET';

// Action Types: Create New Job.
export const CREATE_JOB_START = 'CREATE_JOB_START';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILED = 'CREATE_JOB_FAILED';
export const CREATE_JOB_RESET = 'CREATE_JOB_RESET';

// Action Types: Update One Job.
export const UPDATE_ONE_JOB_START = 'UPDATE_ONE_JOB_START';
export const UPDATE_ONE_JOB_SUCCESS = 'UPDATE_ONE_JOB_SUCCESS';
export const UPDATE_ONE_JOB_FAILED = 'UPDATE_ONE_JOB_FAILED';
export const UPDATE_ONE_JOB_RESET = 'UPDATE_ONE_JOB_RESET';

// Action Types: Delete One Job.
export const DELETE_ONE_JOB_START = 'DELETE_ONE_JOB_START';
export const DELETE_ONE_JOB_SUCCESS = 'DELETE_ONE_JOB_SUCCESS';
export const DELETE_ONE_JOB_FAILED = 'DELETE_ONE_JOB_FAILED';
export const DELETE_ONE_JOB_RESET = 'DELETE_ONE_JOB_RESET';

// Action Types: Get All Matches For One Job
export const GET_ALL_MATCHES_START = 'GET_ALL_MATCHES_START';
export const GET_ALL_MATCHES_SUCCESS = 'GET_ALL_MATCHES_SUCCESS';
export const GET_ALL_MATCHES_FAILED = 'GET_ALL_MATCHES_FAILED';
export const GET_ALL_MATCHES_RESET = 'GET_ALL_MATCHES_RESET';

// Action Types: Get All Matches For One Job
export const GET_INTERN_MATCHES_START = 'GET_INTERN_MATCHES_START';
export const GET_INTERN_MATCHES_SUCCESS = 'GET_INTERN_MATCHES_SUCCESS';
export const GET_INTERN_MATCHES_FAILED = 'GET_INTERN_MATCHES_FAILED';
export const GET_INTERN_MATCHES_RESET = 'GET_INTERN_MATCHES_RESET';

// Action Types: Invite One Intern
export const INVITE_ONE_INTERN_START = 'INVITE_ONE_INTERN_START';
export const INVITE_ONE_INTERN_SUCCESS = 'INVITE_ONE_INTERN_SUCCESS';
export const INVITE_ONE_INTERN_FAILED = 'INVITE_ONE_INTERN_FAILED';
export const INVITE_ONE_INTERN_RESET = 'INVITE_ONE_INTERN_RESET';

// Action Creators: Get All Jobs.
const getAllJobsStart = (payload) => ({
  type: GET_ALL_JOBS_START,
  payload,
});
const getAllJobsSuccess = (payload) => ({
  type: GET_ALL_JOBS_SUCCESS,
  payload,
});
const getAllJobsFailed = (payload) => ({
  type: GET_ALL_JOBS_FAILED,
  payload,
});
const getAllJobsReset = () => ({ type: GET_ALL_JOBS_RESET });

// Action Creators: Get One Job.
const getOneJobStart = (payload) => ({
  type: GET_ONE_JOB_START,
  payload,
});
const getOneJobSuccess = (payload) => ({
  type: GET_ONE_JOB_SUCCESS,
  payload,
});
const getOneJobFailed = (payload) => ({
  type: GET_ONE_JOB_FAILED,
  payload,
});
const getOneJobReset = () => ({ type: GET_ONE_JOB_RESET });

// Action Creators: Create New Job.
const createJobStart = (payload) => ({
  type: CREATE_JOB_START,
  payload,
});
const createJobSuccess = (payload) => ({
  type: CREATE_JOB_SUCCESS,
  payload,
});
const createJobFailed = (payload) => ({
  type: CREATE_JOB_FAILED,
  payload,
});
const createJobReset = () => ({ type: CREATE_JOB_RESET });

// Action Creators: Update One Job.
const updateOneJobStart = (payload) => ({
  type: UPDATE_ONE_JOB_START,
  payload,
});
const updateOneJobSuccess = (payload) => ({
  type: UPDATE_ONE_JOB_SUCCESS,
  payload,
});
const updateOneJobFailed = (payload) => ({
  type: UPDATE_ONE_JOB_FAILED,
  payload,
});
const updateOneJobReset = () => ({ type: UPDATE_ONE_JOB_RESET });

// Action Creators: Delete One Job.
const deleteOneJobStart = (payload) => ({
  type: DELETE_ONE_JOB_START,
  payload,
});
const deleteOneJobSuccess = (payload) => ({
  type: DELETE_ONE_JOB_SUCCESS,
  payload,
});
const deleteOneJobFailed = (payload) => ({
  type: DELETE_ONE_JOB_FAILED,
  payload,
});
const deleteOneJobReset = () => ({ type: DELETE_ONE_JOB_RESET });

// Action Creators: Get All Matches
const getAllMatchesStart = (payload) => ({
  type: GET_ALL_MATCHES_START,
  payload,
});
const getAllMatchesSuccess = (payload) => ({
  type: GET_ALL_MATCHES_SUCCESS,
  payload,
});
const getAllMatchesFailed = (payload) => ({
  type: GET_ALL_MATCHES_FAILED,
  payload,
});
const getAllMatchesReset = () => ({ type: GET_ALL_MATCHES_RESET });

// Action Creators: Get All Matches
const getInternMatchesStart = (payload) => ({
  type: GET_INTERN_MATCHES_START,
  payload,
});
const getInternMatchesSuccess = (payload) => ({
  type: GET_INTERN_MATCHES_SUCCESS,
  payload,
});
const getInternMatchesFailed = (payload) => ({
  type: GET_INTERN_MATCHES_FAILED,
  payload,
});
const getInternMatchesReset = () => ({ type: GET_INTERN_MATCHES_RESET });

// Action Creators: Invite One Intern
const inviteOneInternStart = (payload) => ({
  type: INVITE_ONE_INTERN_START,
  payload,
});
const inviteOneInternSuccess = (payload) => ({
  type: INVITE_ONE_INTERN_SUCCESS,
  payload,
});
const inviteOneInternFailed = (payload) => ({
  type: INVITE_ONE_INTERN_FAILED,
  payload,
});
const inviteOneInternReset = () => ({ type: INVITE_ONE_INTERN_RESET });

// Actions: Get All Jobs.
export const getAllJobs = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllJobsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        categories,
        skills,
        municipality,
        business,
        createdSort,
        deadlineSort,
        incomingJobs,
        training,
        title,
        language,
      } = payload;
      const result = await ApiClient.get('jobs', {
        params: {
          page,
          limit,
          pagination,
          categories,
          skills,
          municipality,
          business,
          createdSort,
          deadlineSort,
          incomingJobs,
          training,
          title,
          language,
        },
      });
      if (result.data?.success) {
        const { jobPostings } = result.data.data;
        dispatch(
          getAllJobsSuccess({
            loading: false,
            success: true,
            data: { jobPostings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllJobsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllJobsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Jobs.
export const clearGetAllJobs = () => getAllJobsReset();

// Actions: Get One Job.
export const getOneJob = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneJobStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { jobId } = payload;
      const result = await ApiClient.get(`jobs/${jobId}`);
      if (result.data?.success) {
        const { jobPosting } = result.data.data;
        dispatch(
          getOneJobSuccess({
            loading: false,
            success: true,
            data: { jobPosting },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Job.
export const clearGetOneJob = () => getOneJobReset();

// Actions: Create New Job.
export const createJob = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createJobStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      title,
      body,
      deadline,
      categories,
      skills,
      municipality,
      engagementLength,
      language,
      training,
      trainingType,
      trainingDescription,
      trainingLength,
      business,
      files,
      availableSpots,
    } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('jobs', {
        title,
        body,
        deadline,
        categories,
        skills,
        municipality,
        lengthOfEngagement: engagementLength,
        training,
        language,
        trainingType,
        trainingDescription,
        trainingLength,
        business,
        files,
        availableSpots,
      });
      if (result.data?.success) {
        const { jobPosting } = result.data.data;
        dispatch(
          createJobSuccess({
            loading: false,
            success: true,
            data: { jobPosting },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Job.
export const clearCreateNewJob = () => createJobReset();

// Actions: Update One Job.
export const updateOneJob = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneJobStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      jobId,
      title,
      body,
      deadline,
      categories,
      skills,
      municipality,
      engagementLength,
      language,
      training,
      trainingType,
      trainingDescription,
      trainingLength,
      files,
      business,
      availableSpots,
    } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`jobs/${jobId}`, {
        title,
        body,
        deadline,
        categories,
        skills,
        municipality,
        lengthOfEngagement: engagementLength,
        language,
        training,
        trainingType,
        trainingDescription,
        trainingLength,
        files,
        business,
        availableSpots,
      });
      if (result.data?.success) {
        const { jobPosting } = result.data.data;
        dispatch(
          updateOneJobSuccess({
            loading: false,
            success: true,
            data: { jobPosting },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Job.
export const clearUpdateOneJob = () => updateOneJobReset();

// Actions: Delete One Job.
export const deleteOneJob = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneJobStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { jobId } = payload;
      const result = await ApiClient.delete(`jobs/${jobId}`);
      if (result.data?.success) {
        const { jobPosting } = result.data.data;
        dispatch(
          deleteOneJobSuccess({
            loading: false,
            success: true,
            data: { jobPosting },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Job.
export const clearDeleteOneJob = () => deleteOneJobReset();

// Actions: Get All Matches.
export const getAllMatches = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMatchesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { jobId, page, limit, pagination, sort, internName } = payload;
      const result = await ApiClient.get(`jobs/${jobId}/matches`, {
        params: { page, limit, pagination, sort, internName },
      });
      if (result.data?.success) {
        const { matches } = result.data.data;
        dispatch(
          getAllMatchesSuccess({
            loading: false,
            success: true,
            data: { matches },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMatchesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMatchesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Matches.
export const clearGetAllMatches = () => getAllMatchesReset();

// Actions: Get Intern Matches.
export const getInternMatches = (payload) => {
  return async (dispatch) => {
    dispatch(
      getInternMatchesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        internId,
        page,
        limit,
        pagination,
        categories,
        skills,
        municipality,
        business,
        sort = 'desc',
        incomingJobs,
        training,
        title,
        language,
      } = payload;
      const result = await ApiClient.get(`jobs/intern/${internId}/matches`, {
        params: {
          page,
          limit,
          pagination,
          categories,
          skills,
          municipality,
          business,
          sort,
          incomingJobs,
          training,
          title,
          language,
        },
      });
      if (result.data?.success) {
        const { jobPostings } = result.data.data;
        dispatch(
          getInternMatchesSuccess({
            loading: false,
            success: true,
            data: { jobPostings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getInternMatchesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getInternMatchesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get Intern Matches.
export const clearGetInternMatches = () => getInternMatchesReset();

// Actions: Invite One Intern.
export const inviteOneIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      inviteOneInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onInviteFailMessage } = options;

    try {
      const { jobId, inviteeId, emailLanguage } = payload;
      const result = await ApiClient.put(`jobs/${jobId}/invite/${inviteeId}`, { emailLanguage });
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          inviteOneInternSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          inviteOneInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        inviteOneInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'JOB_EXPIRED'
      ) {
        toastNotification('error', onInviteFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Invite One Intern.
export const clearInviteOneIntern = () => inviteOneInternReset();
