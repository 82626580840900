// Imports: local files.
import {
  GET_ALL_TAGS_START,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILED,
  GET_ALL_TAGS_RESET,
  GET_ONE_TAG_START,
  GET_ONE_TAG_SUCCESS,
  GET_ONE_TAG_FAILED,
  GET_ONE_TAG_RESET,
  CREATE_TAG_START,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILED,
  CREATE_TAG_RESET,
  UPDATE_ONE_TAG_START,
  UPDATE_ONE_TAG_SUCCESS,
  UPDATE_ONE_TAG_FAILED,
  UPDATE_ONE_TAG_RESET,
  DELETE_ONE_TAG_START,
  DELETE_ONE_TAG_SUCCESS,
  DELETE_ONE_TAG_FAILED,
  DELETE_ONE_TAG_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Tags
     * =======================================================================
     */
    case GET_ALL_TAGS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TAGS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TAGS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TAGS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Tag
     * =======================================================================
     */
    case GET_ONE_TAG_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TAG_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TAG_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TAG_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Tag
     * =======================================================================
     */
    case CREATE_TAG_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_TAG_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_TAG_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_TAG_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Tag
     * =======================================================================
     */
    case UPDATE_ONE_TAG_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TAG_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TAG_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TAG_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Tag
     * =======================================================================
     */
    case DELETE_ONE_TAG_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TAG_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TAG_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TAG_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default tagsReducer;
