// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Counts.
export const COUNTS_START = 'COUNTS_START';
export const COUNTS_SUCCESS = 'COUNTS_SUCCESS';
export const COUNTS_FAILED = 'COUNTS_FAILED';
export const COUNTS_RESET = 'COUNTS_RESET';

// Action Types: Internship stats.
export const INTERNSHIPS_START = 'INTERNSHIPS_START';
export const INTERNSHIPS_SUCCESS = 'INTERNSHIPS_SUCCESS';
export const INTERNSHIPS_FAILED = 'INTERNSHIPS_FAILED';
export const INTERNSHIPS_RESET = 'INTERNSHIPS_RESET';

// Action Types: Information about Programme stats.
export const PROGRAMME_INFO_START = 'PROGRAMME_INFO_START';
export const PROGRAMME_INFO_SUCCESS = 'PROGRAMME_INFO_SUCCESS';
export const PROGRAMME_INFO_FAILED = 'PROGRAMME_INFO_FAILED';
export const PROGRAMME_INFO_RESET = 'PROGRAMME_INFO_RESET';

// Action Types: Intern By Municipality stats.
export const INTERNS_BY_MUNICIPALITY_START = 'INTERNS_BY_MUNICIPALITY_START';
export const INTERNS_BY_MUNICIPALITY_SUCCESS = 'INTERNS_BY_MUNICIPALITY_SUCCESS';
export const INTERNS_BY_MUNICIPALITY_FAILED = 'INTERNS_BY_MUNICIPALITY_FAILED';
export const INTERNS_BY_MUNICIPALITY_RESET = 'INTERNS_BY_MUNICIPALITY_RESET';

// Action Types: Intern By Skills stats.
export const INTERNS_BY_SKILLS_START = 'INTERNS_BY_SKILLS_START';
export const INTERNS_BY_SKILLS_SUCCESS = 'INTERNS_BY_SKILLS_SUCCESS';
export const INTERNS_BY_SKILLS_FAILED = 'INTERNS_BY_SKILLS_FAILED';
export const INTERNS_BY_SKILLS_RESET = 'INTERNS_BY_SKILLS_RESET';

// Action Types: Intern By Categories stats.
export const INTERNS_BY_CATEGORIES_START = 'INTERNS_BY_CATEGORIES_START';
export const INTERNS_BY_CATEGORIES_SUCCESS = 'INTERNS_BY_CATEGORIES_SUCCESS';
export const INTERNS_BY_CATEGORIES_FAILED = 'INTERNS_BY_CATEGORIES_FAILED';
export const INTERNS_BY_CATEGORIES_RESET = 'INTERNS_BY_CATEGORIES_RESET';

// Action Types: Intern By Gender stats.
export const INTERNS_BY_GENDER_START = 'INTERNS_BY_GENDER_START';
export const INTERNS_BY_GENDER_SUCCESS = 'INTERNS_BY_GENDER_SUCCESS';
export const INTERNS_BY_GENDER_FAILED = 'INTERNS_BY_GENDER_FAILED';
export const INTERNS_BY_GENDER_RESET = 'INTERNS_BY_GENDER_RESET';

// Action Types: Intern By Age stats.
export const INTERNS_BY_AGE_START = 'INTERNS_BY_AGE_START';
export const INTERNS_BY_AGE_SUCCESS = 'INTERNS_BY_AGE_SUCCESS';
export const INTERNS_BY_AGE_FAILED = 'INTERNS_BY_AGE_FAILED';
export const INTERNS_BY_AGE_RESET = 'INTERNS_BY_AGE_RESET';

// Action Types: Intern By Ethnicity stats.
export const INTERNS_BY_ETHNICITY_START = 'INTERNS_BY_ETHNICITY_START';
export const INTERNS_BY_ETHNICITY_SUCCESS = 'INTERNS_BY_ETHNICITY_SUCCESS';
export const INTERNS_BY_ETHNICITY_FAILED = 'INTERNS_BY_ETHNICITY_FAILED';
export const INTERNS_BY_ETHNICITY_RESET = 'INTERNS_BY_ETHNICITY_RESET';

// Action Types: Intern By Health Condition stats.
export const INTERNS_BY_HEALTH_CONDITION_START = 'INTERNS_BY_HEALTH_CONDITION_START';
export const INTERNS_BY_HEALTH_CONDITION_SUCCESS = 'INTERNS_BY_HEALTH_CONDITION_SUCCESS';
export const INTERNS_BY_HEALTH_CONDITION_FAILED = 'INTERNS_BY_HEALTH_CONDITION_FAILED';
export const INTERNS_BY_HEALTH_CONDITION_RESET = 'INTERNS_BY_HEALTH_CONDITION_RESET';

// Action Types: Businesses By Municipality stats.
export const BUSINESSES_BY_MUNICIPALITY_START = 'BUSINESSES_BY_MUNICIPALITY_START';
export const BUSINESSES_BY_MUNICIPALITY_SUCCESS = 'BUSINESSES_BY_MUNICIPALITY_SUCCESS';
export const BUSINESSES_BY_MUNICIPALITY_FAILED = 'BUSINESSES_BY_MUNICIPALITY_FAILED';
export const BUSINESSES_BY_MUNICIPALITY_RESET = 'BUSINESSES_BY_MUNICIPALITY_RESET';

// Action Types: Businesses By Type stats.
export const BUSINESSES_BY_TYPE_START = 'BUSINESSES_BY_TYPE_START';
export const BUSINESSES_BY_TYPE_SUCCESS = 'BUSINESSES_BY_TYPE_SUCCESS';
export const BUSINESSES_BY_TYPE_FAILED = 'BUSINESSES_BY_TYPE_FAILED';
export const BUSINESSES_BY_TYPE_RESET = 'BUSINESSES_BY_TYPE_RESET';

// Action Types: Businesses By Categories stats.
export const BUSINESSES_BY_CATEGORIES_START = 'BUSINESSES_BY_CATEGORIES_START';
export const BUSINESSES_BY_CATEGORIES_SUCCESS = 'BUSINESSES_BY_CATEGORIES_SUCCESS';
export const BUSINESSES_BY_CATEGORIES_FAILED = 'BUSINESSES_BY_CATEGORIES_FAILED';
export const BUSINESSES_BY_CATEGORIES_RESET = 'BUSINESSES_BY_CATEGORIES_RESET';

// Action Types: Mentors By Municipality stats.
export const MENTORS_BY_MUNICIPALITY_START = 'MENTORS_BY_MUNICIPALITY_START';
export const MENTORS_BY_MUNICIPALITY_SUCCESS = 'MENTORS_BY_MUNICIPALITY_SUCCESS';
export const MENTORS_BY_MUNICIPALITY_FAILED = 'MENTORS_BY_MUNICIPALITY_FAILED';
export const MENTORS_BY_MUNICIPALITY_RESET = 'MENTORS_BY_MUNICIPALITY_RESET';

// Action Types: Mentors By Skills stats.
export const MENTORS_BY_SKILLS_START = 'MENTORS_BY_SKILLS_START';
export const MENTORS_BY_SKILLS_SUCCESS = 'MENTORS_BY_SKILLS_SUCCESS';
export const MENTORS_BY_SKILLS_FAILED = 'MENTORS_BY_SKILLS_FAILED';
export const MENTORS_BY_SKILLS_RESET = 'MENTORS_BY_SKILLS_RESET';

// Action Types: Mentors By Categories stats.
export const MENTORS_BY_CATEGORIES_START = 'MENTORS_BY_CATEGORIES_START';
export const MENTORS_BY_CATEGORIES_SUCCESS = 'MENTORS_BY_CATEGORIES_SUCCESS';
export const MENTORS_BY_CATEGORIES_FAILED = 'MENTORS_BY_CATEGORIES_FAILED';
export const MENTORS_BY_CATEGORIES_RESET = 'MENTORS_BY_CATEGORIES_RESET';

// Action Types: Mentors By Gender stats.
export const MENTORS_BY_GENDER_START = 'MENTORS_BY_GENDER_START';
export const MENTORS_BY_GENDER_SUCCESS = 'MENTORS_BY_GENDER_SUCCESS';
export const MENTORS_BY_GENDER_FAILED = 'MENTORS_BY_GENDER_FAILED';
export const MENTORS_BY_GENDER_RESET = 'MENTORS_BY_GENDER_RESET';

// Action Types: Mentors By Interns stats.
export const MENTORS_BY_INTERNS_START = 'MENTORS_BY_INTERNS_START';
export const MENTORS_BY_INTERNS_SUCCESS = 'MENTORS_BY_INTERNS_SUCCESS';
export const MENTORS_BY_INTERNS_FAILED = 'MENTORS_BY_INTERNS_FAILED';
export const MENTORS_BY_INTERNS_RESET = 'MENTORS_BY_INTERNS_RESET';

// Action Types: Mentor By Ethnicity stats.
export const MENTORS_BY_ETHNICITY_START = 'MENTORS_BY_ETHNICITY_START';
export const MENTORS_BY_ETHNICITY_SUCCESS = 'MENTORS_BY_ETHNICITY_SUCCESS';
export const MENTORS_BY_ETHNICITY_FAILED = 'MENTORS_BY_ETHNICITY_FAILED';
export const MENTORS_BY_ETHNICITY_RESET = 'MENTORS_BY_ETHNICITY_RESET';

// Action Types: Mentor By Health Condition stats.
export const MENTORS_BY_HEALTH_CONDITION_START = 'MENTORS_BY_HEALTH_CONDITION_START';
export const MENTORS_BY_HEALTH_CONDITION_SUCCESS = 'MENTORS_BY_HEALTH_CONDITION_SUCCESS';
export const MENTORS_BY_HEALTH_CONDITION_FAILED = 'MENTORS_BY_HEALTH_CONDITION_FAILED';
export const MENTORS_BY_HEALTH_CONDITION_RESET = 'MENTORS_BY_HEALTH_CONDITION_RESET';

// Action Types: Applications By Municipality stats.
export const APPLICATIONS_BY_MUNICIPALITY_START = 'APPLICATIONS_BY_MUNICIPALITY_START';
export const APPLICATIONS_BY_MUNICIPALITY_SUCCESS = 'APPLICATIONS_BY_MUNICIPALITY_SUCCESS';
export const APPLICATIONS_BY_MUNICIPALITY_FAILED = 'APPLICATIONS_BY_MUNICIPALITY_FAILED';
export const APPLICATIONS_BY_MUNICIPALITY_RESET = 'APPLICATIONS_BY_MUNICIPALITY_RESET';

// Action Types: Applications By Status stats.
export const APPLICATIONS_BY_STATUS_START = 'APPLICATIONS_BY_STATUS_START';
export const APPLICATIONS_BY_STATUS_SUCCESS = 'APPLICATIONS_BY_STATUS_SUCCESS';
export const APPLICATIONS_BY_STATUS_FAILED = 'APPLICATIONS_BY_STATUS_FAILED';
export const APPLICATIONS_BY_STATUS_RESET = 'APPLICATIONS_BY_STATUS_RESET';

// Action Types: Applications By Business stats.
export const APPLICATIONS_BY_BUSINESS_START = 'APPLICATIONS_BY_BUSINESS_START';
export const APPLICATIONS_BY_BUSINESS_SUCCESS = 'APPLICATIONS_BY_BUSINESS_SUCCESS';
export const APPLICATIONS_BY_BUSINESS_FAILED = 'APPLICATIONS_BY_BUSINESS_FAILED';
export const APPLICATIONS_BY_BUSINESS_RESET = 'APPLICATIONS_BY_BUSINESS_RESET';

// Action Types: Applications By Job stats.
export const APPLICATIONS_BY_JOB_START = 'APPLICATIONS_BY_JOB_START';
export const APPLICATIONS_BY_JOB_SUCCESS = 'APPLICATIONS_BY_JOB_SUCCESS';
export const APPLICATIONS_BY_JOB_FAILED = 'APPLICATIONS_BY_JOB_FAILED';
export const APPLICATIONS_BY_JOB_RESET = 'APPLICATIONS_BY_JOB_RESET';

// Action Types: Applications By Gender stats.
export const APPLICATIONS_BY_GENDER_START = 'APPLICATIONS_BY_GENDER_START';
export const APPLICATIONS_BY_GENDER_SUCCESS = 'APPLICATIONS_BY_GENDER_SUCCESS';
export const APPLICATIONS_BY_GENDER_FAILED = 'APPLICATIONS_BY_GENDER_FAILED';
export const APPLICATIONS_BY_GENDER_RESET = 'APPLICATIONS_BY_GENDER_RESET';

// Action Types: Applications By Age stats.
export const APPLICATIONS_BY_AGE_START = 'APPLICATIONS_BY_AGE_START';
export const APPLICATIONS_BY_AGE_SUCCESS = 'APPLICATIONS_BY_AGE_SUCCESS';
export const APPLICATIONS_BY_AGE_FAILED = 'APPLICATIONS_BY_AGE_FAILED';
export const APPLICATIONS_BY_AGE_RESET = 'APPLICATIONS_BY_AGE_RESET';

// Action Types: Applications By Ethnicity stats.
export const APPLICATIONS_BY_ETHNICITY_START = 'APPLICATIONS_BY_ETHNICITY_START';
export const APPLICATIONS_BY_ETHNICITY_SUCCESS = 'APPLICATIONS_BY_ETHNICITY_SUCCESS';
export const APPLICATIONS_BY_ETHNICITY_FAILED = 'APPLICATIONS_BY_ETHNICITY_FAILED';
export const APPLICATIONS_BY_ETHNICITY_RESET = 'APPLICATIONS_BY_ETHNICITY_RESET';

// Action Types: Applications By Health Condition stats.
export const APPLICATIONS_BY_HEALTH_CONDITION_START = 'APPLICATIONS_BY_HEALTH_CONDITION_START';
export const APPLICATIONS_BY_HEALTH_CONDITION_SUCCESS = 'APPLICATIONS_BY_HEALTH_CONDITION_SUCCESS';
export const APPLICATIONS_BY_HEALTH_CONDITION_FAILED = 'APPLICATIONS_BY_HEALTH_CONDITION_FAILED';
export const APPLICATIONS_BY_HEALTH_CONDITION_RESET = 'APPLICATIONS_BY_HEALTH_CONDITION_RESET';

// Action Types: Applications By Categories stats.
export const APPLICATIONS_BY_CATEGORY_START = 'APPLICATIONS_BY_CATEGORY_START';
export const APPLICATIONS_BY_CATEGORY_SUCCESS = 'APPLICATIONS_BY_CATEGORY_SUCCESS';
export const APPLICATIONS_BY_CATEGORY_FAILED = 'APPLICATIONS_BY_CATEGORY_FAILED';
export const APPLICATIONS_BY_CATEGORY_RESET = 'APPLICATIONS_BY_CATEGORY_RESET';

// Action Types: ENGAGEMENTS By Municipality stats.
export const ENGAGEMENTS_BY_MUNICIPALITY_START = 'ENGAGEMENTS_BY_MUNICIPALITY_START';
export const ENGAGEMENTS_BY_MUNICIPALITY_SUCCESS = 'ENGAGEMENTS_BY_MUNICIPALITY_SUCCESS';
export const ENGAGEMENTS_BY_MUNICIPALITY_FAILED = 'ENGAGEMENTS_BY_MUNICIPALITY_FAILED';
export const ENGAGEMENTS_BY_MUNICIPALITY_RESET = 'ENGAGEMENTS_BY_MUNICIPALITY_RESET';

// Action Types: ENGAGEMENTS By Business stats.
export const ENGAGEMENTS_BY_BUSINESS_START = 'ENGAGEMENTS_BY_BUSINESS_START';
export const ENGAGEMENTS_BY_BUSINESS_SUCCESS = 'ENGAGEMENTS_BY_BUSINESS_SUCCESS';
export const ENGAGEMENTS_BY_BUSINESS_FAILED = 'ENGAGEMENTS_BY_BUSINESS_FAILED';
export const ENGAGEMENTS_BY_BUSINESS_RESET = 'ENGAGEMENTS_BY_BUSINESS_RESET';

// Action Types: ENGAGEMENTS By Gender stats.
export const ENGAGEMENTS_BY_GENDER_START = 'ENGAGEMENTS_BY_GENDER_START';
export const ENGAGEMENTS_BY_GENDER_SUCCESS = 'ENGAGEMENTS_BY_GENDER_SUCCESS';
export const ENGAGEMENTS_BY_GENDER_FAILED = 'ENGAGEMENTS_BY_GENDER_FAILED';
export const ENGAGEMENTS_BY_GENDER_RESET = 'ENGAGEMENTS_BY_GENDER_RESET';

// Action Types: ENGAGEMENTS By Age stats.
export const ENGAGEMENTS_BY_AGE_START = 'ENGAGEMENTS_BY_AGE_START';
export const ENGAGEMENTS_BY_AGE_SUCCESS = 'ENGAGEMENTS_BY_AGE_SUCCESS';
export const ENGAGEMENTS_BY_AGE_FAILED = 'ENGAGEMENTS_BY_AGE_FAILED';
export const ENGAGEMENTS_BY_AGE_RESET = 'ENGAGEMENTS_BY_AGE_RESET';

// Action Types: ENGAGEMENTS By Skills stats.
export const ENGAGEMENTS_BY_SKILLS_START = 'ENGAGEMENTS_BY_SKILLS_START';
export const ENGAGEMENTS_BY_SKILLS_SUCCESS = 'ENGAGEMENTS_BY_SKILLS_SUCCESS';
export const ENGAGEMENTS_BY_SKILLS_FAILED = 'ENGAGEMENTS_BY_SKILLS_FAILED';
export const ENGAGEMENTS_BY_SKILLS_RESET = 'ENGAGEMENTS_BY_SKILLS_RESET';

// Action Types: ENGAGEMENTS By Categories stats.
export const ENGAGEMENTS_BY_CATEGORIES_START = 'ENGAGEMENTS_BY_CATEGORIES_START';
export const ENGAGEMENTS_BY_CATEGORIES_SUCCESS = 'ENGAGEMENTS_BY_CATEGORIES_SUCCESS';
export const ENGAGEMENTS_BY_CATEGORIES_FAILED = 'ENGAGEMENTS_BY_CATEGORIES_FAILED';
export const ENGAGEMENTS_BY_CATEGORIES_RESET = 'ENGAGEMENTS_BY_CATEGORIES_RESET';

// Action Types: Engagements By Ethnicity stats.
export const ENGAGEMENTS_BY_ETHNICITY_START = 'ENGAGEMENTS_BY_ETHNICITY_START';
export const ENGAGEMENTS_BY_ETHNICITY_SUCCESS = 'ENGAGEMENTS_BY_ETHNICITY_SUCCESS';
export const ENGAGEMENTS_BY_ETHNICITY_FAILED = 'ENGAGEMENTS_BY_ETHNICITY_FAILED';
export const ENGAGEMENTS_BY_ETHNICITY_RESET = 'ENGAGEMENTS_BY_ETHNICITY_RESET';

// Action Types: Engagements By Health Condition stats.
export const ENGAGEMENTS_BY_HEALTH_CONDITION_START = 'ENGAGEMENTS_BY_HEALTH_CONDITION_START';
export const ENGAGEMENTS_BY_HEALTH_CONDITION_SUCCESS = 'ENGAGEMENTS_BY_HEALTH_CONDITION_SUCCESS';
export const ENGAGEMENTS_BY_HEALTH_CONDITION_FAILED = 'ENGAGEMENTS_BY_HEALTH_CONDITION_FAILED';
export const ENGAGEMENTS_BY_HEALTH_CONDITION_RESET = 'ENGAGEMENTS_BY_HEALTH_CONDITION_RESET';

// Action Types: Jobs By Municipality stats.
export const JOBS_BY_MUNICIPALITY_START = 'JOBS_BY_MUNICIPALITY_START';
export const JOBS_BY_MUNICIPALITY_SUCCESS = 'JOBS_BY_MUNICIPALITY_SUCCESS';
export const JOBS_BY_MUNICIPALITY_FAILED = 'JOBS_BY_MUNICIPALITY_FAILED';
export const JOBS_BY_MUNICIPALITY_RESET = 'JOBS_BY_MUNICIPALITY_RESET';

// Action Types: Jobs By Skills stats.
export const JOBS_BY_SKILLS_START = 'JOBS_BY_SKILLS_START';
export const JOBS_BY_SKILLS_SUCCESS = 'JOBS_BY_SKILLS_SUCCESS';
export const JOBS_BY_SKILLS_FAILED = 'JOBS_BY_SKILLS_FAILED';
export const JOBS_BY_SKILLS_RESET = 'JOBS_BY_SKILLS_RESET';

// Action Types: Jobs By Categories stats.
export const JOBS_BY_CATEGORIES_START = 'JOBS_BY_CATEGORIES_START';
export const JOBS_BY_CATEGORIES_SUCCESS = 'JOBS_BY_CATEGORIES_SUCCESS';
export const JOBS_BY_CATEGORIES_FAILED = 'JOBS_BY_CATEGORIES_FAILED';
export const JOBS_BY_CATEGORIES_RESET = 'JOBS_BY_CATEGORIES_RESET';

// Action Types: Jobs By Business stats.
export const JOBS_BY_BUSINESS_START = 'JOBS_BY_BUSINESS_START';
export const JOBS_BY_BUSINESS_SUCCESS = 'JOBS_BY_BUSINESS_SUCCESS';
export const JOBS_BY_BUSINESS_FAILED = 'JOBS_BY_BUSINESS_FAILED';
export const JOBS_BY_BUSINESS_RESET = 'JOBS_BY_BUSINESS_RESET';

// Action Types: Jobs By Spots stats.
export const JOBS_BY_SPOTS_START = 'JOBS_BY_SPOTS_START';
export const JOBS_BY_SPOTS_SUCCESS = 'JOBS_BY_SPOTS_SUCCESS';
export const JOBS_BY_SPOTS_FAILED = 'JOBS_BY_SPOTS_FAILED';
export const JOBS_BY_SPOTS_RESET = 'JOBS_BY_SPOTS_RESET';

//Action Types: Counts Data
export const COUNTS_DATA_START = 'COUNTS_DATA_START';
export const COUNTS_DATA_SUCCESS = 'COUNTS_DATA_SUCCESS';
export const COUNTS_DATA_FAILED = 'COUNTS_DATA_FAILED';
export const COUNTS_DATA_RESET = 'COUNTS_DATA_RESET';

// Action Types: Trainings By Applications stats.
export const TRAININGS_BY_APPLICATIONS_START = 'TRAININGS_BY_APPLICATIONS_START';
export const TRAININGS_BY_APPLICATIONS_SUCCESS = 'TRAININGS_BY_APPLICATIONS_SUCCESS';
export const TRAININGS_BY_APPLICATIONS_FAILED = 'TRAININGS_BY_APPLICATIONS_FAILED';
export const TRAININGS_BY_APPLICATIONS_RESET = 'TRAININGS_BY_APPLICATIONS_RESET';

// Action Types: Trainings By Spots stats.
export const TRAININGS_BY_SPOTS_START = 'TRAININGS_BY_SPOTS_START';
export const TRAININGS_BY_SPOTS_SUCCESS = 'TRAININGS_BY_SPOTS_SUCCESS';
export const TRAININGS_BY_SPOTS_FAILED = 'TRAININGS_BY_SPOTS_FAILED';
export const TRAININGS_BY_SPOTS_RESET = 'TRAININGS_BY_SPOTS_RESET';

// Action Types: Trainings By Categories stats.
export const TRAININGS_BY_CATEGORIES_START = 'TRAININGS_BY_CATEGORIES_START';
export const TRAININGS_BY_CATEGORIES_SUCCESS = 'TRAININGS_BY_CATEGORIES_SUCCESS';
export const TRAININGS_BY_CATEGORIES_FAILED = 'TRAININGS_BY_CATEGORIES_FAILED';
export const TRAININGS_BY_CATEGORIES_RESET = 'TRAININGS_BY_CATEGORIES_RESET';

// Action Types: Trainings By Engagements stats.
export const TRAININGS_BY_ENGAGEMENTS_START = 'TRAININGS_BY_ENGAGEMENTS_START';
export const TRAININGS_BY_ENGAGEMENTS_SUCCESS = 'TRAININGS_BY_ENGAGEMENTS_SUCCESS';
export const TRAININGS_BY_ENGAGEMENTS_FAILED = 'TRAININGS_BY_ENGAGEMENTS_FAILED';
export const TRAININGS_BY_ENGAGEMENTS_RESET = 'TRAININGS_BY_ENGAGEMENTS_RESET';

// Action Types: Trainings By Engagements stats.
export const TRAININGS_BY_ENGAGEMENTS_GENDER_START = 'TRAININGS_BY_ENGAGEMENTS_GENDER_START';
export const TRAININGS_BY_ENGAGEMENTS_GENDER_SUCCESS = 'TRAININGS_BY_ENGAGEMENTS_GENDER_SUCCESS';
export const TRAININGS_BY_ENGAGEMENTS_GENDER_FAILED = 'TRAININGS_BY_ENGAGEMENTS_GENDER_FAILED';
export const TRAININGS_BY_ENGAGEMENTS_GENDER_RESET = 'TRAININGS_BY_ENGAGEMENTS_GENDER_RESET';

// Action Types: Trainings By Openings stats.
export const TRAININGS_BY_OPENINGS_START = 'TRAININGS_BY_OPENINGS_START';
export const TRAININGS_BY_OPENINGS_SUCCESS = 'TRAININGS_BY_OPENINGS_SUCCESS';
export const TRAININGS_BY_OPENINGS_FAILED = 'TRAININGS_BY_OPENINGS_FAILED';
export const TRAININGS_BY_OPENINGS_RESET = 'TRAININGS_BY_OPENINGS_RESET';

// Action Creators: Counts.
const countsStart = (payload) => ({
  type: COUNTS_START,
  payload,
});
const countsSuccess = (payload) => ({
  type: COUNTS_SUCCESS,
  payload,
});
const countsFailed = (payload) => ({
  type: COUNTS_FAILED,
  payload,
});
const countsReset = () => ({ type: COUNTS_RESET });

// Action Creators: Internship stats.
const internshipsStart = (payload) => ({
  type: INTERNSHIPS_START,
  payload,
});
const internshipsSuccess = (payload) => ({
  type: INTERNSHIPS_SUCCESS,
  payload,
});
const internshipsFailed = (payload) => ({
  type: INTERNSHIPS_FAILED,
  payload,
});
const internshipsReset = () => ({ type: INTERNSHIPS_RESET });

// Action Creators: Information about Programme stats.
const programmeInfoStart = (payload) => ({
  type: PROGRAMME_INFO_START,
  payload,
});
const programmeInfoSuccess = (payload) => ({
  type: PROGRAMME_INFO_SUCCESS,
  payload,
});
const programmeInfoFailed = (payload) => ({
  type: PROGRAMME_INFO_FAILED,
  payload,
});
const programmeInfoReset = () => ({ type: PROGRAMME_INFO_RESET });

// Action Creators: Intern By Municipality stats.
const internsByMunicipalityStart = (payload) => ({
  type: INTERNS_BY_MUNICIPALITY_START,
  payload,
});
const internsByMunicipalitySuccess = (payload) => ({
  type: INTERNS_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const internsByMunicipalityFailed = (payload) => ({
  type: INTERNS_BY_MUNICIPALITY_FAILED,
  payload,
});
const internsByMunicipalityReset = () => ({ type: INTERNS_BY_MUNICIPALITY_RESET });

// Action Creators: Intern By Skills stats.
const internsBySkillsStart = (payload) => ({
  type: INTERNS_BY_SKILLS_START,
  payload,
});
const internsBySkillsSuccess = (payload) => ({
  type: INTERNS_BY_SKILLS_SUCCESS,
  payload,
});
const internsBySkillsFailed = (payload) => ({
  type: INTERNS_BY_SKILLS_FAILED,
  payload,
});
const internsBySkillsReset = () => ({ type: INTERNS_BY_SKILLS_RESET });

// Action Creators: Intern By Categories stats.
const internsByCategoriesStart = (payload) => ({
  type: INTERNS_BY_CATEGORIES_START,
  payload,
});
const internsByCategoriesSuccess = (payload) => ({
  type: INTERNS_BY_CATEGORIES_SUCCESS,
  payload,
});
const internsByCategoriesFailed = (payload) => ({
  type: INTERNS_BY_CATEGORIES_FAILED,
  payload,
});
const internsByCategoriesReset = () => ({ type: INTERNS_BY_CATEGORIES_RESET });

// Action Creators: Intern By Gender stats.
const internsByGenderStart = (payload) => ({
  type: INTERNS_BY_GENDER_START,
  payload,
});
const internsByGenderSuccess = (payload) => ({
  type: INTERNS_BY_GENDER_SUCCESS,
  payload,
});
const internsByGenderFailed = (payload) => ({
  type: INTERNS_BY_GENDER_FAILED,
  payload,
});
const internsByGenderReset = () => ({ type: INTERNS_BY_GENDER_RESET });

// Action Creators: Intern By Age stats.
const internsByAgeStart = (payload) => ({
  type: INTERNS_BY_AGE_START,
  payload,
});
const internsByAgeSuccess = (payload) => ({
  type: INTERNS_BY_AGE_SUCCESS,
  payload,
});
const internsByAgeFailed = (payload) => ({
  type: INTERNS_BY_AGE_FAILED,
  payload,
});
const internsByAgeReset = () => ({ type: INTERNS_BY_AGE_RESET });

// Action Creators: Intern By Ethnicity stats.
const internsByEthnicityStart = (payload) => ({
  type: INTERNS_BY_ETHNICITY_START,
  payload,
});
const internsByEthnicitySuccess = (payload) => ({
  type: INTERNS_BY_ETHNICITY_SUCCESS,
  payload,
});
const internsByEthnicityFailed = (payload) => ({
  type: INTERNS_BY_ETHNICITY_FAILED,
  payload,
});
const internsByEthnicityReset = () => ({ type: INTERNS_BY_ETHNICITY_RESET });

// Action Creators: Intern By HealthCondition stats.
const internsByHealthConditionStart = (payload) => ({
  type: INTERNS_BY_HEALTH_CONDITION_START,
  payload,
});
const internsByHealthConditionSuccess = (payload) => ({
  type: INTERNS_BY_HEALTH_CONDITION_SUCCESS,
  payload,
});
const internsByHealthConditionFailed = (payload) => ({
  type: INTERNS_BY_HEALTH_CONDITION_FAILED,
  payload,
});
const internsByHealthConditionReset = () => ({ type: INTERNS_BY_HEALTH_CONDITION_RESET });

// Action Creators: Businesses By Municipality stats.
const businessesByMunicipalityStart = (payload) => ({
  type: BUSINESSES_BY_MUNICIPALITY_START,
  payload,
});
const businessesByMunicipalitySuccess = (payload) => ({
  type: BUSINESSES_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const businessesByMunicipalityFailed = (payload) => ({
  type: BUSINESSES_BY_MUNICIPALITY_FAILED,
  payload,
});
const businessesByMunicipalityReset = () => ({ type: BUSINESSES_BY_MUNICIPALITY_RESET });

// Action Creators: Businesses By Type stats.
const businessesByTypeStart = (payload) => ({
  type: BUSINESSES_BY_TYPE_START,
  payload,
});
const businessesByTypeSuccess = (payload) => ({
  type: BUSINESSES_BY_TYPE_SUCCESS,
  payload,
});
const businessesByTypeFailed = (payload) => ({
  type: BUSINESSES_BY_TYPE_FAILED,
  payload,
});
const businessesByTypeReset = () => ({ type: BUSINESSES_BY_TYPE_RESET });

// Action Creators: Businesses Categories stats.
const businessesByCategoriesStart = (payload) => ({
  type: BUSINESSES_BY_CATEGORIES_START,
  payload,
});
const businessesByCategoriesSuccess = (payload) => ({
  type: BUSINESSES_BY_CATEGORIES_SUCCESS,
  payload,
});
const businessesByCategoriesFailed = (payload) => ({
  type: BUSINESSES_BY_CATEGORIES_FAILED,
  payload,
});
const businessesByCategoriesReset = () => ({ type: BUSINESSES_BY_CATEGORIES_RESET });

// Action Creators: Mentors By Municipality stats.
const mentorsByMunicipalityStart = (payload) => ({
  type: MENTORS_BY_MUNICIPALITY_START,
  payload,
});
const mentorsByMunicipalitySuccess = (payload) => ({
  type: MENTORS_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const mentorsByMunicipalityFailed = (payload) => ({
  type: MENTORS_BY_MUNICIPALITY_FAILED,
  payload,
});
const mentorsByMunicipalityReset = () => ({ type: MENTORS_BY_MUNICIPALITY_RESET });

// Action Creators: Mentors By Skills stats.
const mentorsBySkillsStart = (payload) => ({
  type: MENTORS_BY_SKILLS_START,
  payload,
});
const mentorsBySkillsSuccess = (payload) => ({
  type: MENTORS_BY_SKILLS_SUCCESS,
  payload,
});
const mentorsBySkillsFailed = (payload) => ({
  type: MENTORS_BY_SKILLS_FAILED,
  payload,
});
const mentorsBySkillsReset = () => ({ type: MENTORS_BY_SKILLS_RESET });

// Action Creators: Mentors By Categories stats.
const mentorsByCategoriesStart = (payload) => ({
  type: MENTORS_BY_CATEGORIES_START,
  payload,
});
const mentorsByCategoriesSuccess = (payload) => ({
  type: MENTORS_BY_CATEGORIES_SUCCESS,
  payload,
});
const mentorsByCategoriesFailed = (payload) => ({
  type: MENTORS_BY_CATEGORIES_FAILED,
  payload,
});
const mentorsByCategoriesReset = () => ({ type: MENTORS_BY_CATEGORIES_RESET });

// Action Creators: Mentors By Gender stats.
const mentorsByGenderStart = (payload) => ({
  type: MENTORS_BY_GENDER_START,
  payload,
});
const mentorsByGenderSuccess = (payload) => ({
  type: MENTORS_BY_GENDER_SUCCESS,
  payload,
});
const mentorsByGenderFailed = (payload) => ({
  type: MENTORS_BY_GENDER_FAILED,
  payload,
});
const mentorsByGenderReset = () => ({ type: MENTORS_BY_GENDER_RESET });

// Action Creators: Mentors By Interns stats.
const mentorsByInternsStart = (payload) => ({
  type: MENTORS_BY_INTERNS_START,
  payload,
});
const mentorsByInternsSuccess = (payload) => ({
  type: MENTORS_BY_INTERNS_SUCCESS,
  payload,
});
const mentorsByInternsFailed = (payload) => ({
  type: MENTORS_BY_INTERNS_FAILED,
  payload,
});
const mentorsByInternsReset = () => ({ type: MENTORS_BY_INTERNS_RESET });

// Action Creators: Mentor By Ethnicity stats.
const mentorsByEthnicityStart = (payload) => ({
  type: MENTORS_BY_ETHNICITY_START,
  payload,
});
const mentorsByEthnicitySuccess = (payload) => ({
  type: MENTORS_BY_ETHNICITY_SUCCESS,
  payload,
});
const mentorsByEthnicityFailed = (payload) => ({
  type: MENTORS_BY_ETHNICITY_FAILED,
  payload,
});
const mentorsByEthnicityReset = () => ({ type: MENTORS_BY_ETHNICITY_RESET });

// Action Creators: Mentor By HealthCondition stats.
const mentorsByHealthConditionStart = (payload) => ({
  type: MENTORS_BY_HEALTH_CONDITION_START,
  payload,
});
const mentorsByHealthConditionSuccess = (payload) => ({
  type: MENTORS_BY_HEALTH_CONDITION_SUCCESS,
  payload,
});
const mentorsByHealthConditionFailed = (payload) => ({
  type: MENTORS_BY_HEALTH_CONDITION_FAILED,
  payload,
});
const mentorsByHealthConditionReset = () => ({ type: MENTORS_BY_HEALTH_CONDITION_RESET });

// Action Creators: Applications By Municipality stats.
const applicationsByMunicipalityStart = (payload) => ({
  type: APPLICATIONS_BY_MUNICIPALITY_START,
  payload,
});
const applicationsByMunicipalitySuccess = (payload) => ({
  type: APPLICATIONS_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const applicationsByMunicipalityFailed = (payload) => ({
  type: APPLICATIONS_BY_MUNICIPALITY_FAILED,
  payload,
});
const applicationsByMunicipalityReset = () => ({ type: APPLICATIONS_BY_MUNICIPALITY_RESET });

// Action Creators: Applications By Status stats.
const applicationsByStatusStart = (payload) => ({
  type: APPLICATIONS_BY_STATUS_START,
  payload,
});
const applicationsByStatusSuccess = (payload) => ({
  type: APPLICATIONS_BY_STATUS_SUCCESS,
  payload,
});
const applicationsByStatusFailed = (payload) => ({
  type: APPLICATIONS_BY_STATUS_FAILED,
  payload,
});
const applicationsByStatusReset = () => ({ type: APPLICATIONS_BY_STATUS_RESET });

// Action Creators: Applications by Business stats.
const applicationsByBusinessStart = (payload) => ({
  type: APPLICATIONS_BY_BUSINESS_START,
  payload,
});
const applicationsByBusinessSuccess = (payload) => ({
  type: APPLICATIONS_BY_BUSINESS_SUCCESS,
  payload,
});
const applicationsByBusinessFailed = (payload) => ({
  type: APPLICATIONS_BY_BUSINESS_FAILED,
  payload,
});
const applicationsByBusinessReset = () => ({ type: APPLICATIONS_BY_BUSINESS_RESET });

// Action Creators: Applications by Job stats.
const applicationsByJobStart = (payload) => ({
  type: APPLICATIONS_BY_JOB_START,
  payload,
});
const applicationsByJobSuccess = (payload) => ({
  type: APPLICATIONS_BY_JOB_SUCCESS,
  payload,
});
const applicationsByJobFailed = (payload) => ({
  type: APPLICATIONS_BY_JOB_FAILED,
  payload,
});
const applicationsByJobReset = () => ({ type: APPLICATIONS_BY_JOB_RESET });

// Action Creators: Applications by Gender stats.
const applicationsByGenderStart = (payload) => ({
  type: APPLICATIONS_BY_GENDER_START,
  payload,
});
const applicationsByGenderSuccess = (payload) => ({
  type: APPLICATIONS_BY_GENDER_SUCCESS,
  payload,
});
const applicationsByGenderFailed = (payload) => ({
  type: APPLICATIONS_BY_GENDER_FAILED,
  payload,
});
const applicationsByGenderReset = () => ({ type: APPLICATIONS_BY_GENDER_RESET });

// Action Creators: Applications by Age stats.
const applicationsByAgeStart = (payload) => ({
  type: APPLICATIONS_BY_AGE_START,
  payload,
});
const applicationsByAgeSuccess = (payload) => ({
  type: APPLICATIONS_BY_AGE_SUCCESS,
  payload,
});
const applicationsByAgeFailed = (payload) => ({
  type: APPLICATIONS_BY_AGE_FAILED,
  payload,
});
const applicationsByAgeReset = () => ({ type: APPLICATIONS_BY_AGE_RESET });

// Action Creators: Applications By Ethnicity stats.
const applicationsByEthnicityStart = (payload) => ({
  type: APPLICATIONS_BY_ETHNICITY_START,
  payload,
});
const applicationsByEthnicitySuccess = (payload) => ({
  type: APPLICATIONS_BY_ETHNICITY_SUCCESS,
  payload,
});
const applicationsByEthnicityFailed = (payload) => ({
  type: APPLICATIONS_BY_ETHNICITY_FAILED,
  payload,
});
const applicationsByEthnicityReset = () => ({ type: APPLICATIONS_BY_ETHNICITY_RESET });

// Action Creators: Applications By HealthCondition stats.
const applicationsByHealthConditionStart = (payload) => ({
  type: APPLICATIONS_BY_HEALTH_CONDITION_START,
  payload,
});
const applicationsByHealthConditionSuccess = (payload) => ({
  type: APPLICATIONS_BY_HEALTH_CONDITION_SUCCESS,
  payload,
});
const applicationsByHealthConditionFailed = (payload) => ({
  type: APPLICATIONS_BY_HEALTH_CONDITION_FAILED,
  payload,
});
const applicationsByHealthConditionReset = () => ({ type: APPLICATIONS_BY_HEALTH_CONDITION_RESET });

// Action Creators: Applications By Category stats.
const applicationsByCategoryStart = (payload) => ({
  type: APPLICATIONS_BY_CATEGORY_START,
  payload,
});
const applicationsByCategorySuccess = (payload) => ({
  type: APPLICATIONS_BY_CATEGORY_SUCCESS,
  payload,
});
const applicationsByCategoryFailed = (payload) => ({
  type: APPLICATIONS_BY_CATEGORY_FAILED,
  payload,
});
const applicationsByCategoryReset = () => ({ type: APPLICATIONS_BY_CATEGORY_RESET });

// Action Creators: Engagements By Municipality stats.
const engagementsByMunicipalityStart = (payload) => ({
  type: ENGAGEMENTS_BY_MUNICIPALITY_START,
  payload,
});
const engagementsByMunicipalitySuccess = (payload) => ({
  type: ENGAGEMENTS_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const engagementsByMunicipalityFailed = (payload) => ({
  type: ENGAGEMENTS_BY_MUNICIPALITY_FAILED,
  payload,
});
const engagementsByMunicipalityReset = () => ({ type: ENGAGEMENTS_BY_MUNICIPALITY_RESET });

// Action Creators: Engagements by Business stats.
const engagementsByBusinessStart = (payload) => ({
  type: ENGAGEMENTS_BY_BUSINESS_START,
  payload,
});
const engagementsByBusinessSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_BUSINESS_SUCCESS,
  payload,
});
const engagementsByBusinessFailed = (payload) => ({
  type: ENGAGEMENTS_BY_BUSINESS_FAILED,
  payload,
});
const engagementsByBusinessReset = () => ({ type: ENGAGEMENTS_BY_BUSINESS_RESET });

// Action Creators: Engagements by Gender stats.
const engagementsByGenderStart = (payload) => ({
  type: ENGAGEMENTS_BY_GENDER_START,
  payload,
});
const engagementsByGenderSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_GENDER_SUCCESS,
  payload,
});
const engagementsByGenderFailed = (payload) => ({
  type: ENGAGEMENTS_BY_GENDER_FAILED,
  payload,
});
const engagementsByGenderReset = () => ({ type: ENGAGEMENTS_BY_GENDER_RESET });

// Action Creators: Engagements by Age stats.
const engagementsByAgeStart = (payload) => ({
  type: ENGAGEMENTS_BY_AGE_START,
  payload,
});
const engagementsByAgeSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_AGE_SUCCESS,
  payload,
});
const engagementsByAgeFailed = (payload) => ({
  type: ENGAGEMENTS_BY_AGE_FAILED,
  payload,
});
const engagementsByAgeReset = () => ({ type: ENGAGEMENTS_BY_AGE_RESET });

// Action Creators: Engagements by Skills stats.
const engagementsBySkillsStart = (payload) => ({
  type: ENGAGEMENTS_BY_SKILLS_START,
  payload,
});
const engagementsBySkillsSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_SKILLS_SUCCESS,
  payload,
});
const engagementsBySkillsFailed = (payload) => ({
  type: ENGAGEMENTS_BY_SKILLS_FAILED,
  payload,
});
const engagementsBySkillsReset = () => ({ type: ENGAGEMENTS_BY_SKILLS_RESET });

// Action Creators: Engagements by Categories stats.
const engagementsByCategoriesStart = (payload) => ({
  type: ENGAGEMENTS_BY_CATEGORIES_START,
  payload,
});
const engagementsByCategoriesSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_CATEGORIES_SUCCESS,
  payload,
});
const engagementsByCategoriesFailed = (payload) => ({
  type: ENGAGEMENTS_BY_CATEGORIES_FAILED,
  payload,
});
const engagementsByCategoriesReset = () => ({ type: ENGAGEMENTS_BY_CATEGORIES_RESET });

// Action Creators: Engagements By Ethnicity stats.
const engagementsByEthnicityStart = (payload) => ({
  type: ENGAGEMENTS_BY_ETHNICITY_START,
  payload,
});
const engagementsByEthnicitySuccess = (payload) => ({
  type: ENGAGEMENTS_BY_ETHNICITY_SUCCESS,
  payload,
});
const engagementsByEthnicityFailed = (payload) => ({
  type: ENGAGEMENTS_BY_ETHNICITY_FAILED,
  payload,
});
const engagementsByEthnicityReset = () => ({ type: ENGAGEMENTS_BY_ETHNICITY_RESET });

// Action Creators: Engagements By HealthCondition stats.
const engagementsByHealthConditionStart = (payload) => ({
  type: ENGAGEMENTS_BY_HEALTH_CONDITION_START,
  payload,
});
const engagementsByHealthConditionSuccess = (payload) => ({
  type: ENGAGEMENTS_BY_HEALTH_CONDITION_SUCCESS,
  payload,
});
const engagementsByHealthConditionFailed = (payload) => ({
  type: ENGAGEMENTS_BY_HEALTH_CONDITION_FAILED,
  payload,
});
const engagementsByHealthConditionReset = () => ({ type: ENGAGEMENTS_BY_HEALTH_CONDITION_RESET });

// Action Creators: Jobs By Municipality stats.
const jobsByMunicipalityStart = (payload) => ({
  type: JOBS_BY_MUNICIPALITY_START,
  payload,
});
const jobsByMunicipalitySuccess = (payload) => ({
  type: JOBS_BY_MUNICIPALITY_SUCCESS,
  payload,
});
const jobsByMunicipalityFailed = (payload) => ({
  type: JOBS_BY_MUNICIPALITY_FAILED,
  payload,
});
const jobsByMunicipalityReset = () => ({ type: JOBS_BY_MUNICIPALITY_RESET });

// Action Creators: Jobs By Skills stats.
const jobsBySkillsStart = (payload) => ({
  type: JOBS_BY_SKILLS_START,
  payload,
});
const jobsBySkillsSuccess = (payload) => ({
  type: JOBS_BY_SKILLS_SUCCESS,
  payload,
});
const jobsBySkillsFailed = (payload) => ({
  type: JOBS_BY_SKILLS_FAILED,
  payload,
});
const jobsBySkillsReset = () => ({ type: JOBS_BY_SKILLS_RESET });

// Action Creators: Jobs By Categories stats.
const jobsByCategoriesStart = (payload) => ({
  type: JOBS_BY_CATEGORIES_START,
  payload,
});
const jobsByCategoriesSuccess = (payload) => ({
  type: JOBS_BY_CATEGORIES_SUCCESS,
  payload,
});
const jobsByCategoriesFailed = (payload) => ({
  type: JOBS_BY_CATEGORIES_FAILED,
  payload,
});
const jobsByCategoriesReset = () => ({ type: JOBS_BY_CATEGORIES_RESET });

// Action Creators: Jobs By Business stats.
const jobsByBusinessStart = (payload) => ({
  type: JOBS_BY_BUSINESS_START,
  payload,
});
const jobsByBusinessSuccess = (payload) => ({
  type: JOBS_BY_BUSINESS_SUCCESS,
  payload,
});
const jobsByBusinessFailed = (payload) => ({
  type: JOBS_BY_BUSINESS_FAILED,
  payload,
});
const jobsByBusinessReset = () => ({ type: JOBS_BY_BUSINESS_RESET });

// Action Creators: Jobs By Spots stats.
const jobsBySpotsStart = (payload) => ({
  type: JOBS_BY_SPOTS_START,
  payload,
});
const jobsBySpotsSuccess = (payload) => ({
  type: JOBS_BY_SPOTS_SUCCESS,
  payload,
});
const jobsBySpotsFailed = (payload) => ({
  type: JOBS_BY_SPOTS_FAILED,
  payload,
});
const jobsBySpotsReset = () => ({ type: JOBS_BY_SPOTS_RESET });

// Action Creators: Trainings By Applications stats.
const trainingsByApplicationsStart = (payload) => ({
  type: TRAININGS_BY_APPLICATIONS_START,
  payload,
});
const trainingsByApplicationsSuccess = (payload) => ({
  type: TRAININGS_BY_APPLICATIONS_SUCCESS,
  payload,
});
const trainingsByApplicationsFailed = (payload) => ({
  type: TRAININGS_BY_APPLICATIONS_FAILED,
  payload,
});
const trainingsByApplicationsReset = () => ({ type: TRAININGS_BY_APPLICATIONS_RESET });

// Action Creators: Trainings By Engagements stats.
const trainingsByEngagementsStart = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_START,
  payload,
});
const trainingsByEngagementsSuccess = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_SUCCESS,
  payload,
});
const trainingsByEngagementsFailed = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_FAILED,
  payload,
});
const trainingsByEngagementsReset = () => ({ type: TRAININGS_BY_ENGAGEMENTS_RESET });

// Action Creators: Trainings By Engagements stats.
const trainingsByEngagementsGenderStart = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_GENDER_START,
  payload,
});
const trainingsByEngagementsGenderSuccess = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_GENDER_SUCCESS,
  payload,
});
const trainingsByEngagementsGenderFailed = (payload) => ({
  type: TRAININGS_BY_ENGAGEMENTS_GENDER_FAILED,
  payload,
});
const trainingsByEngagementsGenderReset = () => ({ type: TRAININGS_BY_ENGAGEMENTS_GENDER_RESET });

// Action Creators: Trainings By Categories stats.
const trainingsByCategoriesStart = (payload) => ({
  type: TRAININGS_BY_CATEGORIES_START,
  payload,
});
const trainingsByCategoriesSuccess = (payload) => ({
  type: TRAININGS_BY_CATEGORIES_SUCCESS,
  payload,
});
const trainingsByCategoriesFailed = (payload) => ({
  type: TRAININGS_BY_CATEGORIES_FAILED,
  payload,
});
const trainingsByCategoriesReset = () => ({ type: TRAININGS_BY_CATEGORIES_RESET });

// Action Creators: Trainings By Openings stats.
const trainingsByOpeningsStart = (payload) => ({
  type: TRAININGS_BY_OPENINGS_START,
  payload,
});
const trainingsByOpeningsSuccess = (payload) => ({
  type: TRAININGS_BY_OPENINGS_SUCCESS,
  payload,
});
const trainingsByOpeningsFailed = (payload) => ({
  type: TRAININGS_BY_OPENINGS_FAILED,
  payload,
});
const trainingsByOpeningsReset = () => ({ type: TRAININGS_BY_OPENINGS_RESET });

// Action Creators: Trainings By Spots stats.
const trainingsBySpotsStart = (payload) => ({
  type: TRAININGS_BY_SPOTS_START,
  payload,
});
const trainingsBySpotsSuccess = (payload) => ({
  type: TRAININGS_BY_SPOTS_SUCCESS,
  payload,
});
const trainingsBySpotsFailed = (payload) => ({
  type: TRAININGS_BY_SPOTS_FAILED,
  payload,
});
const trainingsBySpotsReset = () => ({ type: TRAININGS_BY_SPOTS_RESET });

// Action Creators: Counts.
const countsDataStart = (payload) => ({
  type: COUNTS_DATA_START,
  payload,
});
const countsDataSuccess = (payload) => ({
  type: COUNTS_DATA_SUCCESS,
  payload,
});
const countsDataFailed = (payload) => ({
  type: COUNTS_DATA_FAILED,
  payload,
});
const countsDataReset = () => ({ type: COUNTS_DATA_RESET });

// Actions: Counts.
export const counts = (payload) => {
  return async (dispatch) => {
    dispatch(
      countsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate } = payload;
      const query = {};
      if (startDate) query['startDate'] = startDate;
      if (endDate) query['endDate'] = endDate;
      const result = await ApiClient.post('reports/counts', query);
      if (result.data?.success) {
        const { counts } = result.data.data;
        dispatch(
          countsSuccess({
            loading: false,
            success: true,
            data: { counts },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          countsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        countsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Counts.
export const clearCounts = () => countsReset();

// Actions: Internships.
export const internships = (payload) => {
  return async (dispatch) => {
    dispatch(
      internshipsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        functionalConstrains,
        genders,
        categories,
        municipalities,
        birthYear,
        organisation,
      } = payload;
      const result = await ApiClient.post(`reports/internships`, {
        startDate,
        endDate,
        ethnicities,
        functionalConstrains,
        genders,
        categories,
        municipalities,
        birthYear,
        organisation,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          internshipsSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internshipsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internshipsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Internships.
export const clearInternships = () => internshipsReset();

// Actions: Information about Programme.
export const programmeInfo = (payload) => {
  return async (dispatch) => {
    dispatch(
      programmeInfoStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, genders, municipalities, birthYear } = payload;
      const result = await ApiClient.post(`reports/programmeinfo`, {
        startDate,
        endDate,
        genders,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { info } = result.data.data;
        dispatch(
          programmeInfoSuccess({
            loading: false,
            success: true,
            data: { info },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          programmeInfoFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        programmeInfoFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Programme Information.
export const clearProgrammeInfo = () => programmeInfoReset();

// Actions: Interns By Municipality.
export const internsByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Municipality.
export const clearInternsByMunicipality = () => internsByMunicipalityReset();

// Actions: Interns By Skills.
export const internsBySkills = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsBySkillsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_SKILLS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsBySkillsSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsBySkillsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsBySkillsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Skills.
export const clearInternsBySkills = () => internsBySkillsReset();

// Actions: Interns By Categories.
export const internsByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByCategoriesSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Categories.
export const clearInternsByCategories = () => internsByCategoriesReset();

// Actions: Interns By Gender.
export const internsByGender = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByGenderStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_GENDER',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByGenderSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByGenderFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByGenderFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Gender.
export const clearInternsByGender = () => internsByGenderReset();

// Actions: Interns By Age.
export const internsByAge = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByAgeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_AGE',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByAgeSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByAgeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByAgeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Age.
export const clearInternsByAge = () => internsByAgeReset();

// Actions: Interns By Ethnicity.
export const internsByEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_ETHNICITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByEthnicitySuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By Ethnicity.
export const clearInternsByEthnicity = () => internsByEthnicityReset();

// Actions: Interns By HealthCondition.
export const internsByHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      internsByHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/interns`, {
        startDate,
        endDate,
        type: 'BY_HEALTHCONDITION',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { interns } = result.data.data;
        dispatch(
          internsByHealthConditionSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          internsByHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        internsByHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Interns By HealthCondition.
export const clearInternsByHealthCondition = () => internsByHealthConditionReset();

// Actions: Businesses By Municipality.
export const businessesByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      businessesByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, entityTypes, categories, municipalities } = payload;
      const result = await ApiClient.post(`reports/businesses`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        entityTypes,
        categories,
        municipalities,
      });
      if (result.data?.success) {
        const { businesses } = result.data.data;
        dispatch(
          businessesByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { businesses },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          businessesByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        businessesByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Bussinesses By Municipality.
export const clearBusinessesByMunicipality = () => businessesByMunicipalityReset();

// Actions: Businesses By Type.
export const businessesByType = (payload) => {
  return async (dispatch) => {
    dispatch(
      businessesByTypeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, entityTypes, categories, municipalities } = payload;
      const result = await ApiClient.post(`reports/businesses`, {
        startDate,
        endDate,
        type: 'BY_TYPE',
        entityTypes,
        categories,
        municipalities,
      });
      if (result.data?.success) {
        const { businesses } = result.data.data;
        dispatch(
          businessesByTypeSuccess({
            loading: false,
            success: true,
            data: { businesses },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          businessesByTypeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        businessesByTypeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Bussinesses By Type.
export const clearBusinessesByType = () => businessesByTypeReset();

// Actions: Businesses By Categories.
export const businessesByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      businessesByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, entityTypes, categories, municipalities } = payload;
      const result = await ApiClient.post(`reports/businesses`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        entityTypes,
        categories,
        municipalities,
      });
      if (result.data?.success) {
        const { businesses } = result.data.data;
        dispatch(
          businessesByCategoriesSuccess({
            loading: false,
            success: true,
            data: { businesses },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          businessesByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        businessesByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Bussinesses By Categories.
export const clearBusinessesByCategories = () => businessesByCategoriesReset();

// Actions: Mentors By Municipality.
export const mentorsByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Municipality.
export const clearMentorsByMunicipality = () => mentorsByMunicipalityReset();

// Actions: Mentors By Skills.
export const mentorsBySkills = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsBySkillsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_SKILLS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsBySkillsSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsBySkillsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsBySkillsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Skills.
export const clearMentorsBySkills = () => mentorsBySkillsReset();

// Actions: Mentors By Gender.
export const mentorsByGender = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByGenderStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_GENDER',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByGenderSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByGenderFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByGenderFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Gender.
export const clearMentorsByGender = () => mentorsByGenderReset();

// Actions: Mentors By Categories.
export const mentorsByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByCategoriesSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Categories.
export const clearMentorsByCategories = () => mentorsByCategoriesReset();

// Actions: Mentors By Interns.
export const mentorsByInterns = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByInternsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_INTERNS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByInternsSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByInternsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByInternsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Interns.
export const clearMentorsByInterns = () => mentorsByInternsReset();

// Actions: Mentors By Ethnicity.
export const mentorsByEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_ETHNICITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByEthnicitySuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By Ethnicity.
export const clearMentorsByEthnicity = () => mentorsByEthnicityReset();

// Actions: Mentors By HealthCondition.
export const mentorsByHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      mentorsByHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, ethnicities, healthConditions, genders, categories, skills, municipalities } =
        payload;
      const result = await ApiClient.post(`reports/mentors`, {
        startDate,
        endDate,
        type: 'BY_HEALTHCONDITION',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { mentors } = result.data.data;
        dispatch(
          mentorsByHealthConditionSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          mentorsByHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        mentorsByHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Mentors By HealthCondition.
export const clearMentorsByHealthCondition = () => mentorsByHealthConditionReset();

// Actions: Applications By Municipality.
export const applicationsByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Municipality.
export const clearApplicationsByMunicipality = () => applicationsByMunicipalityReset();

// Actions: Applications By Status.
export const applicationsByStatus = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByStatusStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_STATUS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByStatusSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByStatusFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByStatusFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Status.
export const clearApplicationsByStatus = () => applicationsByStatusReset();

// Actions: Applications By Business.
export const applicationsByBusiness = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_BUSINESS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByBusinessSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Business.
export const clearApplicationsByBusiness = () => applicationsByBusinessReset();

// Actions: Applications By Job.
export const applicationsByJob = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByJobStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_JOB',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByJobSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Job.
export const clearApplicationsByJob = () => applicationsByJobReset();

// Actions: Applications By Gender.
export const applicationsByGender = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByGenderStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_GENDER',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByGenderSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByGenderFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByGenderFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Gender.
export const clearApplicationsByGender = () => applicationsByGenderReset();

// Actions: Applications By Age.
export const applicationsByAge = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByAgeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_AGE',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByAgeSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByAgeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByAgeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Age.
export const clearApplicationsByAge = () => applicationsByAgeReset();

// Actions: Applications By Ethnicity.
export const applicationsByEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_ETHNICITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByEthnicitySuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications By Ethnicity.
export const clearApplicationsByEthnicity = () => applicationsByEthnicityReset();

// Actions: Applications By HealthCondition.
export const applicationsByHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_HEALTHCONDITION',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByHealthConditionSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications By HealthCondition.
export const clearApplicationsByHealthCondition = () => applicationsByHealthConditionReset();

// Actions: Applications By Category.
export const applicationsByCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      applicationsByCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/applications`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          applicationsByCategorySuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          applicationsByCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationsByCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications By Category.
export const clearApplicationsByCategory = () => applicationsByCategoryReset();

// Actions: Engagements By Municipality.
export const engagementsByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Engagements by Municipality.
export const clearEngagementsByMunicipality = () => engagementsByMunicipalityReset();

// Actions: Engagements By Business.
export const engagementsByBusiness = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_BUSINESS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByBusinessSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear engagements by Status.
export const clearengagementsByBusiness = () => engagementsByBusinessReset();

// Actions: Engagements By Gender.
export const engagementsByGender = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByGenderStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_GENDER',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByGenderSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByGenderFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByGenderFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear engagements by Gender.
export const clearEngagementsByGender = () => engagementsByGenderReset();

// Actions: Applications By Age.
export const engagementsByAge = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByAgeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_AGE',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByAgeSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByAgeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByAgeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Age.
export const clearEngagementsByAge = () => engagementsByAgeReset();

// Actions: Applications By Skills.
export const engagementsBySkills = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsBySkillsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_SKILLS',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsBySkillsSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsBySkillsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsBySkillsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Skills.
export const clearEngagementsBySkills = () => engagementsBySkillsReset();

// Actions: Applications By Categories.
export const engagementsByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByCategoriesSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Applications by Categories.
export const clearEngagementsByCategories = () => engagementsByCategoriesReset();

// Actions: Engagements By Ethnicity.
export const engagementsByEthnicity = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByEthnicityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_ETHNICITY',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByEthnicitySuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByEthnicityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByEthnicityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Engagements By Ethnicity.
export const clearEngagementsByEthnicity = () => engagementsByEthnicityReset();

// Actions: Engagements By HealthCondition.
export const engagementsByHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      engagementsByHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        startDate,
        endDate,
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      } = payload;
      const result = await ApiClient.post(`reports/engagements`, {
        startDate,
        endDate,
        type: 'BY_HEALTHCONDITION',
        ethnicities,
        healthConditions,
        genders,
        categories,
        skills,
        municipalities,
        birthYear,
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          engagementsByHealthConditionSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          engagementsByHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        engagementsByHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Engagements By HealthCondition.
export const clearEngagementsByHealthCondition = () => engagementsByHealthConditionReset();

// Actions: Jobs By Municipality.
export const jobsByMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      jobsByMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, skills, municipalities } = payload;
      const result = await ApiClient.post(`reports/jobs`, {
        startDate,
        endDate,
        type: 'BY_MUNICIPALITY',
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { jobs } = result?.data?.data;
        dispatch(
          jobsByMunicipalitySuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          jobsByMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        jobsByMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Jobs By Municipality.
export const clearJobsByMunicipality = () => jobsByMunicipalityReset();

// Actions: Jobs By Skills.
export const jobsBySkills = (payload) => {
  return async (dispatch) => {
    dispatch(
      jobsBySkillsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, skills, municipalities } = payload;
      const result = await ApiClient.post(`reports/jobs`, {
        startDate,
        endDate,
        type: 'BY_SKILLS',
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { jobs } = result?.data?.data;

        dispatch(
          jobsBySkillsSuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          jobsBySkillsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        jobsBySkillsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Jobs By Skills.
export const clearJobsBySkills = () => jobsBySkillsReset();

// Actions: Jobs By Categories.
export const jobsByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      jobsByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, skills, municipalities } = payload;
      const result = await ApiClient.post(`reports/jobs`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { jobs } = result?.data?.data;
        dispatch(
          jobsByCategoriesSuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          jobsByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        jobsByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Jobs By Categories.
export const clearJobsByCategories = () => jobsByCategoriesReset();

// Actions: Jobs By Business.
export const jobsByBusiness = (payload) => {
  return async (dispatch) => {
    dispatch(
      jobsByBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, skills, municipalities } = payload;
      const result = await ApiClient.post(`reports/jobs`, {
        startDate,
        endDate,
        type: 'BY_BUSINESS',
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { jobs } = result?.data?.data;
        dispatch(
          jobsByBusinessSuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          jobsByBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        jobsByBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Jobs By Business.
export const clearJobsByBusiness = () => jobsByBusinessReset();

// Actions: Jobs By Spots.
export const jobsBySpots = (payload) => {
  return async (dispatch) => {
    dispatch(
      jobsBySpotsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, skills, municipalities } = payload;
      const result = await ApiClient.post(`reports/jobs`, {
        startDate,
        endDate,
        type: 'BY_SPOTS',
        categories,
        skills,
        municipalities,
      });
      if (result.data?.success) {
        const { jobs } = result?.data?.data;
        dispatch(
          jobsBySpotsSuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          jobsBySpotsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        jobsBySpotsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Jobs By Spots.
export const clearJobsBySpots = () => jobsBySpotsReset();

// Actions: Trainings By Applications.
export const trainingsByApplications = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsByApplicationsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_APPLICATIONS',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsByApplicationsSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsByApplicationsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsByApplicationsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Applications.
export const clearTrainingsByApplications = () => trainingsByApplicationsReset();

// Actions: Trainings By Engagements.
export const trainingsByEngagements = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsByEngagementsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_ENGAGEMENTS',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsByEngagementsSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsByEngagementsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsByEngagementsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Engagements.
export const clearTrainingsByEngagements = () => trainingsByEngagementsReset();

// Actions: Trainings By Engagements.
export const trainingsByEngagementsGender = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsByEngagementsGenderStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_ENGAGEMENTSGENDER',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsByEngagementsGenderSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsByEngagementsGenderFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsByEngagementsGenderFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Engagements.
export const clearTrainingsByEngagementsGender = () => trainingsByEngagementsGenderReset();

// Actions: Trainings By Categories.
export const trainingsByCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsByCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_CATEGORIES',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsByCategoriesSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsByCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsByCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Categories.
export const clearTrainingsByCategories = () => trainingsByCategoriesReset();

// Actions: Trainings By Openings.
export const trainingsByOpenings = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsByOpeningsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_OPENINGS',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsByOpeningsSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsByOpeningsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsByOpeningsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Openings.
export const clearTrainingsByOpenings = () => trainingsByOpeningsReset();

// Actions: Trainings By Spots.
export const trainingsBySpots = (payload) => {
  return async (dispatch) => {
    dispatch(
      trainingsBySpotsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { startDate, endDate, categories, organisation, municipalities } = payload;
      const result = await ApiClient.post(`reports/trainings`, {
        startDate,
        endDate,
        type: 'BY_SPOTS',
        categories,
        organisation,
        municipalities,
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;
        dispatch(
          trainingsBySpotsSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          trainingsBySpotsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        trainingsBySpotsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Trainings By Spots.
export const clearTrainingsBySpots = () => trainingsBySpotsReset();

// Actions: Counts Data.
export const getAllCountsData = () => {
  return async (dispatch) => {
    dispatch(
      countsDataStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      // const {} = payload;

      const result = await ApiClient.post('reports/counts/home');
      if (result.data?.success) {
        const { counts } = result.data.data;

        dispatch(
          countsDataSuccess({
            loading: false,
            success: true,
            data: { counts },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          countsDataFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        countsDataFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Counts.
export const clearDataCounts = () => countsDataReset();
