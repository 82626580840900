// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Documents
export const GET_ALL_DOCUMENTS_START = 'GET_ALL_DOCUMENTS_START';
export const GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS';
export const GET_ALL_DOCUMENTS_FAILED = 'GET_ALL_DOCUMENTS_FAILED';
export const GET_ALL_DOCUMENTS_RESET = 'GET_ALL_DOCUMENTS_RESET';

// Action Types: Get One Documents
export const GET_ONE_DOCUMENT_START = 'GET_ONE_DOCUMENT_START';
export const GET_ONE_DOCUMENT_SUCCESS = 'GET_ONE_DOCUMENT_SUCCESS';
export const GET_ONE_DOCUMENT_FAILED = 'GET_ONE_DOCUMENT_FAILED';
export const GET_ONE_DOCUMENT_RESET = 'GET_ONE_DOCUMENT_RESET';

// Action Types: Create One Document
export const CREATE_ONE_DOCUMENT_START = 'CREATE_ONE_DOCUMENT_START';
export const CREATE_ONE_DOCUMENT_SUCCESS = 'CREATE_ONE_DOCUMENT_SUCCESS';
export const CREATE_ONE_DOCUMENT_FAILED = 'CREATE_ONE_DOCUMENT_FAILED';
export const CREATE_ONE_DOCUMENT_RESET = 'CREATE_ONE_DOCUMENT_RESET';

// Action Types: Update One Document
export const UPDATE_ONE_DOCUMENT_START = 'UPDATE_ONE_DOCUMENT_START';
export const UPDATE_ONE_DOCUMENT_SUCCESS = 'UPDATE_ONE_DOCUMENT_SUCCESS';
export const UPDATE_ONE_DOCUMENT_FAILED = 'UPDATE_ONE_DOCUMENT_FAILED';
export const UPDATE_ONE_DOCUMENT_RESET = 'UPDATE_ONE_DOCUMENT_RESET';

// Action Types: Delete One Document
export const DELETE_ONE_DOCUMENT_START = 'DELETE_ONE_DOCUMENT_START';
export const DELETE_ONE_DOCUMENT_SUCCESS = 'DELETE_ONE_DOCUMENT_SUCCESS';
export const DELETE_ONE_DOCUMENT_FAILED = 'DELETE_ONE_DOCUMENT_FAILED';
export const DELETE_ONE_DOCUMENT_RESET = 'DELETE_ONE_DOCUMENT_RESET';

// Action Creators: Get All Documents
const getAllDocumentsStart = (payload) => ({
  type: GET_ALL_DOCUMENTS_START,
  payload,
});

const getAllDocumentsSuccess = (payload) => ({
  type: GET_ALL_DOCUMENTS_SUCCESS,
  payload,
});

const getAllDocumentsFailed = (payload) => ({
  type: GET_ALL_DOCUMENTS_FAILED,
  payload,
});

const getAllDocumentsReset = (payload) => ({
  type: GET_ALL_DOCUMENTS_RESET,
  payload,
});

// Action Creators: Get One Document
const getOneDocumentStart = (payload) => ({
  type: GET_ONE_DOCUMENT_START,
  payload,
});

const getOneDocumentSuccess = (payload) => ({
  type: GET_ONE_DOCUMENT_SUCCESS,
  payload,
});

const getOneDocumentFailed = (payload) => ({
  type: GET_ONE_DOCUMENT_FAILED,
  payload,
});

const getOneDocumentReset = () => ({ type: GET_ONE_DOCUMENT_RESET });

// Action Creators: Create New Document
const createOneDocumentStart = (payload) => ({ type: CREATE_ONE_DOCUMENT_START, payload });

const createOneDocumentSuccess = (payload) => ({ type: CREATE_ONE_DOCUMENT_SUCCESS, payload });

const createOneDocumentFailed = (payload) => ({ type: CREATE_ONE_DOCUMENT_FAILED, payload });

const createOneDocumentReset = () => ({ type: CREATE_ONE_DOCUMENT_RESET });

// Action Creators: Update One Document
const updateOneDocumentStart = (payload) => ({ type: UPDATE_ONE_DOCUMENT_START, payload });

const updateOneDocumentSuccess = (payload) => ({ type: UPDATE_ONE_DOCUMENT_SUCCESS, payload });

const updateOneDocumentFailed = (payload) => ({ type: UPDATE_ONE_DOCUMENT_FAILED, payload });

const updateOneDocumentReset = () => ({ type: UPDATE_ONE_DOCUMENT_RESET });

// Action Creators: Delete One Document
const deleteOneDocumentStart = (payload) => ({ type: DELETE_ONE_DOCUMENT_START, payload });

const deleteOneDocumentSuccess = (payload) => ({ type: DELETE_ONE_DOCUMENT_SUCCESS, payload });

const deleteOneDocumentFailed = (payload) => ({ type: DELETE_ONE_DOCUMENT_FAILED, payload });

const deleteOneDocumentReset = () => ({ type: DELETE_ONE_DOCUMENT_RESET });

// Actions: Get All Documents
export const getAllDocuments = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllDocumentsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language, isGuide } = payload;
      const result = await ApiClient.get('documents', {
        params: { page, limit, pagination, selectedLanguage: language, isGuide },
      });
      if (result.data?.success) {
        const { documents } = result.data.data;
        dispatch(
          getAllDocumentsSuccess({
            loading: false,
            success: true,
            data: { documents },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllDocumentsSuccess({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllDocumentsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

export const clearGetAllDocuments = () => getAllDocumentsReset();

// Actions: Get One Document
export const getOneDocument = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneDocumentStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { documentId } = payload;
      const result = await ApiClient.get(`documents/${documentId}`);
      if (result.data?.success) {
        const { document } = result.data.data;
        dispatch(
          getOneDocumentSuccess({
            loading: false,
            success: true,
            data: { document },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneDocumentFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneDocumentFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Documents
export const clearGetOneDocument = () => getOneDocumentReset();

// Actions:  Create One Document
export const createOneDocument = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createOneDocumentStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { formData } = payload;

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onDocumentsExistsMessage } = options;

    try {
      const result = await ApiClient.post('documents', formData);
      if (result.data?.success) {
        const { document } = result.data.data;
        dispatch(
          createOneDocumentSuccess({
            loading: false,
            success: true,
            data: { document },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createOneDocumentFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createOneDocumentFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const documentExists = 'DOCUMENTS_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === documentExists
      ) {
        toastNotification('error', onDocumentsExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

//Actions: Clear Create One Document|
export const clearCreateOneDocument = () => createOneDocumentReset();

// Actions: Update One Documeny.
export const updateOneDocument = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneDocumentStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { documentId, formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`documents/${documentId}`, formData);
      if (result.data?.success) {
        const { document } = result.data.data;
        dispatch(
          updateOneDocumentSuccess({
            loading: false,
            success: true,
            data: { document },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneDocumentFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneDocumentFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Document.
export const clearUpdateOneDocument = () => updateOneDocumentReset();

// Actions: Delete One Document.
export const deleteOneDocument = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneDocumentStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { documentId } = payload;
      const result = await ApiClient.delete(`documents/${documentId}`);
      if (result.data?.success) {
        const { document } = result.data.data;
        dispatch(
          deleteOneDocumentSuccess({
            loading: false,
            success: true,
            data: { document },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneDocumentFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneDocumentFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Document.
export const cleareDeleteOneDocument = () => deleteOneDocumentReset();
