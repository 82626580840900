import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, OutlinedInput, IconButton, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    borderRadius: '6.88px !important',
    height: '41px',
    '& legend': {
      display: 'none !important',
    },

    '& fieldset': {
      top: 0,
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#01486B !important',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Mark-Light !important',
      fontSize: '12px !important',
      padding: '16.5px 14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },
  menuStyle: {
    '&.MuiMenuItem-root': { fontFamily: 'Mark-Light !important', fontSize: '12px !important' },
  },
});

const FilterInput = ({
  handleChange,
  inputValue,
  data = [],
  fieldId,
  icon,
  includesAll,
  allLabel,
  label,
  borderColor,
}) => {
  const language = useSelector((state) => state.language);
  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" sx={{ backgroundColor: 'white', borderRadius: '6.88px !important' }}>
      {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
      <Select
        variant="outlined"
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select"
        value={inputValue}
        onChange={handleChange}
        label="Select"
        startAdornment={
          icon ? (
            <InputAdornment position="start">
              <img src={icon} width="28px" height="28px" />
            </InputAdornment>
          ) : null
        }
        className={classes.root}
        sx={{
          '& fieldset': {
            borderColor: borderColor ? `${borderColor} !important` : 'white !important',
          },
        }}
      >
        <MenuItem
          sx={{
            fontFamily: 'Mark-Medium !important',
            fontSize: '12px !important',
            lineHeight: '15px !important',
            alignItems: 'center !important',
            color: '#1E1E1E',
          }}
          value="choose"
          disabled
          selected
        >
          {label}
        </MenuItem>
        {includesAll ? (
          <MenuItem
            sx={{
              fontFamily: 'Mark-Medium !important',
              fontSize: '12px !important',
              lineHeight: '15px !important',
              alignItems: 'center !important',
              color: '#707070',
            }}
            value="all"
          >
            {allLabel}
          </MenuItem>
        ) : null}
        {data.length > 0 &&
          data.map((element) => {
            let value;
            let key;
            if (element.localisations) {
              value = element.localisations[language].name;
              key = element._id;
            } else if (element.id) {
              value = element.statisticsTitle;
              key = element;
            } else {
              value = element.option;
              key = element.value;
            }
            return (
              <MenuItem
                sx={{
                  fontFamily: 'Mark-Medium !important',
                  fontSize: '12px !important',
                  lineHeight: '15px !important',
                  alignItems: 'center !important',
                  color: '#707070',
                }}
                value={key}
                key={key}
              >
                {value}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default FilterInput;
