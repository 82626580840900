// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Questions.
export const GET_ALL_QUESTIONS_START = 'GET_ALL_QUESTIONS_START';
export const GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS';
export const GET_ALL_QUESTIONS_FAILED = 'GET_ALL_QUESTIONS_FAILED';
export const GET_ALL_QUESTIONS_RESET = 'GET_ALL_QUESTIONS_RESET';

// Action Types: Get Statistics Questions.
export const GET_STATISTICS_QUESTIONS_START = 'GET_STATISTICS_QUESTIONS_START';
export const GET_STATISTICS_QUESTIONS_SUCCESS = 'GET_STATISTICS_QUESTIONS_SUCCESS';
export const GET_STATISTICS_QUESTIONS_FAILED = 'GET_STATISTICS_QUESTIONS_FAILED';
export const GET_STATISTICS_QUESTIONS_RESET = 'GET_STATISTICS_QUESTIONS_RESET';

// Action Types: Create New Question.
export const CREATE_QUESTIONS_START = 'CREATE_QUESTIONS_START';
export const CREATE_QUESTIONS_SUCCESS = 'CREATE_QUESTIONS_SUCCESS';
export const CREATE_QUESTIONS_FAILED = 'CREATE_QUESTIONS_FAILED';
export const CREATE_QUESTIONS_RESET = 'CREATE_QUESTIONS_RESET';

// Action Types: Update  Question.
export const UPDATE_QUESTIONS_START = 'UPDATE_QUESTIONS_START';
export const UPDATE_QUESTIONS_SUCCESS = 'UPDATE_QUESTIONS_SUCCESS';
export const UPDATE_QUESTIONS_FAILED = 'UPDATE_QUESTIONS_FAILED';
export const UPDATE_QUESTIONS_RESET = 'UPDATE_QUESTIONS_RESET';

// Action Creators: Get All Questions.
const getAllQuestionsStart = (payload) => ({
  type: GET_ALL_QUESTIONS_START,
  payload,
});
const getAllQuestionsSuccess = (payload) => ({
  type: GET_ALL_QUESTIONS_SUCCESS,
  payload,
});
const getAllQuestionsFailed = (payload) => ({
  type: GET_ALL_QUESTIONS_FAILED,
  payload,
});
const getAllQuestionsReset = () => ({ type: GET_ALL_QUESTIONS_RESET });

// Action Creators: Get Statistics Questions.
const getStatisticsQuestionsStart = (payload) => ({
  type: GET_STATISTICS_QUESTIONS_START,
  payload,
});
const getStatisticsQuestionsSuccess = (payload) => ({
  type: GET_STATISTICS_QUESTIONS_SUCCESS,
  payload,
});
const getStatisticsQuestionsFailed = (payload) => ({
  type: GET_STATISTICS_QUESTIONS_FAILED,
  payload,
});
const getStatisticsQuestionsReset = () => ({ type: GET_STATISTICS_QUESTIONS_RESET });

// Action Creators: Create Questions.
const createQuestionsStart = (payload) => ({
  type: CREATE_QUESTIONS_START,
  payload,
});
const createQuestionsSuccess = (payload) => ({
  type: CREATE_QUESTIONS_SUCCESS,
  payload,
});
const createQuestionsFailed = (payload) => ({
  type: CREATE_QUESTIONS_FAILED,
  payload,
});
const createQuestionsReset = () => ({ type: CREATE_QUESTIONS_RESET });

// Action Creators: Update  Question.
const updateQuestionsStart = (payload) => ({
  type: UPDATE_QUESTIONS_START,
  payload,
});
const updateQuestionsSuccess = (payload) => ({
  type: UPDATE_QUESTIONS_SUCCESS,
  payload,
});
const updateQuestionsFailed = (payload) => ({
  type: UPDATE_QUESTIONS_FAILED,
  payload,
});
const updateQuestionsReset = () => ({ type: UPDATE_QUESTIONS_RESET });

// Actions: Get All Questions.
export const getAllQuestions = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllQuestionsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, surveyType } = payload;
      const result = await ApiClient.get('questions', {
        params: { page, limit, pagination, surveyType },
      });
      if (result.data?.success) {
        const { questions } = result.data.data;
        dispatch(
          getAllQuestionsSuccess({
            loading: false,
            success: true,
            data: { questions },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllQuestionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllQuestionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Questions.
export const clearGetAllQuestions = () => getAllQuestionsReset();

// Actions: Get Statistics Questions.
export const getStatisticsQuestions = (payload) => {
  return async (dispatch) => {
    try {
      const { page, limit, pagination, surveyType, filter } = payload;
      const result = await ApiClient.get('questions', {
        params: { page, limit, pagination, surveyType, filter },
      });
      if (result.data?.success) {
        const { questions } = result.data.data;
        dispatch(
          getStatisticsQuestionsSuccess({
            loading: false,
            success: true,
            data: { questions },
            error: false,
            errorMessage: null,
            dataDocs: [],
          })
        );
      } else {
        dispatch(
          getStatisticsQuestionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getStatisticsQuestionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get Statistics Questions.
export const clearGetStatisticsQuestions = () => getStatisticsQuestionsReset();

// Actions: Create Questions.
export const createQuestions = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createQuestionsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { questions, surveyType } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('questions', {
        questions,
        surveyType,
      });
      if (result.data?.success) {
        const { created } = result.data.data;
        dispatch(
          createQuestionsSuccess({
            loading: false,
            success: true,
            data: { created },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createQuestionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createQuestionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Question.
export const clearCreateNewQuestions = () => createQuestionsReset();

// Actions: Update  Question.
export const updateQuestions = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateQuestionsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { questions, surveyType } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`questions`, {
        questions,
        surveyType,
      });
      if (result.data?.success) {
        const { updated } = result.data.data;
        dispatch(
          updateQuestionsSuccess({
            loading: false,
            success: true,
            data: { updated },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateQuestionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateQuestionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update  Question.
export const clearUpdateQuestions = () => updateQuestionsReset();
