// Imports: local files.
import {
  GET_ALL_MENTORSHIPS_START,
  GET_ALL_MENTORSHIPS_SUCCESS,
  GET_ALL_MENTORSHIPS_FAILED,
  GET_ALL_MENTORSHIPS_RESET,
  GET_ONE_MENTORSHIP_START,
  GET_ONE_MENTORSHIP_SUCCESS,
  GET_ONE_MENTORSHIP_FAILED,
  GET_ONE_MENTORSHIP_RESET,
  GET_MENTORSHIP_MEETINGS_START,
  GET_MENTORSHIP_MEETINGS_SUCCESS,
  GET_MENTORSHIP_MEETINGS_FAILED,
  GET_MENTORSHIP_MEETINGS_RESET,
  CREATE_MENTORSHIP_START,
  CREATE_MENTORSHIP_SUCCESS,
  CREATE_MENTORSHIP_FAILED,
  CREATE_MENTORSHIP_RESET,
  CREATE_MENTORSHIP_AUTOMATICALLY_START,
  CREATE_MENTORSHIP_AUTOMATICALLY_SUCCESS,
  CREATE_MENTORSHIP_AUTOMATICALLY_FAILED,
  CREATE_MENTORSHIP_AUTOMATICALLY_RESET,
  UPDATE_ONE_MENTORSHIP_START,
  UPDATE_ONE_MENTORSHIP_SUCCESS,
  UPDATE_ONE_MENTORSHIP_FAILED,
  UPDATE_ONE_MENTORSHIP_RESET,
  UPLOAD_FILE_MENTORSHIP_START,
  UPLOAD_FILE_MENTORSHIP_SUCCESS,
  UPLOAD_FILE_MENTORSHIP_FAILED,
  UPLOAD_FILE_MENTORSHIP_RESET,
  UPLOAD_LINK_MENTORSHIP_START,
  UPLOAD_LINK_MENTORSHIP_SUCCESS,
  UPLOAD_LINK_MENTORSHIP_FAILED,
  UPLOAD_LINK_MENTORSHIP_RESET,
  DELETE_FILE_MENTORSHIP_START,
  DELETE_FILE_MENTORSHIP_SUCCESS,
  DELETE_FILE_MENTORSHIP_FAILED,
  DELETE_FILE_MENTORSHIP_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  getMeetings: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  uploadFile: initialLoadingState,
  uploadLink: initialLoadingState,
  deleteFile: initialLoadingState,
};

const mentorshipsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Mentorships
     * =======================================================================
     */
    case GET_ALL_MENTORSHIPS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORSHIPS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORSHIPS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORSHIPS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Mentorship
     * =======================================================================
     */
    case GET_ONE_MENTORSHIP_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTORSHIP_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTORSHIP_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTORSHIP_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get Mentorship Meetings
     * =======================================================================
     */
    case GET_MENTORSHIP_MEETINGS_START:
      return { ...state, getMeetings: { ...action.payload } };
    case GET_MENTORSHIP_MEETINGS_SUCCESS:
      return { ...state, getMeetings: { ...action.payload } };
    case GET_MENTORSHIP_MEETINGS_FAILED:
      return { ...state, getMeetings: { ...action.payload } };
    case GET_MENTORSHIP_MEETINGS_RESET:
      return { ...state, getMeetings: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create Mentorship
     * =======================================================================
     */
    case CREATE_MENTORSHIP_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_MENTORSHIP_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_MENTORSHIP_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_MENTORSHIP_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create Mentorship Automatically
     * =======================================================================
     */
    case CREATE_MENTORSHIP_AUTOMATICALLY_START:
      return { ...state, createAutomatically: { ...action.payload } };
    case CREATE_MENTORSHIP_AUTOMATICALLY_SUCCESS:
      return { ...state, createAutomatically: { ...action.payload } };
    case CREATE_MENTORSHIP_AUTOMATICALLY_FAILED:
      return { ...state, createAutomatically: { ...action.payload } };
    case CREATE_MENTORSHIP_AUTOMATICALLY_RESET:
      return { ...state, createAutomatically: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Mentorship
     * =======================================================================
     */
    case UPDATE_ONE_MENTORSHIP_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTORSHIP_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTORSHIP_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTORSHIP_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload File Mentorship
     * =======================================================================
     */
    case UPLOAD_FILE_MENTORSHIP_START:
      return { ...state, uploadFile: { ...action.payload } };
    case UPLOAD_FILE_MENTORSHIP_SUCCESS:
      return { ...state, uploadFile: { ...action.payload } };
    case UPLOAD_FILE_MENTORSHIP_FAILED:
      return { ...state, uploadFile: { ...action.payload } };
    case UPLOAD_FILE_MENTORSHIP_RESET:
      return { ...state, uploadFile: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Lik Mentorship
     * =======================================================================
     */
    case UPLOAD_LINK_MENTORSHIP_START:
      return { ...state, uploadLink: { ...action.payload } };
    case UPLOAD_LINK_MENTORSHIP_SUCCESS:
      return { ...state, uploadLink: { ...action.payload } };
    case UPLOAD_LINK_MENTORSHIP_FAILED:
      return { ...state, uploadLink: { ...action.payload } };
    case UPLOAD_LINK_MENTORSHIP_RESET:
      return { ...state, uploadLink: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete File Mentorship
     * =======================================================================
     */
    case DELETE_FILE_MENTORSHIP_START:
      return { ...state, deleteFile: { ...action.payload } };
    case DELETE_FILE_MENTORSHIP_SUCCESS:
      return { ...state, deleteFile: { ...action.payload } };
    case DELETE_FILE_MENTORSHIP_FAILED:
      return { ...state, deleteFile: { ...action.payload } };
    case DELETE_FILE_MENTORSHIP_RESET:
      return { ...state, deleteFile: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default mentorshipsReducer;
