// Imports: local files.
import {
  GET_ALL_BUSINESSES_START,
  GET_ALL_BUSINESSES_SUCCESS,
  GET_ALL_BUSINESSES_FAILED,
  GET_ALL_BUSINESSES_RESET,
  GET_ALL_ADMINS_START,
  GET_ALL_ADMINS_SUCCESS,
  GET_ALL_ADMINS_FAILED,
  GET_ALL_ADMINS_RESET,
  GET_ONE_BUSINESS_START,
  GET_ONE_BUSINESS_SUCCESS,
  GET_ONE_BUSINESS_FAILED,
  GET_ONE_BUSINESS_RESET,
  UPDATE_ONE_BUSINESS_START,
  UPDATE_ONE_BUSINESS_SUCCESS,
  UPDATE_ONE_BUSINESS_FAILED,
  UPDATE_ONE_BUSINESS_RESET,
  UPLOAD_LOGO_BUSINESS_START,
  UPLOAD_LOGO_BUSINESS_SUCCESS,
  UPLOAD_LOGO_BUSINESS_FAILED,
  UPLOAD_LOGO_BUSINESS_RESET,
  UPLOAD_INTERN_CONTRACT_START,
  UPLOAD_INTERN_CONTRACT_SUCCESS,
  UPLOAD_INTERN_CONTRACT_FAILED,
  UPLOAD_INTERN_CONTRACT_RESET,
  DELETE_ONE_BUSINESS_START,
  DELETE_ONE_BUSINESS_SUCCESS,
  DELETE_ONE_BUSINESS_FAILED,
  DELETE_ONE_BUSINESS_RESET,
  SIGN_UP_BUSINESS_START,
  SIGN_UP_BUSINESS_SUCCESS,
  SIGN_UP_BUSINESS_FAILED,
  SIGN_UP_BUSINESS_RESET,
  LOGIN_BUSINESS_START,
  LOGIN_BUSINESS_SUCCESS,
  LOGIN_BUSINESS_FAILED,
  LOGIN_BUSINESS_RESET,
  FORGOT_PASS_BUSINESS_START,
  FORGOT_PASS_BUSINESS_SUCCESS,
  FORGOT_PASS_BUSINESS_FAILED,
  FORGOT_PASS_BUSINESS_RESET,
  RESET_PASS_BUSINESS_START,
  RESET_PASS_BUSINESS_SUCCESS,
  RESET_PASS_BUSINESS_FAILED,
  RESET_PASS_BUSINESS_RESET,
  CONFIRM_BUSINESS_START,
  CONFIRM_BUSINESS_SUCCESS,
  CONFIRM_BUSINESS_FAILED,
  CONFIRM_BUSINESS_RESET,
  RESEND_BUSINESS_EMAIL_START,
  RESEND_BUSINESS_EMAIL_SUCCESS,
  RESEND_BUSINESS_EMAIL_FAILED,
  RESEND_BUSINESS_EMAIL_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getAllAdmins: initialLoadingState,
  getOne: initialLoadingState,
  updateOne: initialLoadingState,
  uploadLogo: initialLoadingState,
  uploadContract: initialLoadingState,
  deleteOne: initialLoadingState,
  signup: initialLoadingState,
  login: initialLoadingState,
  forgotPass: initialLoadingState,
  resetPass: initialLoadingState,
  confirm: initialLoadingState,
  resendEmail: initialLoadingState,
};

const businessesReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Businesses
     * =======================================================================
     */
    case GET_ALL_BUSINESSES_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESSES_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESSES_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESSES_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Businesses
     * =======================================================================
     */
    case GET_ALL_ADMINS_START:
      return { ...state, getAllAdmins: { ...action.payload } };
    case GET_ALL_ADMINS_SUCCESS:
      return { ...state, getAllAdmins: { ...action.payload } };
    case GET_ALL_ADMINS_FAILED:
      return { ...state, getAllAdmins: { ...action.payload } };
    case GET_ALL_ADMINS_RESET:
      return { ...state, getAllAdmins: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Business
     * =======================================================================
     */
    case GET_ONE_BUSINESS_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Business
     * =======================================================================
     */
    case UPDATE_ONE_BUSINESS_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Logo Business
     * =======================================================================
     */
    case UPLOAD_LOGO_BUSINESS_START:
      return { ...state, uploadLogo: { ...action.payload } };
    case UPLOAD_LOGO_BUSINESS_SUCCESS:
      return { ...state, uploadLogo: { ...action.payload } };
    case UPLOAD_LOGO_BUSINESS_FAILED:
      return { ...state, uploadLogo: { ...action.payload } };
    case UPLOAD_LOGO_BUSINESS_RESET:
      return { ...state, uploadLogo: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Intern Contract
     * =======================================================================
     */
    case UPLOAD_INTERN_CONTRACT_START:
      return { ...state, uploadContract: { ...action.payload } };
    case UPLOAD_INTERN_CONTRACT_SUCCESS:
      return { ...state, uploadContract: { ...action.payload } };
    case UPLOAD_INTERN_CONTRACT_FAILED:
      return { ...state, uploadContract: { ...action.payload } };
    case UPLOAD_INTERN_CONTRACT_RESET:
      return { ...state, uploadContract: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Business
     * =======================================================================
     */
    case DELETE_ONE_BUSINESS_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Signup Business
     * =======================================================================
     */
    case SIGN_UP_BUSINESS_START:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_BUSINESS_SUCCESS:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_BUSINESS_FAILED:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_BUSINESS_RESET:
      return { ...state, signup: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Login Business
     * =======================================================================
     */
    case LOGIN_BUSINESS_START:
      return { ...state, login: { ...action.payload } };
    case LOGIN_BUSINESS_SUCCESS:
      return { ...state, login: { ...action.payload } };
    case LOGIN_BUSINESS_FAILED:
      return { ...state, login: { ...action.payload } };
    case LOGIN_BUSINESS_RESET:
      return { ...state, login: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Forgot Password Business
     * =======================================================================
     */
    case FORGOT_PASS_BUSINESS_START:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_BUSINESS_SUCCESS:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_BUSINESS_FAILED:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_BUSINESS_RESET:
      return { ...state, forgotPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Reset Password Business
     * =======================================================================
     */
    case RESET_PASS_BUSINESS_START:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_BUSINESS_SUCCESS:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_BUSINESS_FAILED:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_BUSINESS_RESET:
      return { ...state, resetPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Confirm Business
     * =======================================================================
     */
    case CONFIRM_BUSINESS_START:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_BUSINESS_SUCCESS:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_BUSINESS_FAILED:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_BUSINESS_RESET:
      return { ...state, confirm: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Resend Business Email
     * =======================================================================
     */
    case RESEND_BUSINESS_EMAIL_START:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_BUSINESS_EMAIL_SUCCESS:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_BUSINESS_EMAIL_FAILED:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_BUSINESS_EMAIL_RESET:
      return { ...state, resendEmail: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default businessesReducer;
