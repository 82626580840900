import React from 'react';
import Api from '../../../api';
import { toastNotification } from '../../../utils/toastNotification';
import { Header } from '../../../components/Header';
import { Form, Input } from 'antd';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const ResetUserPassword = (props) => {
  const { t } = useTranslation();
  const userToken = props.match.params.token;
  const userEmail = jwt_decode(userToken);

  const initialState = {
    email: userEmail?.email,
    password: '',
    confirm_password: '',
  };

  const handleSubmit = (data) => {
    Api('users/auth/resetPassword', 'POST', data)
      .then((response) => {
        toastNotification('success', response.message);
        props.history.push('/');
      })
      .catch((err) => toastNotification('error', err.error || 'Something went wrong, please try again!'));
  };

  return (
    <>
      <Header {...props} />
      <div id="resetPassword" className="page-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="title">{t('password-qs1')}</h1>
              <h3 className="subtitle">
                {t('password-qs1-tx')}
                <br />
                {t('password-qs1-tx1')}
                <a className="secondary" href="mailto:info@kosovogenu.com">
                  info@kosovogenu.com
                </a>
              </h3>
            </div>
          </div>
          <Form layout="vertical" name="basic" hideRequiredMark initialValues={initialState} onFinish={handleSubmit}>
            <div className="row">
              {userEmail && (
                <div className="col-sm-12 offset-md-4 col-md-4">
                  <Form.Item label={t('email')} name="email" value={userEmail}>
                    <Input value={userEmail} disabled={true} />
                  </Form.Item>
                </div>
              )}
              <div className="col-sm-12 offset-md-4 col-md-4">
                <Form.Item
                  label={t('new-pass')}
                  name="password"
                  rules={[{ required: true, message: 'Ju lutem vendoseni fjalëkalimin.' }]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <div className="col-sm-12 offset-md-4 col-md-4">
                <Form.Item
                  label={t('new-pass-confirm')}
                  name="confirm_password"
                  rules={[{ required: true, message: 'Ju lutem vendoseni fjalëkalimin.' }]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <div className="col-sm-12 text-center">
                <button type="submit" className="btn btn-kgen-secondary m-auto">
                  RUAJ
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ResetUserPassword;
