import React, { useEffect, useState } from 'react';
import { toastNotification } from '../../../utils/toastNotification';
import { Header } from '../../../components/Header';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  forgotPassBusiness,
  clearForgotPass,
  forgotPassMentor,
  clearForgotPassMentor,
  forgotPassIntern,
  clearForgotPassIntern,
} from '../../../store/actions/actions';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import { Typography, OutlinedInput } from '@mui/material';
import { LoadingOutlined } from '@ant-design/icons';
import { makeStyles } from '@mui/styles';

const schema = Joi.object({
  email: Joi.string().required(),
}).required();

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '35px !important',
    borderRadius: '7px !important',
    fontSize: '14px !important',
    fontFamily: 'Mark-Light !important',
    color: '#1E1E1E !important',
    boxShadow: '0px 3px 6px #00000029 !important',
    opacity: 1,
    marginTop: '7px',
    '& input::placeholder': {
      fontFamily: 'Mark-Light !important',
      fontSize: '13px',
      color: '#1E1E1E !important',
      alignItems: 'center',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white',
    },
    '&.Mui-focused': {
      border: '1px solid #01486B',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
});

const ForgotUserPassword = (props) => {
  let defaultUser = 'business';
  if (props.location && props.location.state && props.location.state.type === 'mentor') defaultUser = 'mentor';
  else if (props.location && props.location.state && props.location.state.type === 'intern') defaultUser = 'intern';

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const token = localStorage.getItem('kgen-token');

  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const forgotBusinessPassResponse = useSelector(({ businesses }) => businesses.forgotPass);
  const forgotInternPassResponse = useSelector(({ interns }) => interns.forgotPass);
  const forgotMentorPassResponse = useSelector(({ mentors }) => mentors.forgotPass);
  const language = useSelector((state) => state.language);

  const resetField = () => {
    setValue('email', '');
  };

  const onSubmit = (data) => {
    const { email } = data;
    const payload = { email, emailLanguage: language };
    const options = {
      toastNotification,
      history,
      onSuccessMessage: t('mailSent'),
      onFailMessage: t('failedToSend'),
      onUserFailMessage: t('userNotFound'),
    };
    if (defaultUser === 'mentor') {
      dispatch(forgotPassMentor(payload, { ...options, pathname: '/admin/login' }));
      resetField();
    } else if (defaultUser === 'intern') {
      dispatch(forgotPassIntern(payload, { ...options, pathname: '/login' }));
      resetField();
    } else {
      dispatch(forgotPassBusiness(payload, { ...options, pathname: '/admin/login' }));
      resetField();
    }
  };

  useEffect(() => {
    if (forgotBusinessPassResponse && forgotBusinessPassResponse.loading) setLoading(true);
    else if (forgotBusinessPassResponse && forgotBusinessPassResponse.error) setLoading(false);
    else if (forgotInternPassResponse && forgotInternPassResponse.loading) setLoading(true);
    else if (forgotInternPassResponse && forgotInternPassResponse.error) setLoading(false);
    else if (forgotMentorPassResponse && forgotMentorPassResponse.loading) setLoading(true);
    else if (forgotMentorPassResponse && forgotMentorPassResponse.error) setLoading(false);
    else if (forgotBusinessPassResponse && forgotBusinessPassResponse.success) setLoading(false);
    else if (forgotInternPassResponse && forgotInternPassResponse.success) setLoading(false);
    else if (forgotMentorPassResponse && forgotMentorPassResponse.success) setLoading(false);
  }, [forgotBusinessPassResponse, forgotInternPassResponse, forgotMentorPassResponse]);

  useEffect(() => {
    dispatch(clearForgotPass());
    dispatch(clearForgotPassMentor());
    dispatch(clearForgotPassIntern());
  }, []);

  if (loading) {
    return (
      <>
        <Header {...props} />
        <div id="forgotPassword" className="page-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Typography
                  variant="h3"
                  sx={{
                    color: '#01486B !important',
                    fontFamily: 'Mark-Bold !important',
                    fontSize: '32px',
                    textAlign: 'center',
                    mb: 2,
                  }}
                >
                  {t('password-qs1')}
                </Typography>
                <h3 className="subtitle">
                  {t('password-sntc')}
                  <br />
                  {t('password-qs1-tx1')}
                  <a className="secondary" href="mailto:info@kosovogenu.com">
                    info@kosovogenu.com
                  </a>
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 offset-md-5 col-md-4">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 100, textAlign: 'center' }} spin />} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (token) return <Redirect to="/" />;
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Header {...props} />
      <div id="forgotPassword" className="page-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Typography
                variant="h3"
                sx={{
                  color: '#01486B !important',
                  fontFamily: 'Mark-Bold !important',
                  fontSize: '32px',
                  textAlign: 'center',
                }}
              >
                {t('password-qs1')}
              </Typography>
              <Typography
                sx={{
                  color: '#01486B !important',
                  fontFamily: 'Mark-Light !important',
                  fontSize: '16px',
                  textAlign: 'center',
                  pt: 2,
                }}
              >
                {t('password-sntc')}
                <br />
                {t('password-qs1-tx1')}
                <a className="secondary" href="mailto:info@kosovogenu.com">
                  info@kosovogenu.com
                </a>
              </Typography>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="org-form"
            layout="vertical"
            name="basic"
            style={{ marginTop: '15px' }}
          >
            <div className="row">
              <div className="col-sm-12 offset-md-4 col-md-4">
                <OutlinedInput
                  fullWidth
                  className={classes.root}
                  placeholder={'Email'}
                  id="email"
                  onChange={(e) => setValue('email', e.target.value)}
                  sx={{
                    border: errors.email ? '1px solid red' : '1px solid #d9d9d9',
                    '&:hover fieldset': {
                      borderColor: errors.email ? 'red !important' : '#01486B !important',
                    },
                  }}
                />
                {errors.email && <span style={{ color: 'red' }}>{t('emailError')}</span>}
              </div>
              <div className="col-sm-12 text-center" style={{ marginTop: '15px' }}>
                <button
                  type="submit"
                  className="btn btn-kgen m-auto pl-5 pr-5 pt-2 pb-2"
                  style={{ backgroundColor: '#01486B', color: '#fafafa', borderRadius: '8px' }}
                >
                  {t('dergo')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('translations')(ForgotUserPassword);
