import {
  GET_ALL_DOCUMENTS_START,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_FAILED,
  GET_ALL_DOCUMENTS_RESET,
  GET_ONE_DOCUMENT_START,
  GET_ONE_DOCUMENT_SUCCESS,
  GET_ONE_DOCUMENT_FAILED,
  GET_ONE_DOCUMENT_RESET,
  CREATE_ONE_DOCUMENT_START,
  CREATE_ONE_DOCUMENT_SUCCESS,
  CREATE_ONE_DOCUMENT_FAILED,
  CREATE_ONE_DOCUMENT_RESET,
  UPDATE_ONE_DOCUMENT_START,
  UPDATE_ONE_DOCUMENT_SUCCESS,
  UPDATE_ONE_DOCUMENT_FAILED,
  UPDATE_ONE_DOCUMENT_RESET,
  DELETE_ONE_DOCUMENT_START,
  DELETE_ONE_DOCUMENT_SUCCESS,
  DELETE_ONE_DOCUMENT_FAILED,
  DELETE_ONE_DOCUMENT_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};

const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Documents
     * =======================================================================
     */
    case GET_ALL_DOCUMENTS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_DOCUMENTS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_DOCUMENTS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_DOCUMENTS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Document
     * =======================================================================
     */
    case GET_ONE_DOCUMENT_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_DOCUMENT_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_DOCUMENT_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_DOCUMENT_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Document
     * =======================================================================
     */
    case CREATE_ONE_DOCUMENT_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_ONE_DOCUMENT_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_ONE_DOCUMENT_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_ONE_DOCUMENT_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Document
     * =======================================================================
     */
    case UPDATE_ONE_DOCUMENT_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_DOCUMENT_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_DOCUMENT_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_DOCUMENT_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Document
     * =======================================================================
     */
    case DELETE_ONE_DOCUMENT_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_DOCUMENT_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_DOCUMENT_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_DOCUMENT_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default documentsReducer;
