// Imports: local files.
import {
  GET_ALL_ANSWERS_START,
  GET_ALL_ANSWERS_SUCCESS,
  GET_ALL_ANSWERS_FAILED,
  GET_ALL_ANSWERS_RESET,
  GET_ONE_ANSWER_START,
  GET_ONE_ANSWER_SUCCESS,
  GET_ONE_ANSWER_FAILED,
  GET_ONE_ANSWER_RESET,
  CREATE_ANSWER_START,
  CREATE_ANSWER_SUCCESS,
  CREATE_ANSWER_FAILED,
  CREATE_ANSWER_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
};

const answersReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Answers
     * =======================================================================
     */
    case GET_ALL_ANSWERS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_ANSWERS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_ANSWERS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_ANSWERS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Answer
     * =======================================================================
     */
    case GET_ONE_ANSWER_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_ANSWER_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_ANSWER_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_ANSWER_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Answer
     * =======================================================================
     */
    case CREATE_ANSWER_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_ANSWER_RESET:
      return { ...state, create: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default answersReducer;
