import Chart from 'react-apexcharts';

export const PieChart = ({ labels, values }) => {
  const chartData = {
    height: 480,
    type: 'pie',
    options: {
      chart: {
        id: 'checkbox-chart',
        toolbar: {
          show: true,
        },
      },
      labels: labels,
      legend: {
        show: true,
        position: 'bottom',
        fontFamily: 'inherit',
        labels: {
          colors: 'inherit',
        },
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        },
      },
      theme: {
        monochrome: {
          enabled: true,
        },
      },
    },
    series: values,
  };
  return <Chart {...chartData} />;
};
