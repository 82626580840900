// Imports: local files.
import {
  ASSIGN_MENTOR_START,
  ASSIGN_MENTOR_SUCCESS,
  ASSIGN_MENTOR_FAILED,
  ASSIGN_MENTOR_RESET,
  REASSIGN_MENTOR_START,
  REASSIGN_MENTOR_SUCCESS,
  REASSIGN_MENTOR_FAILED,
  REASSIGN_MENTOR_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  assign: initialLoadingState,
  reassign: initialLoadingState,
};

const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Assign Mentor
     * =======================================================================
     */
    case ASSIGN_MENTOR_START:
      return { ...state, assign: { ...action.payload } };
    case ASSIGN_MENTOR_SUCCESS:
      return { ...state, assign: { ...action.payload } };
    case ASSIGN_MENTOR_FAILED:
      return { ...state, assign: { ...action.payload } };
    case ASSIGN_MENTOR_RESET:
      return { ...state, assign: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Intern
     * =======================================================================
     */
    case REASSIGN_MENTOR_START:
      return { ...state, reassign: { ...action.payload } };
    case REASSIGN_MENTOR_SUCCESS:
      return { ...state, reassign: { ...action.payload } };
    case REASSIGN_MENTOR_FAILED:
      return { ...state, reassign: { ...action.payload } };
    case REASSIGN_MENTOR_RESET:
      return { ...state, reassign: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default adminsReducer;
