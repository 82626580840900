// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All Surveys.
export const GET_ALL_SURVEYS_START = 'GET_ALL_SURVEYS_START';
export const GET_ALL_SURVEYS_SUCCESS = 'GET_ALL_SURVEYS_SUCCESS';
export const GET_ALL_SURVEYS_FAILED = 'GET_ALL_SURVEYS_FAILED';
export const GET_ALL_SURVEYS_RESET = 'GET_ALL_SURVEYS_RESET';

// Action Types: Get One Survey.
export const GET_ONE_SURVEY_START = 'GET_ONE_SURVEY_START';
export const GET_ONE_SURVEY_SUCCESS = 'GET_ONE_SURVEY_SUCCESS';
export const GET_ONE_SURVEY_FAILED = 'GET_ONE_SURVEY_FAILED';
export const GET_ONE_SURVEY_RESET = 'GET_ONE_SURVEY_RESET';

// Action Types: Update One Survey.
export const UPDATE_ONE_SURVEY_START = 'UPDATE_ONE_SURVEY_START';
export const UPDATE_ONE_SURVEY_SUCCESS = 'UPDATE_ONE_SURVEY_SUCCESS';
export const UPDATE_ONE_SURVEY_FAILED = 'UPDATE_ONE_SURVEY_FAILED';
export const UPDATE_ONE_SURVEY_RESET = 'UPDATE_ONE_SURVEY_RESET';

// Action Types: Delete One Survey.
export const DELETE_ONE_SURVEY_START = 'DELETE_ONE_SURVEY_START';
export const DELETE_ONE_SURVEY_SUCCESS = 'DELETE_ONE_SURVEY_SUCCESS';
export const DELETE_ONE_SURVEY_FAILED = 'DELETE_ONE_SURVEY_FAILED';
export const DELETE_ONE_SURVEY_RESET = 'DELETE_ONE_SURVEY_RESET';

// Action Creators: Get All Surveys.
const getAllSurveysStart = (payload) => ({
  type: GET_ALL_SURVEYS_START,
  payload,
});
const getAllSurveysSuccess = (payload) => ({
  type: GET_ALL_SURVEYS_SUCCESS,
  payload,
});
const getAllSurveysFailed = (payload) => ({
  type: GET_ALL_SURVEYS_FAILED,
  payload,
});
const getAllSurveysReset = () => ({ type: GET_ALL_SURVEYS_RESET });

// Action Creators: Get One Survey.
const getOneSurveyStart = (payload) => ({
  type: GET_ONE_SURVEY_START,
  payload,
});
const getOneSurveySuccess = (payload) => ({
  type: GET_ONE_SURVEY_SUCCESS,
  payload,
});
const getOneSurveyFailed = (payload) => ({
  type: GET_ONE_SURVEY_FAILED,
  payload,
});
const getOneSurveyReset = () => ({ type: GET_ONE_SURVEY_RESET });

// Action Creators: Update One Survey.
const updateOneSurveyStart = (payload) => ({
  type: UPDATE_ONE_SURVEY_START,
  payload,
});
const updateOneSurveySuccess = (payload) => ({
  type: UPDATE_ONE_SURVEY_SUCCESS,
  payload,
});
const updateOneSurveyFailed = (payload) => ({
  type: UPDATE_ONE_SURVEY_FAILED,
  payload,
});
const updateOneSurveyReset = () => ({ type: UPDATE_ONE_SURVEY_RESET });

// Action Creators: Delete One Survey.
const deleteOneSurveyStart = (payload) => ({
  type: DELETE_ONE_SURVEY_START,
  payload,
});
const deleteOneSurveySuccess = (payload) => ({
  type: DELETE_ONE_SURVEY_SUCCESS,
  payload,
});
const deleteOneSurveyFailed = (payload) => ({
  type: DELETE_ONE_SURVEY_FAILED,
  payload,
});
const deleteOneSurveyReset = () => ({ type: DELETE_ONE_SURVEY_RESET });

// Actions: Get All Surveys.
export const getAllSurveys = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllSurveysStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { business, mentor, completed } = payload;
      const result = await ApiClient.get('surveys', {
        params: { business, mentor, completed },
      });
      if (result.data?.success) {
        const { surveys } = result.data.data;
        dispatch(
          getAllSurveysSuccess({
            loading: false,
            success: true,
            data: { surveys },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllSurveysFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllSurveysFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Surveys.
export const clearGetAllSurveys = () => getAllSurveysReset();

// Actions: Get One Survey.
export const getOneSurvey = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneSurveyStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { surveyId } = payload;
      const result = await ApiClient.get(`surveys/${surveyId}`);
      if (result.data?.success) {
        const { survey } = result.data.data;
        dispatch(
          getOneSurveySuccess({
            loading: false,
            success: true,
            data: { survey },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneSurveyFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneSurveyFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Survey.
export const clearGetOneSurvey = () => getOneSurveyReset();

// Actions: Update One Survey.
export const updateOneSurvey = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      updateOneSurveyStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { surveyId, questions } = payload;
    const { toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage, onCompleteMessage } =
      options;
    try {
      const result = await ApiClient.put(`surveys/${surveyId}`, { questions });
      if (result.data?.success) {
        const { survey } = result.data.data;
        dispatch(
          updateOneSurveySuccess({
            loading: false,
            success: true,
            data: { survey },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onCompleteMessage}`,
            // text: 'Thank you!',
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
          shouldRedirect && history.push(pathname);
        } else {
          toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          updateOneSurveyFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneSurveyFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Survey.
export const clearUpdateOneSurvey = () => updateOneSurveyReset();

// Actions: Delete One Survey.
export const deleteOneSurvey = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneSurveyStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { surveyId } = payload;
      const result = await ApiClient.delete(`surveys/${surveyId}`);
      if (result.data?.success) {
        const { survey } = result.data.data;
        dispatch(
          deleteOneSurveySuccess({
            loading: false,
            success: true,
            data: { survey },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneSurveyFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneSurveyFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Survey.
export const clearDeleteOneSurvey = () => deleteOneSurveyReset();
