// Imports: local files.
import ApiClient from '../../services/ApiClient';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

// Action Types: Assign Mentor.
export const ASSIGN_MENTOR_START = 'ASSIGN_MENTOR_START';
export const ASSIGN_MENTOR_SUCCESS = 'ASSIGN_MENTOR_SUCCESS';
export const ASSIGN_MENTOR_FAILED = 'ASSIGN_MENTOR_FAILED';
export const ASSIGN_MENTOR_RESET = 'ASSIGN_MENTOR_RESET';

// Action Types: Reassign Mentor.
export const REASSIGN_MENTOR_START = 'REASSIGN_MENTOR_START';
export const REASSIGN_MENTOR_SUCCESS = 'REASSIGN_MENTOR_SUCCESS';
export const REASSIGN_MENTOR_FAILED = 'REASSIGN_MENTOR_FAILED';
export const REASSIGN_MENTOR_RESET = 'REASSIGN_MENTOR_RESET';

// Action Creators: Assign Mentor.
const assignMentorStart = (payload) => ({
  type: ASSIGN_MENTOR_START,
  payload,
});
const assignMentorSuccess = (payload) => ({
  type: ASSIGN_MENTOR_SUCCESS,
  payload,
});
const assignMentorFailed = (payload) => ({
  type: ASSIGN_MENTOR_FAILED,
  payload,
});
const assignMentorReset = () => ({ type: ASSIGN_MENTOR_RESET });

// Action Creators: Update One Intern.
const reassignMentorStart = (payload) => ({
  type: REASSIGN_MENTOR_SUCCESS,
  payload,
});
const reassignMentorSuccess = (payload) => ({
  type: REASSIGN_MENTOR_START,
  payload,
});
const reassignMentorFailed = (payload) => ({
  type: REASSIGN_MENTOR_FAILED,
  payload,
});
const reassignMentorReset = () => ({ type: REASSIGN_MENTOR_RESET });

// Actions: Get All Interns.
export const assignMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      assignMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { applicationId, internId, mentorId, emailLanguage } = payload;
      const result = await ApiClient.post('admins/assigns', {
        applicationId,
        internId,
        mentorId,
        emailLanguage
      });
      if (result.data?.success) {
        const { mentorship } = result.data?.data;
        dispatch(
          assignMentorSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          assignMentorFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        assignMentorFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Assign Mentor.
export const clearAssignMentor = () => assignMentorReset();

// Actions: Reassign Mentor.
export const reassignMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      reassignMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, internId, mentorId, emailLanguage } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.post('admins/reassigns', {
        applicationId,
        internId,
        mentorId,
        emailLanguage
      });
      if (result.data?.success) {
        const { mentorship } = result.data.data;
        dispatch(
          reassignMentorSuccess({
            loading: false,
            success: true,
            data: { mentorship },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          reassignMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        reassignMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One.
export const clearReassignMentor = () => reassignMentorReset();
