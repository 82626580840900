import React from 'react';
import { InputAdornment, OutlinedInput, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchIcon } from '../SearchIcon';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  defaultInput: {
    width: '100%',
    height: '41px',
    borderWidth: '6px',
    fontFamily: 'Mark-Light !important',
    borderRadius: '6.88px !important',
    backgroundColor: '#FFFFFF',

    '&.MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'red !important',
      },
    },

    '& input::placeholder': {
      fontFamily: 'Mark-Light',
      color: '#707070',
      fontSize: '12px',
      lineHeight: '15px',
      alignItems: 'center',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },

    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: 'red',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '12px',
      fontFamily: 'Mark-Light',
    },
  },
});

const SearchBar = ({ handleChange, inputValue, handleEnter }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <OutlinedInput
      fullWidth
      className={classes.defaultInput}
      value={inputValue ? inputValue : ''}
      onChange={handleChange}
      onKeyPress={handleEnter ? handleEnter : null}
      placeholder={t('writeTitle')}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        inputValue &&
        inputValue.trim() !== '' && (
          <IconButton position="start">
            <CloseOutlined stroke={1.5} onClick={() => handleChange(null)} size="1rem" color={'gray'} />
          </IconButton>
        )
      }
    />
  );
};

export default SearchBar;
