// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All Applications.
export const GET_ALL_APPLICATIONS_START = 'GET_ALL_APPLICATIONS_START';
export const GET_ALL_APPLICATIONS_SUCCESS = 'GET_ALL_APPLICATIONS_SUCCESS';
export const GET_ALL_APPLICATIONS_FAILED = 'GET_ALL_APPLICATIONS_FAILED';
export const GET_ALL_APPLICATIONS_RESET = 'GET_ALL_APPLICATIONS_RESET';

// Action Types: Get All Accepted For Validation Applications.
export const GET_VALIDATION_APPLICATIONS_START = 'GET_VALIDATION_APPLICATIONS_START';
export const GET_VALIDATION_APPLICATIONS_SUCCESS = 'GET_VALIDATION_APPLICATIONS_SUCCESS';
export const GET_VALIDATION_APPLICATIONS_FAILED = 'GET_VALIDATION_APPLICATIONS_FAILED';
export const GET_VALIDATION_APPLICATIONS_RESET = 'GET_VALIDATION_APPLICATIONS_RESET';

// Action Types: Get One Application.
export const GET_ONE_APPLICATION_START = 'GET_ONE_APPLICATION_START';
export const GET_ONE_APPLICATION_SUCCESS = 'GET_ONE_APPLICATION_SUCCESS';
export const GET_ONE_APPLICATION_FAILED = 'GET_ONE_APPLICATION_FAILED';
export const GET_ONE_APPLICATION_RESET = 'GET_ONE_APPLICATION_RESET';

// Action Types: Create New Application.
export const CREATE_APPLICATION_START = 'CREATE_APPLICATION_START';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const CREATE_APPLICATION_RESET = 'CREATE_APPLICATION_RESET';

// Action Types: Update One Application.
export const UPDATE_ONE_APPLICATION_START = 'UPDATE_ONE_APPLICATION_START';
export const UPDATE_ONE_APPLICATION_SUCCESS = 'UPDATE_ONE_APPLICATION_SUCCESS';
export const UPDATE_ONE_APPLICATION_FAILED = 'UPDATE_ONE_APPLICATION_FAILED';
export const UPDATE_ONE_APPLICATION_RESET = 'UPDATE_ONE_APPLICATION_RESET';

// Action Types: Favourite One Application.
export const FAVOURITE_ONE_APPLICATION_START = 'FAVOURITE_ONE_APPLICATION_START';
export const FAVOURITE_ONE_APPLICATION_SUCCESS = 'FAVOURITE_ONE_APPLICATION_SUCCESS';
export const FAVOURITE_ONE_APPLICATION_FAILED = 'FAVOURITE_ONE_APPLICATION_FAILED';
export const FAVOURITE_ONE_APPLICATION_RESET = 'FAVOURITE_ONE_APPLICATION_RESET';

// Action Types: Already Applied Application.
export const ALREADY_APPLIED_APPLICATION_START = 'ALREADY_APPLIED_APPLICATION_START';
export const ALREADY_APPLIED_APPLICATION_SUCCESS = 'ALREADY_APPLIED_APPLICATION_SUCCESS';
export const ALREADY_APPLIED_APPLICATION_FAILED = 'ALREADY_APPLIED_APPLICATION_FAILED';
export const ALREADY_APPLIED_APPLICATION_RESET = 'ALREADY_APPLIED_APPLICATION_RESET';

// Action Types: Delete One Application.
export const DELETE_ONE_APPLICATION_START = 'DELETE_ONE_APPLICATION_START';
export const DELETE_ONE_APPLICATION_SUCCESS = 'DELETE_ONE_APPLICATION_SUCCESS';
export const DELETE_ONE_APPLICATION_FAILED = 'DELETE_ONE_APPLICATION_FAILED';
export const DELETE_ONE_APPLICATION_RESET = 'DELETE_ONE_APPLICATION_RESET';

// Action Types: Generate One Certificate.
export const GENERATE_ONE_CERTIFICATE_START = 'GENERATE_ONE_CERTIFICATE_START';
export const GENERATE_ONE_CERTIFICATE_SUCCESS = 'GENERATE_ONE_CERTIFICATE_SUCCESS';
export const GENERATE_ONE_CERTIFICATE_FAILED = 'GENERATE_ONE_CERTIFICATE_FAILED';
export const GENERATE_ONE_CERTIFICATE_RESET = 'GENERATE_ONE_CERTIFICATE_RESET';

//Action Types: Finished Intership
export const FINISHED_INTERSHIP_START = 'FINISHED_INTERSHIP_START';
export const FINISHED_INTERSHIP_SUCCESS = 'FINISHED_INTERSHIP_SUCCESS';
export const FINISHED_INTERSHIP_FAILED = 'FINISHED_INTERSHIP_FAILED';
export const FINISHED_INTERSHIP_RESET = 'FINISHED_INTERSHIP_RESET';

// Action Creators: Get All Applications.
const getAllApplicationsStart = (payload) => ({
  type: GET_ALL_APPLICATIONS_START,
  payload,
});
const getAllApplicationsSuccess = (payload) => ({
  type: GET_ALL_APPLICATIONS_SUCCESS,
  payload,
});
const getAllApplicationsFailed = (payload) => ({
  type: GET_ALL_APPLICATIONS_FAILED,
  payload,
});
const getAllApplicationsReset = () => ({ type: GET_ALL_APPLICATIONS_RESET });

// Action Creators: Get All Accepted For Validation Applications.
const getValidationApplicationsStart = (payload) => ({
  type: GET_VALIDATION_APPLICATIONS_START,
  payload,
});
const getValidationApplicationsSuccess = (payload) => ({
  type: GET_VALIDATION_APPLICATIONS_SUCCESS,
  payload,
});
const getValidationApplicationsFailed = (payload) => ({
  type: GET_VALIDATION_APPLICATIONS_FAILED,
  payload,
});
const getValidationApplicationsReset = () => ({ type: GET_VALIDATION_APPLICATIONS_RESET });

// Action Creators: Get One Application.
const getOneApplicationStart = (payload) => ({
  type: GET_ONE_APPLICATION_START,
  payload,
});
const getOneApplicationSuccess = (payload) => ({
  type: GET_ONE_APPLICATION_SUCCESS,
  payload,
});
const getOneApplicationFailed = (payload) => ({
  type: GET_ONE_APPLICATION_FAILED,
  payload,
});
const getOneApplicationReset = () => ({ type: GET_ONE_APPLICATION_RESET });

// Action Creators: Create New Application.
const createApplicationStart = (payload) => ({
  type: CREATE_APPLICATION_START,
  payload,
});
const createApplicationSuccess = (payload) => ({
  type: CREATE_APPLICATION_SUCCESS,
  payload,
});
const createApplicationFailed = (payload) => ({
  type: CREATE_APPLICATION_FAILED,
  payload,
});
const createApplicationReset = () => ({ type: CREATE_APPLICATION_RESET });

// Action Creators: Update One Application.
const updateOneApplicationStart = (payload) => ({
  type: UPDATE_ONE_APPLICATION_START,
  payload,
});
const updateOneApplicationSuccess = (payload) => ({
  type: UPDATE_ONE_APPLICATION_SUCCESS,
  payload,
});
const updateOneApplicationFailed = (payload) => ({
  type: UPDATE_ONE_APPLICATION_FAILED,
  payload,
});
const updateOneApplicationReset = () => ({ type: UPDATE_ONE_APPLICATION_RESET });

// Action Creators: Favourite One Application.
const favouriteOneApplicationStart = (payload) => ({
  type: FAVOURITE_ONE_APPLICATION_START,
  payload,
});
const favouriteOneApplicationSuccess = (payload) => ({
  type: FAVOURITE_ONE_APPLICATION_SUCCESS,
  payload,
});
const favouriteOneApplicationFailed = (payload) => ({
  type: FAVOURITE_ONE_APPLICATION_FAILED,
  payload,
});
const favouriteOneApplicationReset = () => ({ type: FAVOURITE_ONE_APPLICATION_RESET });

// Action Creators: Already Applied Application.
const alreadyAppliedApplicationStart = (payload) => ({
  type: ALREADY_APPLIED_APPLICATION_START,
  payload,
});
const alreadyAppliedApplicationSuccess = (payload) => ({
  type: ALREADY_APPLIED_APPLICATION_SUCCESS,
  payload,
});
const alreadyAppliedApplicationFailed = (payload) => ({
  type: ALREADY_APPLIED_APPLICATION_FAILED,
  payload,
});
const alreadyAppliedApplicationReset = () => ({ type: ALREADY_APPLIED_APPLICATION_RESET });

// Action Creators: Delete One Application.
const deleteOneApplicationStart = (payload) => ({
  type: DELETE_ONE_APPLICATION_START,
  payload,
});
const deleteOneApplicationSuccess = (payload) => ({
  type: DELETE_ONE_APPLICATION_SUCCESS,
  payload,
});
const deleteOneApplicationFailed = (payload) => ({
  type: DELETE_ONE_APPLICATION_FAILED,
  payload,
});
const deleteOneApplicationReset = () => ({ type: DELETE_ONE_APPLICATION_RESET });

// Action Creators: Generate One Certificate.
const generateOneCertificateStart = (payload) => ({
  type: GENERATE_ONE_CERTIFICATE_START,
  payload,
});
const generateOneCertificateSuccess = (payload) => ({
  type: GENERATE_ONE_CERTIFICATE_SUCCESS,
  payload,
});
const generateOneCertificateFailed = (payload) => ({
  type: GENERATE_ONE_CERTIFICATE_FAILED,
  payload,
});
const generateOneCertificateReset = () => ({ type: GENERATE_ONE_CERTIFICATE_RESET });

// Action Creators: Finished Intership.
const finishedIntershipStart = (payload) => ({
  type: FINISHED_INTERSHIP_START,
  payload,
});
const finishedIntershipSuccess = (payload) => ({
  type: FINISHED_INTERSHIP_SUCCESS,
  payload,
});
const finishedIntershipFailed = (payload) => ({
  type: FINISHED_INTERSHIP_FAILED,
  payload,
});
const finishedIntershipReset = () => ({ type: FINISHED_INTERSHIP_RESET });

// Actions: Get All Applications.
export const getAllApplications = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllApplicationsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        municipality,
        business,
        intern,
        job,
        status,
        favourites,
        sort = 'desc',
        availability,
        type,
        training,
      } = payload;
      const result = await ApiClient.get('applications', {
        params: {
          page,
          limit,
          pagination,
          municipality,
          business,
          intern,
          job,
          status,
          favourites,
          sort,
          availability,
          type,
          training,
        },
      });
      if (result.data?.success) {
        const { applications } = result.data.data;

        dispatch(
          getAllApplicationsSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllApplicationsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllApplicationsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Applications.
export const clearGetAllApplications = () => getAllApplicationsReset();

// Actions: Get All Applications.
export const getValidationApplications = (payload) => {
  return async (dispatch) => {
    dispatch(
      getValidationApplicationsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        firstName,
        lastName,
        municipality,
        skills,
        categories,
        sort,
        gender,
        type,
        availability,
        startDate,
        endDate,
      } = payload;
      const result = await ApiClient.get('applications/interns/validation', {
        params: {
          page,
          limit,
          pagination,
          firstName,
          lastName,
          municipality,
          skills,
          categories,
          sort,
          gender,
          type,
          availability,
          startDate,
          endDate,
        },
      });
      if (result.data?.success) {
        const { applications } = result.data.data;
        dispatch(
          getValidationApplicationsSuccess({
            loading: false,
            success: true,
            data: { applications },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getValidationApplicationsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getValidationApplicationsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Applications.
export const clearGetValidationApplications = () => getValidationApplicationsReset();

// Actions: Get One Application.
export const getOneApplication = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { applicationId } = payload;
      const result = await ApiClient.get(`applications/${applicationId}`);
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          getOneApplicationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Application.
export const clearGetOneApplication = () => getOneApplicationReset();

// Actions: Create New Application.
export const createApplication = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { jobId, trainingId, type, formData } = payload;
    const {
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      alreadyWorkingMessage,
      onAgeFailMessage,
    } = options;

    try {
      const result = await ApiClient.post('applications', formData);
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          createApplicationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          createApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'ALREADY_WORKING'
      ) {
        toastNotification('error', alreadyWorkingMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'ABOVE_AGE'
      ) {
        toastNotification('error', onAgeFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Application.
export const clearCreateNewApplication = () => createApplicationReset();

// Actions: Update One Application.
export const updateOneApplication = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      updateOneApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, formData } = payload;
    const {
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onHireMessage,
      onHireText,
      onHireFailMessage,
      shouldShowHireMessage,
      onAgeFailMessage,
    } = options;
    try {
      const result = await ApiClient.put(`applications/${applicationId}`, formData);
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          updateOneApplicationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onHireMessage}`,
            text: `${onHireText}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
          shouldRedirect && history.push(pathname);
        } else {
          toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          updateOneApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'ALREADY_WORKING'
      ) {
        shouldShowHireMessage && toastNotification('error', onHireFailMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'ABOVE_AGE'
      ) {
        shouldShowHireMessage && toastNotification('error', onAgeFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Update One Application.
export const clearUpdateOneApplication = () => updateOneApplicationReset();

// Actions: Favourite One Application.
export const favouriteOneApplication = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      favouriteOneApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onAgeFailMessage } = options;

    try {
      const result = await ApiClient.put(`applications/${applicationId}/favourite`);
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          favouriteOneApplicationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          favouriteOneApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        favouriteOneApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'ABOVE_AGE'
      ) {
        toastNotification('error', onAgeFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Favourite One Application.
export const clearFavouriteOneApplication = () => favouriteOneApplicationReset();

// Actions: Already Applied Application.
export const alreadyAppliedApplication = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      alreadyAppliedApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { jobId, trainingId, type } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    const path = type === 'TRAINING' ? `applications/${trainingId}/applied` : `applications/${jobId}/applied`;
    try {
      const result = await ApiClient.get(path, {
        params: {
          type,
        },
      });
      if (result.data?.success) {
        const { applied, application } = result.data.data;
        dispatch(
          alreadyAppliedApplicationSuccess({
            loading: false,
            success: true,
            data: { applied, application },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          alreadyAppliedApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        alreadyAppliedApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Already Applied Application.
export const clearAlreadyAppliedApplication = () => alreadyAppliedApplicationReset();

// Actions: Delete One Application.
export const deleteOneApplication = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneApplicationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { applicationId } = payload;
      const result = await ApiClient.delete(`applications/${applicationId}`);
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          deleteOneApplicationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneApplicationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneApplicationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Application.
export const clearDeleteOneApplication = () => deleteOneApplicationReset();

// Actions: Generate One Certificate.

export const generateOneCertificate = (payload) => {
  return async (dispatch) => {
    dispatch(
      generateOneCertificateStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { applicationId } = payload;
      const result = await ApiClient.get(`applications/${applicationId}/generate`, {
        params: {
          payload,
        },
        responseType: 'blob',
      });
      if (result.data?.success) {
        const { data } = result.data;
        dispatch(
          generateOneCertificateSuccess({
            loading: false,
            success: true,
            data: { data },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          generateOneCertificateFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        generateOneCertificateFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Generate One Certificate.
export const clearGenerateOneCertificate = () => generateOneCertificateReset();

// Actions: Finished Intership.

export const finishedIntershipApplication = (payload) => {
  return async (dispatch) => {
    dispatch(
      finishedIntershipStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { intern } = payload;
      const result = await ApiClient.get(`applications/finished`, {
        params: {
          intern,
        },
      });
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          finishedIntershipSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          finishedIntershipFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        finishedIntershipFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear FinishedApplication
export const clearFinishedIntershipApplication = () => finishedIntershipReset();
