// Imports: local files.
import {
  GET_ALL_HEALTH_CONDITIONS_START,
  GET_ALL_HEALTH_CONDITIONS_SUCCESS,
  GET_ALL_HEALTH_CONDITIONS_FAILED,
  GET_ALL_HEALTH_CONDITIONS_RESET,
  GET_ONE_HEALTH_CONDITION_START,
  GET_ONE_HEALTH_CONDITION_SUCCESS,
  GET_ONE_HEALTH_CONDITION_FAILED,
  GET_ONE_HEALTH_CONDITION_RESET,
  CREATE_HEALTH_CONDITION_START,
  CREATE_HEALTH_CONDITION_SUCCESS,
  CREATE_HEALTH_CONDITION_FAILED,
  CREATE_HEALTH_CONDITION_RESET,
  UPDATE_ONE_HEALTH_CONDITION_START,
  UPDATE_ONE_HEALTH_CONDITION_SUCCESS,
  UPDATE_ONE_HEALTH_CONDITION_FAILED,
  UPDATE_ONE_HEALTH_CONDITION_RESET,
  DELETE_ONE_HEALTH_CONDITION_START,
  DELETE_ONE_HEALTH_CONDITION_SUCCESS,
  DELETE_ONE_HEALTH_CONDITION_FAILED,
  DELETE_ONE_HEALTH_CONDITION_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const healthConditionsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Health Conditions
     * =======================================================================
     */
    case GET_ALL_HEALTH_CONDITIONS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_HEALTH_CONDITIONS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_HEALTH_CONDITIONS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_HEALTH_CONDITIONS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Health Condition
     * =======================================================================
     */
    case GET_ONE_HEALTH_CONDITION_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_HEALTH_CONDITION_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_HEALTH_CONDITION_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_HEALTH_CONDITION_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Health Condition
     * =======================================================================
     */
    case CREATE_HEALTH_CONDITION_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_HEALTH_CONDITION_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_HEALTH_CONDITION_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_HEALTH_CONDITION_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Health Condition
     * =======================================================================
     */
    case UPDATE_ONE_HEALTH_CONDITION_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_HEALTH_CONDITION_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_HEALTH_CONDITION_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_HEALTH_CONDITION_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Health Condition
     * =======================================================================
     */
    case DELETE_ONE_HEALTH_CONDITION_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_HEALTH_CONDITION_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_HEALTH_CONDITION_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_HEALTH_CONDITION_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default healthConditionsReducer;
