// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Hear About.
export const GET_ALL_HEAR_ABOUT_START = 'GET_ALL_HEAR_ABOUT_START';
export const GET_ALL_HEAR_ABOUT_SUCCESS = 'GET_ALL_HEAR_ABOUT_SUCCESS';
export const GET_ALL_HEAR_ABOUT_FAILED = 'GET_ALL_HEAR_ABOUT_FAILED';
export const GET_ALL_HEAR_ABOUT_RESET = 'GET_ALL_HEAR_ABOUT_RESET';

// Action Types: Get One Hear About.
export const GET_ONE_HEAR_ABOUT_START = 'GET_ONE_HEAR_ABOUT_START';
export const GET_ONE_HEAR_ABOUT_SUCCESS = 'GET_ONE_HEAR_ABOUT_SUCCESS';
export const GET_ONE_HEAR_ABOUT_FAILED = 'GET_ONE_HEAR_ABOUT_FAILED';
export const GET_ONE_HEAR_ABOUT_RESET = 'GET_ONE_HEAR_ABOUT_RESET';

// Action Types: Create New Hear About.
export const CREATE_HEAR_ABOUT_START = 'CREATE_HEAR_ABOUT_START';
export const CREATE_HEAR_ABOUT_SUCCESS = 'CREATE_HEAR_ABOUT_SUCCESS';
export const CREATE_HEAR_ABOUT_FAILED = 'CREATE_HEAR_ABOUT_FAILED';
export const CREATE_HEAR_ABOUT_RESET = 'CREATE_HEAR_ABOUT_RESET';

// Action Types: Update One Hear About.
export const UPDATE_ONE_HEAR_ABOUT_START = 'UPDATE_ONE_HEAR_ABOUT_START';
export const UPDATE_ONE_HEAR_ABOUT_SUCCESS = 'UPDATE_ONE_HEAR_ABOUT_SUCCESS';
export const UPDATE_ONE_HEAR_ABOUT_FAILED = 'UPDATE_ONE_HEAR_ABOUT_FAILED';
export const UPDATE_ONE_HEAR_ABOUT_RESET = 'UPDATE_ONE_HEAR_ABOUT_RESET';

// Action Types: Delete One Hear About.
export const DELETE_ONE_HEAR_ABOUT_START = 'DELETE_ONE_HEAR_ABOUT_START';
export const DELETE_ONE_HEAR_ABOUT_SUCCESS = 'DELETE_ONE_HEAR_ABOUT_SUCCESS';
export const DELETE_ONE_HEAR_ABOUT_FAILED = 'DELETE_ONE_HEAR_ABOUT_FAILED';
export const DELETE_ONE_HEAR_ABOUT_RESET = 'DELETE_ONE_HEAR_ABOUT_RESET';

// Action Creators: Get All Hear About.
const getAllHearAboutStart = (payload) => ({
  type: GET_ALL_HEAR_ABOUT_START,
  payload,
});
const getAllHearAboutSuccess = (payload) => ({
  type: GET_ALL_HEAR_ABOUT_SUCCESS,
  payload,
});
const getAllHearAboutFailed = (payload) => ({
  type: GET_ALL_HEAR_ABOUT_FAILED,
  payload,
});
const getAllHearAboutReset = () => ({ type: GET_ALL_HEAR_ABOUT_RESET });

// Action Creators: Get One Hear About.
const getOneHearAboutStart = (payload) => ({
  type: GET_ONE_HEAR_ABOUT_START,
  payload,
});
const getOneHearAboutSuccess = (payload) => ({
  type: GET_ONE_HEAR_ABOUT_SUCCESS,
  payload,
});
const getOneHearAboutFailed = (payload) => ({
  type: GET_ONE_HEAR_ABOUT_FAILED,
  payload,
});
const getOneHearAboutReset = () => ({ type: GET_ONE_HEAR_ABOUT_RESET });

// Action Creators: Create New Hear About.
const createHearAboutStart = (payload) => ({
  type: CREATE_HEAR_ABOUT_START,
  payload,
});
const createHearAboutSuccess = (payload) => ({
  type: CREATE_HEAR_ABOUT_SUCCESS,
  payload,
});
const createHearAboutFailed = (payload) => ({
  type: CREATE_HEAR_ABOUT_FAILED,
  payload,
});
const createHearAboutReset = () => ({ type: CREATE_HEAR_ABOUT_RESET });

// Action Creators: Update One Hear About.
const updateOneHearAboutStart = (payload) => ({
  type: UPDATE_ONE_HEAR_ABOUT_START,
  payload,
});
const updateOneHearAboutSuccess = (payload) => ({
  type: UPDATE_ONE_HEAR_ABOUT_SUCCESS,
  payload,
});
const updateOneHearAboutFailed = (payload) => ({
  type: UPDATE_ONE_HEAR_ABOUT_FAILED,
  payload,
});
const updateOneHearAboutReset = () => ({ type: UPDATE_ONE_HEAR_ABOUT_RESET });

// Action Creators: Delete One Hear About.
const deleteOneHearAboutStart = (payload) => ({
  type: DELETE_ONE_HEAR_ABOUT_START,
  payload,
});
const deleteOneHearAboutSuccess = (payload) => ({
  type: DELETE_ONE_HEAR_ABOUT_SUCCESS,
  payload,
});
const deleteOneHearAboutFailed = (payload) => ({
  type: DELETE_ONE_HEAR_ABOUT_FAILED,
  payload,
});
const deleteOneHearAboutReset = () => ({ type: DELETE_ONE_HEAR_ABOUT_RESET });

// Actions: Get All Hear About.
export const getAllHearAbout = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllHearAboutStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('hearabout', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { hearabout } = result.data.data;
        dispatch(
          getAllHearAboutSuccess({
            loading: false,
            success: true,
            data: { hearabout },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllHearAboutFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllHearAboutFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Hear About.
export const clearGetAllHearAbout = () => getAllHearAboutReset();

// Actions: Get One Hear About.
export const getOneHearAbout = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneHearAboutStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { hearAboutId } = payload;
      const result = await ApiClient.get(`hearabout/${hearAboutId}`);
      if (result.data?.success) {
        const { hearAbout } = result.data.data;
        dispatch(
          getOneHearAboutSuccess({
            loading: false,
            success: true,
            data: { hearAbout },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneHearAboutFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneHearAboutFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Hear About.
export const clearGetOneHearAbout = () => getOneHearAboutReset();

// Actions: Create New Hear About.
export const createHearAbout = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createHearAboutStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onHearAboutExistsMessage } = options;

    try {
      const result = await ApiClient.post('hearabout', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { hearAbout } = result.data.data;
        dispatch(
          createHearAboutSuccess({
            loading: false,
            success: true,
            data: { hearAbout },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createHearAboutFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createHearAboutFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'HEAR_ABOUT_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onHearAboutExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Hear About.
export const clearCreateNewHearAbout = () => createHearAboutReset();

// Actions: Update One Hear About.
export const updateOneHearAbout = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneHearAboutStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { hearAboutId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`hearabout/${hearAboutId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { hearAbout } = result.data.data;
        dispatch(
          updateOneHearAboutSuccess({
            loading: false,
            success: true,
            data: { hearAbout },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneHearAboutFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneHearAboutFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Hear About.
export const clearUpdateOneHearAbout = () => updateOneHearAboutReset();

// Actions: Delete One Hear About.
export const deleteOneHearAbout = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneHearAboutStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { hearAboutId } = payload;
      const result = await ApiClient.delete(`hearabout/${hearAboutId}`);
      if (result.data?.success) {
        const { hearAbout } = result.data.data;
        dispatch(
          deleteOneHearAboutSuccess({
            loading: false,
            success: true,
            data: { hearAbout },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneHearAboutFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneHearAboutFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Hear About.
export const cleareDeleteOneHearAbout = () => deleteOneHearAboutReset();
