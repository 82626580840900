// Action Types: Token
export const TOKEN = 'TOKEN';

// Action Types: Delete Token
export const DELETE_TOKEN = 'DELETE_TOKEN';

// Actions: Change Token
export function changeToken(name, value) {
  return { type: name, value };
}

export default changeToken;
