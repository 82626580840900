// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All CANCELLATIONS.
export const GET_ALL_CANCELLATIONS_START = 'GET_ALL_CANCELLATIONS_START';
export const GET_ALL_CANCELLATIONS_SUCCESS = 'GET_ALL_CANCELLATIONS_SUCCESS';
export const GET_ALL_CANCELLATIONS_FAILED = 'GET_ALL_CANCELLATIONS_FAILED';
export const GET_ALL_CANCELLATIONS_RESET = 'GET_ALL_CANCELLATIONS_RESET';

// Action Types: Get One CANCELLATION.
export const GET_ONE_CANCELLATION_START = 'GET_ONE_CANCELLATION_START';
export const GET_ONE_CANCELLATION_SUCCESS = 'GET_ONE_CANCELLATION_SUCCESS';
export const GET_ONE_CANCELLATION_FAILED = 'GET_ONE_CANCELLATION_FAILED';
export const GET_ONE_CANCELLATION_RESET = 'GET_ONE_CANCELLATION_RESET';

// Action Types: Create CANCELLATION.
export const CREATE_CANCELLATION_START = 'CREATE_CANCELLATION_START';
export const CREATE_CANCELLATION_SUCCESS = 'CREATE_CANCELLATION_SUCCESS';
export const CREATE_CANCELLATION_FAILED = 'CREATE_CANCELLATION_FAILED';
export const CREATE_CANCELLATION_RESET = 'CREATE_CANCELLATION_RESET';

// Action Creators: Get All Cancellations.
const getAllCancellationsStart = (payload) => ({
  type: GET_ALL_CANCELLATIONS_START,
  payload,
});
const getAllCancellationsSuccess = (payload) => ({
  type: GET_ALL_CANCELLATIONS_SUCCESS,
  payload,
});
const getAllCancellationsFailed = (payload) => ({
  type: GET_ALL_CANCELLATIONS_FAILED,
  payload,
});
const getAllCancellationsReset = () => ({ type: GET_ALL_CANCELLATIONS_RESET });

// Action Creators: Get One Cancellation.
const getOneCancellationStart = (payload) => ({
  type: GET_ONE_CANCELLATION_START,
  payload,
});
const getOneCancellationSuccess = (payload) => ({
  type: GET_ONE_CANCELLATION_SUCCESS,
  payload,
});
const getOneCancellationFailed = (payload) => ({
  type: GET_ONE_CANCELLATION_FAILED,
  payload,
});
const getOneCancellationReset = () => ({ type: GET_ONE_CANCELLATION_RESET });

// Action Creators: Create Cancellation.
const createCancellationStart = (payload) => ({
  type: CREATE_CANCELLATION_START,
  payload,
});
const createCancellationSuccess = (payload) => ({
  type: CREATE_CANCELLATION_SUCCESS,
  payload,
});
const createCancellationFailed = (payload) => ({
  type: CREATE_CANCELLATION_FAILED,
  payload,
});
const createCancellationReset = () => ({ type: CREATE_CANCELLATION_RESET });

// Actions: Get All Cancellations.
export const getAllCancellations = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllCancellationsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, application, business, intern } = payload;
      const result = await ApiClient.get('cancellations', {
        params: {
          page,
          limit,
          pagination,
          application,
          business,
          intern,
        },
      });
      if (result.data?.success) {
        const { cancellations } = result.data.data;
        dispatch(
          getAllCancellationsSuccess({
            loading: false,
            success: true,
            data: { cancellations },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllCancellationsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllCancellationsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Cancellations.
export const clearGetAllCancellations = () => getAllCancellationsReset();

// Actions: Get One Cancellation.
export const getOneCancellation = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneCancellationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { cancellationId } = payload;
      const result = await ApiClient.get(`cancellations/${cancellationId}`);
      if (result.data?.success) {
        const { cancellation } = result.data.data;
        dispatch(
          getOneCancellationSuccess({
            loading: false,
            success: true,
            data: { cancellation },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneCancellationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneCancellationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Cancellation.
export const clearGetOneCancellation = () => getOneCancellationReset();

// Actions: Create Cancellation.
export const createCancellation = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createCancellationStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, businessId, internId, reasonText, reasons, emailLanguage } = payload;
    const {
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onNotFoundMessage,
      onCreationFailedMessage,
    } = options;

    try {
      const result = await ApiClient.post('cancellations', {
        applicationId,
        businessId,
        internId,
        reasonText,
        reasons,
        emailLanguage,
      });
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          createCancellationSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          createCancellationFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createCancellationFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'NOT_FOUND'
      ) {
        toastNotification('error', onNotFoundMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'FAILED_CREATE'
      ) {
        toastNotification('error', onCreationFailedMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Cancellation.
export const clearCreateNewCancellation = () => createCancellationReset();
