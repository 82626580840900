import React from 'react';
import { Typography } from '@mui/material';

const Label = ({ label, lan }) => {
  return (
    <span
      className="required"
      id="basic-addon3"
      style={{
        fontSize: '14px',
        width: '100%',
        color: '#707070',
        fontFamily: 'Mark-Medium',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      {label} {lan}
    </span>
  );
};

export default Label;
