export const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.8545 23.0749L22.2953 18.6669C21.5436 17.9384 20.4302 17.7976 19.5428 18.2104L18.4762 17.1792C20.0501 15.4066 21.0011 13.1047 21.0011 10.5923C21.0011 5.00737 16.3013 0.463379 10.525 0.463379C4.7486 0.463379 0.0488281 5.00737 0.0488281 10.5923C0.0488281 16.1771 4.7486 20.7211 10.525 20.7211C13.1235 20.7211 15.5043 19.8017 17.336 18.2816L18.4026 19.3128C17.9773 20.1707 18.123 21.2456 18.8747 21.9741L23.4339 26.3805C24.3782 27.2951 25.9068 27.2951 26.8528 26.3805C27.7954 25.4707 27.7954 23.9863 26.8528 23.0765L26.8545 23.0749ZM16.7919 16.6466C16.7919 16.6466 16.7919 16.6466 16.7885 16.6482C16.7885 16.6482 16.7885 16.6482 16.7869 16.6515C15.1829 18.2023 12.9678 19.1622 10.525 19.1622C5.63766 19.1622 1.65951 15.3176 1.65951 10.5906C1.65951 5.86372 5.63766 2.02229 10.525 2.02229C15.4123 2.02229 19.3887 5.86696 19.3887 10.5923C19.3887 12.9541 18.3942 15.0958 16.7919 16.6466ZM25.7143 25.2765C25.4012 25.5792 24.8888 25.5792 24.5741 25.2765L20.0149 20.8685C19.7002 20.5641 19.7002 20.072 20.0149 19.766C20.3297 19.4633 20.8404 19.4633 21.1551 19.766L25.7143 24.1741C26.0274 24.4768 26.0274 24.9721 25.7143 25.2765Z"
        fill="#01486B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9343 8.76965C16.8405 8.48474 16.5844 8.27753 16.2796 8.23544L12.7971 7.7579L11.245 4.78576C11.1077 4.52514 10.8298 4.3584 10.5267 4.3584C10.2237 4.3584 9.94407 4.52514 9.80677 4.78576L8.25469 7.7579L4.77214 8.23544C4.46741 8.27753 4.21292 8.48474 4.11748 8.76965C4.02372 9.05456 4.10744 9.36699 4.33012 9.57096L6.83823 11.8778L6.2472 15.1299C6.1953 15.4213 6.31752 15.7176 6.56364 15.8924C6.80977 16.0689 7.13626 16.0948 7.40917 15.9588L10.5284 14.4112L13.646 15.9588C13.9172 16.0931 14.2454 16.0689 14.4915 15.8924C14.7393 15.7176 14.8598 15.423 14.8079 15.1299L14.2169 11.8778L16.725 9.57096C16.9477 9.36537 17.0297 9.05456 16.9376 8.76965H16.9343ZM12.7904 11.0376C12.5962 11.2173 12.5058 11.4795 12.5526 11.7369L12.9377 13.8576L10.8934 12.8442C10.6623 12.7293 10.3878 12.7293 10.1567 12.8442L8.11405 13.8576L8.49914 11.7369C8.54602 11.4795 8.45561 11.2173 8.26139 11.0376L6.64736 9.55154L8.89763 9.24234C9.15882 9.20673 9.38485 9.0497 9.50372 8.82307L10.5284 6.86431L11.5514 8.82307C11.6703 9.0497 11.8963 9.20673 12.1575 9.24234L14.4078 9.55154L12.7937 11.0376H12.7904Z"
        fill="#01486B"
      />
    </svg>
  );
};
