// Imports: local files.
import {
  GET_ALL_JOBS_START,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILED,
  GET_ALL_JOBS_RESET,
  GET_ONE_JOB_START,
  GET_ONE_JOB_SUCCESS,
  GET_ONE_JOB_FAILED,
  GET_ONE_JOB_RESET,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILED,
  CREATE_JOB_RESET,
  UPDATE_ONE_JOB_START,
  UPDATE_ONE_JOB_SUCCESS,
  UPDATE_ONE_JOB_FAILED,
  UPDATE_ONE_JOB_RESET,
  DELETE_ONE_JOB_START,
  DELETE_ONE_JOB_SUCCESS,
  DELETE_ONE_JOB_FAILED,
  DELETE_ONE_JOB_RESET,
  GET_ALL_MATCHES_START,
  GET_ALL_MATCHES_SUCCESS,
  GET_ALL_MATCHES_FAILED,
  GET_ALL_MATCHES_RESET,
  GET_INTERN_MATCHES_START,
  GET_INTERN_MATCHES_SUCCESS,
  GET_INTERN_MATCHES_FAILED,
  GET_INTERN_MATCHES_RESET,
  INVITE_ONE_INTERN_START,
  INVITE_ONE_INTERN_SUCCESS,
  INVITE_ONE_INTERN_FAILED,
  INVITE_ONE_INTERN_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
  getAllMatches: initialLoadingState,
  getInternMatches: initialLoadingState,
  inviteOneIntern: initialLoadingState,
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Jobs
     * =======================================================================
     */
    case GET_ALL_JOBS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Job
     * =======================================================================
     */
    case GET_ONE_JOB_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Job
     * =======================================================================
     */
    case CREATE_JOB_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Job
     * =======================================================================
     */
    case UPDATE_ONE_JOB_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_JOB_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_JOB_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_JOB_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Job
     * =======================================================================
     */
    case DELETE_ONE_JOB_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_JOB_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_JOB_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_JOB_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Matches
     * =======================================================================
     */
    case GET_ALL_MATCHES_START:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHES_SUCCESS:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHES_FAILED:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHES_RESET:
      return { ...state, getAllMatches: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get Intern Matches
     * =======================================================================
     */
    case GET_INTERN_MATCHES_START:
      return { ...state, getInternMatches: { ...action.payload } };
    case GET_INTERN_MATCHES_SUCCESS:
      return { ...state, getInternMatches: { ...action.payload } };
    case GET_INTERN_MATCHES_FAILED:
      return { ...state, getInternMatches: { ...action.payload } };
    case GET_INTERN_MATCHES_RESET:
      return { ...state, getInternMatches: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Matches
     * =======================================================================
     */
    case INVITE_ONE_INTERN_START:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_SUCCESS:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_FAILED:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_RESET:
      return { ...state, inviteOneIntern: { ...initialLoadingState } };

    default:
      return state;
  }
};

export default jobsReducer;
