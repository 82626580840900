// action - state management
import * as actionTypes from '../actions/mui.actions';

const config = {
  fontFamilyRegular: `'Mark-Regular', sans-serif`,
  fontFamilyLight: `'Mark-Light', sans-serif`,
  fontFamilyBook: `'Mark-Book', sans-serif`,
  fontFamilyMedium: `'Mark-Medium', sans-serif`,
  fontFamilyBold: `'Mark-Bold', sans-serif`,
  fontFamilyHeavy: `'Mark-Heavy', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
};

export const initialState = {
  fontFamily: {
    fontFamilyRegular: config.fontFamilyRegular,
    fontFamilyLight: config.fontFamilyLight,
    fontFamilyBook: config.fontFamilyBook,
    fontFamilyMedium: config.fontFamilyMedium,
    fontFamilyBold: config.fontFamilyBold,
    fontFamilyHeavy: config.fontFamilyHeavy,
  },
  borderRadius: config.borderRadius,
  outlinedFilled: config.outlinedFilled,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_OUTLINED_FILLED:
      return {
        ...state,
        outlinedFilled: action.outlinedFilled,
      };
    default:
      return state;
  }
};

export default customizationReducer;
