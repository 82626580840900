import { Grid, LinearProgress, Typography } from '@mui/material';
import { BarChart } from '../Chart/Bar';
import { PieChart } from '../Chart/Pie';

export const StatsChart = ({ response, label, data, chart, title }) => {
  return (
    <Grid item xs={12}>
      {response && response.loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontFamily: 'Mark-Bold', color: '#01486B' }}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {chart === 'Pie' ? (
              <PieChart
                labels={data.map((value) => (value[0] === null ? 0 : value[0]))}
                values={data.map((value) => (value[1] === null ? 0 : value[1]))}
              />
            ) : (
              <BarChart
                labels={data.map((value) => (value[0] === null ? 0 : value[0]))}
                values={data.map((value) => (value[1] === null ? 0 : value[1]))}
                title={title}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
