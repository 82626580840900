import React, { useState, useEffect } from 'react';
import { Avatar, Menu, Dropdown, Spin } from 'antd';
import '../style/header.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import jwt from 'jsonwebtoken';
import { useSelector, useDispatch } from 'react-redux';
import { getOneIntern, clearGetOneIntern, changeLanguage } from '../store/actions/actions';
import { Link, useLocation } from 'react-router-dom';
import Api from '../api';
import {
  UserOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  ReadOutlined,
  SnippetsOutlined,
  CalendarOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Loading } from './Loading';
import { Box } from '@mui/material';
import { Anchor } from '@mui/icons-material';

export const Header = ({ handleInternChange, tilted, role, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [activeLink, setIsActiveLink] = useState('ballina');

  const location = useLocation();
  const path = location.pathname;

  const getCurrentLanguage = localStorage.getItem('default-language');

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const getOneInternResponse = useSelector(({ interns }) => interns.getOne);
  const language = useSelector((state) => state.language);

  const changeLang = (val) => {
    i18n.changeLanguage(val);
  };
  const interData = JSON.parse(localStorage.getItem('intern'));

  const token = localStorage.getItem('kgen-token');
  const decodedToken = jwt.decode(token);
  const decodedRole = decodedToken?.role;
  const userId = decodedToken?.id;
  const updateFinished = decodedToken?.updateFinished;
  const updateRequired = decodedToken?.updateRequired;
  const aboveOrUnderAge = decodedToken?.aboveOrUnderAge;

  useEffect(() => {
    if (decodedRole === 'intern') dispatch(getOneIntern({ internId: userId }));
  }, []);

  useEffect(() => {
    return () => {
      if (decodedRole === 'intern') dispatch(clearGetOneIntern());
    };
  }, []);

  useEffect(() => {
    if (getOneInternResponse && getOneInternResponse.loading) setLoading(true);
    else if (getOneInternResponse && getOneInternResponse.success) setLoading(false);
    else if (getOneInternResponse && getOneInternResponse.error) setLoading(false);
  }, [getOneInternResponse]);

  useEffect(() => {
    if (getOneInternResponse && getOneInternResponse.success && handleInternChange) {
      handleInternChange(getOneInternResponse.data.intern);
    }
  }, [getOneInternResponse]);

  const all_intern_data = JSON.parse(localStorage.getItem('all_intern_data'));

  const goTo = (param, options = {}) => {
    if (options && options.shouldUseState) {
      props.history.push({ pathname: param, state: options.state });
    } else {
      props.history.push(param);
    }
  };
  const logout = () => {
    localStorage.clear();
    props.history.push('/');
  };

  // const archiveUser = () => {
  //   let data = {
  //     id: interData._id,
  //     archive: true,
  //   };
  //   Api('interns/archive', 'POST', data)
  //     .then(() => {
  //       toastNotification('success', 'Successfully archived');
  //     })
  //     .catch((err) => toastNotification('error', err.error || 'Error while archiving intern'));

  //   logout();
  // };
  const menu = (
    <Menu>
      <Menu.Item>
        <a
          rel="noopener noreferrer"
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          onClick={() => goTo('/admin/login', { shouldUseState: true, state: { type: 'business' } })}
        >
          {t('business')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          rel="noopener noreferrer"
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          onClick={() => goTo('/login')}
        >
          {t('intern')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          rel="noopener noreferrer"
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          onClick={() => goTo('/admin/login', { shouldUseState: true, state: { type: 'mentor' } })}
        >
          {t('mentor')}
        </a>
      </Menu.Item>
    </Menu>
  );
  const registerMenu = (
    <Menu>
      <Menu.Item>
        <a
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          rel="noopener noreferrer"
          onClick={() => goTo('/registerBusiness')}
        >
          {t('business')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          rel="noopener noreferrer"
          onClick={() => goTo('/register')}
        >
          {t('intern')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          rel="noopener noreferrer"
          onClick={() => goTo('/registerMentors')}
        >
          {t('mentor')}
        </a>
      </Menu.Item>
    </Menu>
  );

  const languages = (
    <Menu>
      <Menu.Item
        onClick={() => {
          changeLang('sq');
          dispatch(changeLanguage('sq'));
        }}
      >
        <a style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }} rel="noopener noreferrer">
          {t('shqip')}
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          changeLang('sr');
          dispatch(changeLanguage('sr'));
        }}
      >
        <a style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }} rel="noopener noreferrer">
          {t('serbisht')}
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          changeLang('en');
          dispatch(changeLanguage('en'));
        }}
      >
        <a style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }} rel="noopener noreferrer">
          {t('anglisht')}
        </a>
      </Menu.Item>
    </Menu>
  );

  const menuInfo = (
    <Menu>
      <Menu.Item
        onClick={() => (path === '/' ? goTo('/') : goTo('/', { shouldUseState: true, state: { scrollId: 'faq' } }))}
      >
        <a
          rel="noopener noreferrer"
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          key="faq"
          href={path === '/' ? '#faq' : null}
        >
          {'Faq'}
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => (path === '/' ? goTo('/') : goTo('/', { shouldUseState: true, state: { scrollId: 'faq' } }))}
      >
        <a
          rel="noopener noreferrer"
          style={{ fontFamily: 'Mark-Light', color: '#1E1E1E' }}
          key="faq"
          href={path === '/' ? '#faq' : null}
        >
          {'Demo'}
        </a>
      </Menu.Item>
    </Menu>
  );

  if (loading) return <Loading />;
  return (
    <div className="nav-wrapper">
      <nav className={`navbar navbar-expand-lg custom`}>
        <img src={'/images/Frame.png'} alt="" loading="lazy" className="logoHeader" />

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse nav-content"
          id="navbarSupportedContent"
          style={{
            marginTop: '16px',
            width: '100%',
            height: '50px',
            borderRadius: '25px',
            backgroundColor: '#01486B',
            justifyContent: 'center',
          }}
        >
          <ul className="navbar-nav ">
            <li className="nav-item active" onClick={() => goTo('/')}>
              <a key="ballina" className="nav-link" style={{ color: path === '/' ? '#F36E21' : '#FFFFFF' }}>
                {t('ballina')}

                <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item" onClick={() => goTo('/opportunitiesinterships')}>
              <a
                key="shpallje"
                className="nav-link"
                style={{
                  color: path === '/opportunitiesinterships' ? '#F36E21' : '#FFFFFF',
                }}
              >
                {t('shpallje')}
              </a>
            </li>
            <li className="nav-item" onClick={() => goTo('/opportunitiestraining')}>
              <a
                key="trajnime"
                className="nav-link"
                style={{ color: path === '/opportunitiestraining' ? '#F36E21' : '#FFFFFF' }}
              >
                {t('trajnime')}
              </a>
            </li>
            <li className="nav-item" onClick={() => goTo('/resources')}>
              <a className="nav-link" key="resources" style={{ color: path === '/resources' ? '#F36E21' : '#FFFFFF' }}>
                {t('resources')}
              </a>
            </li>
            <li className="nav-item" onClick={() => goTo('/mentorship')}>
              <a
                className="nav-link"
                key="mentorship"
                style={{ color: path === '/mentorship' ? '#F36E21' : '#FFFFFF' }}
              >
                {t('mentorship')}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                path === '/' ? goTo('/') : goTo('/', { shouldUseState: true, state: { scrollId: 'aboutus' } })
              }
            >
              <a
                key="rreth-nesh"
                className="nav-link"
                // href="#aboutus"
                href={path === '/' ? '#aboutus' : null}
                style={{ color: '#FFFFFF' }}
              >
                {t('rreth-nesh')}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                path === '/' ? goTo('/') : goTo('/', { shouldUseState: true, state: { scrollId: 'contactus' } })
              }
            >
              <a
                className="nav-link"
                key="kontakti"
                href={path === '/' ? '#contactus' : null}
                style={{ color: '#FFFFFF' }}
              >
                {t('kontakti')}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                path === '/' ? goTo('/') : goTo('/', { shouldUseState: true, state: { scrollId: 'faq' } })
              }
            >
              {/* <a className="nav-link" key="faq" href={path === '/' ? '#faq' : null} style={{ color: '#FFFFFF' }}>
                FAQ
              </a> */}
              <Dropdown overlay={menuInfo} placement="bottomCenter" arrow>
                <a className="nav-link" style={{ color: '#FFFFFF' }}>
                  Info
                </a>
              </Dropdown>
            </li>
            {/* <li className="nav-item">
                <a
                  className="nav-link visited"
                  //href="https://mentorship4youth.net/"
                  href="#"
                  //target="_blank"
                  rel="noreferrer"
                >
                  MENTORS
                </a>
              </li> */}

            {!token && (
              <Box style={{ display: window.innerWidth <= 900 ? 'block !important' : 'flex' }}>
                <li className="nav-item">
                  <Dropdown overlay={menu} placement="bottomCenter" arrow>
                    <button
                      type="button"
                      className="btn nav-btn"
                      style={{
                        backgroundColor: '#00AEEF',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        width: '125px',
                        fontFamily: 'Mark-Medium',
                        fontSize: '16px',
                      }}
                    >
                      {t('sign-in')}
                    </button>
                  </Dropdown>
                </li>
                <li className="nav-item">
                  <Dropdown overlay={registerMenu} placement="bottomCenter" arrow>
                    <button
                      type="button"
                      className="btn nav-btn"
                      style={{
                        backgroundColor: '#F36E21',
                        mixBlendMode: 'hard-light',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        width: '125px',
                        fontFamily: 'Mark-Medium',
                        fontSize: '16px',
                      }}
                    >
                      {t('register')}
                    </button>
                  </Dropdown>
                </li>
              </Box>
            )}
            <li className="nav-item">
              <Dropdown overlay={languages} placement="bottomCenter" arrow>
                <button
                  type="button"
                  className="btn nav-btn lang-btn"
                  style={{
                    color: '#fff',
                    paddingTop: '8px',
                    outline: 'none',
                    boxShadow: 'none',
                    fontFamily: 'Mark-Medium',
                    fontSize: '16px',
                  }}
                >
                  {/* {getCurrentLanguage?.toUpperCase() || 'SHQ'} */}
                  {language?.toUpperCase() || 'SQ'}
                </button>
              </Dropdown>
            </li>
            {loading ? (
              <>
                <li className="nav-item">
                  <div className="btn-group">
                    <a
                      className="nav-link user dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Avatar
                        style={{
                          backgroundColor: '#00a2ae',
                          verticalAlign: 'middle',
                        }}
                        size="large"
                      >
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#01486B' }} spin />} />
                      </Avatar>
                    </a>
                  </div>
                </li>
              </>
            ) : (
              <>
                {token && decodedRole === 'intern' ? (
                  <>
                    <li className="nav-item">
                      <div className="btn-group">
                        <a
                          className="nav-link user dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {getOneInternResponse && getOneInternResponse?.success && (
                            <Avatar
                              style={{
                                backgroundColor: '#00a2ae',
                                verticalAlign: 'middle',
                              }}
                              size="large"
                            >
                              {getOneInternResponse?.data.intern.internName.charAt(0).toUpperCase()}
                            </Avatar>
                          )}
                        </a>
                        <div className="dropdown-menu">
                          {/* {all_intern_data?.activeQuiz && (
                          <a className="dropdown-item" onClick={() => goTo('/quiz')}>
                            {t('start-quiz')}
                          </a>
                        )} */}
                          {getOneInternResponse && getOneInternResponse?.success && (
                            <a className="dropdown-item1" onClick={() => goTo('/resume')}>
                              <UserOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                              {getOneInternResponse?.data.intern.internName}{' '}
                              {getOneInternResponse?.data.intern.internLastName}
                            </a>
                          )}

                          {!aboveOrUnderAge &&
                            !updateRequired &&
                            updateFinished &&
                            getOneInternResponse &&
                            getOneInternResponse?.success && (
                              <a className="dropdown-item1" onClick={() => goTo('/applications')}>
                                <FileDoneOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                {t('applications')}
                              </a>
                            )}

                          {!aboveOrUnderAge && !updateRequired && updateFinished && (
                            <a className="dropdown-item1" onClick={() => goTo('/internship')}>
                              <ReadOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                              {t('myInternship')}
                            </a>
                          )}
                          {!aboveOrUnderAge && !updateRequired && updateFinished && (
                            <a className="dropdown-item1" onClick={() => goTo('/myTrainings')}>
                              <ReadOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                              {t('myTrainings')}
                            </a>
                          )}

                          {!aboveOrUnderAge &&
                            !updateRequired &&
                            updateFinished &&
                            getOneInternResponse &&
                            getOneInternResponse.success &&
                            getOneInternResponse.data &&
                            getOneInternResponse.data.intern &&
                            getOneInternResponse.data.intern.internCurrentMentor && (
                              <a className="dropdown-item1" onClick={() => goTo('/myMentor')}>
                                <CalendarOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                {t('myMentor')}
                              </a>
                            )}

                          {!aboveOrUnderAge && !updateRequired && updateFinished && (
                            <>
                              {window.location.pathname === '/' ? (
                                // <AnchorLink className="dropdown-item" onClick={() => goTo('/')} href="#postings">
                                //   <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                //   {t('opportunities')}
                                // </AnchorLink>
                                <Link
                                  className="dropdown-item1"
                                  to={{ pathname: '/opportunities', state: { scrollToAbout: true } }}
                                >
                                  <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                  {t('opportunities')}
                                </Link>
                              ) : (
                                <Link
                                  className="dropdown-item1"
                                  to={{ pathname: '/opportunities', state: { scrollToAbout: true } }}
                                >
                                  <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                  {t('opportunities')}
                                </Link>
                              )}
                            </>
                          )}

                          {!aboveOrUnderAge && !updateRequired && updateFinished && (
                            <>
                              {window.location.pathname === '/' ? (
                                // <AnchorLink
                                //   className="dropdown-item"
                                //   onClick={() => goTo('/opportunitiestraining')}
                                //   href="#trainings"
                                // >
                                //   <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                //   {t('trainings')}
                                // </AnchorLink>
                                <Link
                                  className="dropdown-item1"
                                  to={{ pathname: '/trainings', state: { scrollToAbout: true } }}
                                >
                                  <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                  {t('trainings')}
                                </Link>
                              ) : (
                                <Link
                                  className="dropdown-item1"
                                  to={{ pathname: '/trainings', state: { scrollToAbout: true } }}
                                >
                                  <SnippetsOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                                  {t('trainings')}
                                </Link>
                              )}
                            </>
                          )}

                          <a className="dropdown-item1" onClick={logout}>
                            <LogoutOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                            {t('dil')}
                          </a>

                          {/* <a
                          className="dropdown-item"
                          onClick={() => {
                            let sure = window.confirm(t('are_you_sure_intern'));
                            if (sure) {
                              archiveUser();
                            }
                          }}
                        >
                          {t('archive')}
                        </a>{' '} */}
                        </div>
                      </div>
                    </li>
                  </>
                ) : token && decodedRole === 'business' ? (
                  <>
                    <li className="nav-item">
                      <div className="btn-group">
                        <a
                          className="nav-link user dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Avatar
                            style={{
                              backgroundColor: '#00a2ae',
                              verticalAlign: 'middle',
                            }}
                            size="large"
                          >
                            O
                          </Avatar>
                        </a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item1" onClick={() => goTo('/dashboard')}>
                            <UserOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />

                            {t('profile')}
                          </a>

                          <a className="dropdown-item1" onClick={logout}>
                            <LogoutOutlined style={{ paddingRight: '10px', fontSize: '12px' }} />
                            {t('dil')}
                          </a>
                        </div>
                      </div>
                    </li>
                  </>
                ) : null}
              </>
            )}
          </ul>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
};
