// Imports: local files.
import {
  GET_ALL_APPLICATIONS_START,
  GET_ALL_APPLICATIONS_SUCCESS,
  GET_ALL_APPLICATIONS_FAILED,
  GET_ALL_APPLICATIONS_RESET,
  GET_VALIDATION_APPLICATIONS_START,
  GET_VALIDATION_APPLICATIONS_SUCCESS,
  GET_VALIDATION_APPLICATIONS_FAILED,
  GET_VALIDATION_APPLICATIONS_RESET,
  GET_ONE_APPLICATION_START,
  GET_ONE_APPLICATION_SUCCESS,
  GET_ONE_APPLICATION_FAILED,
  GET_ONE_APPLICATION_RESET,
  CREATE_APPLICATION_START,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILED,
  CREATE_APPLICATION_RESET,
  UPDATE_ONE_APPLICATION_START,
  UPDATE_ONE_APPLICATION_SUCCESS,
  UPDATE_ONE_APPLICATION_FAILED,
  UPDATE_ONE_APPLICATION_RESET,
  FAVOURITE_ONE_APPLICATION_START,
  FAVOURITE_ONE_APPLICATION_SUCCESS,
  FAVOURITE_ONE_APPLICATION_FAILED,
  FAVOURITE_ONE_APPLICATION_RESET,
  ALREADY_APPLIED_APPLICATION_START,
  ALREADY_APPLIED_APPLICATION_SUCCESS,
  ALREADY_APPLIED_APPLICATION_FAILED,
  ALREADY_APPLIED_APPLICATION_RESET,
  DELETE_ONE_APPLICATION_START,
  DELETE_ONE_APPLICATION_SUCCESS,
  DELETE_ONE_APPLICATION_FAILED,
  DELETE_ONE_APPLICATION_RESET,
  GENERATE_ONE_CERTIFICATE_START,
  GENERATE_ONE_CERTIFICATE_SUCCESS,
  GENERATE_ONE_CERTIFICATE_FAILED,
  GENERATE_ONE_CERTIFICATE_RESET,
  FINISHED_INTERSHIP_START,
  FINISHED_INTERSHIP_SUCCESS,
  FINISHED_INTERSHIP_FAILED,
  FINISHED_INTERSHIP_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getValidations: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  favouriteOne: initialLoadingState,
  alreadyApplied: initialLoadingState,
  deleteOne: initialLoadingState,
  generateOne: initialLoadingState,
  finishedIntership: initialLoadingState,
};

const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Applications
     * =======================================================================
     */
    case GET_ALL_APPLICATIONS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_APPLICATIONS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_APPLICATIONS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_APPLICATIONS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Accepted For Validation Applications
     * =======================================================================
     */
    case GET_VALIDATION_APPLICATIONS_START:
      return { ...state, getValidations: { ...action.payload } };
    case GET_VALIDATION_APPLICATIONS_SUCCESS:
      return { ...state, getValidations: { ...action.payload } };
    case GET_VALIDATION_APPLICATIONS_FAILED:
      return { ...state, getValidations: { ...action.payload } };
    case GET_VALIDATION_APPLICATIONS_RESET:
      return { ...state, getValidations: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Application
     * =======================================================================
     */
    case GET_ONE_APPLICATION_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_APPLICATION_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_APPLICATION_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_APPLICATION_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Application
     * =======================================================================
     */
    case CREATE_APPLICATION_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_APPLICATION_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Application
     * =======================================================================
     */
    case UPDATE_ONE_APPLICATION_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_APPLICATION_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_APPLICATION_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_APPLICATION_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Favourite One Application
     * =======================================================================
     */
    case FAVOURITE_ONE_APPLICATION_START:
      return { ...state, favouriteOne: { ...action.payload } };
    case FAVOURITE_ONE_APPLICATION_SUCCESS:
      return { ...state, favouriteOne: { ...action.payload } };
    case FAVOURITE_ONE_APPLICATION_FAILED:
      return { ...state, favouriteOne: { ...action.payload } };
    case FAVOURITE_ONE_APPLICATION_RESET:
      return { ...state, favouriteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Already Applied Application
     * =======================================================================
     */
    case ALREADY_APPLIED_APPLICATION_START:
      return { ...state, alreadyApplied: { ...action.payload } };
    case ALREADY_APPLIED_APPLICATION_SUCCESS:
      return { ...state, alreadyApplied: { ...action.payload } };
    case ALREADY_APPLIED_APPLICATION_FAILED:
      return { ...state, alreadyApplied: { ...action.payload } };
    case ALREADY_APPLIED_APPLICATION_RESET:
      return { ...state, alreadyApplied: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Application
     * =======================================================================
     */
    case DELETE_ONE_APPLICATION_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_APPLICATION_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_APPLICATION_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_APPLICATION_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Generate One Certificate
     * =======================================================================
     */
    case GENERATE_ONE_CERTIFICATE_START:
      return { ...state, generateOne: { ...action.payload } };
    case GENERATE_ONE_CERTIFICATE_SUCCESS:
      return { ...state, generateOne: { ...action.payload } };
    case GENERATE_ONE_CERTIFICATE_FAILED:
      return { ...state, generateOne: { ...action.payload } };
    case GENERATE_ONE_CERTIFICATE_RESET:
      return { ...state, generateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Finished Intership Application
     * =======================================================================
     */
    case FINISHED_INTERSHIP_START:
      return { ...state, finishedIntership: { ...action.payload } };
    case FINISHED_INTERSHIP_SUCCESS:
      return { ...state, finishedIntership: { ...action.payload } };
    case FINISHED_INTERSHIP_FAILED:
      return { ...state, finishedIntership: { ...action.payload } };
    case FINISHED_INTERSHIP_RESET:
      return { ...state, finishedIntership: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default applicationsReducer;
