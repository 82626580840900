// Imports: local files.
import {
  GET_ALL_BUSINESS_TYPES_START,
  GET_ALL_BUSINESS_TYPES_SUCCESS,
  GET_ALL_BUSINESS_TYPES_FAILED,
  GET_ALL_BUSINESS_TYPES_RESET,
  GET_ONE_BUSINESS_TYPE_START,
  GET_ONE_BUSINESS_TYPE_SUCCESS,
  GET_ONE_BUSINESS_TYPE_FAILED,
  GET_ONE_BUSINESS_TYPE_RESET,
  CREATE_BUSINESS_TYPE_START,
  CREATE_BUSINESS_TYPE_SUCCESS,
  CREATE_BUSINESS_TYPE_FAILED,
  CREATE_BUSINESS_TYPE_RESET,
  UPDATE_ONE_BUSINESS_TYPE_START,
  UPDATE_ONE_BUSINESS_TYPE_SUCCESS,
  UPDATE_ONE_BUSINESS_TYPE_FAILED,
  UPDATE_ONE_BUSINESS_TYPE_RESET,
  DELETE_ONE_BUSINESS_TYPE_START,
  DELETE_ONE_BUSINESS_TYPE_SUCCESS,
  DELETE_ONE_BUSINESS_TYPE_FAILED,
  DELETE_ONE_BUSINESS_TYPE_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const businessTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Business Types
     * =======================================================================
     */
    case GET_ALL_BUSINESS_TYPES_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESS_TYPES_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESS_TYPES_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_BUSINESS_TYPES_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Business Type
     * =======================================================================
     */
    case GET_ONE_BUSINESS_TYPE_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_TYPE_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_TYPE_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_BUSINESS_TYPE_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Business Type
     * =======================================================================
     */
    case CREATE_BUSINESS_TYPE_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_BUSINESS_TYPE_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_BUSINESS_TYPE_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_BUSINESS_TYPE_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Business Type
     * =======================================================================
     */
    case UPDATE_ONE_BUSINESS_TYPE_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_TYPE_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_TYPE_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_BUSINESS_TYPE_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Business Type
     * =======================================================================
     */
    case DELETE_ONE_BUSINESS_TYPE_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_TYPE_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_TYPE_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_BUSINESS_TYPE_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default businessTypesReducer;
