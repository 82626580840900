// Imports: local files.
import {
  GET_ALL_INTERNS_START,
  GET_ALL_INTERNS_SUCCESS,
  GET_ALL_INTERNS_FAILED,
  GET_ALL_INTERNS_RESET,
  GET_ONE_INTERN_START,
  GET_ONE_INTERN_SUCCESS,
  GET_ONE_INTERN_FAILED,
  GET_ONE_INTERN_RESET,
  UPDATE_ONE_INTERN_START,
  UPDATE_ONE_INTERN_SUCCESS,
  UPDATE_ONE_INTERN_FAILED,
  UPDATE_ONE_INTERN_RESET,
  UPLOAD_PHOTO_INTERN_START,
  UPLOAD_PHOTO_INTERN_SUCCESS,
  UPLOAD_PHOTO_INTERN_FAILED,
  UPLOAD_PHOTO_INTERN_RESET,
  DELETE_ONE_INTERN_START,
  DELETE_ONE_INTERN_SUCCESS,
  DELETE_ONE_INTERN_FAILED,
  DELETE_ONE_INTERN_RESET,
  SIGN_UP_INTERN_START,
  SIGN_UP_INTERN_SUCCESS,
  SIGN_UP_INTERN_FAILED,
  SIGN_UP_INTERN_RESET,
  LOGIN_INTERN_START,
  LOGIN_INTERN_SUCCESS,
  LOGIN_INTERN_FAILED,
  LOGIN_INTERN_RESET,
  FORGOT_PASS_INTERN_START,
  FORGOT_PASS_INTERN_SUCCESS,
  FORGOT_PASS_INTERN_FAILED,
  FORGOT_PASS_INTERN_RESET,
  RESET_PASS_INTERN_START,
  RESET_PASS_INTERN_SUCCESS,
  RESET_PASS_INTERN_FAILED,
  RESET_PASS_INTERN_RESET,
  CONFIRM_INTERN_START,
  CONFIRM_INTERN_SUCCESS,
  CONFIRM_INTERN_FAILED,
  CONFIRM_INTERN_RESET,
  RESEND_INTERN_EMAIL_START,
  RESEND_INTERN_EMAIL_SUCCESS,
  RESEND_INTERN_EMAIL_FAILED,
  RESEND_INTERN_EMAIL_RESET,
  GENERATE_INTERN_CV_START,
  GENERATE_INTERN_CV_SUCCESS,
  GENERATE_INTERN_CV_FAILED,
  GENERATE_INTERN_CV_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  updateOne: initialLoadingState,
  uploadPhoto: initialLoadingState,
  deleteOne: initialLoadingState,
  signup: initialLoadingState,
  login: initialLoadingState,
  forgotPass: initialLoadingState,
  resetPass: initialLoadingState,
  confirm: initialLoadingState,
  resendEmail: initialLoadingState,
  generateCv: initialLoadingState,
};

const internsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Interns
     * =======================================================================
     */
    case GET_ALL_INTERNS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_INTERNS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_INTERNS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_INTERNS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Intern
     * =======================================================================
     */
    case GET_ONE_INTERN_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_INTERN_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_INTERN_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_INTERN_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Intern
     * =======================================================================
     */
    case UPDATE_ONE_INTERN_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_INTERN_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_INTERN_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_INTERN_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Photo Intern
     * =======================================================================
     */
    case UPLOAD_PHOTO_INTERN_START:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_INTERN_SUCCESS:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_INTERN_FAILED:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_INTERN_RESET:
      return { ...state, uploadPhoto: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Intern
     * =======================================================================
     */
    case DELETE_ONE_INTERN_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_INTERN_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_INTERN_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_INTERN_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Signup Intern
     * =======================================================================
     */
    case SIGN_UP_INTERN_START:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_INTERN_SUCCESS:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_INTERN_FAILED:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_INTERN_RESET:
      return { ...state, signup: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Login Intern
     * =======================================================================
     */
    case LOGIN_INTERN_START:
      return { ...state, login: { ...action.payload } };
    case LOGIN_INTERN_SUCCESS:
      return { ...state, login: { ...action.payload } };
    case LOGIN_INTERN_FAILED:
      return { ...state, login: { ...action.payload } };
    case LOGIN_INTERN_RESET:
      return { ...state, login: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Forgot Password Intern
     * =======================================================================
     */
    case FORGOT_PASS_INTERN_START:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_INTERN_SUCCESS:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_INTERN_FAILED:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_INTERN_RESET:
      return { ...state, forgotPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Reset Password Intern
     * =======================================================================
     */
    case RESET_PASS_INTERN_START:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_INTERN_SUCCESS:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_INTERN_FAILED:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_INTERN_RESET:
      return { ...state, resetPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Confirm Intern
     * =======================================================================
     */
    case CONFIRM_INTERN_START:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_INTERN_SUCCESS:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_INTERN_FAILED:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_INTERN_RESET:
      return { ...state, confirm: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Resend Intern Email
     * =======================================================================
     */
    case RESEND_INTERN_EMAIL_START:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_INTERN_EMAIL_SUCCESS:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_INTERN_EMAIL_FAILED:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_INTERN_EMAIL_RESET:
      return { ...state, resendEmail: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Generate Intern Cv
     * =======================================================================
     */
    case GENERATE_INTERN_CV_START:
      return { ...state, generateCv: { ...action.payload } };
    case GENERATE_INTERN_CV_SUCCESS:
      return { ...state, generateCv: { ...action.payload } };
    case GENERATE_INTERN_CV_FAILED:
      return { ...state, generateCv: { ...action.payload } };
    case GENERATE_INTERN_CV_RESET:
      return { ...state, generateCv: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default internsReducer;
