import React, { useEffect } from 'react';
import '../../../style/jobPostings.scss';
import { Header } from '../../../components/Header';
import { Partners } from '../../../components/home/partners';
import { OpportunitiesList } from './OpportunitiesList';
import { useLocation } from 'react-router-dom';

const OpportunititesInterships = ({ allPostingsLimit, ...props }) => {
  const location = useLocation();
  // useEffect(() => {
  //   document.documentElement.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'instant',
  //   });
  // }, [location]);
  return (
    <div style={{ backgroundColor: '#D9D9D9' }}>
      <Header {...props} />
      <OpportunitiesList />
      <Partners {...props} />
    </div>
  );
};

export default OpportunititesInterships;
