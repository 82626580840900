// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All Answers.
export const GET_ALL_ANSWERS_START = 'GET_ALL_ANSWERS_START';
export const GET_ALL_ANSWERS_SUCCESS = 'GET_ALL_ANSWERS_SUCCESS';
export const GET_ALL_ANSWERS_FAILED = 'GET_ALL_ANSWERS_FAILED';
export const GET_ALL_ANSWERS_RESET = 'GET_ALL_ANSWERS_RESET';

// Action Types: Get One Answer.
export const GET_ONE_ANSWER_START = 'GET_ONE_ANSWER_START';
export const GET_ONE_ANSWER_SUCCESS = 'GET_ONE_ANSWER_SUCCESS';
export const GET_ONE_ANSWER_FAILED = 'GET_ONE_ANSWER_FAILED';
export const GET_ONE_ANSWER_RESET = 'GET_ONE_ANSWER_RESET';

// Action Types: Create New Answer.
export const CREATE_ANSWER_START = 'CREATE_ANSWER_START';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_FAILED = 'CREATE_ANSWER_FAILED';
export const CREATE_ANSWER_RESET = 'CREATE_ANSWER_RESET';

// Action Creators: Get All Answers.
const getAllAnswersStart = (payload) => ({
  type: GET_ALL_ANSWERS_START,
  payload,
});
const getAllAnswersSuccess = (payload) => ({
  type: GET_ALL_ANSWERS_SUCCESS,
  payload,
});
const getAllAnswersFailed = (payload) => ({
  type: GET_ALL_ANSWERS_FAILED,
  payload,
});
const getAllAnswersReset = () => ({ type: GET_ALL_ANSWERS_RESET });

// Action Creators: Get One Answer.
const getOneAnswerStart = (payload) => ({
  type: GET_ONE_ANSWER_START,
  payload,
});
const getOneAnswerSuccess = (payload) => ({
  type: GET_ONE_ANSWER_SUCCESS,
  payload,
});
const getOneAnswerFailed = (payload) => ({
  type: GET_ONE_ANSWER_FAILED,
  payload,
});
const getOneAnswerReset = () => ({ type: GET_ONE_ANSWER_RESET });

// Action Creators: Create New Answer.
const createAnswerStart = (payload) => ({
  type: CREATE_ANSWER_START,
  payload,
});
const createAnswerSuccess = (payload) => ({
  type: CREATE_ANSWER_SUCCESS,
  payload,
});
const createAnswerFailed = (payload) => ({
  type: CREATE_ANSWER_FAILED,
  payload,
});
const createAnswerReset = () => ({ type: CREATE_ANSWER_RESET });

// Actions: Get All Answers.
export const getAllAnswers = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllAnswersStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, survey, intern, business, mentor, answer, surveyType } = payload;
      const result = await ApiClient.get('answers', {
        params: {
          page,
          limit,
          pagination,
          survey,
          intern,
          business,
          mentor,
          answer,
          surveyType,
        },
      });
      if (result.data?.success) {
        const { answers } = result.data.data;

        dispatch(
          getAllAnswersSuccess({
            loading: false,
            success: true,
            data: { answers },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllAnswersFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllAnswersFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Answers.
export const clearGetAllAnswers = () => getAllAnswersReset();

// Actions: Get One Answer.
export const getOneAnswer = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneAnswerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { answerId } = payload;
      const result = await ApiClient.get(`answers/${answerId}`);
      if (result.data?.success) {
        const { answer } = result.data.data;
        dispatch(
          getOneAnswerSuccess({
            loading: false,
            success: true,
            data: { answer },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneAnswerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneAnswerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Answer.
export const clearGetOneAnswer = () => getOneAnswerReset();

// Actions: Create New Answer.
export const createAnswer = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createAnswerStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { answers, survey, intern, business, mentor, application } = payload;
    const {
      toastNotification,
      history,
      pathname,
      showSwal,
      onSuccessMessage,
      onSuccessSecondMessage,
      onFailMessage,
      shouldRedirect,
      handleClose,
      fromMobile,
    } = options;

    try {
      const result = await ApiClient.post('answers', { answers, survey, intern, business, mentor, application });
      if (result.data?.success) {
        const { answer } = result.data.data;
        dispatch(
          createAnswerSuccess({
            loading: false,
            success: true,
            data: { answer },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onSuccessMessage}`,
            text: `${onSuccessSecondMessage}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {
            if (fromMobile && (p.isConfirmed || p.isDismissed)) {
              handleClose();
            }
          });
          shouldRedirect && history.push(pathname);
        } else {
          toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          createAnswerFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createAnswerFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );

      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Answer.
export const clearCreateNewAnswer = () => createAnswerReset();
