// Icons
import React from 'react';
import {
  UserOutlined,
  UsergroupDeleteOutlined,
  DesktopOutlined,
  ApartmentOutlined,
  BlockOutlined,
  BookOutlined,
  BankOutlined,
  OrderedListOutlined,
  ReadOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  BarChartOutlined,
  ExclamationCircleOutlined,
  MedicineBoxOutlined,
  PaperClipOutlined,
  LaptopOutlined,
  QuestionCircleOutlined,
  DownCircleOutlined,
  TagOutlined,
  HddOutlined,
  CloseCircleOutlined,
  LinkOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { ToggleOnOutlined, ToggleOffOutlined, HearingOutlined, GroupWorkOutlined } from '@mui/icons-material';
import { Route, Switch } from 'react-router-dom';
import { AdminBusinessLogin } from '../views/Dashboard/Auth/AdminBusinessLogin';

// Routes
import { Loading } from '../components/Loading';
import { PageNotFound } from '../components/PageNotFound';
import { StatisticPage } from '../views/Dashboard/Statistic';
import ForgotUserPassword from '../views/Home/Auth/ForgotUserPassword';
import ResetUserPassword from '../views/Home/Auth/ResetUserPassword';
import ResetBusinessPassword from '../views/Home/Auth/ResetBusinessPassword';
import OpportunititesInterships from '../views/Home/Opportunities/OpportunititesInterships';

// Routes Lazy
const Home = React.lazy(() => import('../views/Home'));
const Login = React.lazy(() => import('../views/Home/Auth/Login'));
const PrivacyPolicy = React.lazy(() => import('../components/home/PrivacyPolicy'));
const AllPostings = React.lazy(() => import('../views/Home/AllPostings'));
const AllTrainings = React.lazy(() => import('../views/Home/AllTrainings'));
const Register = React.lazy(() => import('../views/Home/Auth/Register'));
const RegisterBusiness = React.lazy(() => import('../views/Home/Auth/RegisterBusiness'));
const RegisterMentors = React.lazy(() => import('../views/Home/Auth/RegisterMentors'));
const Confirm = React.lazy(() => import('../views/Home/Auth/Confirm'));
const Article = React.lazy(() => import('../views/Home/Article'));
const Articles = React.lazy(() => import('../views/Dashboard/Articles'));
const Dashboard = React.lazy(() => import('../views/Dashboard'));
const Business = React.lazy(() => import('../views/Dashboard/business'));
const Mentor = React.lazy(() => import('../views/Dashboard/mentor'));
const MentorInterns = React.lazy(() => import('../views/Dashboard/MentorInterns'));
const SkillsCategory = React.lazy(() => import('../views/Dashboard/SkillsCategory'));
const Resources = React.lazy(() => import('../views/Dashboard/Resources'));
const Tags = React.lazy(() => import('../views/Dashboard/Tags'));
const SubCategories = React.lazy(() => import('../views/Dashboard/SubCategories'));
const Surveys = React.lazy(() => import('../views/Dashboard/Surveys'));
const Municipalities = React.lazy(() => import('../views/Dashboard/Municipalities'));
const BusinessTypes = React.lazy(() => import('../views/Dashboard/BusinessTypes'));
const FacilitationGuides = React.lazy(() => import('../views/Dashboard/FacilitationGuides'));
const Files = React.lazy(() => import('../views/Dashboard/FacilitationGuides/Files'));
const Documents = React.lazy(() => import('../views/Dashboard/Documents'));
const DocumentFiles = React.lazy(() => import('../views/Dashboard/Documents/DocumentFiles'));
const GuideFiles = React.lazy(() => import('../views/Dashboard/Documents/GuideFiles'));
const Payment = React.lazy(() => import('../views/Dashboard/Documents/Payment'));
const CreateRedFlag = React.lazy(() => import('../views/Dashboard/RedFlags/CreateRedFlag'));
const Institutions = React.lazy(() => import('../views/Dashboard/Institutions'));
// const BusinessCategories = React.lazy(() => import('../views/Dashboard/BusinessCategories'));
const Ethnicities = React.lazy(() => import('../views/Dashboard/Ethnicities'));
const UserContainer = React.lazy(() => import('../views/Dashboard/Users/UserContainer'));
const ConfirmEmail = React.lazy(() => import('../components/ConfirmEmail'));
const Jobs = React.lazy(() => import('../views/Dashboard/Jobs'));
const Trainings = React.lazy(() => import('../views/Dashboard/Trainings'));
const ActiveInterns = React.lazy(() => import('../views/Dashboard/ActiveInterns'));
const PastInterns = React.lazy(() => import('../views/Dashboard/PastInterns'));
const MentorshipMeetings = React.lazy(() => import('../views/Dashboard/MentorshipMeetings'));
const JobApplications = React.lazy(() => import('../views/Dashboard/JobApplications'));
const TrainingApplications = React.lazy(() => import('../views/Dashboard/TrainingApplications'));
// const SurveysAnalytics = React.lazy(() => import('../views/Dashboard/SurveysAnalytics'));
const InternDetail = React.lazy(() => import('../views/Dashboard/InternDetail'));
const MentorshipDetail = React.lazy(() => import('../views/Dashboard/Mentorship'));
const MentorshipsList = React.lazy(() => import('../views/Dashboard/MentorshipsList'));
const Cancellations = React.lazy(() => import('../views/Dashboard/Cancellations'));
const RedFlags = React.lazy(() => import('../views/Dashboard/RedFlags'));
// const Quiz = React.lazy(() => import('../views/Dashboard/Quiz'));
// const HomeQuiz = React.lazy(() => import('../views/Home/Quiz'));
// const Interns = React.lazy(() => import('../views/Dashboard/Interns'));
const Resume = React.lazy(() => import('../views/Home/Resume'));
const Sliders = React.lazy(() => import('../views/Dashboard/Slider'));
const ListBanner = React.lazy(() => import('../views/Dashboard/Banner/ListBanner'));
const JobPostings = React.lazy(() => import('../views/Home/JobPostings'));
const TrainingPostings = React.lazy(() => import('../views/Home/TrainingPostings'));
// const InternApplications = React.lazy(() => import('../views/Home/InternApplications'));
const Applications = React.lazy(() => import('../views/Home/Applications/Applications'));
const Internship = React.lazy(() => import('../views/Home/Internship'));
const MyTrainings = React.lazy(() => import('../views/Home/MyTrainings'));
// const PastInternships = React.lazy(() => import('../views/Home/PastInternships'));
// const Meetings = React.lazy(() => import('../views/Home/Meetings/Meetings'));
const MyMentor = React.lazy(() => import('../views/Home/Mentor/MyMentor'));
const MentorAssignedInterns = React.lazy(() => import('../views/Dashboard/Users/Mentors/MentorAssignedInterns'));
const HealthConditions = React.lazy(() => import('../views/Dashboard/HealthConditions'));
const HearAbout = React.lazy(() => import('../views/Dashboard/HearAbout'));
const OpportunitiesTraining = React.lazy(() => import('../views/Home/Opportunities/OpportunitiesTraining'));
// const OpportunitiesInternships = React.lazy(() => import('../views/Home/Opportunities/OpportunititesInterships'));
// const MyInternship = React.lazy(() => import('../views/Home/MyInternship'));
// const ForgotPassword = React.lazy(() => import('../views/Home/Auth/ForgotPassword'));
// const ResetPassword = React.lazy(() => import('../views/Home/Auth/ResetPassword'));
const ResourcesHome = React.lazy(() => import('../views/Home/Resources'));
const MobileSurvey = React.lazy(() => import('../views/Home/Surveys/MobileSurvey'));
const SurveyStatistics = React.lazy(() => import('../views/Dashboard/Reports/SurveyStatistics'));
const Questions = React.lazy(() => import('../views/Dashboard/Reports/SurveyStatistics/questions'));
const CompareQuestions = React.lazy(() => import('../views/Dashboard/Reports/SurveyStatistics/CompareQuestions'));
const PublicMentors = React.lazy(() => import('../views/Dashboard/PublicMentors'));
const Mentorship = React.lazy(() => import('../views/Home/Mentorship'));

const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: (props) => <Home {...props} />,
  },
  {
    path: '/admin/login',
    key: 'AUTH',
    exact: true,
    component: (props) => <AdminBusinessLogin {...props} />,
  },
  {
    path: '/login',
    key: 'AUTH',
    exact: true,
    component: (props) => <Login {...props} />,
  },
  {
    path: '/confirm-email/:id',
    key: 'AUTH',
    exact: true,
    component: (props) => <ConfirmEmail {...props} />,
  },
  {
    path: '/register',
    key: 'AUTH',
    exact: true,
    component: (props) => <Register {...props} />,
  },

  {
    path: '/jobs/:id/',
    key: 'AUTH',
    exact: true,
    component: (props) => <JobPostings {...props} />,
  },
  {
    path: '/resume',
    key: 'AUTH',
    exact: true,
    component: (props) => <Resume {...props} />,
  },

  {
    path: '/dashboard',
    key: 'Main',
    component: (props) => <Dashboard {...props} />,
    routes: [
      {
        path: '/dashboard',
        key: 'Dashboard',
        exact: true,
        role: 'admin',
        categoryName: 'Dashboard',
        icon: DesktopOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <StatisticPage {...props} />,
      },
      {
        path: '/dashboard/users',
        key: 'users',
        exact: true,
        role: 'admin',
        categoryName: 'Users',
        icon: UsergroupDeleteOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <UserContainer {...props} />,
      },
      {
        path: '/dashboard/surveyStatistics',
        key: 'surveyAnalytics',
        exact: true,
        role: 'admin',
        categoryName: 'Survey Statistics',
        icon: BarChartOutlined,
        shouldShow: true,
        component: (props) => <SurveyStatistics {...props} />,
      },
      {
        path: '/dashboard/questions',
        key: 'questions',
        exact: true,
        role: 'admin',
        categoryName: 'Questions',
        icon: BarChartOutlined,
        shouldShow: false,
        component: (props) => <Questions {...props} />,
      },
      {
        path: '/dashboard/compareQuestions',
        key: 'compareQuestions',
        exact: true,
        role: 'admin',
        categoryName: 'Compare Questions',
        icon: BarChartOutlined,
        shouldShow: false,
        component: (props) => <CompareQuestions {...props} />,
      },
      {
        path: '/dashboard/survey',
        key: 'surveys',
        exact: true,
        role: 'business',
        categoryName: 'Surveys',
        icon: QuestionCircleOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <Surveys {...props} role="admin" />,
      },
      {
        path: '/dashboard/jobs',
        key: 'opportunities',
        exact: true,
        role: 'business',
        categoryName: 'Opportunities',
        icon: ApartmentOutlined,
        shouldShow: true,
        component: (props) => <Jobs {...props} role="admin" />,
      },
      {
        path: '/dashboard/jobs/:id',
        key: 'jobDetails',
        exact: true,
        role: 'admin',
        categoryName: 'Job Applications',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <JobApplications {...props} />,
      },
      {
        path: '/dashboard/trainings',
        key: 'trainings',
        exact: true,
        role: 'business',
        categoryName: 'Trainings',
        icon: LaptopOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <Trainings {...props} role="admin" />,
      },
      {
        path: '/dashboard/publicmentors',
        key: 'mentorship',
        exact: true,
        role: 'admin',
        categoryName: 'Mentorship',
        icon: DatabaseOutlined,
        shouldShow: true,
        component: (props) => <PublicMentors {...props} />,
      },
      {
        path: '/dashboard/mentorshipsList',
        key: 'mentorshipsList',
        exact: true,
        role: 'admin',
        categoryName: 'Mentorships',
        icon: DatabaseOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <MentorshipsList {...props} />,
      },
      {
        path: '/dashboard/cancellations',
        key: 'cancellations',
        exact: true,
        role: 'admin',
        categoryName: 'Internship Cancellations',
        icon: CloseCircleOutlined,
        shouldShow: true,
        component: (props) => <Cancellations {...props} />,
      },
      {
        path: '/dashboard/redFlags',
        key: 'redFlags',
        exact: true,
        role: 'admin',
        categoryName: 'Organisations Red Flagged',
        icon: ExclamationCircleOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <RedFlags {...props} />,
      },
      {
        path: '/dashboard/skills-category',
        key: 'skillsCategory',
        exact: true,
        role: 'admin',
        categoryName: 'Knowledges/Skills & Fields of Interests',
        icon: BlockOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <SkillsCategory {...props} />,
      },
      {
        path: '/dashboard/resources',
        key: 'resources',
        exact: true,
        role: 'admin',
        categoryName: 'Resources',
        icon: HddOutlined,
        shouldShow: true,
        component: (props) => <Resources {...props} />,
      },
      {
        path: '/dashboard/sub-categories',
        key: 'subCategories',
        exact: true,
        role: 'admin',
        categoryName: 'Sub Categories',
        icon: DownCircleOutlined,
        shouldShow: true,
        component: (props) => <SubCategories {...props} />,
      },

      {
        path: '/dashboard/tags',
        key: 'tags',
        exact: true,
        role: 'admin',
        categoryName: 'Tags',
        icon: TagOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <Tags {...props} />,
      },
      {
        path: '/dashboard/municipalities',
        key: 'municipalities',
        exact: true,
        role: 'admin',
        categoryName: 'Municipalities',
        icon: BankOutlined,
        shouldShow: true,
        component: (props) => <Municipalities {...props} />,
      },
      {
        path: '/dashboard/institutions',
        key: 'institutions',
        exact: true,
        role: 'admin',
        categoryName: 'Institutions',
        icon: ReadOutlined,
        shouldShow: true,
        component: (props) => <Institutions {...props} />,
      },
      {
        path: '/dashboard/businessTypes',
        key: 'businessTypes',
        exact: true,
        role: 'admin',
        categoryName: 'Entity Types',
        icon: OrderedListOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <BusinessTypes {...props} />,
      },
      {
        path: '/dashboard/facilitationGuides',
        key: 'facilitationGuides',
        exact: true,
        role: 'admin',
        categoryName: 'Facilitation Guides',
        icon: PaperClipOutlined,
        shouldShow: true,
        component: (props) => <FacilitationGuides {...props} />,
      },
      {
        path: '/dashboard/documents',
        key: 'documents',
        exact: true,
        role: 'admin',
        categoryName: 'Documents',
        icon: PaperClipOutlined,
        showLine: true,
        shouldShow: true,
        component: (props) => <Documents {...props} />,
      },
      // {
      //   path: '/dashboard/businessCategories',
      //   key: 'businessCategories',
      //   exact: true,
      //   role: 'admin',
      //   categoryName: 'Organisation Categories',
      //   icon: UnorderedListOutlined,
      //   shouldShow: true,
      //   component: (props) => <BusinessCategories {...props} />,
      // },
      {
        path: '/dashboard/ethnicities',
        key: 'ethnicities',
        exact: true,
        role: 'admin',
        categoryName: 'Ethnicities',
        icon: GroupWorkOutlined,
        shouldShow: true,
        component: (props) => <Ethnicities {...props} />,
      },
      {
        path: '/dashboard/healthConditions',
        key: 'healthConditions',
        exact: true,
        role: 'admin',
        categoryName: 'Health Conditions',
        icon: MedicineBoxOutlined,
        shouldShow: true,
        component: (props) => <HealthConditions {...props} />,
      },
      {
        path: '/dashboard/hearAbout',
        key: 'aboutPlatform',
        exact: true,
        role: 'admin',
        categoryName: 'Hear About Platform',
        icon: HearingOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <HearAbout {...props} />,
      },
      {
        path: '/dashboard/articles',
        key: 'articles',
        exact: true,
        role: 'admin',
        categoryName: 'Articles',
        icon: BookOutlined,
        shouldShow: true,
        component: (props) => <Articles {...props} />,
      },
      {
        path: '/dashboard/banner',
        key: 'banner',
        exact: true,
        role: 'admin',
        categoryName: 'Banner',
        // icon: SlidersOutlined,
        icon: SlidersOutlined,
        shouldShow: true,
        component: (props) => <ListBanner {...props} />,
      },
      // {
      //   path: '/dashboard/slider',
      //   key: 'slider',
      //   exact: true,
      //   role: 'admin',
      //   categoryName: 'Slider',
      //    icon: SlidersOutlined,
      //   shouldShow: true,
      //   component: (props) => <Sliders {...props} />,
      // },

      // {
      //   path: '/dashboard/quiz',
      //   key: 'quiz',
      //   exact: true,
      //   role: 'admin',
      //   categoryName: 'Quiz',
      //   icon: PicCenterOutlined,
      //   component: (props) => <Quiz {...props} />,
      // },
      {
        path: '/dashboard/internsAssigned/:id',
        key: 'internsAssigned',
        exact: true,
        role: 'admin',
        categoryName: 'Interns Assigned',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <MentorAssignedInterns {...props} />,
      },
      {
        path: '/dashboard/trainings/:id',
        key: 'trainingDetails',
        exact: true,
        role: 'admin',
        categoryName: 'Training Applications',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <TrainingApplications {...props} />,
      },
      {
        path: '/dashboard/internDetail/:id',
        key: 'internDetail',
        exact: true,
        role: 'admin',
        categoryName: 'Intern Detail',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <InternDetail {...props} />,
      },
      // {
      //   path: '/dashboard/sliders',
      //   key: 'internDetail',
      //   exact: true,
      //   role: 'admin',
      //   categoryName: 'Slider',
      //   icon: UserOutlined,
      //   shouldShow: false,
      //   component: (props) => <Sliders {...props} />,
      // },
    ],
  },
  {
    path: '/dashboard',
    key: 'Main',
    component: (props) => <Dashboard {...props} />,
    routes: [
      {
        path: '/dashboard',
        key: 'profile',
        exact: true,
        role: 'business',
        categoryName: 'profile',
        icon: UserOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <Business {...props} />,
      },
      {
        path: '/dashboard/guideDocuments',
        key: 'guide',
        exact: true,
        role: 'business',
        categoryName: 'Guide',
        icon: PaperClipOutlined,
        shouldShow: true,
        component: (props) => <GuideFiles {...props} />,
      },
      {
        path: '/dashboard/documents',
        key: 'documents',
        exact: true,
        role: 'business',
        categoryName: 'Documents',
        icon: PaperClipOutlined,
        shouldShow: true,
        component: (props) => <DocumentFiles {...props} />,
      },
      {
        path: '/dashboard/payment',
        key: 'payment',
        exact: true,
        role: 'business',
        categoryName: 'Payment',
        icon: LinkOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <Payment {...props} />,
      },
      // {
      //   path: '/dashboard/interns',
      //   key: 'interns',
      //   exact: true,
      //   role: 'business',
      //   categoryName: 'Interns',
      //   icon: UsergroupDeleteOutlined,
      //   shouldShow: true,
      //   component: (props) => <Interns {...props} />,
      // },
      {
        path: '/dashboard/activeInterns',
        key: 'activeInterns',
        exact: true,
        role: 'business',
        categoryName: 'Active Interns',
        icon: ToggleOnOutlined,
        shouldShow: true,
        component: (props) => <ActiveInterns {...props} />,
      },
      {
        path: '/dashboard/pastInterns',
        key: 'pastInterns',
        exact: true,
        role: 'business',
        categoryName: 'Past Interns',
        icon: ToggleOffOutlined,
        shouldShow: true,
        showLine: true,
        component: (props) => <PastInterns {...props} />,
      },
      {
        path: '/dashboard/jobs',
        key: 'opportunities',
        exact: true,
        role: 'business',
        categoryName: 'opportunities',
        icon: ApartmentOutlined,
        shouldShow: true,
        component: (props) => <Jobs {...props} />,
      },
      {
        path: '/dashboard/trainings',
        key: 'trainings',
        exact: true,
        role: 'business',
        categoryName: 'trainings',
        icon: LaptopOutlined,
        shouldShow: true,
        component: (props) => <Trainings {...props} />,
      },
      {
        path: '/dashboard/jobs/:id',
        key: 'jobDetails',
        exact: true,
        role: 'business',
        categoryName: 'Job Applications',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <JobApplications {...props} />,
      },
      {
        path: '/dashboard/trainings/:id',
        key: 'trainingDetails',
        exact: true,
        role: 'business',
        categoryName: 'Training Applications',
        icon: UserOutlined,
        shouldShow: false,
        showLine: true,
        component: (props) => <TrainingApplications {...props} />,
      },
      {
        path: '/dashboard/internDetail/:id',
        key: 'internDetail',
        exact: true,
        role: 'business',
        categoryName: 'Intern Detail',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <InternDetail {...props} />,
      },
    ],
  },
  {
    path: '/dashboard',
    key: 'Main',
    component: (props) => <Dashboard {...props} />,
    routes: [
      {
        path: '/dashboard',
        key: 'profile',
        exact: true,
        role: 'mentor',
        categoryName: 'Profile',
        icon: UserOutlined,
        shouldShow: true,
        component: (props) => <Mentor {...props} />,
      },
      {
        path: '/dashboard/facilitationGuides',
        key: 'facilitationGuides',
        exact: true,
        role: 'mentor',
        categoryName: 'Facilitation Guides',
        icon: PaperClipOutlined,
        shouldShow: true,
        component: (props) => <Files {...props} />,
      },
      {
        path: '/dashboard/myinterns',
        key: 'myInterns',
        exact: true,
        role: 'mentor',
        categoryName: 'My Interns',
        icon: UsergroupAddOutlined,
        shouldShow: true,
        component: (props) => <MentorInterns {...props} />,
      },
      {
        path: '/dashboard/mentorshipMeetings',
        key: 'meetings',
        exact: true,
        role: 'mentor',
        categoryName: 'mentorshipMeetings',
        icon: CalendarOutlined,
        shouldShow: true,
        component: (props) => <MentorshipMeetings {...props} />,
      },
      {
        path: '/dashboard/report',
        key: 'reportOrganisation',
        exact: true,
        role: 'mentor',
        categoryName: 'report',
        icon: ExclamationCircleOutlined,
        shouldShow: true,
        component: (props) => <CreateRedFlag {...props} />,
      },
      {
        path: '/dashboard/mentorshipDetail/:id',
        key: 'mentorshipDetail',
        exact: true,
        role: 'mentor',
        categoryName: 'Mentorship Detail',
        icon: UserOutlined,
        shouldShow: false,
        component: (props) => <MentorshipDetail {...props} />,
      },
    ],
  },

  {
    path: '/registerBusiness',
    key: 'AUTH',
    exact: true,
    component: (props) => <RegisterBusiness {...props} />,
  },
  {
    path: '/resetBusinessPassword',
    key: 'AUTH',
    exact: true,
    component: (props) => <ResetBusinessPassword {...props} />,
  },
  {
    path: '/trainings/:id/',
    key: 'AUTH',
    exact: true,
    component: (props) => <TrainingPostings {...props} />,
  },
  // {
  //   path: '/opportunitiesinternships',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <OpportunitiesInternships {...props} />,
  // },

  {
    path: '/opportunitiesinterships',
    key: 'AUTH',
    expect: true,
    component: (props) => <OpportunititesInterships {...props} />,
  },
  {
    path: '/opportunitiestraining',
    key: 'AUTH',
    exact: true,
    component: (props) => <OpportunitiesTraining {...props} />,
  },
  // {
  //   path: '/forgot-password',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <ForgotPassword {...props} />,
  // },
  {
    path: '/forgot-user-password',
    key: 'AUTH',
    exact: true,
    component: (props) => <ForgotUserPassword {...props} />,
  },
  {
    path: '/registerMentors',
    key: 'AUTH',
    exact: true,
    component: (props) => <RegisterMentors {...props} />,
  },
  {
    path: '/confirm',
    key: 'AUTH',
    exact: true,
    component: (props) => <Confirm {...props} />,
  },

  {
    path: '/internship',
    key: 'AUTH',
    exact: true,
    component: (props) => <Internship {...props} />,
  },
  {
    path: '/myTrainings',
    key: 'AUTH',
    exact: true,
    component: (props) => <MyTrainings {...props} />,
  },

  // {
  //   path: '/pastInternships',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <PastInternships {...props} />,
  // },
  {
    path: '/myMentor',
    key: 'AUTH',
    exact: true,
    component: (props) => <MyMentor {...props} />,
  },

  // {
  //   path: '/quiz',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <HomeQuiz {...props} />,
  // },,
  // {
  //   path: '/applications',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <InternApplications {...props} />,
  // },
  {
    path: '/applications',
    key: 'AUTH',
    exact: true,
    component: (props) => <Applications {...props} />,
  },
  {
    path: '/opportunities',
    key: 'AUTH',
    exact: true,
    component: (props) => <AllPostings {...props} />,
  },
  {
    path: '/trainings',
    key: 'AUTH',
    exact: true,
    component: (props) => <AllTrainings {...props} />,
  },

  {
    path: '/opportunitiestraining',
    key: 'AUTH',
    exact: true,
    component: (props) => <OpportunitiesTraining {...props} />,
  },
  {
    path: '/resources',
    key: 'AUTH',
    exact: true,
    component: (props) => <ResourcesHome {...props} />,
  },
  {
    path: '/mentorship',
    key: 'AUTH',
    exact: true,
    component: (props) => <Mentorship {...props} />,
  },
  {
    path: '/mobilesurvey',
    key: 'AUTH',
    exact: true,
    component: (props) => <MobileSurvey {...props} />,
  },
  {
    path: '/privacyPolicy',
    key: 'AUTH',
    exact: true,
    component: (props) => <PrivacyPolicy {...props} />,
  },
  {
    path: '/reset-password/:token',
    key: 'AUTH',
    exact: true,
    component: (props) => <ResetUserPassword {...props} />,
  },
  // {
  //   path: '/reset-password-intern/:token',
  //   key: 'AUTH',
  //   exact: true,
  //   component: (props) => <ResetPassword {...props} isIntern={true} />,
  // },
  {
    path: '/:id/',
    key: 'AUTH',
    exact: true,
    component: (props) => <Article {...props} />,
  },
];
export default ROUTES;

export const RenderRoutes = ({ routes }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        {routes.map((route) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route component={(props) => <PageNotFound {...props} />} />
      </Switch>
    </React.Suspense>
  );
};

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
