// Imports: local files.
import {
  GET_ALL_COUNTRIES_START,
  GET_ALL_COUNTRIES_SUCCESS,
  GET_ALL_COUNTRIES_FAILED,
  GET_ALL_COUNTRIES_RESET,
  GET_ONE_COUNTRY_START,
  GET_ONE_COUNTRY_SUCCESS,
  GET_ONE_COUNTRY_FAILED,
  GET_ONE_COUNTRY_RESET,
  CREATE_COUNTRY_START,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAILED,
  CREATE_COUNTRY_RESET,
  UPDATE_ONE_COUNTRY_START,
  UPDATE_ONE_COUNTRY_SUCCESS,
  UPDATE_ONE_COUNTRY_FAILED,
  UPDATE_ONE_COUNTRY_RESET,
  DELETE_ONE_COUNTRY_START,
  DELETE_ONE_COUNTRY_SUCCESS,
  DELETE_ONE_COUNTRY_FAILED,
  DELETE_ONE_COUNTRY_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Countries
     * =======================================================================
     */
    case GET_ALL_COUNTRIES_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COUNTRIES_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COUNTRIES_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COUNTRIES_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Country
     * =======================================================================
     */
    case GET_ONE_COUNTRY_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COUNTRY_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COUNTRY_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COUNTRY_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Country
     * =======================================================================
     */
    case CREATE_COUNTRY_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_COUNTRY_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_COUNTRY_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_COUNTRY_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Country
     * =======================================================================
     */
    case UPDATE_ONE_COUNTRY_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_COUNTRY_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_COUNTRY_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_COUNTRY_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Country
     * =======================================================================
     */
    case DELETE_ONE_COUNTRY_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_COUNTRY_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_COUNTRY_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_COUNTRY_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default countriesReducer;
