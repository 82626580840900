// Imports: local files.
import ApiClient from '../../services/ApiClient';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

// Action Types: Get All Mentors.
export const GET_ALL_MENTORS_START = 'GET_ALL_MENTORS_START';
export const GET_ALL_MENTORS_SUCCESS = 'GET_ALL_MENTORS_SUCCESS';
export const GET_ALL_MENTORS_FAILED = 'GET_ALL_MENTORS_FAILED';
export const GET_ALL_MENTORS_RESET = 'GET_ALL_MENTORS_RESET';

// Action Types: Get One Mentor.
export const GET_ONE_MENTOR_START = 'GET_ONE_MENTOR_START';
export const GET_ONE_MENTOR_SUCCESS = 'GET_ONE_MENTOR_SUCCESS';
export const GET_ONE_MENTOR_FAILED = 'GET_ONE_MENTOR_FAILED';
export const GET_ONE_MENTOR_RESET = 'GET_ONE_MENTOR_RESET';

// Action Types: Update One Mentor.
export const UPDATE_ONE_MENTOR_START = 'UPDATE_ONE_MENTOR_START';
export const UPDATE_ONE_MENTOR_SUCCESS = 'UPDATE_ONE_MENTOR_SUCCESS';
export const UPDATE_ONE_MENTOR_FAILED = 'UPDATE_ONE_MENTOR_FAILED';
export const UPDATE_ONE_MENTOR_RESET = 'UPDATE_ONE_MENTOR_RESET';

// Action Types: Upload Photo Mentor.
export const UPLOAD_PHOTO_MENTOR_START = 'UPLOAD_PHOTO_MENTOR_START';
export const UPLOAD_PHOTO_MENTOR_SUCCESS = 'UPLOAD_PHOTO_MENTOR_SUCCESS';
export const UPLOAD_PHOTO_MENTOR_FAILED = 'UPLOAD_PHOTO_MENTOR_FAILED';
export const UPLOAD_PHOTO_MENTOR_RESET = 'UPLOAD_PHOTO_MENTOR_RESET';

// Action Types: Upload Cv Mentor.
export const UPLOAD_CV_MENTOR_START = 'UPLOAD_CV_MENTOR_START';
export const UPLOAD_CV_MENTOR_SUCCESS = 'UPLOAD_CV_MENTOR_SUCCESS';
export const UPLOAD_CV_MENTOR_FAILED = 'UPLOAD_CV_MENTOR_FAILED';
export const UPLOAD_CV_MENTOR_RESET = 'UPLOAD_CV_MENTOR_RESET';

// Action Types: Delete One Mentor.
export const DELETE_ONE_MENTOR_START = 'DELETE_ONE_MENTOR_START';
export const DELETE_ONE_MENTOR_SUCCESS = 'DELETE_ONE_MENTOR_SUCCESS';
export const DELETE_ONE_MENTOR_FAILED = 'DELETE_ONE_MENTOR_FAILED';
export const DELETE_ONE_MENTOR_RESET = 'DELETE_ONE_MENTOR_RESET';

// Action Types: Sign Up Mentor.
export const SIGN_UP_MENTOR_START = 'SIGN_UP_MENTOR_START';
export const SIGN_UP_MENTOR_SUCCESS = 'SIGN_UP_MENTOR_SUCCESS';
export const SIGN_UP_MENTOR_FAILED = 'SIGN_UP_MENTOR_FAILED';
export const SIGN_UP_MENTOR_RESET = 'SIGN_UP_MENTOR_RESET';

// Action Types: Login Mentor.
export const LOGIN_MENTOR_START = 'LOGIN_MENTOR_START';
export const LOGIN_MENTOR_SUCCESS = 'LOGIN_MENTOR_SUCCESS';
export const LOGIN_MENTOR_FAILED = 'LOGIN_MENTOR_FAILED';
export const LOGIN_MENTOR_RESET = 'LOGIN_MENTOR_RESET';

// Action Types: Forgot Password Mentor.
export const FORGOT_PASS_MENTOR_START = 'FORGOT_PASS_MENTOR_START';
export const FORGOT_PASS_MENTOR_SUCCESS = 'FORGOT_PASS_MENTOR_SUCCESS';
export const FORGOT_PASS_MENTOR_FAILED = 'FORGOT_PASS_MENTOR_FAILED';
export const FORGOT_PASS_MENTOR_RESET = 'FORGOT_PASS_MENTOR_RESET';

// Action Types: Reset Password Mentor.
export const RESET_PASS_MENTOR_START = 'RESET_PASS_MENTOR_START';
export const RESET_PASS_MENTOR_SUCCESS = 'RESET_PASS_MENTOR_SUCCESS';
export const RESET_PASS_MENTOR_FAILED = 'RESET_PASS_MENTOR_FAILED';
export const RESET_PASS_MENTOR_RESET = 'RESET_PASS_MENTOR_RESET';

// Action Types: Get All Intern Matches For One Mentor
export const GET_ALL_MENTOR_MATCHES_START = 'GET_ALL_MENTOR_MATCHES_START';
export const GET_ALL_MENTOR_MATCHES_SUCCESS = 'GET_ALL_MENTOR_MATCHES_SUCCESS';
export const GET_ALL_MENTOR_MATCHES_FAILED = 'GET_ALL_MENTOR_MATCHES_FAILED';
export const GET_ALL_MENTOR_MATCHES_RESET = 'GET_ALL_MENTOR_MATCHES_RESET';

// Action Types: Confirm Mentor
export const CONFIRM_MENTOR_START = 'CONFIRM_MENTOR_START';
export const CONFIRM_MENTOR_SUCCESS = 'CONFIRM_MENTOR_SUCCESS';
export const CONFIRM_MENTOR_FAILED = 'CONFIRM_MENTOR_FAILED';
export const CONFIRM_MENTOR_RESET = 'CONFIRM_MENTOR_RESET';

// Action Types: Resend Mentor Email
export const RESEND_MENTOR_EMAIL_START = 'RESEND_MENTOR_EMAIL_START';
export const RESEND_MENTOR_EMAIL_SUCCESS = 'RESEND_MENTOR_EMAIL_SUCCESS';
export const RESEND_MENTOR_EMAIL_FAILED = 'RESEND_MENTOR_EMAIL_FAILED';
export const RESEND_MENTOR_EMAIL_RESET = 'RESEND_MENTOR_EMAIL_RESET';

// Action Creators: Get All Mentors.
const getAllMentorsStart = (payload) => ({
  type: GET_ALL_MENTORS_START,
  payload,
});
const getAllMentorsSuccess = (payload) => ({
  type: GET_ALL_MENTORS_SUCCESS,
  payload,
});
const getAllMentorsFailed = (payload) => ({
  type: GET_ALL_MENTORS_FAILED,
  payload,
});
const getAllMentorsReset = () => ({ type: GET_ALL_MENTORS_RESET });

// Action Creators: Get One Mentor.
const getOneMentorStart = (payload) => ({
  type: GET_ONE_MENTOR_START,
  payload,
});
const getOneMentorSuccess = (payload) => ({
  type: GET_ONE_MENTOR_SUCCESS,
  payload,
});
const getOneMentorFailed = (payload) => ({
  type: GET_ONE_MENTOR_FAILED,
  payload,
});
const getOneMentorReset = () => ({ type: GET_ONE_MENTOR_RESET });

// Action Creators: Update One Mentor.
const updateOneMentorStart = (payload) => ({
  type: UPDATE_ONE_MENTOR_SUCCESS,
  payload,
});
const updateOneMentorSuccess = (payload) => ({
  type: UPDATE_ONE_MENTOR_START,
  payload,
});
const updateOneMentorFailed = (payload) => ({
  type: UPDATE_ONE_MENTOR_FAILED,
  payload,
});
const updateOneMentorReset = () => ({ type: UPDATE_ONE_MENTOR_RESET });

// Action Creators: Upload Photo Mentor.
const uploadPhotoMentorStart = (payload) => ({
  type: UPLOAD_PHOTO_MENTOR_START,
  payload,
});
const uploadPhotoMentorSuccess = (payload) => ({
  type: UPLOAD_PHOTO_MENTOR_SUCCESS,
  payload,
});
const uploadPhotoMentorFailed = (payload) => ({
  type: UPLOAD_PHOTO_MENTOR_FAILED,
  payload,
});
const uploadPhotoMentorReset = () => ({ type: UPLOAD_PHOTO_MENTOR_RESET });

// Action Creators: Upload Cv Mentor.
const uploadCvMentorStart = (payload) => ({
  type: UPLOAD_CV_MENTOR_START,
  payload,
});
const uploadCvMentorSuccess = (payload) => ({
  type: UPLOAD_CV_MENTOR_SUCCESS,
  payload,
});
const uploadCvMentorFailed = (payload) => ({
  type: UPLOAD_CV_MENTOR_FAILED,
  payload,
});
const uploadCvMentorReset = () => ({ type: UPLOAD_CV_MENTOR_RESET });

// Action Creators: Delete One Mentor.
const deleteOneMentorStart = (payload) => ({
  type: DELETE_ONE_MENTOR_START,
  payload,
});
const deleteOneMentorSuccess = (payload) => ({
  type: DELETE_ONE_MENTOR_SUCCESS,
  payload,
});
const deleteOneMentorFailed = (payload) => ({
  type: DELETE_ONE_MENTOR_FAILED,
  payload,
});
const deleteOneMentorReset = () => ({ type: DELETE_ONE_MENTOR_RESET });

// Action Creators: Sing Up Mentor.
const signUpMentorStart = (payload) => ({
  type: SIGN_UP_MENTOR_START,
  payload,
});
const signUpMentorSuccess = (payload) => ({
  type: SIGN_UP_MENTOR_SUCCESS,
  payload,
});
const signUpMentorFailed = (payload) => ({
  type: SIGN_UP_MENTOR_FAILED,
  payload,
});
const signUpMentorReset = () => ({ type: SIGN_UP_MENTOR_RESET });

// Action Creators: Login Mentor.
const loginMentorStart = (payload) => ({
  type: LOGIN_MENTOR_START,
  payload,
});
const loginMentorSuccess = (payload) => ({
  type: LOGIN_MENTOR_SUCCESS,
  payload,
});
const loginMentorFailed = (payload) => ({
  type: LOGIN_MENTOR_FAILED,
  payload,
});
const loginMentorReset = () => ({ type: LOGIN_MENTOR_RESET });

// Action Creators: Forgot Password Mentor.
const forgotPassMentorStart = (payload) => ({
  type: FORGOT_PASS_MENTOR_START,
  payload,
});
const forgotPassMentorSuccess = (payload) => ({
  type: FORGOT_PASS_MENTOR_SUCCESS,
  payload,
});
const forgotPassMentorFailed = (payload) => ({
  type: FORGOT_PASS_MENTOR_FAILED,
  payload,
});
const forgotPassMentorReset = () => ({ type: FORGOT_PASS_MENTOR_RESET });

// Action Creators: Reset Password Mentor.
const resetPassMentorStart = (payload) => ({
  type: RESET_PASS_MENTOR_START,
  payload,
});
const resetPassMentorSuccess = (payload) => ({
  type: RESET_PASS_MENTOR_SUCCESS,
  payload,
});
const resetPassMentorFailed = (payload) => ({
  type: RESET_PASS_MENTOR_FAILED,
  payload,
});
const resetPassMentorReset = () => ({ type: RESET_PASS_MENTOR_RESET });

// Action Creators: Get All Mentor Matches
const getAllMentorMatchesStart = (payload) => ({
  type: GET_ALL_MENTOR_MATCHES_START,
  payload,
});
const getAllMentorMatchesSuccess = (payload) => ({
  type: GET_ALL_MENTOR_MATCHES_SUCCESS,
  payload,
});
const getAllMentorMatchesFailed = (payload) => ({
  type: GET_ALL_MENTOR_MATCHES_FAILED,
  payload,
});
const getAllMentorMatchesReset = () => ({ type: GET_ALL_MENTOR_MATCHES_RESET });

// Action Creators: Confirm Mentor.
const confirmMentorStart = (payload) => ({
  type: CONFIRM_MENTOR_START,
  payload,
});
const confirmMentorSuccess = (payload) => ({
  type: CONFIRM_MENTOR_SUCCESS,
  payload,
});
const confirmMentorFailed = (payload) => ({
  type: CONFIRM_MENTOR_FAILED,
  payload,
});
const confirmMentorReset = () => ({ type: CONFIRM_MENTOR_RESET });

// Action Creators: Resend Mentor Email.
const resendMentorEmailStart = (payload) => ({
  type: RESEND_MENTOR_EMAIL_START,
  payload,
});
const resendMentorEmailSuccess = (payload) => ({
  type: RESEND_MENTOR_EMAIL_SUCCESS,
  payload,
});
const resendMentorEmailFailed = (payload) => ({
  type: RESEND_MENTOR_EMAIL_FAILED,
  payload,
});
const resendMentorEmailReset = () => ({ type: RESEND_MENTOR_EMAIL_RESET });

// Actions: Get All Mentors.
export const getAllMentors = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMentorsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      page,
      limit,
      pagination,
      firstName,
      lastName,
      municipality,
      skills,
      categories,
      gender,
      availability,
      hasOpenSpots,
      application,
      removeInvitedMentors,
      approved,
      registeredFrom,
      registeredTo,
    } = payload;

    try {
      const result = await ApiClient.get('mentors', {
        params: {
          page,
          limit,
          pagination,
          firstName,
          lastName,
          municipality,
          skills,
          categories,
          gender,
          availability,
          hasOpenSpots,
          application,
          removeInvitedMentors,
          approved,
          registeredFrom,
          registeredTo,
        },
      });
      if (result.data?.success) {
        const { mentors } = result.data?.data;
        dispatch(
          getAllMentorsSuccess({
            loading: false,
            success: true,
            data: { mentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMentorsFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMentorsFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All.
export const clearGetAllMentors = () => getAllMentorsReset();

// Actions: Get One Mentor.
export const getOneMentor = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { mentorId } = payload;

    try {
      const result = await ApiClient.get(`mentors/${mentorId}`);
      if (result.data?.success) {
        const { mentor } = result.data?.data;
        dispatch(
          getOneMentorSuccess({ loading: false, success: true, data: { mentor }, error: false, errorMessage: null })
        );
      } else {
        dispatch(
          getOneMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One.
export const clearGetOneMentor = () => getOneMentorReset();

// Actions: Update One Mentor.
export const updateOneMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      mentorId,
      mentorName,
      mentorSurname,
      mentorMunicipality,
      mentorJob,
      mentorFieldOfEducation,
      mentorGender,
      mentorSkills,
      mentorCategories,
      mentorAvailability,
      mentorEthnicity,
      mentorDisabilities,
      mentorApprovedByAdmin,
      mentorNumberOfInterns,
      mentorLanguages,
    } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`mentors/${mentorId}`, {
        mentorName,
        mentorSurname,
        mentorMunicipality,
        mentorJob,
        mentorFieldOfEducation,
        mentorGender,
        mentorSkills,
        mentorCategories,
        mentorAvailability,
        mentorEthnicity,
        mentorDisabilities,
        mentorApprovedByAdmin,
        mentorNumberOfInterns,
        mentorLanguages,
      });
      if (result.data?.success) {
        const { mentor } = result.data.data;
        dispatch(
          updateOneMentorSuccess({
            loading: false,
            success: true,
            data: { mentor },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One.
export const clearUpdateOneMentor = () => updateOneMentorReset();

// Actions: Upload Photo Mentor.
export const uploadPhotoMentor = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadPhotoMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { showToast, toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage } =
      options;
    try {
      const { mentorId, formData } = payload;
      const result = await ApiClient.put(`mentors/${mentorId}/logo`, formData);
      if (result.data?.success) {
        const { mentor } = result.data?.data;
        dispatch(
          uploadPhotoMentorSuccess({
            loading: false,
            success: true,
            data: { mentor },
            error: false,
            errorMessage: null,
          })
        );
        // showToast && toastNotification('success', onSuccessMessage);
        // shouldRedirect && history.push(pathname);
        if (showSwal) {
          const swalOptions = {
            title: 'Email sent successfully!',
            text: 'A verification email was sent to you, please check your email for further instructions!',
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          // Swal.fire(swalOptions).then((p) => {});
          Swal.fire(swalOptions).then((result) => result.isConfirmed && shouldRedirect && history.push(pathname));
        } else {
          showToast && toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          uploadPhotoMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadPhotoMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Photo.
export const clearUploadPhotoMentor = () => uploadPhotoMentorReset();

// Actions: Upload Cv Mentor.
export const uploadCvMentor = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadCvMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onSuccessMessageEmailSent,
      onSuccessMessageEmail,
    } = options;
    try {
      const { mentorId, formData } = payload;
      const result = await ApiClient.put(`mentors/${mentorId}/cv`, formData);
      if (result.data?.success) {
        const { mentor } = result.data?.data;
        dispatch(
          uploadPhotoMentorSuccess({
            loading: false,
            success: true,
            data: { mentor },
            error: false,
            errorMessage: null,
          })
        );
        // showToast && toastNotification('success', onSuccessMessage);
        // shouldRedirect && history.push(pathname);
        if (showSwal) {
          const swalOptions = {
            title: `${onSuccessMessageEmailSent}`,
            text: `${onSuccessMessageEmail}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          // Swal.fire(swalOptions).then((p) => {});
          Swal.fire(swalOptions).then((result) => result.isConfirmed && shouldRedirect && history.push(pathname));
        } else {
          showToast && toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          uploadCvMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadCvMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Cv.
export const clearUploadCvMentor = () => uploadCvMentorReset();

// Actions: Delete One Mentor.
export const deleteOneMentor = (payload, options = {}) => {
  return async (dispatch) => {
    dispatch(
      deleteOneMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { mentorId } = payload;
    const { toastNotification, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.delete(`mentors/${mentorId}`);
      if (result.data?.success) {
        const { mentor } = result.data.data;
        dispatch(
          deleteOneMentorSuccess({
            loading: false,
            success: true,
            data: { mentor },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          deleteOneMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        deleteOneMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Delete One.
export const clearDeleteOneMentor = () => deleteOneMentorReset();

// Actions: Sign Up Mentor.
export const signUpMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      signUpMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onFailMessage, onSuccessMessage, userExistsMessage } = options;
    const {
      email,
      password,
      passwordConfirm,
      name,
      surname,
      municipality,
      job,
      fieldOfEducation,
      gender,
      skills,
      categories,
      ethnicity,
      disabilities,
      emailLanguage,
    } = payload;
    try {
      const result = await ApiClient.post('mentors/auth/signup', {
        email: email.toLowerCase(),
        password,
        passwordConfirm,
        name,
        surname,
        municipality,
        job,
        fieldOfEducation,
        gender,
        skills,
        categories,
        ethnicity,
        disabilities,
        emailLanguage,
      });
      if (result.data?.success) {
        const { sent, mentorId } = result.data.data;
        dispatch(
          signUpMentorSuccess({
            loading: false,
            success: true,
            data: { sent, mentorId },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          signUpMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        signUpMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const emailExistsMessage = 'USER_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === emailExistsMessage
      ) {
        toastNotification('error', userExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Sign Up.
export const clearSignUpMentor = () => signUpMentorReset();

// Actions: Login Mentor.
export const loginMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      loginMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onFailConfirmMessage,
      onFailCredentialMessage,
    } = options;
    try {
      const { email, password, remember, emailLanguage } = payload;
      const result = await ApiClient.post('mentors/auth/login', {
        email: email.toLowerCase(),
        password,
        remember,
        emailLanguage,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          loginMentorSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        history.push(pathname);
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          loginMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        loginMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const unconfirmedMessage = 'UNCONFIRMED_ACCOUNT';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === unconfirmedMessage
      ) {
        toastNotification('error', onFailConfirmMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'INVALID_CREDENTIALS'
      ) {
        toastNotification('error', onFailCredentialMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Login.
export const clearLoginMentor = () => loginMentorReset();

// Actions: Forgot Password Mentor.
export const forgotPassMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      forgotPassMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onUserFailMessage } = options;
    try {
      const { email, emailLanguage } = payload;
      const result = await ApiClient.post('mentors/auth/forgot', { email: email.toLowerCase(), emailLanguage });
      if (result.data?.success) {
        dispatch(
          forgotPassMentorSuccess({
            loading: false,
            success: true,
            data: result.data.data,
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          forgotPassMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
        history.push(pathname);
      }
    } catch (error) {
      dispatch(
        forgotPassMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'RESOURCE_NOT_FOUND'
      ) {
        toastNotification('error', onUserFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Forgot Pass.
export const clearForgotPassMentor = () => forgotPassMentorReset();

// Actions: Reset Password Mentor.
export const resetPassMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resetPassMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onFailMessageExpired } = options;
    try {
      const { email, newPassword, passwordConfirm, resetToken, type, emailLanguage } = payload;
      const result = await ApiClient.post(`mentors/auth/reset/${resetToken}`, {
        email: email.toLowerCase(),
        newPassword,
        passwordConfirm,
        emailLanguage,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          resetPassMentorSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push({ pathname, state: { shouldUseState: true, type } });
      } else {
        dispatch(
          resetPassMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      const errorType = error.response?.data?.errorType;
      let errorMessage = error.message || 'Internal Server Error!';

      if (errorType === 'RESOURCE_NOT_FOUND') {
        errorMessage = onFailMessageExpired;
      } else if (errorType === 'PASSWORD_SAME') {
        errorMessage = onFailMessage;
      }
      dispatch(
        resetPassMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage,
        })
      );
      toastNotification('error', errorMessage);
    }
  };
};

// Actions: Clear Reset Pass.
export const clearResetPass = () => resetPassMentorReset();

// Actions: Get All Mentor Matches.
export const getAllMentorMatches = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMentorMatchesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { mentorId, page, limit, pagination, sort, internName } = payload;
      const result = await ApiClient.get(`mentors/${mentorId}/matches`, {
        params: { page, limit, pagination, sort, internName },
      });
      if (result.data?.success) {
        const { matches } = result.data.data;
        dispatch(
          getAllMentorMatchesSuccess({
            loading: false,
            success: true,
            data: { matches },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMentorMatchesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMentorMatchesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Mentor Matches.
export const clearGetAllMentorMatches = () => getAllMentorMatchesReset();

// Actions: Confirm Mentor.
export const confirmMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      confirmMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email, accountConfirmationCode, accountConfirmationToken } = payload;
      const result = await ApiClient.post('mentors/auth/confirm', {
        email: email.toLowerCase(),
        accountConfirmationCode,
        accountConfirmationToken,
      });
      if (result.data?.success) {
        const { token } = result.data?.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          confirmMentorSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          confirmMentorFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        confirmMentorFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const codeExpiredMessage = 'CODE_EXPIRED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === codeExpiredMessage
      ) {
        toastNotification('error', 'Code has expired, please click resend code!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Confirm Mentor.
export const clearConfirmMentor = () => confirmMentorReset();

// Actions: Resend Mentor Email.
export const resendMentorEmail = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resendMentorEmailStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email } = payload;
      const result = await ApiClient.post('mentors/auth/resend', {
        email: email.toLowerCase(),
      });
      if (result.data?.success) {
        const { sent, mentorId } = result.data?.data;
        dispatch(
          resendMentorEmailSuccess({
            loading: false,
            success: true,
            data: { sent, mentorId },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          resendMentorEmailFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        resendMentorEmailFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const accountConfirmedMessage = 'ACCOUNT_CONFIRMED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === accountConfirmedMessage
      ) {
        toastNotification('error', 'Account already confirmed!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Resend Mentor Email.
export const clearResendMentorEmail = () => resendMentorEmailReset();
