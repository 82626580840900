// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Business Types.
export const GET_ALL_BUSINESS_TYPES_START = 'GET_ALL_BUSINESS_TYPES_START';
export const GET_ALL_BUSINESS_TYPES_SUCCESS = 'GET_ALL_BUSINESS_TYPES_SUCCESS';
export const GET_ALL_BUSINESS_TYPES_FAILED = 'GET_ALL_BUSINESS_TYPES_FAILED';
export const GET_ALL_BUSINESS_TYPES_RESET = 'GET_ALL_BUSINESS_TYPES_RESET';

// Action Types: Get One Business Type.
export const GET_ONE_BUSINESS_TYPE_START = 'GET_ONE_BUSINESS_TYPE_START';
export const GET_ONE_BUSINESS_TYPE_SUCCESS = 'GET_ONE_BUSINESS_TYPE_SUCCESS';
export const GET_ONE_BUSINESS_TYPE_FAILED = 'GET_ONE_BUSINESS_TYPE_FAILED';
export const GET_ONE_BUSINESS_TYPE_RESET = 'GET_ONE_BUSINESS_TYPE_RESET';

// Action Types: Create New Business Type.
export const CREATE_BUSINESS_TYPE_START = 'CREATE_BUSINESS_TYPE_START';
export const CREATE_BUSINESS_TYPE_SUCCESS = 'CREATE_BUSINESS_TYPE_SUCCESS';
export const CREATE_BUSINESS_TYPE_FAILED = 'CREATE_BUSINESS_TYPE_FAILED';
export const CREATE_BUSINESS_TYPE_RESET = 'CREATE_BUSINESS_TYPE_RESET';

// Action Types: Update One Business Type.
export const UPDATE_ONE_BUSINESS_TYPE_START = 'UPDATE_ONE_BUSINESS_TYPE_START';
export const UPDATE_ONE_BUSINESS_TYPE_SUCCESS = 'UPDATE_ONE_BUSINESS_TYPE_SUCCESS';
export const UPDATE_ONE_BUSINESS_TYPE_FAILED = 'UPDATE_ONE_BUSINESS_TYPE_FAILED';
export const UPDATE_ONE_BUSINESS_TYPE_RESET = 'UPDATE_ONE_BUSINESS_TYPE_RESET';

// Action Types: Delete One Business Type.
export const DELETE_ONE_BUSINESS_TYPE_START = 'DELETE_ONE_BUSINESS_TYPE_START';
export const DELETE_ONE_BUSINESS_TYPE_SUCCESS = 'DELETE_ONE_BUSINESS_TYPE_SUCCESS';
export const DELETE_ONE_BUSINESS_TYPE_FAILED = 'DELETE_ONE_BUSINESS_TYPE_FAILED';
export const DELETE_ONE_BUSINESS_TYPE_RESET = 'DELETE_ONE_BUSINESS_TYPE_RESET';

// Action Creators: Get All Business Types.
const getAllBusinessTypesStart = (payload) => ({
  type: GET_ALL_BUSINESS_TYPES_START,
  payload,
});
const getAllBusinessTypesSuccess = (payload) => ({
  type: GET_ALL_BUSINESS_TYPES_SUCCESS,
  payload,
});
const getAllBusinessTypesFailed = (payload) => ({
  type: GET_ALL_BUSINESS_TYPES_FAILED,
  payload,
});
const getAllBusinessTypesReset = () => ({ type: GET_ALL_BUSINESS_TYPES_RESET });

// Action Creators: Get One Business Type.
const getOneBusinessTypeStart = (payload) => ({
  type: GET_ONE_BUSINESS_TYPE_START,
  payload,
});
const getOneBusinessTypeSuccess = (payload) => ({
  type: GET_ONE_BUSINESS_TYPE_SUCCESS,
  payload,
});
const getOneBusinessTypeFailed = (payload) => ({
  type: GET_ONE_BUSINESS_TYPE_FAILED,
  payload,
});
const getOneBusinessTypeReset = () => ({ type: GET_ONE_BUSINESS_TYPE_RESET });

// Action Creators: Create New Business Type.
const createBusinessTypeStart = (payload) => ({
  type: CREATE_BUSINESS_TYPE_START,
  payload,
});
const createBusinessTypeSuccess = (payload) => ({
  type: CREATE_BUSINESS_TYPE_SUCCESS,
  payload,
});
const createBusinessTypeFailed = (payload) => ({
  type: CREATE_BUSINESS_TYPE_FAILED,
  payload,
});
const createBusinessTypeReset = () => ({ type: CREATE_BUSINESS_TYPE_RESET });

// Action Creators: Update One Business Type.
const updateOneBusinessTypeStart = (payload) => ({
  type: UPDATE_ONE_BUSINESS_TYPE_START,
  payload,
});
const updateOneBusinessTypeSuccess = (payload) => ({
  type: UPDATE_ONE_BUSINESS_TYPE_SUCCESS,
  payload,
});
const updateOneBusinessTypeFailed = (payload) => ({
  type: UPDATE_ONE_BUSINESS_TYPE_FAILED,
  payload,
});
const updateOneBusinessTypeReset = () => ({ type: UPDATE_ONE_BUSINESS_TYPE_RESET });

// Action Creators: Delete One Business Type.
const deleteOneBusinessTypeStart = (payload) => ({
  type: DELETE_ONE_BUSINESS_TYPE_START,
  payload,
});
const deleteOneBusinessTypeSuccess = (payload) => ({
  type: DELETE_ONE_BUSINESS_TYPE_SUCCESS,
  payload,
});
const deleteOneBusinessTypeFailed = (payload) => ({
  type: DELETE_ONE_BUSINESS_TYPE_FAILED,
  payload,
});
const deleteOneBusinessTypeReset = () => ({ type: DELETE_ONE_BUSINESS_TYPE_RESET });

// Actions: Get All Business Types.
export const getAllBusinessTypes = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllBusinessTypesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('businesstypes', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { businesstypes } = result.data.data;
        dispatch(
          getAllBusinessTypesSuccess({
            loading: false,
            success: true,
            data: { businesstypes },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllBusinessTypesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllBusinessTypesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Business Types.
export const clearGetAllBusinessTypes = () => getAllBusinessTypesReset();

// Actions: Get One Business Type.
export const getOneBusinessType = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneBusinessTypeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { businessTypeId } = payload;
      const result = await ApiClient.get(`businesstypes/${businessTypeId}`);
      if (result.data?.success) {
        const { businessType } = result.data.data;
        dispatch(
          getOneBusinessTypeSuccess({
            loading: false,
            success: true,
            data: { businessType },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneBusinessTypeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneBusinessTypeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Business Type.
export const clearGetOneBusinessType = () => getOneBusinessTypeReset();

// Actions: Create New Business Type.
export const createBusinessType = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createBusinessTypeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription, hasUniqueIdentifier } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onBusinessTypeExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('businesstypes', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        hasUniqueIdentifier,
      });
      if (result.data?.success) {
        const { businessType } = result.data.data;
        dispatch(
          createBusinessTypeSuccess({
            loading: false,
            success: true,
            data: { businessType },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createBusinessTypeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createBusinessTypeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessTypeExists = 'BUSINESS_TYPE_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessTypeExists
      ) {
        toastNotification('error', onBusinessTypeExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Business Type.
export const clearCreateNewBusinessType = () => createBusinessTypeReset();

// Actions: Update One Business Type.
export const updateOneBusinessType = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneBusinessTypeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { businessTypeId, enName, enDescription, sqName, sqDescription, srName, srDescription, hasUniqueIdentifier } =
      payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`businesstypes/${businessTypeId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
        hasUniqueIdentifier,
      });
      if (result.data?.success) {
        const { businessType } = result.data.data;
        dispatch(
          updateOneBusinessTypeSuccess({
            loading: false,
            success: true,
            data: { businessType },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneBusinessTypeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneBusinessTypeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Business Type.
export const clearUpdateOneBusinessType = () => updateOneBusinessTypeReset();

// Actions: Delete One Business Type.
export const deleteOneBusinessType = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneBusinessTypeStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { businessTypeId } = payload;
      const result = await ApiClient.delete(`businesstypes/${businessTypeId}`);
      if (result.data?.success) {
        const { businessType } = result.data.data;
        dispatch(
          deleteOneBusinessTypeSuccess({
            loading: false,
            success: true,
            data: { businessType },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneBusinessTypeFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneBusinessTypeFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Business Type.
export const cleareDeleteOneBusinessType = () => deleteOneBusinessTypeReset();
