// Imports: local files.
import {
  COUNTS_START,
  COUNTS_SUCCESS,
  COUNTS_FAILED,
  COUNTS_RESET,
  INTERNSHIPS_START,
  INTERNSHIPS_SUCCESS,
  INTERNSHIPS_FAILED,
  INTERNSHIPS_RESET,
  PROGRAMME_INFO_START,
  PROGRAMME_INFO_SUCCESS,
  PROGRAMME_INFO_FAILED,
  PROGRAMME_INFO_RESET,
  INTERNS_BY_MUNICIPALITY_START,
  INTERNS_BY_MUNICIPALITY_SUCCESS,
  INTERNS_BY_MUNICIPALITY_FAILED,
  INTERNS_BY_MUNICIPALITY_RESET,
  INTERNS_BY_SKILLS_START,
  INTERNS_BY_SKILLS_SUCCESS,
  INTERNS_BY_SKILLS_FAILED,
  INTERNS_BY_SKILLS_RESET,
  INTERNS_BY_CATEGORIES_START,
  INTERNS_BY_CATEGORIES_SUCCESS,
  INTERNS_BY_CATEGORIES_FAILED,
  INTERNS_BY_CATEGORIES_RESET,
  INTERNS_BY_GENDER_START,
  INTERNS_BY_GENDER_SUCCESS,
  INTERNS_BY_GENDER_FAILED,
  INTERNS_BY_GENDER_RESET,
  INTERNS_BY_AGE_START,
  INTERNS_BY_AGE_SUCCESS,
  INTERNS_BY_AGE_FAILED,
  INTERNS_BY_AGE_RESET,
  INTERNS_BY_ETHNICITY_START,
  INTERNS_BY_ETHNICITY_SUCCESS,
  INTERNS_BY_ETHNICITY_FAILED,
  INTERNS_BY_ETHNICITY_RESET,
  INTERNS_BY_HEALTH_CONDITION_START,
  INTERNS_BY_HEALTH_CONDITION_SUCCESS,
  INTERNS_BY_HEALTH_CONDITION_FAILED,
  INTERNS_BY_HEALTH_CONDITION_RESET,
  BUSINESSES_BY_MUNICIPALITY_START,
  BUSINESSES_BY_MUNICIPALITY_SUCCESS,
  BUSINESSES_BY_MUNICIPALITY_FAILED,
  BUSINESSES_BY_MUNICIPALITY_RESET,
  BUSINESSES_BY_TYPE_START,
  BUSINESSES_BY_TYPE_SUCCESS,
  BUSINESSES_BY_TYPE_FAILED,
  BUSINESSES_BY_TYPE_RESET,
  BUSINESSES_BY_CATEGORIES_START,
  BUSINESSES_BY_CATEGORIES_SUCCESS,
  BUSINESSES_BY_CATEGORIES_FAILED,
  BUSINESSES_BY_CATEGORIES_RESET,
  MENTORS_BY_MUNICIPALITY_START,
  MENTORS_BY_MUNICIPALITY_SUCCESS,
  MENTORS_BY_MUNICIPALITY_FAILED,
  MENTORS_BY_MUNICIPALITY_RESET,
  MENTORS_BY_SKILLS_START,
  MENTORS_BY_SKILLS_SUCCESS,
  MENTORS_BY_SKILLS_FAILED,
  MENTORS_BY_SKILLS_RESET,
  MENTORS_BY_CATEGORIES_START,
  MENTORS_BY_CATEGORIES_SUCCESS,
  MENTORS_BY_CATEGORIES_FAILED,
  MENTORS_BY_CATEGORIES_RESET,
  MENTORS_BY_GENDER_START,
  MENTORS_BY_GENDER_SUCCESS,
  MENTORS_BY_GENDER_FAILED,
  MENTORS_BY_GENDER_RESET,
  MENTORS_BY_INTERNS_START,
  MENTORS_BY_INTERNS_SUCCESS,
  MENTORS_BY_INTERNS_FAILED,
  MENTORS_BY_INTERNS_RESET,
  MENTORS_BY_ETHNICITY_START,
  MENTORS_BY_ETHNICITY_SUCCESS,
  MENTORS_BY_ETHNICITY_FAILED,
  MENTORS_BY_ETHNICITY_RESET,
  MENTORS_BY_HEALTH_CONDITION_START,
  MENTORS_BY_HEALTH_CONDITION_SUCCESS,
  MENTORS_BY_HEALTH_CONDITION_FAILED,
  MENTORS_BY_HEALTH_CONDITION_RESET,
  APPLICATIONS_BY_MUNICIPALITY_START,
  APPLICATIONS_BY_MUNICIPALITY_SUCCESS,
  APPLICATIONS_BY_MUNICIPALITY_FAILED,
  APPLICATIONS_BY_MUNICIPALITY_RESET,
  APPLICATIONS_BY_STATUS_START,
  APPLICATIONS_BY_STATUS_SUCCESS,
  APPLICATIONS_BY_STATUS_FAILED,
  APPLICATIONS_BY_STATUS_RESET,
  APPLICATIONS_BY_BUSINESS_START,
  APPLICATIONS_BY_BUSINESS_SUCCESS,
  APPLICATIONS_BY_BUSINESS_FAILED,
  APPLICATIONS_BY_BUSINESS_RESET,
  APPLICATIONS_BY_JOB_START,
  APPLICATIONS_BY_JOB_SUCCESS,
  APPLICATIONS_BY_JOB_FAILED,
  APPLICATIONS_BY_JOB_RESET,
  APPLICATIONS_BY_GENDER_START,
  APPLICATIONS_BY_GENDER_SUCCESS,
  APPLICATIONS_BY_GENDER_FAILED,
  APPLICATIONS_BY_GENDER_RESET,
  APPLICATIONS_BY_AGE_START,
  APPLICATIONS_BY_AGE_SUCCESS,
  APPLICATIONS_BY_AGE_FAILED,
  APPLICATIONS_BY_AGE_RESET,
  APPLICATIONS_BY_CATEGORY_START,
  APPLICATIONS_BY_CATEGORY_SUCCESS,
  APPLICATIONS_BY_CATEGORY_FAILED,
  APPLICATIONS_BY_CATEGORY_RESET,
  APPLICATIONS_BY_ETHNICITY_START,
  APPLICATIONS_BY_ETHNICITY_SUCCESS,
  APPLICATIONS_BY_ETHNICITY_FAILED,
  APPLICATIONS_BY_ETHNICITY_RESET,
  APPLICATIONS_BY_HEALTH_CONDITION_START,
  APPLICATIONS_BY_HEALTH_CONDITION_SUCCESS,
  APPLICATIONS_BY_HEALTH_CONDITION_FAILED,
  APPLICATIONS_BY_HEALTH_CONDITION_RESET,
  ENGAGEMENTS_BY_MUNICIPALITY_START,
  ENGAGEMENTS_BY_MUNICIPALITY_SUCCESS,
  ENGAGEMENTS_BY_MUNICIPALITY_FAILED,
  ENGAGEMENTS_BY_MUNICIPALITY_RESET,
  ENGAGEMENTS_BY_BUSINESS_START,
  ENGAGEMENTS_BY_BUSINESS_SUCCESS,
  ENGAGEMENTS_BY_BUSINESS_FAILED,
  ENGAGEMENTS_BY_BUSINESS_RESET,
  ENGAGEMENTS_BY_GENDER_START,
  ENGAGEMENTS_BY_GENDER_SUCCESS,
  ENGAGEMENTS_BY_GENDER_FAILED,
  ENGAGEMENTS_BY_GENDER_RESET,
  ENGAGEMENTS_BY_AGE_START,
  ENGAGEMENTS_BY_AGE_SUCCESS,
  ENGAGEMENTS_BY_AGE_FAILED,
  ENGAGEMENTS_BY_AGE_RESET,
  ENGAGEMENTS_BY_SKILLS_START,
  ENGAGEMENTS_BY_SKILLS_SUCCESS,
  ENGAGEMENTS_BY_SKILLS_FAILED,
  ENGAGEMENTS_BY_SKILLS_RESET,
  ENGAGEMENTS_BY_CATEGORIES_START,
  ENGAGEMENTS_BY_CATEGORIES_SUCCESS,
  ENGAGEMENTS_BY_CATEGORIES_FAILED,
  ENGAGEMENTS_BY_CATEGORIES_RESET,
  ENGAGEMENTS_BY_ETHNICITY_START,
  ENGAGEMENTS_BY_ETHNICITY_SUCCESS,
  ENGAGEMENTS_BY_ETHNICITY_FAILED,
  ENGAGEMENTS_BY_ETHNICITY_RESET,
  ENGAGEMENTS_BY_HEALTH_CONDITION_START,
  ENGAGEMENTS_BY_HEALTH_CONDITION_SUCCESS,
  ENGAGEMENTS_BY_HEALTH_CONDITION_FAILED,
  ENGAGEMENTS_BY_HEALTH_CONDITION_RESET,
  JOBS_BY_MUNICIPALITY_START,
  JOBS_BY_MUNICIPALITY_SUCCESS,
  JOBS_BY_MUNICIPALITY_FAILED,
  JOBS_BY_MUNICIPALITY_RESET,
  JOBS_BY_SKILLS_START,
  JOBS_BY_SKILLS_SUCCESS,
  JOBS_BY_SKILLS_FAILED,
  JOBS_BY_SKILLS_RESET,
  JOBS_BY_CATEGORIES_START,
  JOBS_BY_CATEGORIES_SUCCESS,
  JOBS_BY_CATEGORIES_FAILED,
  JOBS_BY_CATEGORIES_RESET,
  JOBS_BY_BUSINESS_START,
  JOBS_BY_BUSINESS_SUCCESS,
  JOBS_BY_BUSINESS_FAILED,
  JOBS_BY_BUSINESS_RESET,
  JOBS_BY_SPOTS_START,
  JOBS_BY_SPOTS_SUCCESS,
  JOBS_BY_SPOTS_FAILED,
  JOBS_BY_SPOTS_RESET,
  TRAININGS_BY_APPLICATIONS_START,
  TRAININGS_BY_APPLICATIONS_SUCCESS,
  TRAININGS_BY_APPLICATIONS_FAILED,
  TRAININGS_BY_APPLICATIONS_RESET,
  TRAININGS_BY_ENGAGEMENTS_START,
  TRAININGS_BY_ENGAGEMENTS_SUCCESS,
  TRAININGS_BY_ENGAGEMENTS_FAILED,
  TRAININGS_BY_ENGAGEMENTS_RESET,
  TRAININGS_BY_CATEGORIES_START,
  TRAININGS_BY_CATEGORIES_SUCCESS,
  TRAININGS_BY_CATEGORIES_FAILED,
  TRAININGS_BY_CATEGORIES_RESET,
  TRAININGS_BY_OPENINGS_START,
  TRAININGS_BY_OPENINGS_SUCCESS,
  TRAININGS_BY_OPENINGS_FAILED,
  TRAININGS_BY_OPENINGS_RESET,
  TRAININGS_BY_SPOTS_START,
  TRAININGS_BY_SPOTS_SUCCESS,
  TRAININGS_BY_SPOTS_FAILED,
  TRAININGS_BY_SPOTS_RESET,
  TRAININGS_BY_ENGAGEMENTS_GENDER_START,
  TRAININGS_BY_ENGAGEMENTS_GENDER_SUCCESS,
  TRAININGS_BY_ENGAGEMENTS_GENDER_FAILED,
  TRAININGS_BY_ENGAGEMENTS_GENDER_RESET,
  COUNTS_DATA_START,
  COUNTS_DATA_SUCCESS,
  COUNTS_DATA_FAILED,
  COUNTS_DATA_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  counts: initialLoadingState,
  internships: initialLoadingState,
  programmeInfo: initialLoadingState,
  internsByMunicipality: initialLoadingState,
  internsBySkills: initialLoadingState,
  internsByCategories: initialLoadingState,
  internsByGender: initialLoadingState,
  internsByAge: initialLoadingState,
  internsByEthnicity: initialLoadingState,
  internsByHealthCondition: initialLoadingState,
  businessesByMunicipality: initialLoadingState,
  businessesByType: initialLoadingState,
  businessesByCategories: initialLoadingState,
  mentorsByMunicipality: initialLoadingState,
  mentorsBySkills: initialLoadingState,
  mentorsByCategories: initialLoadingState,
  mentorsByGender: initialLoadingState,
  mentorsByInterns: initialLoadingState,
  mentorsByEthnicity: initialLoadingState,
  mentorsByHealthCondition: initialLoadingState,
  applicationsByMunicipality: initialLoadingState,
  applicationsByStatus: initialLoadingState,
  applicationsByBusiness: initialLoadingState,
  applicationsByJob: initialLoadingState,
  applicationsByGender: initialLoadingState,
  applicationsByAge: initialLoadingState,
  applicationsByEthnicity: initialLoadingState,
  applicationsByHealthCondition: initialLoadingState,
  applicationsByCategories: initialLoadingState,
  engagementsByMunicipality: initialLoadingState,
  engagementsByBusiness: initialLoadingState,
  engagementsByGender: initialLoadingState,
  engagementsByAge: initialLoadingState,
  engagementsBySkills: initialLoadingState,
  engagementsByCategories: initialLoadingState,
  engagementsByEthnicity: initialLoadingState,
  engagementsByHealthCondition: initialLoadingState,
  jobsByMunicipality: initialLoadingState,
  jobsBySkills: initialLoadingState,
  jobsByCategories: initialLoadingState,
  jobsByBusiness: initialLoadingState,
  jobsBySpots: initialLoadingState,
  trainingsByApplications: initialLoadingState,
  trainingsByEngagements: initialLoadingState,
  trainingsByCategories: initialLoadingState,
  trainingsByOpenings: initialLoadingState,
  trainingsBySpots: initialLoadingState,
  trainingsByEngagementsGender: initialLoadingState,
  countsData: initialLoadingState,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Counts
     * =======================================================================
     */
    case COUNTS_START:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_SUCCESS:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_FAILED:
      return { ...state, counts: { ...action.payload } };
    case COUNTS_RESET:
      return { ...state, counts: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Internships
     * =======================================================================
     */
    case INTERNSHIPS_START:
      return { ...state, internships: { ...action.payload } };
    case INTERNSHIPS_SUCCESS:
      return { ...state, internships: { ...action.payload } };
    case INTERNSHIPS_FAILED:
      return { ...state, internships: { ...action.payload } };
    case INTERNSHIPS_RESET:
      return { ...state, internships: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Information about programme
     * =======================================================================
     */
    case PROGRAMME_INFO_START:
      return { ...state, programmeInfo: { ...action.payload } };
    case PROGRAMME_INFO_SUCCESS:
      return { ...state, programmeInfo: { ...action.payload } };
    case PROGRAMME_INFO_FAILED:
      return { ...state, programmeInfo: { ...action.payload } };
    case PROGRAMME_INFO_RESET:
      return { ...state, programmeInfo: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Municipality
     * =======================================================================
     */
    case INTERNS_BY_MUNICIPALITY_START:
      return { ...state, internsByMunicipality: { ...action.payload } };
    case INTERNS_BY_MUNICIPALITY_SUCCESS:
      return { ...state, internsByMunicipality: { ...action.payload } };
    case INTERNS_BY_MUNICIPALITY_FAILED:
      return { ...state, internsByMunicipality: { ...action.payload } };
    case INTERNS_BY_MUNICIPALITY_RESET:
      return { ...state, internsByMunicipality: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Interns By Skills
     * =======================================================================
     */
    case INTERNS_BY_SKILLS_START:
      return { ...state, internsBySkills: { ...action.payload } };
    case INTERNS_BY_SKILLS_SUCCESS:
      return { ...state, internsBySkills: { ...action.payload } };
    case INTERNS_BY_SKILLS_FAILED:
      return { ...state, internsBySkills: { ...action.payload } };
    case INTERNS_BY_SKILLS_RESET:
      return { ...state, internsBySkills: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Categories
     * =======================================================================
     */
    case INTERNS_BY_CATEGORIES_START:
      return { ...state, internsByCategories: { ...action.payload } };
    case INTERNS_BY_CATEGORIES_SUCCESS:
      return { ...state, internsByCategories: { ...action.payload } };
    case INTERNS_BY_CATEGORIES_FAILED:
      return { ...state, internsByCategories: { ...action.payload } };
    case INTERNS_BY_CATEGORIES_RESET:
      return { ...state, internsByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Gender
     * =======================================================================
     */
    case INTERNS_BY_GENDER_START:
      return { ...state, internsByGender: { ...action.payload } };
    case INTERNS_BY_GENDER_SUCCESS:
      return { ...state, internsByGender: { ...action.payload } };
    case INTERNS_BY_GENDER_FAILED:
      return { ...state, internsByGender: { ...action.payload } };
    case INTERNS_BY_GENDER_RESET:
      return { ...state, internsByGender: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Gender
     * =======================================================================
     */
    case INTERNS_BY_AGE_START:
      return { ...state, internsByAge: { ...action.payload } };
    case INTERNS_BY_AGE_SUCCESS:
      return { ...state, internsByAge: { ...action.payload } };
    case INTERNS_BY_AGE_FAILED:
      return { ...state, internsByAge: { ...action.payload } };
    case INTERNS_BY_AGE_RESET:
      return { ...state, internsByAge: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Ethnicity
     * =======================================================================
     */
    case INTERNS_BY_ETHNICITY_START:
      return { ...state, internsByEthnicity: { ...action.payload } };
    case INTERNS_BY_ETHNICITY_SUCCESS:
      return { ...state, internsByEthnicity: { ...action.payload } };
    case INTERNS_BY_ETHNICITY_FAILED:
      return { ...state, internsByEthnicity: { ...action.payload } };
    case INTERNS_BY_ETHNICITY_RESET:
      return { ...state, internsByEthnicity: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Interns By Health Condition
     * =======================================================================
     */
    case INTERNS_BY_HEALTH_CONDITION_START:
      return { ...state, internsByHealthCondition: { ...action.payload } };
    case INTERNS_BY_HEALTH_CONDITION_SUCCESS:
      return { ...state, internsByHealthCondition: { ...action.payload } };
    case INTERNS_BY_HEALTH_CONDITION_FAILED:
      return { ...state, internsByHealthCondition: { ...action.payload } };
    case INTERNS_BY_HEALTH_CONDITION_RESET:
      return { ...state, internsByHealthCondition: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Businesses By Municipality
     * =======================================================================
     */
    case BUSINESSES_BY_MUNICIPALITY_START:
      return { ...state, businessesByMunicipality: { ...action.payload } };
    case BUSINESSES_BY_MUNICIPALITY_SUCCESS:
      return { ...state, businessesByMunicipality: { ...action.payload } };
    case BUSINESSES_BY_MUNICIPALITY_FAILED:
      return { ...state, businessesByMunicipality: { ...action.payload } };
    case BUSINESSES_BY_MUNICIPALITY_RESET:
      return { ...state, businessesByMunicipality: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Businesses By Type
     * =======================================================================
     */
    case BUSINESSES_BY_TYPE_START:
      return { ...state, businessesByType: { ...action.payload } };
    case BUSINESSES_BY_TYPE_SUCCESS:
      return { ...state, businessesByType: { ...action.payload } };
    case BUSINESSES_BY_TYPE_FAILED:
      return { ...state, businessesByType: { ...action.payload } };
    case BUSINESSES_BY_TYPE_RESET:
      return { ...state, businessesByType: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Businesses By Categories
     * =======================================================================
     */
    case BUSINESSES_BY_CATEGORIES_START:
      return { ...state, businessesByCategories: { ...action.payload } };
    case BUSINESSES_BY_CATEGORIES_SUCCESS:
      return { ...state, businessesByCategories: { ...action.payload } };
    case BUSINESSES_BY_CATEGORIES_FAILED:
      return { ...state, businessesByCategories: { ...action.payload } };
    case BUSINESSES_BY_CATEGORIES_RESET:
      return { ...state, businessesByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Municipality
     * =======================================================================
     */
    case MENTORS_BY_MUNICIPALITY_START:
      return { ...state, mentorsByMunicipality: { ...action.payload } };
    case MENTORS_BY_MUNICIPALITY_SUCCESS:
      return { ...state, mentorsByMunicipality: { ...action.payload } };
    case MENTORS_BY_MUNICIPALITY_FAILED:
      return { ...state, mentorsByMunicipality: { ...action.payload } };
    case MENTORS_BY_MUNICIPALITY_RESET:
      return { ...state, mentorsByMunicipality: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Mentors By Skills
     * =======================================================================
     */
    case MENTORS_BY_SKILLS_START:
      return { ...state, mentorsBySkills: { ...action.payload } };
    case MENTORS_BY_SKILLS_SUCCESS:
      return { ...state, mentorsBySkills: { ...action.payload } };
    case MENTORS_BY_SKILLS_FAILED:
      return { ...state, mentorsBySkills: { ...action.payload } };
    case MENTORS_BY_SKILLS_RESET:
      return { ...state, mentorsBySkills: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Categories
     * =======================================================================
     */
    case MENTORS_BY_CATEGORIES_START:
      return { ...state, mentorsByCategories: { ...action.payload } };
    case MENTORS_BY_CATEGORIES_SUCCESS:
      return { ...state, mentorsByCategories: { ...action.payload } };
    case MENTORS_BY_CATEGORIES_FAILED:
      return { ...state, mentorsByCategories: { ...action.payload } };
    case MENTORS_BY_CATEGORIES_RESET:
      return { ...state, mentorsByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Gender
     * =======================================================================
     */
    case MENTORS_BY_GENDER_START:
      return { ...state, mentorsByGender: { ...action.payload } };
    case MENTORS_BY_GENDER_SUCCESS:
      return { ...state, mentorsByGender: { ...action.payload } };
    case MENTORS_BY_GENDER_FAILED:
      return { ...state, mentorsByGender: { ...action.payload } };
    case MENTORS_BY_GENDER_RESET:
      return { ...state, mentorsByGender: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Interns
     * =======================================================================
     */
    case MENTORS_BY_INTERNS_START:
      return { ...state, mentorsByInterns: { ...action.payload } };
    case MENTORS_BY_INTERNS_SUCCESS:
      return { ...state, mentorsByInterns: { ...action.payload } };
    case MENTORS_BY_INTERNS_FAILED:
      return { ...state, mentorsByInterns: { ...action.payload } };
    case MENTORS_BY_INTERNS_RESET:
      return { ...state, mentorsByInterns: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Municipality
     * =======================================================================
     */
    case APPLICATIONS_BY_MUNICIPALITY_START:
      return { ...state, applicationsByMunicipality: { ...action.payload } };
    case APPLICATIONS_BY_MUNICIPALITY_SUCCESS:
      return { ...state, applicationsByMunicipality: { ...action.payload } };
    case APPLICATIONS_BY_MUNICIPALITY_FAILED:
      return { ...state, applicationsByMunicipality: { ...action.payload } };
    case APPLICATIONS_BY_MUNICIPALITY_RESET:
      return { ...state, applicationsByMunicipality: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Ethnicity
     * =======================================================================
     */
    case MENTORS_BY_ETHNICITY_START:
      return { ...state, mentorsByEthnicity: { ...action.payload } };
    case MENTORS_BY_ETHNICITY_SUCCESS:
      return { ...state, mentorsByEthnicity: { ...action.payload } };
    case MENTORS_BY_ETHNICITY_FAILED:
      return { ...state, mentorsByEthnicity: { ...action.payload } };
    case MENTORS_BY_ETHNICITY_RESET:
      return { ...state, mentorsByEthnicity: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Mentors By Health Condition
     * =======================================================================
     */
    case MENTORS_BY_HEALTH_CONDITION_START:
      return { ...state, mentorsByHealthCondition: { ...action.payload } };
    case MENTORS_BY_HEALTH_CONDITION_SUCCESS:
      return { ...state, mentorsByHealthCondition: { ...action.payload } };
    case MENTORS_BY_HEALTH_CONDITION_FAILED:
      return { ...state, mentorsByHealthCondition: { ...action.payload } };
    case MENTORS_BY_HEALTH_CONDITION_RESET:
      return { ...state, mentorsByHealthCondition: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Status
     * =======================================================================
     */
    case APPLICATIONS_BY_STATUS_START:
      return { ...state, applicationsByStatus: { ...action.payload } };
    case APPLICATIONS_BY_STATUS_SUCCESS:
      return { ...state, applicationsByStatus: { ...action.payload } };
    case APPLICATIONS_BY_STATUS_FAILED:
      return { ...state, applicationsByStatus: { ...action.payload } };
    case APPLICATIONS_BY_STATUS_RESET:
      return { ...state, applicationsByStatus: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Business
     * =======================================================================
     */
    case APPLICATIONS_BY_BUSINESS_START:
      return { ...state, applicationsByBusiness: { ...action.payload } };
    case APPLICATIONS_BY_BUSINESS_SUCCESS:
      return { ...state, applicationsByBusiness: { ...action.payload } };
    case APPLICATIONS_BY_BUSINESS_FAILED:
      return { ...state, applicationsByBusiness: { ...action.payload } };
    case APPLICATIONS_BY_BUSINESS_RESET:
      return { ...state, applicationsByBusiness: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Job
     * =======================================================================
     */
    case APPLICATIONS_BY_JOB_START:
      return { ...state, applicationsByJob: { ...action.payload } };
    case APPLICATIONS_BY_JOB_SUCCESS:
      return { ...state, applicationsByJob: { ...action.payload } };
    case APPLICATIONS_BY_JOB_FAILED:
      return { ...state, applicationsByJob: { ...action.payload } };
    case APPLICATIONS_BY_JOB_RESET:
      return { ...state, applicationsByJob: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Gender
     * =======================================================================
     */
    case APPLICATIONS_BY_GENDER_START:
      return { ...state, applicationsByGender: { ...action.payload } };
    case APPLICATIONS_BY_GENDER_SUCCESS:
      return { ...state, applicationsByGender: { ...action.payload } };
    case APPLICATIONS_BY_GENDER_FAILED:
      return { ...state, applicationsByGender: { ...action.payload } };
    case APPLICATIONS_BY_GENDER_RESET:
      return { ...state, applicationsByGender: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Age
     * =======================================================================
     */
    case APPLICATIONS_BY_AGE_START:
      return { ...state, applicationsByAge: { ...action.payload } };
    case APPLICATIONS_BY_AGE_SUCCESS:
      return { ...state, applicationsByAge: { ...action.payload } };
    case APPLICATIONS_BY_AGE_FAILED:
      return { ...state, applicationsByAge: { ...action.payload } };
    case APPLICATIONS_BY_AGE_RESET:
      return { ...state, applicationsByAge: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Applications By Ethnicity
     * =======================================================================
     */
    case APPLICATIONS_BY_ETHNICITY_START:
      return { ...state, applicationsByEthnicity: { ...action.payload } };
    case APPLICATIONS_BY_ETHNICITY_SUCCESS:
      return { ...state, applicationsByEthnicity: { ...action.payload } };
    case APPLICATIONS_BY_ETHNICITY_FAILED:
      return { ...state, applicationsByEthnicity: { ...action.payload } };
    case APPLICATIONS_BY_ETHNICITY_RESET:
      return { ...state, applicationsByEthnicity: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Health Condition
     * =======================================================================
     */
    case APPLICATIONS_BY_HEALTH_CONDITION_START:
      return { ...state, applicationsByHealthCondition: { ...action.payload } };
    case APPLICATIONS_BY_HEALTH_CONDITION_SUCCESS:
      return { ...state, applicationsByHealthCondition: { ...action.payload } };
    case APPLICATIONS_BY_HEALTH_CONDITION_FAILED:
      return { ...state, applicationsByHealthCondition: { ...action.payload } };
    case APPLICATIONS_BY_HEALTH_CONDITION_RESET:
      return { ...state, applicationsByHealthCondition: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Applications By Category
     * =======================================================================
     */
    case APPLICATIONS_BY_CATEGORY_START:
      return { ...state, applicationsByCategories: { ...action.payload } };
    case APPLICATIONS_BY_CATEGORY_SUCCESS:
      return { ...state, applicationsByCategories: { ...action.payload } };
    case APPLICATIONS_BY_CATEGORY_FAILED:
      return { ...state, applicationsByCategories: { ...action.payload } };
    case APPLICATIONS_BY_CATEGORY_RESET:
      return { ...state, applicationsByCategories: { ...initialLoadingState } };
    // =============================================
    // =============================================
    // =============================================
    // =============================================

    /**
     * =======================================================================
     * Engagements By Municipality
     * =======================================================================
     */
    case ENGAGEMENTS_BY_MUNICIPALITY_START:
      return { ...state, engagementsByMunicipality: { ...action.payload } };
    case ENGAGEMENTS_BY_MUNICIPALITY_SUCCESS:
      return { ...state, engagementsByMunicipality: { ...action.payload } };
    case ENGAGEMENTS_BY_MUNICIPALITY_FAILED:
      return { ...state, engagementsByMunicipality: { ...action.payload } };
    case ENGAGEMENTS_BY_MUNICIPALITY_RESET:
      return { ...state, engagementsByMunicipality: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Business
     * =======================================================================
     */
    case ENGAGEMENTS_BY_BUSINESS_START:
      return { ...state, engagementsByBusiness: { ...action.payload } };
    case ENGAGEMENTS_BY_BUSINESS_SUCCESS:
      return { ...state, engagementsByBusiness: { ...action.payload } };
    case ENGAGEMENTS_BY_BUSINESS_FAILED:
      return { ...state, engagementsByBusiness: { ...action.payload } };
    case ENGAGEMENTS_BY_BUSINESS_RESET:
      return { ...state, engagementsByBusiness: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Gender
     * =======================================================================
     */
    case ENGAGEMENTS_BY_GENDER_START:
      return { ...state, engagementsByGender: { ...action.payload } };
    case ENGAGEMENTS_BY_GENDER_SUCCESS:
      return { ...state, engagementsByGender: { ...action.payload } };
    case ENGAGEMENTS_BY_GENDER_FAILED:
      return { ...state, engagementsByGender: { ...action.payload } };
    case ENGAGEMENTS_BY_GENDER_RESET:
      return { ...state, engagementsByGender: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Age
     * =======================================================================
     */
    case ENGAGEMENTS_BY_AGE_START:
      return { ...state, engagementsByAge: { ...action.payload } };
    case ENGAGEMENTS_BY_AGE_SUCCESS:
      return { ...state, engagementsByAge: { ...action.payload } };
    case ENGAGEMENTS_BY_AGE_FAILED:
      return { ...state, engagementsByAge: { ...action.payload } };
    case ENGAGEMENTS_BY_AGE_RESET:
      return { ...state, engagementsByAge: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Skills
     * =======================================================================
     */
    case ENGAGEMENTS_BY_SKILLS_START:
      return { ...state, engagementsBySkills: { ...action.payload } };
    case ENGAGEMENTS_BY_SKILLS_SUCCESS:
      return { ...state, engagementsBySkills: { ...action.payload } };
    case ENGAGEMENTS_BY_SKILLS_FAILED:
      return { ...state, engagementsBySkills: { ...action.payload } };
    case ENGAGEMENTS_BY_SKILLS_RESET:
      return { ...state, engagementsBySkills: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Categories
     * =======================================================================
     */
    case ENGAGEMENTS_BY_CATEGORIES_START:
      return { ...state, engagementsByCategories: { ...action.payload } };
    case ENGAGEMENTS_BY_CATEGORIES_SUCCESS:
      return { ...state, engagementsByCategories: { ...action.payload } };
    case ENGAGEMENTS_BY_CATEGORIES_FAILED:
      return { ...state, engagementsByCategories: { ...action.payload } };
    case ENGAGEMENTS_BY_CATEGORIES_RESET:
      return { ...state, engagementsByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Ethnicity
     * =======================================================================
     */
    case ENGAGEMENTS_BY_ETHNICITY_START:
      return { ...state, engagementsByEthnicity: { ...action.payload } };
    case ENGAGEMENTS_BY_ETHNICITY_SUCCESS:
      return { ...state, engagementsByEthnicity: { ...action.payload } };
    case ENGAGEMENTS_BY_ETHNICITY_FAILED:
      return { ...state, engagementsByEthnicity: { ...action.payload } };
    case ENGAGEMENTS_BY_ETHNICITY_RESET:
      return { ...state, engagementsByEthnicity: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Engagements By Health Condition
     * =======================================================================
     */
    case ENGAGEMENTS_BY_HEALTH_CONDITION_START:
      return { ...state, engagementsByHealthCondition: { ...action.payload } };
    case ENGAGEMENTS_BY_HEALTH_CONDITION_SUCCESS:
      return { ...state, engagementsByHealthCondition: { ...action.payload } };
    case ENGAGEMENTS_BY_HEALTH_CONDITION_FAILED:
      return { ...state, engagementsByHealthCondition: { ...action.payload } };
    case ENGAGEMENTS_BY_HEALTH_CONDITION_RESET:
      return { ...state, engagementsByHealthCondition: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Municipality
     * =======================================================================
     */
    case JOBS_BY_MUNICIPALITY_START:
      return { ...state, jobsByMunicipality: { ...action.payload } };
    case JOBS_BY_MUNICIPALITY_SUCCESS:
      return { ...state, jobsByMunicipality: { ...action.payload } };
    case JOBS_BY_MUNICIPALITY_FAILED:
      return { ...state, jobsByMunicipality: { ...action.payload } };
    case JOBS_BY_MUNICIPALITY_RESET:
      return { ...state, jobsByMunicipality: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Jobs By Skills
     * =======================================================================
     */
    case JOBS_BY_SKILLS_START:
      return { ...state, jobsBySkills: { ...action.payload } };
    case JOBS_BY_SKILLS_SUCCESS:
      return { ...state, jobsBySkills: { ...action.payload } };
    case JOBS_BY_SKILLS_FAILED:
      return { ...state, jobsBySkills: { ...action.payload } };
    case JOBS_BY_SKILLS_RESET:
      return { ...state, jobsBySkills: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Categories
     * =======================================================================
     */
    case JOBS_BY_CATEGORIES_START:
      return { ...state, jobsByCategories: { ...action.payload } };
    case JOBS_BY_CATEGORIES_SUCCESS:
      return { ...state, jobsByCategories: { ...action.payload } };
    case JOBS_BY_CATEGORIES_FAILED:
      return { ...state, jobsByCategories: { ...action.payload } };
    case JOBS_BY_CATEGORIES_RESET:
      return { ...state, jobsByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Business
     * =======================================================================
     */
    case JOBS_BY_BUSINESS_START:
      return { ...state, jobsByBusiness: { ...action.payload } };
    case JOBS_BY_BUSINESS_SUCCESS:
      return { ...state, jobsByBusiness: { ...action.payload } };
    case JOBS_BY_BUSINESS_FAILED:
      return { ...state, jobsByBusiness: { ...action.payload } };
    case JOBS_BY_BUSINESS_RESET:
      return { ...state, jobsByBusiness: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Spots
     * =======================================================================
     */
    case JOBS_BY_SPOTS_START:
      return { ...state, jobsBySpots: { ...action.payload } };
    case JOBS_BY_SPOTS_SUCCESS:
      return { ...state, jobsBySpots: { ...action.payload } };
    case JOBS_BY_SPOTS_FAILED:
      return { ...state, jobsBySpots: { ...action.payload } };
    case JOBS_BY_SPOTS_RESET:
      return { ...state, jobsBySpots: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Trainigs by openings
     * =======================================================================
     */
    case TRAININGS_BY_OPENINGS_START:
      return { ...state, trainingsByOpenings: { ...action.payload } };
    case TRAININGS_BY_OPENINGS_SUCCESS:
      return { ...state, trainingsByOpenings: { ...action.payload } };
    case TRAININGS_BY_OPENINGS_FAILED:
      return { ...state, trainingsByOpenings: { ...action.payload } };
    case TRAININGS_BY_OPENINGS_RESET:
      return { ...state, trainingsByOpenings: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Trainings By Applications
     * =======================================================================
     */
    case TRAININGS_BY_APPLICATIONS_START:
      return { ...state, trainingsByApplications: { ...action.payload } };
    case TRAININGS_BY_APPLICATIONS_SUCCESS:
      return { ...state, trainingsByApplications: { ...action.payload } };
    case TRAININGS_BY_APPLICATIONS_FAILED:
      return { ...state, trainingsByApplications: { ...action.payload } };
    case TRAININGS_BY_APPLICATIONS_RESET:
      return { ...state, trainingsByApplications: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Categories
     * =======================================================================
     */
    case TRAININGS_BY_CATEGORIES_START:
      return { ...state, trainingsByCategories: { ...action.payload } };
    case TRAININGS_BY_CATEGORIES_SUCCESS:
      return { ...state, trainingsByCategories: { ...action.payload } };
    case TRAININGS_BY_CATEGORIES_FAILED:
      return { ...state, trainingsByCategories: { ...action.payload } };
    case TRAININGS_BY_CATEGORIES_RESET:
      return { ...state, trainingsByCategories: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Business
     * =======================================================================
     */
    case TRAININGS_BY_ENGAGEMENTS_START:
      return { ...state, trainingsByEngagements: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_SUCCESS:
      return { ...state, trainingsByEngagements: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_FAILED:
      return { ...state, trainingsByEngagements: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_RESET:
      return { ...state, trainingsByEngagements: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Trainings by engagements gender
     * =======================================================================
     */
    case TRAININGS_BY_ENGAGEMENTS_GENDER_START:
      return { ...state, trainingsByEngagementsGender: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_GENDER_SUCCESS:
      return { ...state, trainingsByEngagementsGender: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_GENDER_FAILED:
      return { ...state, trainingsByEngagementsGender: { ...action.payload } };
    case TRAININGS_BY_ENGAGEMENTS_GENDER_RESET:
      return { ...state, trainingsByEngagementsGender: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Jobs By Spots
     * =======================================================================
     */
    case TRAININGS_BY_SPOTS_START:
      return { ...state, trainingsBySpots: { ...action.payload } };
    case TRAININGS_BY_SPOTS_SUCCESS:
      return { ...state, trainingsBySpots: { ...action.payload } };
    case TRAININGS_BY_SPOTS_FAILED:
      return { ...state, trainingsBySpots: { ...action.payload } };
    case TRAININGS_BY_SPOTS_RESET:
      return { ...state, trainingsBySpots: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Counts Data
     * =======================================================================
     */
    case COUNTS_DATA_START:
      return { ...state, countsData: { ...action.payload } };
    case COUNTS_DATA_SUCCESS:
      return { ...state, countsData: { ...action.payload } };
    case COUNTS_DATA_FAILED:
      return { ...state, countsData: { ...action.payload } };
    case COUNTS_DATA_RESET:
      return { ...state, countsData: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default reportsReducer;
