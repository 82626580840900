// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Facilitation Guides.
export const GET_ALL_FACILITATION_GUIDES_START = 'GET_ALL_FACILITATION_GUIDES_START';
export const GET_ALL_FACILITATION_GUIDES_SUCCESS = 'GET_ALL_FACILITATION_GUIDES_SUCCESS';
export const GET_ALL_FACILITATION_GUIDES_FAILED = 'GET_ALL_FACILITATION_GUIDES_FAILED';
export const GET_ALL_FACILITATION_GUIDES_RESET = 'GET_ALL_FACILITATION_GUIDES_RESET';

// Action Types: Get One Facilitation Guide.
export const GET_ONE_FACILITATION_GUIDE_START = 'GET_ONE_FACILITATION_GUIDE_START';
export const GET_ONE_FACILITATION_GUIDE_SUCCESS = 'GET_ONE_FACILITATION_GUIDE_SUCCESS';
export const GET_ONE_FACILITATION_GUIDE_FAILED = 'GET_ONE_FACILITATION_GUIDE_FAILED';
export const GET_ONE_FACILITATION_GUIDE_RESET = 'GET_ONE_FACILITATION_GUIDE_RESET';

// Action Types: Create New Facilitation Guide.
export const CREATE_FACILITATION_GUIDE_START = 'CREATE_FACILITATION_GUIDE_START';
export const CREATE_FACILITATION_GUIDE_SUCCESS = 'CREATE_FACILITATION_GUIDE_SUCCESS';
export const CREATE_FACILITATION_GUIDE_FAILED = 'CREATE_FACILITATION_GUIDE_FAILED';
export const CREATE_FACILITATION_GUIDE_RESET = 'CREATE_FACILITATION_GUIDE_RESET';

// Action Types: Update One Facilitation Guide.
export const UPDATE_ONE_FACILITATION_GUIDE_START = 'UPDATE_ONE_FACILITATION_GUIDE_START';
export const UPDATE_ONE_FACILITATION_GUIDE_SUCCESS = 'UPDATE_ONE_FACILITATION_GUIDE_SUCCESS';
export const UPDATE_ONE_FACILITATION_GUIDE_FAILED = 'UPDATE_ONE_FACILITATION_GUIDE_FAILED';
export const UPDATE_ONE_FACILITATION_GUIDE_RESET = 'UPDATE_ONE_FACILITATION_GUIDE_RESET';

// Action Types: Delete One Facilitation Guide.
export const DELETE_ONE_FACILITATION_GUIDE_START = 'DELETE_ONE_FACILITATION_GUIDE_START';
export const DELETE_ONE_FACILITATION_GUIDE_SUCCESS = 'DELETE_ONE_FACILITATION_GUIDE_SUCCESS';
export const DELETE_ONE_FACILITATION_GUIDE_FAILED = 'DELETE_ONE_FACILITATION_GUIDE_FAILED';
export const DELETE_ONE_FACILITATION_GUIDE_RESET = 'DELETE_ONE_FACILITATION_GUIDE_RESET';

// Action Creators: Get All Facilitation Guides.
const getAllFacilitationGuidesStart = (payload) => ({
  type: GET_ALL_FACILITATION_GUIDES_START,
  payload,
});
const getAllFacilitationGuidesSuccess = (payload) => ({
  type: GET_ALL_FACILITATION_GUIDES_SUCCESS,
  payload,
});
const getAllFacilitationGuidesFailed = (payload) => ({
  type: GET_ALL_FACILITATION_GUIDES_FAILED,
  payload,
});
const getAllFacilitationGuidesReset = () => ({ type: GET_ALL_FACILITATION_GUIDES_RESET });

// Action Creators: Get One Facilitation Guide.
const getOneFacilitationGuideStart = (payload) => ({
  type: GET_ONE_FACILITATION_GUIDE_START,
  payload,
});
const getOneFacilitationGuideSuccess = (payload) => ({
  type: GET_ONE_FACILITATION_GUIDE_SUCCESS,
  payload,
});
const getOneFacilitationGuideFailed = (payload) => ({
  type: GET_ONE_FACILITATION_GUIDE_FAILED,
  payload,
});
const getOneFacilitationGuideReset = () => ({ type: GET_ONE_FACILITATION_GUIDE_RESET });

// Action Creators: Create New Facilitation Guide.
const createFacilitationGuideStart = (payload) => ({
  type: CREATE_FACILITATION_GUIDE_START,
  payload,
});
const createFacilitationGuideSuccess = (payload) => ({
  type: CREATE_FACILITATION_GUIDE_SUCCESS,
  payload,
});
const createFacilitationGuideFailed = (payload) => ({
  type: CREATE_FACILITATION_GUIDE_FAILED,
  payload,
});
const createFacilitationGuideReset = () => ({ type: CREATE_FACILITATION_GUIDE_RESET });

// Action Creators: Update One Facilitation Guide.
const updateOneFacilitationGuideStart = (payload) => ({
  type: UPDATE_ONE_FACILITATION_GUIDE_START,
  payload,
});
const updateOneFacilitationGuideSuccess = (payload) => ({
  type: UPDATE_ONE_FACILITATION_GUIDE_SUCCESS,
  payload,
});
const updateOneFacilitationGuideFailed = (payload) => ({
  type: UPDATE_ONE_FACILITATION_GUIDE_FAILED,
  payload,
});
const updateOneFacilitationGuideReset = () => ({ type: UPDATE_ONE_FACILITATION_GUIDE_RESET });

// Action Creators: Delete One Facilitation Guide.
const deleteOneFacilitationGuideStart = (payload) => ({
  type: DELETE_ONE_FACILITATION_GUIDE_START,
  payload,
});
const deleteOneFacilitationGuideSuccess = (payload) => ({
  type: DELETE_ONE_FACILITATION_GUIDE_SUCCESS,
  payload,
});
const deleteOneFacilitationGuideFailed = (payload) => ({
  type: DELETE_ONE_FACILITATION_GUIDE_FAILED,
  payload,
});
const deleteOneFacilitationGuideReset = () => ({ type: DELETE_ONE_FACILITATION_GUIDE_RESET });

// Actions: Get All Facilitation Guides.
export const getAllFacilitationGuides = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllFacilitationGuidesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language, useLanguageFilter } = payload;
      const result = await ApiClient.get('facilitationguides', {
        params: { page, limit, pagination, selectedLanguage: language, useLanguageFilter },
      });
      if (result.data?.success) {
        const { facilitationGuides } = result.data.data;
        dispatch(
          getAllFacilitationGuidesSuccess({
            loading: false,
            success: true,
            data: { facilitationGuides },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllFacilitationGuidesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllFacilitationGuidesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Facilitation Guides.
export const clearGetAllFacilitationGuides = () => getAllFacilitationGuidesReset();

// Actions: Get One Facilitation Guide.
export const getOneFacilitationGuide = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneFacilitationGuideStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { facilitationGuideId } = payload;
      const result = await ApiClient.get(`facilitationguides/${facilitationGuideId}`);
      if (result.data?.success) {
        const { facilitationGuide } = result.data.data;
        dispatch(
          getOneFacilitationGuideSuccess({
            loading: false,
            success: true,
            data: { facilitationGuide },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneFacilitationGuideFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneFacilitationGuideFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Facilitation Guide.
export const clearGetOneFacilitationGuide = () => getOneFacilitationGuideReset();

// Actions: Create New Facilitation Guide.
export const createFacilitationGuide = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createFacilitationGuideStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onFacilitationGuideExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('facilitationguides', formData);
      if (result.data?.success) {
        const { facilitationGuide } = result.data.data;
        dispatch(
          createFacilitationGuideSuccess({
            loading: false,
            success: true,
            data: { facilitationGuide },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createFacilitationGuideFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createFacilitationGuideFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const facilitationGuideExists = 'FACILITATION_GUIDE_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === facilitationGuideExists
      ) {
        toastNotification('error', onFacilitationGuideExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Facilitation Guide.
export const clearCreateNewFacilitationGuide = () => createFacilitationGuideReset();

// Actions: Update One Facilitation Guide.
export const updateOneFacilitationGuide = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneFacilitationGuideStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { facilitationGuideId, formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`facilitationguides/${facilitationGuideId}`, formData);
      if (result.data?.success) {
        const { facilitationGuide } = result.data.data;
        dispatch(
          updateOneFacilitationGuideSuccess({
            loading: false,
            success: true,
            data: { facilitationGuide },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneFacilitationGuideFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneFacilitationGuideFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Facilitation Guide.
export const clearUpdateOneFacilitationGuide = () => updateOneFacilitationGuideReset();

// Actions: Delete One Facilitation Guide.
export const deleteOneFacilitationGuide = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneFacilitationGuideStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { facilitationGuideId } = payload;
      const result = await ApiClient.delete(`facilitationguides/${facilitationGuideId}`);
      if (result.data?.success) {
        const { facilitationGuide } = result.data.data;
        dispatch(
          deleteOneFacilitationGuideSuccess({
            loading: false,
            success: true,
            data: { facilitationGuide },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneFacilitationGuideFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneFacilitationGuideFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Facilitation Guide.
export const cleareDeleteOneFacilitationGuide = () => deleteOneFacilitationGuideReset();
