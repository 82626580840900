// Imports: local files.
import ApiClient from '../../services/ApiClient';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

// Action Types: Get All Interns.
export const GET_ALL_INTERNS_START = 'GET_ALL_INTERNS_START';
export const GET_ALL_INTERNS_SUCCESS = 'GET_ALL_INTERNS_SUCCESS';
export const GET_ALL_INTERNS_FAILED = 'GET_ALL_INTERNS_FAILED';
export const GET_ALL_INTERNS_RESET = 'GET_ALL_INTERNS_RESET';

// Action Types: Get One Intern.
export const GET_ONE_INTERN_START = 'GET_ONE_INTERN_START';
export const GET_ONE_INTERN_SUCCESS = 'GET_ONE_INTERN_SUCCESS';
export const GET_ONE_INTERN_FAILED = 'GET_ONE_INTERN_FAILED';
export const GET_ONE_INTERN_RESET = 'GET_ONE_INTERN_RESET';

// Action Types: Update One Intern.
export const UPDATE_ONE_INTERN_START = 'UPDATE_ONE_INTERN_START';
export const UPDATE_ONE_INTERN_SUCCESS = 'UPDATE_ONE_INTERN_SUCCESS';
export const UPDATE_ONE_INTERN_FAILED = 'UPDATE_ONE_INTERN_FAILED';
export const UPDATE_ONE_INTERN_RESET = 'UPDATE_ONE_INTERN_RESET';

// Action Types: Upload Photo Intern.
export const UPLOAD_PHOTO_INTERN_START = 'UPLOAD_PHOTO_INTERN_START';
export const UPLOAD_PHOTO_INTERN_SUCCESS = 'UPLOAD_PHOTO_INTERN_SUCCESS';
export const UPLOAD_PHOTO_INTERN_FAILED = 'UPLOAD_PHOTO_INTERN_FAILED';
export const UPLOAD_PHOTO_INTERN_RESET = 'UPLOAD_PHOTO_INTERN_RESET';

// Action Types: Delete One Intern.
export const DELETE_ONE_INTERN_START = 'DELETE_ONE_INTERN_START';
export const DELETE_ONE_INTERN_SUCCESS = 'DELETE_ONE_INTERN_SUCCESS';
export const DELETE_ONE_INTERN_FAILED = 'DELETE_ONE_INTERN_FAILED';
export const DELETE_ONE_INTERN_RESET = 'DELETE_ONE_INTERN_RESET';

// Action Types: Sign Up Intern.
export const SIGN_UP_INTERN_START = 'SIGN_UP_INTERN_START';
export const SIGN_UP_INTERN_SUCCESS = 'SIGN_UP_INTERN_SUCCESS';
export const SIGN_UP_INTERN_FAILED = 'SIGN_UP_INTERN_FAILED';
export const SIGN_UP_INTERN_RESET = 'SIGN_UP_INTERN_RESET';

// Action Types: Login Intern.
export const LOGIN_INTERN_START = 'LOGIN_INTERN_START';
export const LOGIN_INTERN_SUCCESS = 'LOGIN_INTERN_SUCCESS';
export const LOGIN_INTERN_FAILED = 'LOGIN_INTERN_FAILED';
export const LOGIN_INTERN_RESET = 'LOGIN_INTERN_RESET';

// Action Types: Forgot Password Intern.
export const FORGOT_PASS_INTERN_START = 'FORGOT_PASS_INTERN_START';
export const FORGOT_PASS_INTERN_SUCCESS = 'FORGOT_PASS_INTERN_SUCCESS';
export const FORGOT_PASS_INTERN_FAILED = 'FORGOT_PASS_INTERN_FAILED';
export const FORGOT_PASS_INTERN_RESET = 'FORGOT_PASS_INTERN_RESET';

// Action Types: Reset Password Intern.
export const RESET_PASS_INTERN_START = 'RESET_PASS_INTERN_START';
export const RESET_PASS_INTERN_SUCCESS = 'RESET_PASS_INTERN_SUCCESS';
export const RESET_PASS_INTERN_FAILED = 'RESET_PASS_INTERN_FAILED';
export const RESET_PASS_INTERN_RESET = 'RESET_PASS_INTERN_RESET';

// Action Types: Confirm Intern
export const CONFIRM_INTERN_START = 'CONFIRM_INTERN_START';
export const CONFIRM_INTERN_SUCCESS = 'CONFIRM_INTERN_SUCCESS';
export const CONFIRM_INTERN_FAILED = 'CONFIRM_INTERN_FAILED';
export const CONFIRM_INTERN_RESET = 'CONFIRM_INTERN_RESET';

// Action Types: Resend Intern Email
export const RESEND_INTERN_EMAIL_START = 'RESEND_INTERN_EMAIL_START';
export const RESEND_INTERN_EMAIL_SUCCESS = 'RESEND_INTERN_EMAIL_SUCCESS';
export const RESEND_INTERN_EMAIL_FAILED = 'RESEND_INTERN_EMAIL_FAILED';
export const RESEND_INTERN_EMAIL_RESET = 'RESEND_INTERN_EMAIL_RESET';

// Action Types: Generate Intern Cv
export const GENERATE_INTERN_CV_START = 'GENERATE_INTERN_CV_START';
export const GENERATE_INTERN_CV_SUCCESS = 'GENERATE_INTERN_CV_SUCCESS';
export const GENERATE_INTERN_CV_FAILED = 'GENERATE_INTERN_CV_FAILED';
export const GENERATE_INTERN_CV_RESET = 'GENERATE_INTERN_CV_RESET';

// Action Creators: Get All Interns.
const getAllInternsStart = (payload) => ({
  type: GET_ALL_INTERNS_START,
  payload,
});
const getAllInternsSuccess = (payload) => ({
  type: GET_ALL_INTERNS_SUCCESS,
  payload,
});
const getAllInternsFailed = (payload) => ({
  type: GET_ALL_INTERNS_FAILED,
  payload,
});
const getAllInternsReset = () => ({ type: GET_ALL_INTERNS_RESET });

// Action Creators: Get One Intern.
const getOneInternStart = (payload) => ({
  type: GET_ONE_INTERN_START,
  payload,
});
const getOneInternSuccess = (payload) => ({
  type: GET_ONE_INTERN_SUCCESS,
  payload,
});
const getOneInternFailed = (payload) => ({
  type: GET_ONE_INTERN_FAILED,
  payload,
});
const getOneInternReset = () => ({ type: GET_ONE_INTERN_RESET });

// Action Creators: Update One Intern.
const updateOneInternStart = (payload) => ({
  type: UPDATE_ONE_INTERN_SUCCESS,
  payload,
});
const updateOneInternSuccess = (payload) => ({
  type: UPDATE_ONE_INTERN_START,
  payload,
});
const updateOneInternFailed = (payload) => ({
  type: UPDATE_ONE_INTERN_FAILED,
  payload,
});
const updateOneInternReset = () => ({ type: UPDATE_ONE_INTERN_RESET });

// Action Creators: Upload Photo Intern.
const uploadPhotoInternStart = (payload) => ({
  type: UPLOAD_PHOTO_INTERN_START,
  payload,
});
const uploadPhotoInternSuccess = (payload) => ({
  type: UPLOAD_PHOTO_INTERN_SUCCESS,
  payload,
});
const uploadPhotoInternFailed = (payload) => ({
  type: UPLOAD_PHOTO_INTERN_FAILED,
  payload,
});
const uploadPhotoInternReset = () => ({ type: UPLOAD_PHOTO_INTERN_RESET });

// Action Creators: Delete One Intern.
const deleteOneInternStart = (payload) => ({
  type: DELETE_ONE_INTERN_START,
  payload,
});
const deleteOneInternSuccess = (payload) => ({
  type: DELETE_ONE_INTERN_SUCCESS,
  payload,
});
const deleteOneInternFailed = (payload) => ({
  type: DELETE_ONE_INTERN_FAILED,
  payload,
});
const deleteOneInternReset = () => ({ type: DELETE_ONE_INTERN_RESET });

// Action Creators: Sing Up Intern.
const signUpInternStart = (payload) => ({
  type: SIGN_UP_INTERN_START,
  payload,
});
const signUpInternSuccess = (payload) => ({
  type: SIGN_UP_INTERN_SUCCESS,
  payload,
});
const signUpInternFailed = (payload) => ({
  type: SIGN_UP_INTERN_FAILED,
  payload,
});
const signUpInternReset = () => ({ type: SIGN_UP_INTERN_RESET });

// Action Creators: Login Intern.
const loginInternStart = (payload) => ({
  type: LOGIN_INTERN_START,
  payload,
});
const loginInternSuccess = (payload) => ({
  type: LOGIN_INTERN_SUCCESS,
  payload,
});
const loginInternFailed = (payload) => ({
  type: LOGIN_INTERN_FAILED,
  payload,
});
const loginInternReset = () => ({ type: LOGIN_INTERN_RESET });

// Action Creators: Forgot Password Intern.
const forgotPassInternStart = (payload) => ({
  type: FORGOT_PASS_INTERN_START,
  payload,
});
const forgotPassInternSuccess = (payload) => ({
  type: FORGOT_PASS_INTERN_SUCCESS,
  payload,
});
const forgotPassInternFailed = (payload) => ({
  type: FORGOT_PASS_INTERN_FAILED,
  payload,
});
const forgotPassInternReset = () => ({ type: FORGOT_PASS_INTERN_RESET });

// Action Creators: Reset Password Intern.
const resetPassInternStart = (payload) => ({
  type: RESET_PASS_INTERN_START,
  payload,
});
const resetPassInternSuccess = (payload) => ({
  type: RESET_PASS_INTERN_SUCCESS,
  payload,
});
const resetPassInternFailed = (payload) => ({
  type: RESET_PASS_INTERN_FAILED,
  payload,
});
const resetPassInternReset = () => ({ type: RESET_PASS_INTERN_RESET });

// Action Creators: Confirm Intern.
const confirmInternStart = (payload) => ({
  type: CONFIRM_INTERN_START,
  payload,
});
const confirmInternSuccess = (payload) => ({
  type: CONFIRM_INTERN_SUCCESS,
  payload,
});
const confirmInternFailed = (payload) => ({
  type: CONFIRM_INTERN_FAILED,
  payload,
});
const confirmInternReset = () => ({ type: CONFIRM_INTERN_RESET });

// Action Creators: Resend Intern Email.
const resendInternEmailStart = (payload) => ({
  type: RESEND_INTERN_EMAIL_START,
  payload,
});
const resendInternEmailSuccess = (payload) => ({
  type: RESEND_INTERN_EMAIL_SUCCESS,
  payload,
});
const resendInternEmailFailed = (payload) => ({
  type: RESEND_INTERN_EMAIL_FAILED,
  payload,
});
const resendInternEmailReset = () => ({ type: RESEND_INTERN_EMAIL_RESET });

// Action Creators: Generate Intern Cv.
const generateInternCvStart = (payload) => ({
  type: GENERATE_INTERN_CV_START,
  payload,
});
const generateInternCvSuccess = (payload) => ({
  type: GENERATE_INTERN_CV_SUCCESS,
  payload,
});
const generateInternCvFailed = (payload) => ({
  type: GENERATE_INTERN_CV_FAILED,
  payload,
});
const generateInternCvReset = () => ({ type: GENERATE_INTERN_CV_RESET });

// Actions: Get All Interns.
export const getAllInterns = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllInternsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        firstName,
        lastName,
        municipality,
        skills,
        categories,
        sort,
        gender,
        type,
        availability,
        hiredFrom,
        hiredTo,
        registeredFrom,
        registeredTo,
      } = payload;

      const result = await ApiClient.get('interns', {
        params: {
          page,
          limit,
          pagination,
          firstName,
          lastName,
          municipality,
          skills,
          categories,
          sort,
          gender,
          type,
          availability,
          hiredFrom,
          hiredTo,
          registeredFrom,
          registeredTo,
        },
      });
      if (result.data?.success) {
        const { interns } = result.data?.data;

        dispatch(
          getAllInternsSuccess({
            loading: false,
            success: true,
            data: { interns },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllInternsFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllInternsFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All.
export const clearGetAllInterns = () => getAllInternsReset();

// Actions: Get One Intern.
export const getOneIntern = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { internId } = payload;

    try {
      const result = await ApiClient.get(`interns/${internId}`);
      if (result.data?.success) {
        const { intern } = result.data?.data;
        dispatch(
          getOneInternSuccess({ loading: false, success: true, data: { intern }, error: false, errorMessage: null })
        );
      } else {
        dispatch(
          getOneInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One.
export const clearGetOneIntern = () => getOneInternReset();

// Actions: Update One Intern.
export const updateOneIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      internId,
      name,
      lastName,
      municipality,
      birthdate,
      birthMonth,
      birthYear,
      phoneNumber,
      gender,
      categories,
      skills,
      availability,
      address,
      hearAbout,
      ethnicity,
      disabilities,
      resume,
      updateRequired,
    } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`interns/${internId}`, {
        name,
        lastName,
        municipality,
        birthdate,
        birthMonth,
        birthYear,
        phoneNumber,
        gender,
        categories,
        skills,
        availability,
        address,
        hearAbout,
        ethnicity,
        disabilities,
        resume,
        updateRequired,
      });
      if (result.data?.success) {
        const { intern, token } = result.data.data;
        if (token) {
          dispatch({ type: 'TOKEN', token });
          localStorage.setItem('kgen-token', token);
        }
        dispatch(
          updateOneInternSuccess({
            loading: false,
            success: true,
            data: { intern },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One.
export const clearUpdateOneIntern = () => updateOneInternReset();

// Actions: Upload Photo Intern.
export const uploadPhotoIntern = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadPhotoInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onSuccessMessageEmailSent,
      onSuccessMessageEmail,
    } = options;
    try {
      const { internId, formData } = payload;
      const result = await ApiClient.put(`interns/${internId}/photo`, formData);
      if (result.data?.success) {
        const { intern } = result.data?.data;
        dispatch(
          uploadPhotoInternSuccess({
            loading: false,
            success: true,
            data: { intern },
            error: false,
            errorMessage: null,
          })
        );
        // showToast && toastNotification('success', onSuccessMessage);
        // shouldRedirect && history.push(pathname);
        if (showSwal) {
          const swalOptions = {
            title: `${onSuccessMessageEmailSent}`,
            text: `${onSuccessMessageEmail}`,
            icon: 'success',

            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          // Swal.fire(swalOptions).then((p) => {});
          Swal.fire(swalOptions).then((result) => result.isConfirmed && shouldRedirect && history.push(pathname));
        } else {
          showToast && toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          uploadPhotoInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadPhotoInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Photo.
export const clearUploadPhotoIntern = () => uploadPhotoInternReset();

// Actions: Delete One Intern.
export const deleteOneIntern = (payload, options = {}) => {
  return async (dispatch) => {
    dispatch(
      deleteOneInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { internId } = payload;
    const { toastNotification, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.delete(`interns/${internId}`);
      if (result.data?.success) {
        const { intern } = result.data.data;
        dispatch(
          deleteOneInternSuccess({
            loading: false,
            success: true,
            data: { intern },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          deleteOneInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        deleteOneInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Delete One.
export const clearDeleteOneIntern = () => deleteOneInternReset();

// Actions: Sign Up Intern.
export const signUpIntern = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      signUpInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onFailMessage,
      onSuccessMessage,
      userExistsMessage,
      onSuccessMessageEmailSent,
      onSuccessMessageEmail,
    } = options;

    const {
      email,
      password,
      passwordConfirm,
      name,
      lastName,
      municipality,
      // birthdate,
      birthMonth,
      birthYear,
      phoneNumber,
      gender,
      categories,
      skills,
      address,
      resume,
      hearAbout,
      ethnicity,
      disabilities,
      emailLanguage,
    } = payload;
    try {
      const result = await ApiClient.post('interns/auth/signup', {
        email: email.toLowerCase(),
        password,
        passwordConfirm,
        name,
        lastName,
        municipality,
        // birthdate,
        birthMonth,
        birthYear,
        phoneNumber,
        gender,
        categories,
        skills,
        address,
        hearAbout,
        ethnicity,
        disabilities,
        resume,
        emailLanguage,
      });
      if (result.data?.success) {
        const { sent, internId } = result.data.data;
        dispatch(
          signUpInternSuccess({
            loading: false,
            success: true,
            data: { sent, internId },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onSuccessMessageEmailSent}`,
            text: `${onSuccessMessageEmail}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          // Swal.fire(swalOptions).then((p) => {});
          Swal.fire(swalOptions).then((result) => result.isConfirmed && shouldRedirect && history.push(pathname));
        } else {
          showToast && toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          signUpInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        signUpInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const emailExistsMessage = 'USER_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === emailExistsMessage
      ) {
        toastNotification('error', userExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Sign Up.
export const clearSignUpIntern = () => signUpInternReset();

// Actions: Login Intern.
export const loginIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      loginInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onFailConfirmMessage,
      onFailCredentialMessage,
      onCVFailMessage,
      oldUserMessage,
      onAgeOverMessage,
    } = options;
    try {
      const { email, password, remember } = payload;
      const result = await ApiClient.post('interns/auth/login', {
        email: email.toLowerCase(),
        password,
        remember,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp, updateFinished, updateRequired, aboveOrUnderAge } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          loginInternSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        history.push(pathname);
        toastNotification('success', onSuccessMessage);
        aboveOrUnderAge && toastNotification('error', onAgeOverMessage);
        updateRequired && toastNotification('error', oldUserMessage);
        {
          !updateFinished && toastNotification('error', onCVFailMessage);
        }
      } else {
        dispatch(
          loginInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        loginInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const unconfirmedMessage = 'UNCONFIRMED_ACCOUNT';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === unconfirmedMessage
      ) {
        toastNotification('error', onFailConfirmMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'INVALID_CREDENTIALS'
      ) {
        toastNotification('error', onFailCredentialMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Login.
export const clearLoginIntern = () => loginInternReset();

// Actions: Forgot Password Intern.
export const forgotPassIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      forgotPassInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onUserFailMessage } = options;
    try {
      const { email, emailLanguage } = payload;
      const result = await ApiClient.post('interns/auth/forgot', {
        email: email.toLowerCase(),
        emailLanguage,
      });
      if (result.data?.success) {
        dispatch(
          forgotPassInternSuccess({
            loading: false,
            success: true,
            data: result.data.data,
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          forgotPassInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        forgotPassInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'RESOURCE_NOT_FOUND'
      ) {
        toastNotification('error', onUserFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Forgot Pass.
export const clearForgotPassIntern = () => forgotPassInternReset();

// Actions: Reset Password Intern.
export const resetPassIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resetPassInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onFailMessageExpired } = options;

    try {
      const { email, newPassword, passwordConfirm, resetToken, emailLanguage } = payload;
      const result = await ApiClient.post(`interns/auth/reset/${resetToken}`, {
        email: email.toLowerCase(),
        newPassword,
        passwordConfirm,
        emailLanguage,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          resetPassInternSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          resetPassInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      const errorType = error.response?.data?.errorType;
      let errorMessage = error.message || 'Internal Server Error!';

      if (errorType === 'RESOURCE_NOT_FOUND') {
        errorMessage = onFailMessageExpired;
      } else if (errorType === 'PASSWORD_SAME') {
        errorMessage = onFailMessage;
      }
      dispatch(
        resetPassInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage,
        })
      );
      toastNotification('error', errorMessage);
    }
  };
};

// Actions: Clear Reset Pass.
export const clearResetPassIntern = () => resetPassInternReset();

// Actions: Confirm Intern.
export const confirmIntern = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      confirmInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email, accountConfirmationCode, accountConfirmationToken } = payload;
      const result = await ApiClient.post('interns/auth/confirm', {
        email: email.toLowerCase(),
        accountConfirmationCode,
        accountConfirmationToken,
      });
      if (result.data?.success) {
        const { token } = result.data?.data;
        const decodedToken = jwt.decode(token);
        const exp = decodedToken.exp;
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          confirmInternSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          confirmInternFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        confirmInternFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const codeExpiredMessage = 'CODE_EXPIRED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === codeExpiredMessage
      ) {
        toastNotification('error', 'Code has expired, please click resend code!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Confirm Intern.
export const clearConfirmIntern = () => confirmInternReset();

// Actions: Resend Intern Email.
export const resendInternEmail = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resendInternEmailStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email } = payload;
      const result = await ApiClient.post('interns/auth/resend', {
        email: email.toLowerCase(),
      });
      if (result.data?.success) {
        const { sent, internId } = result.data?.data;
        dispatch(
          resendInternEmailSuccess({
            loading: false,
            success: true,
            data: { sent, internId },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          resendInternEmailFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        resendInternEmailFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const accountConfirmedMessage = 'ACCOUNT_CONFIRMED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === accountConfirmedMessage
      ) {
        toastNotification('error', 'Account already confirmed!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Resend Intern Email.
export const clearResendInternEmail = () => resendInternEmailReset();

// Actions: Generate Intern Cv.
export const generateInternCv = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      generateInternCvStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { internId, resumeHtml } = payload;
      const result = await ApiClient.put(`interns/${internId}/cv`, { resumeHtml }, { responseType: 'blob' });
      if (result.status === 200) {
        dispatch(
          generateInternCvSuccess({
            loading: false,
            success: true,
            data: result.data,
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          generateInternCvFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        generateInternCvFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Generate Intern CV.
export const clearGenerateInternCv = () => generateInternCvReset();
