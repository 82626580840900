// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Articles.
export const GET_ALL_ARTICLES_START = 'GET_ALL_ARTICLES_START';
export const GET_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS';
export const GET_ALL_ARTICLES_FAILED = 'GET_ALL_ARTICLES_FAILED';
export const GET_ALL_ARTICLES_RESET = 'GET_ALL_ARTICLES_RESET';

// Action Types: Get One Article.
export const GET_ONE_ARTICLE_START = 'GET_ONE_ARTICLE_START';
export const GET_ONE_ARTICLE_SUCCESS = 'GET_ONE_ARTICLE_SUCCESS';
export const GET_ONE_ARTICLE_FAILED = 'GET_ONE_ARTICLE_FAILED';
export const GET_ONE_ARTICLE_RESET = 'GET_ONE_ARTICLE_RESET';

// Action Types: Create New Article.
export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAILED = 'CREATE_ARTICLE_FAILED';
export const CREATE_ARTICLE_RESET = 'CREATE_ARTICLE_RESET';

// Action Types: Update One Article.
export const UPDATE_ONE_ARTICLE_START = 'UPDATE_ONE_ARTICLE_START';
export const UPDATE_ONE_ARTICLE_SUCCESS = 'UPDATE_ONE_ARTICLE_SUCCESS';
export const UPDATE_ONE_ARTICLE_FAILED = 'UPDATE_ONE_ARTICLE_FAILED';
export const UPDATE_ONE_ARTICLE_RESET = 'UPDATE_ONE_ARTICLE_RESET';

// Action Types: Upload Photo Article.
export const UPLOAD_PHOTO_ARTICLE_START = 'UPLOAD_PHOTO_ARTICLE_START';
export const UPLOAD_PHOTO_ARTICLE_SUCCESS = 'UPLOAD_PHOTO_ARTICLE_SUCCESS';
export const UPLOAD_PHOTO_ARTICLE_FAILED = 'UPLOAD_PHOTO_ARTICLE_FAILED';
export const UPLOAD_PHOTO_ARTICLE_RESET = 'UPLOAD_PHOTO_ARTICLE_RESET';

// Action Types: Delete One Article.
export const DELETE_ONE_ARTICLE_START = 'DELETE_ONE_ARTICLE_START';
export const DELETE_ONE_ARTICLE_SUCCESS = 'DELETE_ONE_ARTICLE_SUCCESS';
export const DELETE_ONE_ARTICLE_FAILED = 'DELETE_ONE_ARTICLE_FAILED';
export const DELETE_ONE_ARTICLE_RESET = 'DELETE_ONE_ARTICLE_RESET';

// Action Creators: Get All Articles.
const getAllArticlesStart = (payload) => ({
  type: GET_ALL_ARTICLES_START,
  payload,
});
const getAllArticlesSuccess = (payload) => ({
  type: GET_ALL_ARTICLES_SUCCESS,
  payload,
});
const getAllArticlesFailed = (payload) => ({
  type: GET_ALL_ARTICLES_FAILED,
  payload,
});
const getAllArticlesReset = () => ({ type: GET_ALL_ARTICLES_RESET });

// Action Creators: Get One Article.
const getOneArticleStart = (payload) => ({
  type: GET_ONE_ARTICLE_START,
  payload,
});
const getOneArticleSuccess = (payload) => ({
  type: GET_ONE_ARTICLE_SUCCESS,
  payload,
});
const getOneArticleFailed = (payload) => ({
  type: GET_ONE_ARTICLE_FAILED,
  payload,
});
const getOneArticleReset = () => ({ type: GET_ONE_ARTICLE_RESET });

// Action Creators: Create New Article.
const createArticleStart = (payload) => ({
  type: CREATE_ARTICLE_START,
  payload,
});
const createArticleSuccess = (payload) => ({
  type: CREATE_ARTICLE_SUCCESS,
  payload,
});
const createArticleFailed = (payload) => ({
  type: CREATE_ARTICLE_FAILED,
  payload,
});
const createArticleReset = () => ({ type: CREATE_ARTICLE_RESET });

// Action Creators: Update One Article.
const updateOneArticleStart = (payload) => ({
  type: UPDATE_ONE_ARTICLE_START,
  payload,
});
const updateOneArticleSuccess = (payload) => ({
  type: UPDATE_ONE_ARTICLE_SUCCESS,
  payload,
});
const updateOneArticleFailed = (payload) => ({
  type: UPDATE_ONE_ARTICLE_FAILED,
  payload,
});
const updateOneArticleReset = () => ({ type: UPDATE_ONE_ARTICLE_RESET });

// Action Creators: Upload Photo Article.
const uploadPhotoArticleStart = (payload) => ({
  type: UPLOAD_PHOTO_ARTICLE_START,
  payload,
});
const uploadPhotoArticleSuccess = (payload) => ({
  type: UPLOAD_PHOTO_ARTICLE_SUCCESS,
  payload,
});
const uploadPhotoArticleFailed = (payload) => ({
  type: UPLOAD_PHOTO_ARTICLE_FAILED,
  payload,
});
const uploadPhotoArticleReset = () => ({ type: UPLOAD_PHOTO_ARTICLE_RESET });

// Action Creators: Delete One Article.
const deleteOneArticleStart = (payload) => ({
  type: DELETE_ONE_ARTICLE_START,
  payload,
});
const deleteOneArticleSuccess = (payload) => ({
  type: DELETE_ONE_ARTICLE_SUCCESS,
  payload,
});
const deleteOneArticleFailed = (payload) => ({
  type: DELETE_ONE_ARTICLE_FAILED,
  payload,
});
const deleteOneArticleReset = () => ({ type: DELETE_ONE_ARTICLE_RESET });

// Actions: Get All Articles.
export const getAllArticles = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllArticlesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination } = payload;
      const result = await ApiClient.get('articles', {
        params: { page, limit, pagination },
      });
      if (result.data?.success) {
        const { articles } = result.data.data;
        dispatch(
          getAllArticlesSuccess({
            loading: false,
            success: true,
            data: { articles },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllArticlesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllArticlesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Articles.
export const clearGetAllArticles = () => getAllArticlesReset();

// Actions: Get One Article.
export const getOneArticle = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneArticleStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { articleId } = payload;
      const result = await ApiClient.get(`articles/${articleId}`);
      if (result.data?.success) {
        const { article } = result.data.data;
        dispatch(
          getOneArticleSuccess({
            loading: false,
            success: true,
            data: { article },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneArticleFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneArticleFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Article.
export const clearGetOneArticle = () => getOneArticleReset();

// Actions: Create New Article.
export const createArticle = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createArticleStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enTitle, enText, sqTitle, sqText, srTitle, srText, type } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage, onArticleExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('articles', { enTitle, enText, sqTitle, sqText, srTitle, srText, type });
      if (result.data?.success) {
        const { article } = result.data.data;
        dispatch(
          createArticleSuccess({
            loading: false,
            success: true,
            data: { article },
            error: false,
            errorMessage: null,
          })
        );

        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createArticleFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createArticleFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const articleExists = 'ARTICLE_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === articleExists
      ) {
        toastNotification('error', onArticleExistsMessage);
      } else {
        showToast && toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Article.
export const clearCreateNewArticle = () => createArticleReset();

// Actions: Update One Article.
export const updateOneArticle = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneArticleStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { articleId, enTitle, enText, sqTitle, sqText, srTitle, srText, type } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`articles/${articleId}`, {
        enTitle,
        enText,
        sqTitle,
        sqText,
        srTitle,
        srText,
        type,
      });
      if (result.data?.success) {
        const { article } = result.data.data;
        dispatch(
          updateOneArticleSuccess({
            loading: false,
            success: true,
            data: { article },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneArticleFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneArticleFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Article.
export const clearUpdateOneArticle = () => updateOneArticleReset();

// Actions: Upload Photo Article.
export const uploadPhotoArticle = (payload, options) => {
  return async (dispatch) => {
    dispatch(uploadPhotoArticleStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

    const { articleId, formData } = payload;
    const { showToast, toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`articles/${articleId}/photo`, formData);
      if (result.data?.success) {
        const { article } = result.data?.data;
        dispatch(
          uploadPhotoArticleSuccess({
            loading: false,
            success: true,
            data: { article },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          uploadPhotoArticleFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        uploadPhotoArticleFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Photo Article.
export const clearUploadPhotoArticle = () => uploadPhotoArticleReset();

// Actions: Delete One Article.
export const deleteOneArticle = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneArticleStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { articleId } = payload;
      const result = await ApiClient.delete(`articles/${articleId}`);
      if (result.data?.success) {
        const { article } = result.data.data;
        dispatch(
          deleteOneArticleSuccess({
            loading: false,
            success: true,
            data: { article },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneArticleFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneArticleFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Article.
export const clearDeleteOneArticle = () => deleteOneArticleReset();
