import React, { useEffect, useState } from 'react';
import {
  jobsByMunicipality,
  jobsByCategories,
  jobsBySkills,
  jobsByBusiness,
  jobsBySpots,
} from '../../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Grid } from '@mui/material';
import { toastNotification } from '../../../utils/toastNotification';
import APIClient from '../../../services/ApiClient';
import { saveAs } from 'file-saver';
import MainCard from '../../../utils/MainCard';
import { JobFilter } from './Filters/Job';
import { StatsChart } from './Stats';
import SubCard from '../../../utils/SubCard';

export const JobsReports = ({ startDate, endDate }) => {
  const jobsByMunicipalityResponse = useSelector(({ reports }) => reports.jobsByMunicipality);
  const jobsBySkillsResponse = useSelector(({ reports }) => reports.jobsBySkills);
  const jobsByCategoriesResponse = useSelector(({ reports }) => reports.jobsByCategories);
  const jobsByBusinessResponse = useSelector(({ reports }) => reports.jobsByBusiness);
  const jobsBySpotsResponse = useSelector(({ reports }) => reports.jobsBySpots);

  const [loadingButton, setLoadingButton] = useState(false);
  const [categoryChips, setCategoryChips] = useState([]);
  const [municipalityChips, setMunicipalityChips] = useState([]);
  const [skillChips, setSkillChips] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate || endDate) {
      let payload = {
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
      };
      if (skillChips?.length) payload['skills'] = skillChips.map((en) => en._id);
      if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
      if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
      dispatch(jobsByMunicipality(payload));
      dispatch(jobsBySkills(payload));
      dispatch(jobsByCategories(payload));
      dispatch(jobsByBusiness(payload));
      dispatch(jobsBySpots(payload));
    }
  }, [municipalityChips, skillChips, categoryChips]);

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (skillChips?.length) payload['skills'] = skillChips.map((en) => en._id);
    if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
    if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
    await APIClient.post(`/reports/jobs/export`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Internship-Opportunities_Stats.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };

  if (
    jobsByMunicipalityResponse.loading ||
    jobsBySkillsResponse.loading ||
    jobsByCategoriesResponse.loading ||
    jobsByBusinessResponse.loading ||
    jobsBySpotsResponse.loading
  ) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <MainCard title={t('generalStatistics')} sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <JobFilter
                  categoryChips={categoryChips}
                  skillChips={skillChips}
                  municipalityChips={municipalityChips}
                  setCategoryChips={setCategoryChips}
                  setSkillChips={setSkillChips}
                  setMunicipalityChips={setMunicipalityChips}
                />
              </Grid>
              <Grid item xs={12}>
                <SubCard
                  title={t('charts')}
                  sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}
                  secondary={
                    <Button
                      variant="contained"
                      disabled={loadingButton === true ? true : false}
                      sx={{
                        backgroundColor: '#01486B !important',
                        '&:hover': { backgroundColor: '#012b40 !important' },
                        width: '160px',
                        fontFamily: 'Mark-Regular',
                        fontSize: '14px',
                        textTransform: 'none !important',
                      }}
                      onClick={(e) => {
                        onDocumentDownload(e);
                      }}
                    >
                      {loadingButton ? (
                        <>
                          <CircularProgress size={24} style={{ color: '#fff' }} />
                        </>
                      ) : (
                        <>
                          <FileDownloadOutlined /> {t('exportExcel')}
                        </>
                      )}
                    </Button>
                  }
                >
                  <Grid container spacing={3}>
                    <StatsChart
                      response={jobsByMunicipalityResponse}
                      label={t('jobsByMunicipality')}
                      data={jobsByMunicipalityResponse?.data?.jobs ? jobsByMunicipalityResponse.data.jobs : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={jobsBySkillsResponse}
                      label={t('jobsBySkills')}
                      data={jobsBySkillsResponse?.data?.jobs ? jobsBySkillsResponse?.data?.jobs : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={jobsByCategoriesResponse}
                      label={t('jobsByCategories')}
                      data={jobsByCategoriesResponse?.data?.jobs ? jobsByCategoriesResponse?.data?.jobs : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={jobsByBusinessResponse}
                      label={t('jobsByBusiness')}
                      data={jobsByBusinessResponse?.data?.jobs ? jobsByBusinessResponse?.data?.jobs : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={jobsBySpotsResponse}
                      label={t('jobsBySpots')}
                      data={jobsBySpotsResponse?.data?.jobs ? jobsBySpotsResponse?.data?.jobs : []}
                      chart={'Bar'}
                      title={t('spots')}
                    />
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
