// Imports: local files.
import {
  GET_ALL_MENTORS_START,
  GET_ALL_MENTORS_SUCCESS,
  GET_ALL_MENTORS_FAILED,
  GET_ALL_MENTORS_RESET,
  GET_ONE_MENTOR_START,
  GET_ONE_MENTOR_SUCCESS,
  GET_ONE_MENTOR_FAILED,
  GET_ONE_MENTOR_RESET,
  UPDATE_ONE_MENTOR_START,
  UPDATE_ONE_MENTOR_SUCCESS,
  UPDATE_ONE_MENTOR_FAILED,
  UPDATE_ONE_MENTOR_RESET,
  UPLOAD_PHOTO_MENTOR_START,
  UPLOAD_PHOTO_MENTOR_SUCCESS,
  UPLOAD_PHOTO_MENTOR_FAILED,
  UPLOAD_PHOTO_MENTOR_RESET,
  UPLOAD_CV_MENTOR_START,
  UPLOAD_CV_MENTOR_SUCCESS,
  UPLOAD_CV_MENTOR_FAILED,
  UPLOAD_CV_MENTOR_RESET,
  DELETE_ONE_MENTOR_START,
  DELETE_ONE_MENTOR_SUCCESS,
  DELETE_ONE_MENTOR_FAILED,
  DELETE_ONE_MENTOR_RESET,
  SIGN_UP_MENTOR_START,
  SIGN_UP_MENTOR_SUCCESS,
  SIGN_UP_MENTOR_FAILED,
  SIGN_UP_MENTOR_RESET,
  LOGIN_MENTOR_START,
  LOGIN_MENTOR_SUCCESS,
  LOGIN_MENTOR_FAILED,
  LOGIN_MENTOR_RESET,
  FORGOT_PASS_MENTOR_START,
  FORGOT_PASS_MENTOR_SUCCESS,
  FORGOT_PASS_MENTOR_FAILED,
  FORGOT_PASS_MENTOR_RESET,
  RESET_PASS_MENTOR_START,
  RESET_PASS_MENTOR_SUCCESS,
  RESET_PASS_MENTOR_FAILED,
  RESET_PASS_MENTOR_RESET,
  GET_ALL_MENTOR_MATCHES_START,
  GET_ALL_MENTOR_MATCHES_SUCCESS,
  GET_ALL_MENTOR_MATCHES_FAILED,
  GET_ALL_MENTOR_MATCHES_RESET,
  CONFIRM_MENTOR_START,
  CONFIRM_MENTOR_SUCCESS,
  CONFIRM_MENTOR_FAILED,
  CONFIRM_MENTOR_RESET,
  RESEND_MENTOR_EMAIL_START,
  RESEND_MENTOR_EMAIL_SUCCESS,
  RESEND_MENTOR_EMAIL_FAILED,
  RESEND_MENTOR_EMAIL_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  updateOne: initialLoadingState,
  uploadPhoto: initialLoadingState,
  uploadCv: initialLoadingState,
  deleteOne: initialLoadingState,
  signup: initialLoadingState,
  login: initialLoadingState,
  forgotPass: initialLoadingState,
  resetPass: initialLoadingState,
  getAllMatches: initialLoadingState,
  confirm: initialLoadingState,
  resendEmail: initialLoadingState,
};

const mentorsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Mentors
     * =======================================================================
     */
    case GET_ALL_MENTORS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Mentor
     * =======================================================================
     */
    case GET_ONE_MENTOR_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTOR_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTOR_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTOR_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Mentor
     * =======================================================================
     */
    case UPDATE_ONE_MENTOR_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTOR_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTOR_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_MENTOR_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Logo Mentor
     * =======================================================================
     */
    case UPLOAD_PHOTO_MENTOR_START:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_MENTOR_SUCCESS:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_MENTOR_FAILED:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_MENTOR_RESET:
      return { ...state, uploadPhoto: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Upload Cv Mentor
     * =======================================================================
     */
    case UPLOAD_CV_MENTOR_START:
      return { ...state, uploadCv: { ...action.payload } };
    case UPLOAD_CV_MENTOR_SUCCESS:
      return { ...state, uploadCv: { ...action.payload } };
    case UPLOAD_CV_MENTOR_FAILED:
      return { ...state, uploadCv: { ...action.payload } };
    case UPLOAD_CV_MENTOR_RESET:
      return { ...state, uploadCv: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Mentor
     * =======================================================================
     */
    case DELETE_ONE_MENTOR_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MENTOR_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MENTOR_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_MENTOR_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Signup Mentor
     * =======================================================================
     */
    case SIGN_UP_MENTOR_START:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_MENTOR_SUCCESS:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_MENTOR_FAILED:
      return { ...state, signup: { ...action.payload } };
    case SIGN_UP_MENTOR_RESET:
      return { ...state, signup: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Login Mentor
     * =======================================================================
     */
    case LOGIN_MENTOR_START:
      return { ...state, login: { ...action.payload } };
    case LOGIN_MENTOR_SUCCESS:
      return { ...state, login: { ...action.payload } };
    case LOGIN_MENTOR_FAILED:
      return { ...state, login: { ...action.payload } };
    case LOGIN_MENTOR_RESET:
      return { ...state, login: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Forgot Password Mentor
     * =======================================================================
     */
    case FORGOT_PASS_MENTOR_START:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_MENTOR_SUCCESS:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_MENTOR_FAILED:
      return { ...state, forgotPass: { ...action.payload } };
    case FORGOT_PASS_MENTOR_RESET:
      return { ...state, forgotPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Reset Password Mentor
     * =======================================================================
     */
    case RESET_PASS_MENTOR_START:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_MENTOR_SUCCESS:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_MENTOR_FAILED:
      return { ...state, resetPass: { ...action.payload } };
    case RESET_PASS_MENTOR_RESET:
      return { ...state, resetPass: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Mentors
     * =======================================================================
     */
    case GET_ALL_MENTOR_MATCHES_START:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MENTOR_MATCHES_SUCCESS:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MENTOR_MATCHES_FAILED:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MENTOR_MATCHES_RESET:
      return { ...state, getAllMatches: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Confirm Mentor
     * =======================================================================
     */
    case CONFIRM_MENTOR_START:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_MENTOR_SUCCESS:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_MENTOR_FAILED:
      return { ...state, confirm: { ...action.payload } };
    case CONFIRM_MENTOR_RESET:
      return { ...state, confirm: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Resend Mentor Email
     * =======================================================================
     */
    case RESEND_MENTOR_EMAIL_START:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_MENTOR_EMAIL_SUCCESS:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_MENTOR_EMAIL_FAILED:
      return { ...state, resendEmail: { ...action.payload } };
    case RESEND_MENTOR_EMAIL_RESET:
      return { ...state, resendEmail: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default mentorsReducer;
