import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AllRoleButton from '../../../../components/buttons/AllRoleButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from 'react';
import { ChipSelect } from '../../../../components/inputs/ChipSelect';
import { getAllCategories, getAllMunicipalities, getAllSkills } from '../../../../store/actions/actions';

export const JobFilter = ({
  categoryChips,
  setCategoryChips,
  skillChips,
  setSkillChips,
  municipalityChips,
  setMunicipalityChips,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedMunicipality, setSelectedMunicipality] = useState('choose');
  const [selectedSkill, setSelectedSkill] = useState('choose');
  const [selectedCategory, setSelectedCategory] = useState('choose');

  const categoriesResponse = useSelector(({ categories }) => categories.getAll);
  const skillsResponse = useSelector(({ skills }) => skills.getAll);
  const municipalitiesResponse = useSelector(({ municipalities }) => municipalities.getAll);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getAllCategories({ pagination: false, language }));
    dispatch(getAllSkills({ pagination: false, language }));
    dispatch(getAllMunicipalities({ pagination: false, language }));
  }, []);

  const onCategoryChange = (category, type) => {
    switch (type) {
      case 'add':
        const exists = categoryChips.find((chip) => chip._id === category._id);
        if (exists) return;

        const addedArray = [...categoryChips, category];
        setCategoryChips(addedArray);
        setSelectedCategory(category._id);
        break;
      case 'delete':
        const filteredArray = categoryChips.filter((chip) => chip._id !== category._id);
        setCategoryChips(filteredArray);
        setSelectedCategory('choose');
        break;
      default:
        break;
    }
  };
  const onSkillChange = (skill, type) => {
    switch (type) {
      case 'add':
        const exists = skillChips.find((chip) => chip._id === skill._id);
        if (exists) return;

        const addedArray = [...skillChips, skill];
        setSkillChips(addedArray);
        setSelectedSkill(skill._id);
        break;
      case 'delete':
        const filteredArray = skillChips.filter((chip) => chip._id !== skill._id);
        setSkillChips(filteredArray);
        setSelectedSkill('choose');
        break;
      default:
        break;
    }
  };
  const onMunicipalityChange = (municipality, type) => {
    switch (type) {
      case 'add':
        const exists = municipalityChips.find((chip) => chip._id === municipality._id);
        if (exists) return;

        const addedArray = [...municipalityChips, municipality];
        setMunicipalityChips(addedArray);
        setSelectedMunicipality(municipality._id);
        break;
      case 'delete':
        const filteredArray = municipalityChips.filter((chip) => chip._id !== municipality._id);
        setMunicipalityChips(filteredArray);
        setSelectedMunicipality('choose');
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    setSelectedMunicipality('choose');
    setSelectedSkill('choose');
    setSelectedCategory('choose');
    setCategoryChips([]);
    setMunicipalityChips([]);
    setSkillChips([]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="categories"
              label={t('filter-interest')}
              selectLabel={t('chooseCategories')}
              handleChange={(e) => onCategoryChange(JSON.parse(e.target.value), 'add')}
              value={selectedCategory}
              data={
                categoriesResponse?.success && categoriesResponse.data?.categories?.docs
                  ? categoriesResponse.data.categories.docs
                  : []
              }
              valueChips={categoryChips}
              handleDelete={onCategoryChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="skills"
              label={t('filter-skills')}
              selectLabel={t('chooseSkills')}
              handleChange={(e) => onSkillChange(JSON.parse(e.target.value), 'add')}
              value={selectedSkill}
              data={skillsResponse?.success && skillsResponse.data?.skills?.docs ? skillsResponse.data.skills.docs : []}
              valueChips={skillChips}
              handleDelete={onSkillChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="municipalities"
              label={t('Komuna')}
              selectLabel={t('ChooseMunicipality')}
              handleChange={(e) => onMunicipalityChange(JSON.parse(e.target.value), 'add')}
              value={selectedMunicipality}
              data={
                municipalitiesResponse?.success && municipalitiesResponse.data?.municipalities?.docs
                  ? municipalitiesResponse.data.municipalities.docs
                  : []
              }
              valueChips={municipalityChips}
              handleDelete={onMunicipalityChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: { md: 3.5, lg: 3.6, xl: 3.6 } }}>
            <AllRoleButton
              label={t('resetFilters')}
              handleClick={() => resetFilters()}
              backgroundColor={'#F36E21'}
              icon={<RemoveCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
