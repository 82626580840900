// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All Red Flags.
export const GET_ALL_REDFLAGS_START = 'GET_ALL_REDFLAGS_START';
export const GET_ALL_REDFLAGS_SUCCESS = 'GET_ALL_REDFLAGS_SUCCESS';
export const GET_ALL_REDFLAGS_FAILED = 'GET_ALL_REDFLAGS_FAILED';
export const GET_ALL_REDFLAGS_RESET = 'GET_ALL_REDFLAGS_RESET';

// Action Types: Get One Red Flag.
export const GET_ONE_REDFLAG_START = 'GET_ONE_REDFLAG_START';
export const GET_ONE_REDFLAG_SUCCESS = 'GET_ONE_REDFLAG_SUCCESS';
export const GET_ONE_REDFLAG_FAILED = 'GET_ONE_REDFLAG_FAILED';
export const GET_ONE_REDFLAG_RESET = 'GET_ONE_REDFLAG_RESET';

// Action Types: Create Red Flag.
export const CREATE_REDFLAG_START = 'CREATE_REDFLAG_START';
export const CREATE_REDFLAG_SUCCESS = 'CREATE_REDFLAG_SUCCESS';
export const CREATE_REDFLAG_FAILED = 'CREATE_REDFLAG_FAILED';
export const CREATE_REDFLAG_RESET = 'CREATE_REDFLAG_RESET';

// Action Creators: Get All Red Flags.
const getAllRedFlagsStart = (payload) => ({
  type: GET_ALL_REDFLAGS_START,
  payload,
});
const getAllRedFlagsSuccess = (payload) => ({
  type: GET_ALL_REDFLAGS_SUCCESS,
  payload,
});
const getAllRedFlagsFailed = (payload) => ({
  type: GET_ALL_REDFLAGS_FAILED,
  payload,
});
const getAllRedFlagsReset = () => ({ type: GET_ALL_REDFLAGS_RESET });

// Action Creators: Get One Red Flag.
const getOneRedFlagStart = (payload) => ({
  type: GET_ONE_REDFLAG_START,
  payload,
});
const getOneRedFlagSuccess = (payload) => ({
  type: GET_ONE_REDFLAG_SUCCESS,
  payload,
});
const getOneRedFlagFailed = (payload) => ({
  type: GET_ONE_REDFLAG_FAILED,
  payload,
});
const getOneRedFlagReset = () => ({ type: GET_ONE_REDFLAG_RESET });

// Action Creators: Create Red Flag.
const createRedFlagStart = (payload) => ({
  type: CREATE_REDFLAG_START,
  payload,
});
const createRedFlagSuccess = (payload) => ({
  type: CREATE_REDFLAG_SUCCESS,
  payload,
});
const createRedFlagFailed = (payload) => ({
  type: CREATE_REDFLAG_FAILED,
  payload,
});
const createRedFlagReset = () => ({ type: CREATE_REDFLAG_RESET });

// Actions: Get All Red Flags.
export const getAllRedFlags = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllRedFlagsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, application, business, intern, mentor, mentorship } = payload;
      const result = await ApiClient.get('redflags', {
        params: {
          page,
          limit,
          pagination,
          application,
          business,
          intern,
          mentor,
          mentorship,
        },
      });
      if (result.data?.success) {
        const { redFlags } = result.data.data;
        dispatch(
          getAllRedFlagsSuccess({
            loading: false,
            success: true,
            data: { redFlags },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllRedFlagsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllRedFlagsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Red Flags.
export const clearGetAllRedFlags = () => getAllRedFlagsReset();

// Actions: Get One Red Flag.
export const getOneRedFlag = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneRedFlagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { redFlagId } = payload;
      const result = await ApiClient.get(`redflags/${redFlagId}`);
      if (result.data?.success) {
        const { redFlag } = result.data.data;
        dispatch(
          getOneRedFlagSuccess({
            loading: false,
            success: true,
            data: { redFlag },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneRedFlagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneRedFlagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Red Flag.
export const clearGetOneRedFlag = () => getOneRedFlagReset();

// Actions: Create Red Flag.
export const createRedFlag = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createRedFlagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { applicationId, mentorshipId, businessId, internId, mentorId, reasonText, reasons, emailLanguage } = payload;
    const {
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onNotFoundMessage,
      onCreationFailedMessage,
    } = options;

    try {
      const result = await ApiClient.post('redflags', {
        applicationId,
        mentorshipId,
        businessId,
        internId,
        mentorId,
        reasonText,
        reasons,
        emailLanguage,
      });
      if (result.data?.success) {
        const { redFlag } = result.data.data;
        dispatch(
          createRedFlagSuccess({
            loading: false,
            success: true,
            data: { redFlag },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        shouldRedirect && history.push(pathname);
      } else {
        dispatch(
          createRedFlagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createRedFlagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'NOT_FOUND'
      ) {
        toastNotification('error', onNotFoundMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'FAILED_CREATE'
      ) {
        toastNotification('error', onCreationFailedMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Red Flag.
export const clearCreateNewRedFlag = () => createRedFlagReset();
