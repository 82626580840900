import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Button, Grid, InputLabel, TextField, Select, MenuItem, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AllRoleButton from '../../../components/buttons/AllRoleButton';
import APIClient from '../../../services/ApiClient';
import MainCard from '../../../utils/MainCard';
import { toastNotification } from '../../../utils/toastNotification';
import { saveAs } from 'file-saver';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { FileDownloadOutlined } from '@mui/icons-material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const dateInput = {
  width: '100% !important',
  height: '41px !important',
  fontFamily: 'Mark-Medium !important',
  borderRadius: '6.88px !important',
  '& input::placeholder': {
    fontFamily: 'Mark-Medium !important',
    fontSize: '14px !important',
    lineHeight: '15px !important',
    alignItems: 'center !important',
  },
  '& legend': {
    display: 'none !important',
  },
  '& fieldset': {
    top: 0,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    borderRadius: '6.88px !important',
    height: '41px !important',
    fontFamily: 'Mark-Medium !important',
    fontSize: '14px !important',
    '& fieldset': {},
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#01486B !important',
    },
  },
};
const useStyles = makeStyles({
  selectInput: {
    borderRadius: '6.88px !important',
    height: '41px',
    '& legend': {
      display: 'none !important',
    },
    '& fieldset': {
      top: 0,
      //   borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#01486B !important',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Mark-Light !important',
      fontSize: '12px !important',
      padding: '16.5px 14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },
  dateInput: {
    width: '100% !important',
    height: '41px !important',
    borderWidth: '6px !important',
    fontFamily: 'Mark-Medium !important',
    borderRadius: '6.88px !important',
    '& input::placeholder': {
      fontFamily: 'Mark-Medium !important',
      fontSize: '12px !important',
      lineHeight: '15px !important',
      alignItems: 'center !important',
    },
    '& legend': {
      display: 'none !important',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '6.88px !important',
      '& fieldset': {},
      '&:hover fieldset': {
        borderColor: '#01486B !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },

  menuItem: {
    fontFamily: 'Mark-Regular !important',
    fontSize: '14px !important',
  },

  defaultInput: {
    width: '100%',
    height: '41px',
    borderWidth: '6px',
    fontFamily: 'Mark-Regular !important',
    borderRadius: '6.88px !important',
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
    '& input::placeholder': {
      fontFamily: 'Mark-Regular !important',
      fontSize: '12px !important',
      lineHeight: '15px !important',
      alignItems: 'center !important',
      color: 'black',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      height: '41px !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '6.88px !important',
      fontFamily: 'Mark-Regular !important',
      fontSize: '14px !important',
      paddingTop: '8px !important',
      color: '#707070',
      '& fieldset': {
        fontFamily: 'Mark-Regular !important',
        fontSize: '14px !important',
      },
      '&:hover fieldset': {
        borderColor: '#01486B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#01486B',
      },
    },
  },
  menuItem: {
    fontFamily: 'Mark-Regular !important',
    fontSize: '14px !important',
  },
});

export const Applications = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [internshipStartDate, setInternshipStartDate] = useState(null);
  const [selectedType, setSelectedType] = useState('ALL');
  const [selectedStatus, setSelectedStatus] = useState('choose');

  const { t } = useTranslation();
  const classes = useStyles();

  const onInternshipDateChange = (date) => {
    setInternshipStartDate(date);
    setStartDate(null);
    setEndDate(null);
  };

  const onDateChange = (date) => {
    setStartDate(date);
    setInternshipStartDate(null);
  };

  const onEndDateChange = (endDate) => {
    setEndDate(endDate);
    setInternshipStartDate(null);
  };

  const onStatusChange = (val) => {
    setSelectedStatus(val);
  };

  const resetFilters = () => {
    setSelectedType('ALL');
    setSelectedStatus('choose');
    setStartDate(null);
    setEndDate(null);
    setInternshipStartDate(null);
  };

  const onTypeChange = (value) => {
    setSelectedType(value);
  };

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (selectedType) payload['type'] = selectedType;
    if (selectedStatus !== 'choose') payload['status'] = selectedStatus;
    if (internshipStartDate) payload['hiredFrom'] = internshipStartDate;
    await APIClient.post(`/reports/export/applications`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Applications_List.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };
  return (
    <MainCard
      title={t('exportApplications')}
      sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important', mb: 2, width: '100%' }}
      secondary={
        <Button
          variant="contained"
          disabled={loadingButton === true ? true : false}
          sx={{
            backgroundColor: '#01486B !important',
            '&:hover': { backgroundColor: '#012b40 !important' },
            width: '160px',
            fontFamily: 'Mark-Regular',
            fontSize: '14px',
            textTransform: 'none !important',
          }}
          onClick={(e) => {
            onDocumentDownload(e);
          }}
        >
          {loadingButton ? (
            <>
              <CircularProgress size={24} style={{ color: '#fff' }} />
            </>
          ) : (
            <>
              <FileDownloadOutlined /> {t('exportExcel')}
            </>
          )}
        </Button>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('applicationType')}</InputLabel>
          <Select
            fullWidth
            id="type"
            name="age"
            value={selectedType}
            className={classes.selectInput}
            onChange={(e) => onTypeChange(e.target.value)}
          >
            <MenuItem value="ALL" className={classes.menuItem}>
              {t('all')}
            </MenuItem>
            <MenuItem value="JOB" className={classes.menuItem}>
              {t('intershipApplications')}
            </MenuItem>
            <MenuItem value="TRAINING" className={classes.menuItem}>
              {t('trainingApplications')}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>Status</InputLabel>
          <Select
            fullWidth
            id="type"
            name="age"
            value={selectedStatus}
            className={classes.selectInput}
            onChange={(e) => onStatusChange(e.target.value)}
          >
            <MenuItem value="choose" disabled className={classes.menuItem}>
              Status
            </MenuItem>

            <MenuItem value={'ACCEPTED_FOR_VALIDATION'} className={classes.menuItem}>
              {t('acceptedForValidaion')}
            </MenuItem>
            <MenuItem value={'APPROVED'} className={classes.menuItem}>
              {t('approved')}
            </MenuItem>
            <MenuItem value={'HIRED'} className={classes.menuItem}>
              {t('hired')}
            </MenuItem>
            <MenuItem value={'EXTENDED'} className={classes.menuItem}>
              {t('extended')}
            </MenuItem>
            <MenuItem value={'COMPLETED'} className={classes.menuItem}>
              {t('completed')}
            </MenuItem>
          </Select>
        </Grid>
        {selectedStatus === 'APPROVED' ? (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('internshipStartDate')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                renderInput={(props) => <TextField sx={dateInput} fullWidth {...props} helperText="" />}
                value={internshipStartDate}
                onChange={(e) => onInternshipDateChange(e)}
              />
            </LocalizationProvider>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('startDate')}</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField sx={dateInput} fullWidth {...props} helperText="" />}
                  value={startDate}
                  onChange={(e) => onDateChange(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('endDate')}</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField sx={dateInput} fullWidth {...props} helperText="" />}
                  value={endDate}
                  onChange={(e) => onEndDateChange(e)}
                />
              </LocalizationProvider>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ mt: selectedStatus === 'APPROVED' && { md: 3.5, lg: 3.6, xl: 3.6 } }}
        >
          <AllRoleButton
            label={t('resetFilters')}
            handleClick={() => resetFilters()}
            backgroundColor={'#F36E21'}
            icon={<RemoveCircleOutlineIcon />}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
