import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translationEN.json';
import translationSQ from './locales/sq/translationSQ.json';
import translationSR from './locales/sr/translationSR.json';

const defaultLanguage = 'sq';

const resources = {
  en: {
    translation: translationEN,
  },
  sq: {
    translation: translationSQ,
  },
  sr: {
    translation: translationSR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: !defaultLanguage ? 'sq' : defaultLanguage,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
