// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Skills.
export const GET_ALL_SKILLS_START = 'GET_ALL_SKILLS_START';
export const GET_ALL_SKILLS_SUCCESS = 'GET_ALL_SKILLS_SUCCESS';
export const GET_ALL_SKILLS_FAILED = 'GET_ALL_SKILLS_FAILED';
export const GET_ALL_SKILLS_RESET = 'GET_ALL_SKILLS_RESET';

// Action Types: Get One Skill.
export const GET_ONE_SKILL_START = 'GET_ONE_SKILL_START';
export const GET_ONE_SKILL_SUCCESS = 'GET_ONE_SKILL_SUCCESS';
export const GET_ONE_SKILL_FAILED = 'GET_ONE_SKILL_FAILED';
export const GET_ONE_SKILL_RESET = 'GET_ONE_SKILL_RESET';

// Action Types: Create New Skill.
export const CREATE_SKILL_START = 'CREATE_SKILL_START';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_FAILED = 'CREATE_SKILL_FAILED';
export const CREATE_SKILL_RESET = 'CREATE_SKILL_RESET';

// Action Types: Update One Skill.
export const UPDATE_ONE_SKILL_START = 'UPDATE_ONE_SKILL_START';
export const UPDATE_ONE_SKILL_SUCCESS = 'UPDATE_ONE_SKILL_SUCCESS';
export const UPDATE_ONE_SKILL_FAILED = 'UPDATE_ONE_SKILL_FAILED';
export const UPDATE_ONE_SKILL_RESET = 'UPDATE_ONE_SKILL_RESET';

// Action Types: Delete One Skill.
export const DELETE_ONE_SKILL_START = 'DELETE_ONE_SKILL_START';
export const DELETE_ONE_SKILL_SUCCESS = 'DELETE_ONE_SKILL_SUCCESS';
export const DELETE_ONE_SKILL_FAILED = 'DELETE_ONE_SKILL_FAILED';
export const DELETE_ONE_SKILL_RESET = 'DELETE_ONE_SKILL_RESET';

// Action Creators: Get All Skills.
const getAllSkillsStart = (payload) => ({
  type: GET_ALL_SKILLS_START,
  payload,
});
const getAllSkillsSuccess = (payload) => ({
  type: GET_ALL_SKILLS_SUCCESS,
  payload,
});
const getAllSkillsFailed = (payload) => ({
  type: GET_ALL_SKILLS_FAILED,
  payload,
});
const getAllSkillsReset = () => ({ type: GET_ALL_SKILLS_RESET });

// Action Creators: Get One Skill.
const getOneSkillStart = (payload) => ({ type: GET_ONE_SKILL_START, payload });
const getOneSkillSuccess = (payload) => ({
  type: GET_ONE_SKILL_SUCCESS,
  payload,
});
const getOneSkillFailed = (payload) => ({
  type: GET_ONE_SKILL_FAILED,
  payload,
});
const getOneSkillReset = () => ({ type: GET_ONE_SKILL_RESET });

// Action Creators: Create New Skill.
const createSkillStart = (payload) => ({ type: CREATE_SKILL_START, payload });
const createSkillSuccess = (payload) => ({
  type: CREATE_SKILL_SUCCESS,
  payload,
});
const createSkillFailed = (payload) => ({ type: CREATE_SKILL_FAILED, payload });
const createSkillReset = () => ({ type: CREATE_SKILL_RESET });

// Action Creators: Update One Skill.
const updateOneSkillStart = (payload) => ({
  type: UPDATE_ONE_SKILL_START,
  payload,
});
const updateOneSkillSuccess = (payload) => ({
  type: UPDATE_ONE_SKILL_SUCCESS,
  payload,
});
const updateOneSkillFailed = (payload) => ({
  type: UPDATE_ONE_SKILL_FAILED,
  payload,
});
const updateOneSkillReset = () => ({ type: UPDATE_ONE_SKILL_RESET });

// Action Creators: Delete One Skill.
const deleteOneSkillStart = (payload) => ({
  type: DELETE_ONE_SKILL_START,
  payload,
});
const deleteOneSkillSuccess = (payload) => ({
  type: DELETE_ONE_SKILL_SUCCESS,
  payload,
});
const deleteOneSkillFailed = (payload) => ({
  type: DELETE_ONE_SKILL_FAILED,
  payload,
});
const deleteOneSkillReset = () => ({ type: DELETE_ONE_SKILL_RESET });

// Actions: Get All Skills.
export const getAllSkills = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllSkillsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('skills', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { skills } = result.data.data;
        dispatch(
          getAllSkillsSuccess({
            loading: false,
            success: true,
            data: { skills },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllSkillsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllSkillsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Skills
export const clearGetAllSkills = () => getAllSkillsReset();

// Actions: Get One Skill.
export const getOneSkill = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneSkillStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { skillId } = payload;
      const result = await ApiClient.get(`skills/${skillId}`);
      if (result.data?.success) {
        const { skill } = result.data.data;
        dispatch(
          getOneSkillSuccess({
            loading: false,
            success: true,
            data: { skill },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneSkillFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneSkillFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Skill
export const clearGetOneSkill = () => getOneSkillReset();

// Actions: Create New Skill.
export const createSkill = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createSkillStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onSkillExistsMessage } = options;

    try {
      const result = await ApiClient.post('skills', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { skill } = result.data.data;
        dispatch(
          createSkillSuccess({
            loading: false,
            success: true,
            data: { skill },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createSkillFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createSkillFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const skillExists = 'SKILL_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === skillExists
      ) {
        toastNotification('error', onSkillExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create Skill
export const clearCreateSkill = () => createSkillReset();

// Actions: Update One Skill.
export const updateSkill = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneSkillStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { skillId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`skills/${skillId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { skill } = result.data.data;
        dispatch(
          updateOneSkillSuccess({
            loading: false,
            success: true,
            data: { skill },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneSkillFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneSkillFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create One Skill
export const clearUpdateOneSkill = () => updateOneSkillReset();

// Actions: Delete One Skill.
export const deleteSkill = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneSkillStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { skillId } = payload;
      const result = await ApiClient.delete(`skills/${skillId}`);
      if (result.data?.success) {
        const { skill } = result.data.data;
        dispatch(
          deleteOneSkillSuccess({
            loading: false,
            success: true,
            data: { skill },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneSkillFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneSkillFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Skill
export const clearDeleteOneSkill = () => deleteOneSkillReset();
