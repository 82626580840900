// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Health Conditions.
export const GET_ALL_HEALTH_CONDITIONS_START = 'GET_ALL_HEALTH_CONDITIONS_START';
export const GET_ALL_HEALTH_CONDITIONS_SUCCESS = 'GET_ALL_HEALTH_CONDITIONS_SUCCESS';
export const GET_ALL_HEALTH_CONDITIONS_FAILED = 'GET_ALL_HEALTH_CONDITIONS_FAILED';
export const GET_ALL_HEALTH_CONDITIONS_RESET = 'GET_ALL_HEALTH_CONDITIONS_RESET';

// Action Types: Get One Health Condition.
export const GET_ONE_HEALTH_CONDITION_START = 'GET_ONE_HEALTH_CONDITION_START';
export const GET_ONE_HEALTH_CONDITION_SUCCESS = 'GET_ONE_HEALTH_CONDITION_SUCCESS';
export const GET_ONE_HEALTH_CONDITION_FAILED = 'GET_ONE_HEALTH_CONDITION_FAILED';
export const GET_ONE_HEALTH_CONDITION_RESET = 'GET_ONE_HEALTH_CONDITION_RESET';

// Action Types: Create New Health Condition.
export const CREATE_HEALTH_CONDITION_START = 'CREATE_HEALTH_CONDITION_START';
export const CREATE_HEALTH_CONDITION_SUCCESS = 'CREATE_HEALTH_CONDITION_SUCCESS';
export const CREATE_HEALTH_CONDITION_FAILED = 'CREATE_HEALTH_CONDITION_FAILED';
export const CREATE_HEALTH_CONDITION_RESET = 'CREATE_HEALTH_CONDITION_RESET';

// Action Types: Update One Health Condition.
export const UPDATE_ONE_HEALTH_CONDITION_START = 'UPDATE_ONE_HEALTH_CONDITION_START';
export const UPDATE_ONE_HEALTH_CONDITION_SUCCESS = 'UPDATE_ONE_HEALTH_CONDITION_SUCCESS';
export const UPDATE_ONE_HEALTH_CONDITION_FAILED = 'UPDATE_ONE_HEALTH_CONDITION_FAILED';
export const UPDATE_ONE_HEALTH_CONDITION_RESET = 'UPDATE_ONE_HEALTH_CONDITION_RESET';

// Action Types: Delete One Health Condition.
export const DELETE_ONE_HEALTH_CONDITION_START = 'DELETE_ONE_HEALTH_CONDITION_START';
export const DELETE_ONE_HEALTH_CONDITION_SUCCESS = 'DELETE_ONE_HEALTH_CONDITION_SUCCESS';
export const DELETE_ONE_HEALTH_CONDITION_FAILED = 'DELETE_ONE_HEALTH_CONDITION_FAILED';
export const DELETE_ONE_HEALTH_CONDITION_RESET = 'DELETE_ONE_HEALTH_CONDITION_RESET';

// Action Creators: Get All Health Conditions.
const getAllHealthConditionsStart = (payload) => ({
  type: GET_ALL_HEALTH_CONDITIONS_START,
  payload,
});
const getAllHealthConditionsSuccess = (payload) => ({
  type: GET_ALL_HEALTH_CONDITIONS_SUCCESS,
  payload,
});
const getAllHealthConditionsFailed = (payload) => ({
  type: GET_ALL_HEALTH_CONDITIONS_FAILED,
  payload,
});
const getAllHealthConditionsReset = () => ({ type: GET_ALL_HEALTH_CONDITIONS_RESET });

// Action Creators: Get One Health Condition.
const getOneHealthConditionStart = (payload) => ({
  type: GET_ONE_HEALTH_CONDITION_START,
  payload,
});
const getOneHealthConditionSuccess = (payload) => ({
  type: GET_ONE_HEALTH_CONDITION_SUCCESS,
  payload,
});
const getOneHealthConditionFailed = (payload) => ({
  type: GET_ONE_HEALTH_CONDITION_FAILED,
  payload,
});
const getOneHealthConditionReset = () => ({ type: GET_ONE_HEALTH_CONDITION_RESET });

// Action Creators: Create New Health Condition.
const createHealthConditionStart = (payload) => ({
  type: CREATE_HEALTH_CONDITION_START,
  payload,
});
const createHealthConditionSuccess = (payload) => ({
  type: CREATE_HEALTH_CONDITION_SUCCESS,
  payload,
});
const createHealthConditionFailed = (payload) => ({
  type: CREATE_HEALTH_CONDITION_FAILED,
  payload,
});
const createHealthConditionReset = () => ({ type: CREATE_HEALTH_CONDITION_RESET });

// Action Creators: Update One Health Condition.
const updateOneHealthConditionStart = (payload) => ({
  type: UPDATE_ONE_HEALTH_CONDITION_START,
  payload,
});
const updateOneHealthConditionSuccess = (payload) => ({
  type: UPDATE_ONE_HEALTH_CONDITION_SUCCESS,
  payload,
});
const updateOneHealthConditionFailed = (payload) => ({
  type: UPDATE_ONE_HEALTH_CONDITION_FAILED,
  payload,
});
const updateOneHealthConditionReset = () => ({ type: UPDATE_ONE_HEALTH_CONDITION_RESET });

// Action Creators: Delete One Health Condition.
const deleteOneHealthConditionStart = (payload) => ({
  type: DELETE_ONE_HEALTH_CONDITION_START,
  payload,
});
const deleteOneHealthConditionSuccess = (payload) => ({
  type: DELETE_ONE_HEALTH_CONDITION_SUCCESS,
  payload,
});
const deleteOneHealthConditionFailed = (payload) => ({
  type: DELETE_ONE_HEALTH_CONDITION_FAILED,
  payload,
});
const deleteOneHealthConditionReset = () => ({ type: DELETE_ONE_HEALTH_CONDITION_RESET });

// Actions: Get All Health Conditions.
export const getAllHealthConditions = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllHealthConditionsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('healthconditions', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { healthconditions } = result.data.data;
        dispatch(
          getAllHealthConditionsSuccess({
            loading: false,
            success: true,
            data: { healthconditions },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllHealthConditionsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllHealthConditionsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Health Conditions.
export const clearGetAllHealthConditions = () => getAllHealthConditionsReset();

// Actions: Get One Health Condition.
export const getOneHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { healthConditionId } = payload;
      const result = await ApiClient.get(`healthconditions/${healthConditionId}`);
      if (result.data?.success) {
        const { healthCondition } = result.data.data;
        dispatch(
          getOneHealthConditionSuccess({
            loading: false,
            success: true,
            data: { healthCondition },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Health Condition.
export const clearGetOneHealthCondition = () => getOneHealthConditionReset();

// Actions: Create New Health Condition.
export const createHealthCondition = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onHealthConditionExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('healthconditions', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { healthCondition } = result.data.data;
        dispatch(
          createHealthConditionSuccess({
            loading: false,
            success: true,
            data: { healthCondition },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'HEALTH_CONDITION_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onHealthConditionExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Health Condition.
export const clearCreateNewHealthCondition = () => createHealthConditionReset();

// Actions: Update One Health Condition.
export const updateOneHealthCondition = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { healthConditionId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`healthconditions/${healthConditionId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { healthCondition } = result.data.data;
        dispatch(
          updateOneHealthConditionSuccess({
            loading: false,
            success: true,
            data: { healthCondition },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Health Condition.
export const clearUpdateOneHealthCondition = () => updateOneHealthConditionReset();

// Actions: Delete One Health Condition.
export const deleteOneHealthCondition = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneHealthConditionStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { healthConditionId } = payload;
      const result = await ApiClient.delete(`healthconditions/${healthConditionId}`);
      if (result.data?.success) {
        const { healthCondition } = result.data.data;
        dispatch(
          deleteOneHealthConditionSuccess({
            loading: false,
            success: true,
            data: { healthCondition },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneHealthConditionFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneHealthConditionFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Health Condition.
export const cleareDeleteOneHealthCondition = () => deleteOneHealthConditionReset();
