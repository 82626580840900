import React from 'react';
import { Button } from '@mui/material';
import { useStyles } from './ButtonStyle';

const InternsButton = ({ backgroundColor, label, icon, handleClick, disabled, customWidth }) => {
  const classes = useStyles();
  return (
    <Button
      type="button"
      variant="contained"
      style={{ backgroundColor }}
      className={classes.internsButton}
      onClick={handleClick}
      disabled={disabled}
      startIcon={icon}
      sx={{ width: customWidth ? `${customWidth} !important` : '100% !important' }}
    >
      {label}
    </Button>
  );
};

export default InternsButton;
