// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Resources.
export const GET_ALL_RESOURCES_START = 'GET_ALL_RESOURCES_START';
export const GET_ALL_RESOURCES_SUCCESS = 'GET_ALL_RESOURCES_SUCCESS';
export const GET_ALL_RESOURCES_FAILED = 'GET_ALL_RESOURCES_FAILED';
export const GET_ALL_RESOURCES_RESET = 'GET_ALL_RESOURCES_RESET';

// Action Types: Get One Resource.
export const GET_ONE_RESOURCE_START = 'GET_ONE_RESOURCE_START';
export const GET_ONE_RESOURCE_SUCCESS = 'GET_ONE_RESOURCE_SUCCESS';
export const GET_ONE_RESOURCE_FAILED = 'GET_ONE_RESOURCE_FAILED';
export const GET_ONE_RESOURCE_RESET = 'GET_ONE_RESOURCE_RESET';

// Action Types: Create New Resource.
export const CREATE_RESOURCE_START = 'CREATE_RESOURCE_START';
export const CREATE_RESOURCE_SUCCESS = 'CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_FAILED = 'CREATE_RESOURCE_FAILED';
export const CREATE_RESOURCE_RESET = 'CREATE_RESOURCE_RESET';

// Action Types: Update One Resource.
export const UPDATE_ONE_RESOURCE_START = 'UPDATE_ONE_RESOURCE_START';
export const UPDATE_ONE_RESOURCE_SUCCESS = 'UPDATE_ONE_RESOURCE_SUCCESS';
export const UPDATE_ONE_RESOURCE_FAILED = 'UPDATE_ONE_RESOURCE_FAILED';
export const UPDATE_ONE_RESOURCE_RESET = 'UPDATE_ONE_RESOURCE_RESET';

// Action Types: Delete One Resource.
export const DELETE_ONE_RESOURCE_START = 'DELETE_ONE_RESOURCE_START';
export const DELETE_ONE_RESOURCE_SUCCESS = 'DELETE_ONE_RESOURCE_SUCCESS';
export const DELETE_ONE_RESOURCE_FAILED = 'DELETE_ONE_RESOURCE_FAILED';
export const DELETE_ONE_RESOURCE_RESET = 'DELETE_ONE_RESOURCE_RESET';

// Action Creators: Get All Resources.
const getAllResourcesStart = (payload) => ({
  type: GET_ALL_RESOURCES_START,
  payload,
});
const getAllResourcesSuccess = (payload) => ({
  type: GET_ALL_RESOURCES_SUCCESS,
  payload,
});
const getAllResourcesFailed = (payload) => ({
  type: GET_ALL_RESOURCES_FAILED,
  payload,
});
const getAllResourcesReset = () => ({ type: GET_ALL_RESOURCES_RESET });

// Action Creators: Get One Resource.
const getOneResourceStart = (payload) => ({
  type: GET_ONE_RESOURCE_START,
  payload,
});
const getOneResourceSuccess = (payload) => ({
  type: GET_ONE_RESOURCE_SUCCESS,
  payload,
});
const getOneResourceFailed = (payload) => ({
  type: GET_ONE_RESOURCE_FAILED,
  payload,
});
const getOneResourceReset = () => ({ type: GET_ONE_RESOURCE_RESET });

// Action Creators: Create New Resource.
const createResourceStart = (payload) => ({
  type: CREATE_RESOURCE_START,
  payload,
});
const createResourceSuccess = (payload) => ({
  type: CREATE_RESOURCE_SUCCESS,
  payload,
});
const createResourceFailed = (payload) => ({
  type: CREATE_RESOURCE_FAILED,
  payload,
});
const createResourceReset = () => ({ type: CREATE_RESOURCE_RESET });

// Action Creators: Update One Resource.
const updateOneResourceStart = (payload) => ({
  type: UPDATE_ONE_RESOURCE_START,
  payload,
});
const updateOneResourceSuccess = (payload) => ({
  type: UPDATE_ONE_RESOURCE_SUCCESS,
  payload,
});
const updateOneResourceFailed = (payload) => ({
  type: UPDATE_ONE_RESOURCE_FAILED,
  payload,
});
const updateOneResourceReset = () => ({ type: UPDATE_ONE_RESOURCE_RESET });

// Action Creators: Delete One Resource.
const deleteOneResourceStart = (payload) => ({
  type: DELETE_ONE_RESOURCE_START,
  payload,
});
const deleteOneResourceSuccess = (payload) => ({
  type: DELETE_ONE_RESOURCE_SUCCESS,
  payload,
});
const deleteOneResourceFailed = (payload) => ({
  type: DELETE_ONE_RESOURCE_FAILED,
  payload,
});
const deleteOneResourceReset = () => ({ type: DELETE_ONE_RESOURCE_RESET });

// Actions: Get All Resources.
export const getAllResources = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllResourcesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, category, subcategory, name, tags, language } = payload;
      const result = await ApiClient.get('resources', {
        params: { page, limit, pagination, category, subcategory, name, tags, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { resources } = result.data.data;
        dispatch(
          getAllResourcesSuccess({
            loading: false,
            success: true,
            data: { resources },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllResourcesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllResourcesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Resources.
export const clearGetAllResources = () => getAllResourcesReset();

// Actions: Get One Resource.
export const getOneResource = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneResourceStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { resourceId } = payload;
      const result = await ApiClient.get(`resources/${resourceId}`);
      if (result.data?.success) {
        const { resource } = result.data.data;
        dispatch(
          getOneResourceSuccess({
            loading: false,
            success: true,
            data: { resource },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneResourceFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneResourceFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Resource.
export const clearGetOneResource = () => getOneResourceReset();

// Actions: Create New Resource.
export const createResource = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createResourceStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onResourceExistsMessage } = options;

    try {
      const result = await ApiClient.post('resources', formData);
      if (result.data?.success) {
        const { resource } = result.data.data;
        dispatch(
          createResourceSuccess({
            loading: false,
            success: true,
            data: { resource },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createResourceFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createResourceFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'RESOURCE_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onResourceExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Resource.
export const clearCreateNewResource = () => createResourceReset();

// Actions: Update One Resource.
export const updateOneResource = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneResourceStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { resourceId, formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`resources/${resourceId}`, formData);
      if (result.data?.success) {
        const { resource } = result.data.data;
        dispatch(
          updateOneResourceSuccess({
            loading: false,
            success: true,
            data: { resource },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneResourceFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneResourceFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Resource.
export const clearUpdateOneResource = () => updateOneResourceReset();

// Actions: Delete One Resource.
export const deleteOneResource = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneResourceStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { resourceId } = payload;
      const result = await ApiClient.delete(`resources/${resourceId}`);
      if (result.data?.success) {
        const { resource } = result.data.data;
        dispatch(
          deleteOneResourceSuccess({
            loading: false,
            success: true,
            data: { resource },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneResourceFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneResourceFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Resource.
export const cleareDeleteOneResource = () => deleteOneResourceReset();
