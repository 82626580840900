// Imports: local files.
import ApiClient from '../../services/ApiClient';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

// Action Types: Get All Businesses.
export const GET_ALL_BUSINESSES_START = 'GET_ALL_BUSINESSES_START';
export const GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS';
export const GET_ALL_BUSINESSES_FAILED = 'GET_ALL_BUSINESSES_FAILED';
export const GET_ALL_BUSINESSES_RESET = 'GET_ALL_BUSINESSES_RESET';

// Action Types: Get All Admins.
export const GET_ALL_ADMINS_START = 'GET_ALL_ADMINS_START';
export const GET_ALL_ADMINS_SUCCESS = 'GET_ALL_ADMINS_SUCCESS';
export const GET_ALL_ADMINS_FAILED = 'GET_ALL_ADMINS_FAILED';
export const GET_ALL_ADMINS_RESET = 'GET_ALL_ADMINS_RESET';

// Action Types: Get One Business.
export const GET_ONE_BUSINESS_START = 'GET_ONE_BUSINESS_START';
export const GET_ONE_BUSINESS_SUCCESS = 'GET_ONE_BUSINESS_SUCCESS';
export const GET_ONE_BUSINESS_FAILED = 'GET_ONE_BUSINESS_FAILED';
export const GET_ONE_BUSINESS_RESET = 'GET_ONE_BUSINESS_RESET';

// Action Types: Update One Business.
export const UPDATE_ONE_BUSINESS_START = 'UPDATE_ONE_BUSINESS_START';
export const UPDATE_ONE_BUSINESS_SUCCESS = 'UPDATE_ONE_BUSINESS_SUCCESS';
export const UPDATE_ONE_BUSINESS_FAILED = 'UPDATE_ONE_BUSINESS_FAILED';
export const UPDATE_ONE_BUSINESS_RESET = 'UPDATE_ONE_BUSINESS_RESET';

// Action Types: Upload Logo Business.
export const UPLOAD_LOGO_BUSINESS_START = 'UPLOAD_LOGO_BUSINESS_START';
export const UPLOAD_LOGO_BUSINESS_SUCCESS = 'UPLOAD_LOGO_BUSINESS_SUCCESS';
export const UPLOAD_LOGO_BUSINESS_FAILED = 'UPLOAD_LOGO_BUSINESS_FAILED';
export const UPLOAD_LOGO_BUSINESS_RESET = 'UPLOAD_LOGO_BUSINESS_RESET';

// Action Types: Upload Intern Contract.
export const UPLOAD_INTERN_CONTRACT_START = 'UPLOAD_INTERN_CONTRACT_START';
export const UPLOAD_INTERN_CONTRACT_SUCCESS = 'UPLOAD_INTERN_CONTRACT_SUCCESS';
export const UPLOAD_INTERN_CONTRACT_FAILED = 'UPLOAD_INTERN_CONTRACT_FAILED';
export const UPLOAD_INTERN_CONTRACT_RESET = 'UPLOAD_INTERN_CONTRACT_RESET';

// Action Types: Delete One Business.
export const DELETE_ONE_BUSINESS_START = 'DELETE_ONE_BUSINESS_START';
export const DELETE_ONE_BUSINESS_SUCCESS = 'DELETE_ONE_BUSINESS_SUCCESS';
export const DELETE_ONE_BUSINESS_FAILED = 'DELETE_ONE_BUSINESS_FAILED';
export const DELETE_ONE_BUSINESS_RESET = 'DELETE_ONE_BUSINESS_RESET';

// Action Types: Sign Up Business.
export const SIGN_UP_BUSINESS_START = 'SIGN_UP_BUSINESS_START';
export const SIGN_UP_BUSINESS_SUCCESS = 'SIGN_UP_BUSINESS_SUCCESS';
export const SIGN_UP_BUSINESS_FAILED = 'SIGN_UP_BUSINESS_FAILED';
export const SIGN_UP_BUSINESS_RESET = 'SIGN_UP_BUSINESS_RESET';

// Action Types: Login Business.
export const LOGIN_BUSINESS_START = 'LOGIN_BUSINESS_START';
export const LOGIN_BUSINESS_SUCCESS = 'LOGIN_BUSINESS_SUCCESS';
export const LOGIN_BUSINESS_FAILED = 'LOGIN_BUSINESS_FAILED';
export const LOGIN_BUSINESS_RESET = 'LOGIN_BUSINESS_RESET';

// Action Types: Forgot Password Business.
export const FORGOT_PASS_BUSINESS_START = 'FORGOT_PASS_BUSINESS_START';
export const FORGOT_PASS_BUSINESS_SUCCESS = 'FORGOT_PASS_BUSINESS_SUCCESS';
export const FORGOT_PASS_BUSINESS_FAILED = 'FORGOT_PASS_BUSINESS_FAILED';
export const FORGOT_PASS_BUSINESS_RESET = 'FORGOT_PASS_BUSINESS_RESET';

// Action Types: Reset Password Business.
export const RESET_PASS_BUSINESS_START = 'RESET_PASS_BUSINESS_START';
export const RESET_PASS_BUSINESS_SUCCESS = 'RESET_PASS_BUSINESS_SUCCESS';
export const RESET_PASS_BUSINESS_FAILED = 'RESET_PASS_BUSINESS_FAILED';
export const RESET_PASS_BUSINESS_RESET = 'RESET_PASS_BUSINESS_RESET';

// Action Types: Confirm Business
export const CONFIRM_BUSINESS_START = 'CONFIRM_BUSINESS_START';
export const CONFIRM_BUSINESS_SUCCESS = 'CONFIRM_BUSINESS_SUCCESS';
export const CONFIRM_BUSINESS_FAILED = 'CONFIRM_BUSINESS_FAILED';
export const CONFIRM_BUSINESS_RESET = 'CONFIRM_BUSINESS_RESET';

// Action Types: Resend Business Email
export const RESEND_BUSINESS_EMAIL_START = 'RESEND_BUSINESS_EMAIL_START';
export const RESEND_BUSINESS_EMAIL_SUCCESS = 'RESEND_BUSINESS_EMAIL_SUCCESS';
export const RESEND_BUSINESS_EMAIL_FAILED = 'RESEND_BUSINESS_EMAIL_FAILED';
export const RESEND_BUSINESS_EMAIL_RESET = 'RESEND_BUSINESS_EMAIL_RESET';

// Action Types: Update Business Survey.
export const UPDATE_BUSINESS_SURVEY_START = 'UPDATE_BUSINESS_SURVEY_START';
export const UPDATE_BUSINESS_SURVEY_SUCCESS = 'UPDATE_BUSINESS_SURVEY_SUCCESS';
export const UPDATE_BUSINESS_SURVEY_FAILED = 'UPDATE_BUSINESS_SURVEY_FAILED';
export const UPDATE_BUSINESS_SURVEY_RESET = 'UPDATE_BUSINESS_SURVEY_RESET';

// Action Creators: Get All Businesses.
const getAllBusinessesStart = (payload) => ({
  type: GET_ALL_BUSINESSES_START,
  payload,
});
const getAllBusinessesSuccess = (payload) => ({
  type: GET_ALL_BUSINESSES_SUCCESS,
  payload,
});
const getAllBusinessesFailed = (payload) => ({
  type: GET_ALL_BUSINESSES_FAILED,
  payload,
});
const getAllBusinessesReset = () => ({ type: GET_ALL_BUSINESSES_RESET });

// Action Creators: Get All Admins.
const getAllAdminsStart = (payload) => ({
  type: GET_ALL_ADMINS_START,
  payload,
});
const getAllAdminsSuccess = (payload) => ({
  type: GET_ALL_ADMINS_SUCCESS,
  payload,
});
const getAllAdminsFailed = (payload) => ({
  type: GET_ALL_ADMINS_FAILED,
  payload,
});
const getAllAdminsReset = () => ({ type: GET_ALL_ADMINS_RESET });

// Action Creators: Get One Business.
const getOneBusinessStart = (payload) => ({
  type: GET_ONE_BUSINESS_START,
  payload,
});
const getOneBusinessSuccess = (payload) => ({
  type: GET_ONE_BUSINESS_SUCCESS,
  payload,
});
const getOneBusinessFailed = (payload) => ({
  type: GET_ONE_BUSINESS_FAILED,
  payload,
});
const getOneBusinessReset = () => ({ type: GET_ONE_BUSINESS_RESET });

// Action Creators: Update One Business.
const updateOneBusinessStart = (payload) => ({
  type: UPDATE_ONE_BUSINESS_SUCCESS,
  payload,
});
const updateOneBusinessSuccess = (payload) => ({
  type: UPDATE_ONE_BUSINESS_START,
  payload,
});
const updateOneBusinessFailed = (payload) => ({
  type: UPDATE_ONE_BUSINESS_FAILED,
  payload,
});
const updateOneBusinessReset = () => ({ type: UPDATE_ONE_BUSINESS_RESET });

// Action Creators: Upload Logo Business.
const uploadLogoBusinessStart = (payload) => ({
  type: UPLOAD_LOGO_BUSINESS_START,
  payload,
});
const uploadLogoBusinessSuccess = (payload) => ({
  type: UPLOAD_LOGO_BUSINESS_SUCCESS,
  payload,
});
const uploadLogoBusinessFailed = (payload) => ({
  type: UPLOAD_LOGO_BUSINESS_FAILED,
  payload,
});
const uploadLogoBusinessReset = () => ({ type: UPLOAD_LOGO_BUSINESS_RESET });

// Action Creators: Upload Intern Contract.
const uploadInternContractStart = (payload) => ({
  type: UPLOAD_INTERN_CONTRACT_START,
  payload,
});
const uploadInternContractSuccess = (payload) => ({
  type: UPLOAD_INTERN_CONTRACT_SUCCESS,
  payload,
});
const uploadInternContractFailed = (payload) => ({
  type: UPLOAD_INTERN_CONTRACT_FAILED,
  payload,
});
const uploadInternContractReset = () => ({ type: UPLOAD_INTERN_CONTRACT_RESET });

// Action Creators: Delete One Business.
const deleteOneBusinessStart = (payload) => ({
  type: DELETE_ONE_BUSINESS_START,
  payload,
});
const deleteOneBusinessSuccess = (payload) => ({
  type: DELETE_ONE_BUSINESS_SUCCESS,
  payload,
});
const deleteOneBusinessFailed = (payload) => ({
  type: DELETE_ONE_BUSINESS_FAILED,
  payload,
});
const deleteOneBusinessReset = () => ({ type: DELETE_ONE_BUSINESS_RESET });

// Action Creators: Sing Up Business.
const signUpBusinessStart = (payload) => ({
  type: SIGN_UP_BUSINESS_START,
  payload,
});
const signUpBusinessSuccess = (payload) => ({
  type: SIGN_UP_BUSINESS_SUCCESS,
  payload,
});
const signUpBusinessFailed = (payload) => ({
  type: SIGN_UP_BUSINESS_FAILED,
  payload,
});
const signUpBusinessReset = () => ({ type: SIGN_UP_BUSINESS_RESET });

// Action Creators: Login Business.
const loginBusinessStart = (payload) => ({
  type: LOGIN_BUSINESS_START,
  payload,
});
const loginBusinessSuccess = (payload) => ({
  type: LOGIN_BUSINESS_SUCCESS,
  payload,
});
const loginBusinessFailed = (payload) => ({
  type: LOGIN_BUSINESS_FAILED,
  payload,
});
const loginBusinessReset = () => ({ type: LOGIN_BUSINESS_RESET });

// Action Creators: Forgot Password Business.
const forgotPassBusinessStart = (payload) => ({
  type: FORGOT_PASS_BUSINESS_START,
  payload,
});
const forgotPassBusinessSuccess = (payload) => ({
  type: FORGOT_PASS_BUSINESS_SUCCESS,
  payload,
});
const forgotPassBusinessFailed = (payload) => ({
  type: FORGOT_PASS_BUSINESS_FAILED,
  payload,
});
const forgotPassBusinessReset = () => ({ type: FORGOT_PASS_BUSINESS_RESET });

// Action Creators: Reset Password Business.
const resetPassBusinessStart = (payload) => ({
  type: RESET_PASS_BUSINESS_START,
  payload,
});
const resetPassBusinessSuccess = (payload) => ({
  type: RESET_PASS_BUSINESS_SUCCESS,
  payload,
});
const resetPassBusinessFailed = (payload) => ({
  type: RESET_PASS_BUSINESS_FAILED,
  payload,
});
const resetPassBusinessReset = () => ({ type: RESET_PASS_BUSINESS_RESET });

// Action Creators: Confirm Business.
const confirmBusinessStart = (payload) => ({
  type: CONFIRM_BUSINESS_START,
  payload,
});
const confirmBusinessSuccess = (payload) => ({
  type: CONFIRM_BUSINESS_SUCCESS,
  payload,
});
const confirmBusinessFailed = (payload) => ({
  type: CONFIRM_BUSINESS_FAILED,
  payload,
});
const confirmBusinessReset = () => ({ type: CONFIRM_BUSINESS_RESET });

// Action Creators: Resend Business Email.
const resendBusinessEmailStart = (payload) => ({
  type: RESEND_BUSINESS_EMAIL_START,
  payload,
});
const resendBusinessEmailSuccess = (payload) => ({
  type: RESEND_BUSINESS_EMAIL_SUCCESS,
  payload,
});
const resendBusinessEmailFailed = (payload) => ({
  type: RESEND_BUSINESS_EMAIL_FAILED,
  payload,
});
const resendBusinessEmailReset = () => ({ type: RESEND_BUSINESS_EMAIL_RESET });

// Action Creators: Update Business Survey.
const updateBusinessSurveyStart = (payload) => ({
  type: UPDATE_BUSINESS_SURVEY_START,
  payload,
});
const updateBusinessSurveySuccess = (payload) => ({
  type: UPDATE_BUSINESS_SURVEY_SUCCESS,
  payload,
});
const updateBusinessSurveyFailed = (payload) => ({
  type: UPDATE_BUSINESS_SURVEY_FAILED,
  payload,
});
const updateBusinessSurveyReset = () => ({ type: UPDATE_BUSINESS_SURVEY_RESET });

// Actions: Get All Businesses.
export const getAllBusinesses = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllBusinessesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        businessName,
        businessNumber,
        businessTypes,
        businessCategories,
        municipality,
        sort,
        registeredFrom,
        registeredTo,
      } = payload;
      const result = await ApiClient.get('businesses', {
        params: {
          page,
          limit,
          pagination,
          businessName,
          businessNumber,
          businessTypes,
          businessCategories,
          municipality,
          sort,
          registeredFrom,
          registeredTo,
        },
      });
      if (result.data?.success) {
        const { businesses } = result.data?.data;
        dispatch(
          getAllBusinessesSuccess({
            loading: false,
            success: true,
            data: { businesses },
            error: null,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllBusinessesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllBusinessesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All.
export const clearGetAllBusinesses = () => getAllBusinessesReset();

// Actions: Get All Admins.
export const getAllAdmins = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllAdminsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination } = payload;
      const result = await ApiClient.get('businesses', {
        params: {
          page,
          limit,
          pagination,
          queryForAdmins: true,
        },
      });
      if (result.data?.success) {
        const { businesses } = result.data?.data;
        dispatch(
          getAllAdminsSuccess({
            loading: false,
            success: true,
            data: { admins: businesses },
            error: null,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllAdminsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllAdminsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All.
export const clearGetAllAdmins = () => getAllAdminsReset();

// Actions: Get One Business.
export const getOneBusiness = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { businessId } = payload;

    try {
      const result = await ApiClient.get(`businesses/${businessId}`);
      if (result.data?.success) {
        const { business } = result.data?.data;
        dispatch(
          getOneBusinessSuccess({ loading: false, success: true, data: { business }, error: false, errorMessage: null })
        );
      } else {
        dispatch(
          getOneBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One.
export const clearGetOneBusiness = () => getOneBusinessReset();

// Actions: Update One Business.
export const updateOneBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      businessId,
      businessName,
      businessNumber,
      businessEmail,
      businessType,
      businessCategory,
      businessCategories,
      businessPhoneNumber,
      businessContactPerson,
      businessCountry,
      businessMunicipality,
      businessOtherMunicipality,
      businessAllowNotifications,
    } = payload;
    const {
      showToast,
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      userExistsMessage,
      userNumberExistsMessage,
    } = options;
    try {
      const result = await ApiClient.put(`businesses/${businessId}`, {
        businessName,
        businessNumber,
        businessEmail,
        businessType,
        businessCategory,
        businessCategories,
        businessPhoneNumber,
        businessContactPerson,
        businessCountry,
        businessMunicipality,
        businessOtherMunicipality,
        businessAllowNotifications,
      });
      if (result.data?.success) {
        const { business } = result.data.data;
        dispatch(
          updateOneBusinessSuccess({
            loading: false,
            success: true,
            data: { business },
            error: false,
            errorMessage: null,
          })
        );
        showToast && toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'RESOURCE_EXISTS'
      ) {
        toastNotification('error', userExistsMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'USER_EXISTS_NUMBER'
      ) {
        toastNotification('error', userNumberExistsMessage);
      } else {
        showToast && toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Update One.
export const clearUpdateOneBusiness = () => updateOneBusinessReset();

// Actions: Upload Logo Business.
export const uploadLogoBusiness = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadLogoBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onSuccessMessageEmailSent,
      onSuccessMessageEmail,
    } = options;
    try {
      const { businessId, formData } = payload;
      const result = await ApiClient.put(`businesses/${businessId}/logo`, formData);
      if (result.data?.success) {
        const { business } = result.data?.data;
        dispatch(
          uploadLogoBusinessSuccess({
            loading: false,
            success: true,
            data: { business },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onSuccessMessageEmailSent}`,
            text: `${onSuccessMessageEmail}`,
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          // Swal.fire(swalOptions).then((p) => {});
          Swal.fire(swalOptions).then((result) => result.isConfirmed && shouldRedirect && history.push(pathname));
        } else {
          showToast && toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          uploadLogoBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onSuccessMessage);
      }
    } catch (error) {
      dispatch(
        uploadLogoBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Logo.
export const clearUploadLogoBusiness = () => uploadLogoBusinessReset();

// Actions: Upload Contract Intern.
export const uploadInternContract = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      uploadInternContractStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      showToast,
      toastNotification,
      shouldRedirect,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onContractUpload,
    } = options;
    try {
      const { businessId, applicationId, contract } = payload;
      const result = await ApiClient.post(`businesses/${businessId}/internContract/${applicationId}`, contract);
      if (result.data?.success) {
        const { intern } = result.data?.data;
        dispatch(
          uploadInternContractSuccess({
            loading: false,
            success: true,
            data: { intern },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onContractUpload}`,
            // text: 'A verification email was sent to you, please check your email for further instructions!',
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
        } else {
          showToast && toastNotification('success', onSuccessMessage);
        }
        shouldRedirect && history.push(pathname);
      } else {
        dispatch(
          uploadInternContractFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        showToast && toastNotification('error', onSuccessMessage);
      }
    } catch (error) {
      dispatch(
        uploadInternContractFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      showToast && toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Upload Intern Contract.
export const clearUploadInternContract = () => uploadInternContractReset();

// Actions: Delete One Business.
export const deleteOneBusiness = (payload, options = {}) => {
  return async (dispatch) => {
    dispatch(
      deleteOneBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { businessId } = payload;
    const { toastNotification, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.delete(`businesses/${businessId}`);
      if (result.data?.success) {
        const { business } = result.data.data;
        dispatch(
          deleteOneBusinessSuccess({
            loading: false,
            success: true,
            data: { business },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          deleteOneBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        deleteOneBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Delete One.
export const clearDeleteOneBusiness = () => deleteOneBusinessReset();

// Actions: Sign Up Business.
export const signUpBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      signUpBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      toastNotification,
      history,
      pathname,
      onFailMessage,
      onSuccessMessage,
      userExistsMessage,
      userNumberExistsMessage,
    } = options;
    const {
      email,
      password,
      passwordConfirm,
      name,
      number,
      type,
      category,
      categories,
      phoneNumber,
      contact,
      country,
      municipality,
      otherMunicipality,
      emailLanguage,
    } = payload;
    try {
      const result = await ApiClient.post('businesses/auth/signup', {
        email: email.toLowerCase(),
        password,
        passwordConfirm,
        name,
        number,
        type,
        category,
        categories,
        phoneNumber,
        contact,
        country,
        municipality,
        otherMunicipality,
        emailLanguage,
      });
      if (result.data?.success) {
        const { sent, businessId } = result.data.data;
        dispatch(
          signUpBusinessSuccess({
            loading: false,
            success: true,
            data: { sent, businessId },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          signUpBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        signUpBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const emailExistsMessage = 'USER_EXISTS';
      const numberExistsMessage = 'USER_EXISTS_NUMBER';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === emailExistsMessage
      ) {
        toastNotification('error', userExistsMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === numberExistsMessage
      ) {
        toastNotification('error', userNumberExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Sign Up.
export const clearSignUpBusiness = () => signUpBusinessReset();

// Actions: Login Business.
export const loginBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      loginBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      toastNotification,
      history,
      pathname,
      onSuccessMessage,
      onFailMessage,
      onFailConfirmMessage,
      onFailCredentialMessage,
    } = options;
    try {
      const { email, password, remember } = payload;
      const result = await ApiClient.post('businesses/auth/login', {
        email: email.toLowerCase(),
        password,
        remember,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp, role } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          loginBusinessSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        history.push(role === 'business' ? pathname : '/dashboard');

        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          loginBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        loginBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const unconfirmedMessage = 'UNCONFIRMED_ACCOUNT';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === unconfirmedMessage
      ) {
        toastNotification('error', onFailConfirmMessage);
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'INVALID_CREDENTIALS'
      ) {
        toastNotification('error', onFailCredentialMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Login.
export const clearLoginBusiness = () => loginBusinessReset();

// Actions: Forgot Password Business.
export const forgotPassBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      forgotPassBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onUserFailMessage } = options;
    try {
      const { email, emailLanguage } = payload;
      const result = await ApiClient.post('businesses/auth/forgot', { email: email.toLowerCase(), emailLanguage });
      if (result.data?.success) {
        dispatch(
          forgotPassBusinessSuccess({
            loading: false,
            success: true,
            data: result.data.data,
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          forgotPassBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        forgotPassBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'RESOURCE_NOT_FOUND'
      ) {
        toastNotification('error', onUserFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Forgot Pass.
export const clearForgotPass = () => forgotPassBusinessReset();

// Actions: Reset Password Business.
export const resetPassBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resetPassBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onFailMessageExpired } = options;

    try {
      const { email, newPassword, passwordConfirm, resetToken, type, emailLanguage } = payload;
      const result = await ApiClient.post(`businesses/auth/reset/${resetToken}`, {
        email: email.toLowerCase(),
        newPassword,
        passwordConfirm,
        emailLanguage,
      });
      if (result.data?.success) {
        const { token } = result.data.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          resetPassBusinessSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push(pathname, { shouldUseState: true, state: { type } });
      } else {
        dispatch(
          resetPassBusinessFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      const errorType = error.response?.data?.errorType;
      let errorMessage = error.message || 'Internal Server Error!';

      if (errorType === 'RESOURCE_NOT_FOUND') {
        errorMessage = onFailMessageExpired;
      } else if (errorType === 'PASSWORD_SAME') {
        errorMessage = onFailMessage;
      }

      dispatch(
        resetPassBusinessFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage,
        })
      );
      toastNotification('error', errorMessage);
    }
  };
};

// Actions: Clear Reset Pass.
export const clearResetPass = () => resetPassBusinessReset();

// Actions: Confirm Business.
export const confirmBusiness = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      confirmBusinessStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email, accountConfirmationCode, accountConfirmationToken } = payload;
      const result = await ApiClient.post('businesses/auth/confirm', {
        email: email.toLowerCase(),
        accountConfirmationCode,
        accountConfirmationToken,
      });
      if (result.data?.success) {
        const { token } = result.data?.data;
        const { exp } = jwt.decode(token);
        const expiresIn = new Date(exp * 1000).toISOString();
        dispatch({ type: 'TOKEN', token });
        dispatch(
          confirmBusinessSuccess({
            loading: false,
            success: true,
            data: { token },
            error: false,
            errorMessage: null,
          })
        );
        localStorage.setItem('kgen-token', token);
        localStorage.setItem('expiresIn', expiresIn);
        toastNotification('success', onSuccessMessage);
        history.push(pathname);
      } else {
        dispatch(
          confirmBusinessFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        confirmBusinessFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const codeExpiredMessage = 'CODE_EXPIRED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === codeExpiredMessage
      ) {
        toastNotification('error', 'Code has expired, please click resend code!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Confirm Business.
export const clearConfirmBusiness = () => confirmBusinessReset();

// Actions: Resend Mentor Email.
export const resendBusinessEmail = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      resendBusinessEmailStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const { email } = payload;
      const result = await ApiClient.post('businesses/auth/resend', {
        email: email.toLowerCase(),
      });
      if (result.data?.success) {
        const { sent, businessId } = result.data?.data;
        dispatch(
          resendBusinessEmailSuccess({
            loading: false,
            success: true,
            data: { sent, businessId },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          resendBusinessEmailFailed({
            loading: false,
            success: true,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        resendBusinessEmailFailed({
          loading: false,
          success: true,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const accountConfirmedMessage = 'ACCOUNT_CONFIRMED';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === accountConfirmedMessage
      ) {
        toastNotification('error', 'Account already confirmed!');
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Resend Business Email.
export const clearResendBusinessEmail = () => resendBusinessEmailReset();

// Actions: Update Business Survey.
export const updateBusinessSurvey = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      updateBusinessSurveyStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { businessId, surveyId, questions } = payload;
    const { toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage, onCompleteMessage } =
      options;
    try {
      const result = await ApiClient.put(`businesses/${businessId}/survey`, { surveyId, questions });
      if (result.data?.success) {
        const { business } = result.data.data;
        dispatch(
          updateBusinessSurveySuccess({
            loading: false,
            success: true,
            data: { business },
            error: false,
            errorMessage: null,
          })
        );
        if (showSwal) {
          const swalOptions = {
            title: `${onCompleteMessage}`,
            // text: 'Thank you!',
            icon: 'success',
            iconColor: '#21ac79',
            showCancelButton: false,
            confirmButtonColor: '#01486B',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'OK',
          };
          Swal.fire(swalOptions).then((p) => {});
          shouldRedirect && history.push(pathname);
        } else {
          toastNotification('success', onSuccessMessage);
          shouldRedirect && history.push(pathname);
        }
      } else {
        dispatch(
          updateBusinessSurveyFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateBusinessSurveyFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update Business Survey.
export const clearUpdateBusinessSurvey = () => updateBusinessSurveyReset();
