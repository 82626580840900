// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Countries.
export const GET_ALL_COUNTRIES_START = 'GET_ALL_COUNTRIES_START';
export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_FAILED = 'GET_ALL_COUNTRIES_FAILED';
export const GET_ALL_COUNTRIES_RESET = 'GET_ALL_COUNTRIES_RESET';

// Action Types: Get One Country.
export const GET_ONE_COUNTRY_START = 'GET_ONE_COUNTRY_START';
export const GET_ONE_COUNTRY_SUCCESS = 'GET_ONE_COUNTRY_SUCCESS';
export const GET_ONE_COUNTRY_FAILED = 'GET_ONE_COUNTRY_FAILED';
export const GET_ONE_COUNTRY_RESET = 'GET_ONE_COUNTRY_RESET';

// Action Types: Create New Country.
export const CREATE_COUNTRY_START = 'CREATE_COUNTRY_START';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILED = 'CREATE_COUNTRY_FAILED';
export const CREATE_COUNTRY_RESET = 'CREATE_COUNTRY_RESET';

// Action Types: Update One Country.
export const UPDATE_ONE_COUNTRY_START = 'UPDATE_ONE_COUNTRY_START';
export const UPDATE_ONE_COUNTRY_SUCCESS = 'UPDATE_ONE_COUNTRY_SUCCESS';
export const UPDATE_ONE_COUNTRY_FAILED = 'UPDATE_ONE_COUNTRY_FAILED';
export const UPDATE_ONE_COUNTRY_RESET = 'UPDATE_ONE_COUNTRY_RESET';

// Action Types: Delete One Country.
export const DELETE_ONE_COUNTRY_START = 'DELETE_ONE_COUNTRY_START';
export const DELETE_ONE_COUNTRY_SUCCESS = 'DELETE_ONE_COUNTRY_SUCCESS';
export const DELETE_ONE_COUNTRY_FAILED = 'DELETE_ONE_COUNTRY_FAILED';
export const DELETE_ONE_COUNTRY_RESET = 'DELETE_ONE_COUNTRY_RESET';

// Action Creators: Get All Countries.
const getAllCountriesStart = (payload) => ({
  type: GET_ALL_COUNTRIES_START,
  payload,
});
const getAllCountriesSuccess = (payload) => ({
  type: GET_ALL_COUNTRIES_SUCCESS,
  payload,
});
const getAllCountriesFailed = (payload) => ({
  type: GET_ALL_COUNTRIES_FAILED,
  payload,
});
const getAllCountriesReset = () => ({ type: GET_ALL_COUNTRIES_RESET });

// Action Creators: Get One Country.
const getOneCountryStart = (payload) => ({
  type: GET_ONE_COUNTRY_START,
  payload,
});
const getOneCountrySuccess = (payload) => ({
  type: GET_ONE_COUNTRY_SUCCESS,
  payload,
});
const getOneCountryFailed = (payload) => ({
  type: GET_ONE_COUNTRY_FAILED,
  payload,
});
const getOneCountryReset = () => ({ type: GET_ONE_COUNTRY_RESET });

// Action Creators: Create New Country.
const createCountryStart = (payload) => ({
  type: CREATE_COUNTRY_START,
  payload,
});
const createCountrySuccess = (payload) => ({
  type: CREATE_COUNTRY_SUCCESS,
  payload,
});
const createCountryFailed = (payload) => ({
  type: CREATE_COUNTRY_FAILED,
  payload,
});
const createCountryReset = () => ({ type: CREATE_COUNTRY_RESET });

// Action Creators: Update One Country.
const updateOneCountryStart = (payload) => ({
  type: UPDATE_ONE_COUNTRY_START,
  payload,
});
const updateOneCountrySuccess = (payload) => ({
  type: UPDATE_ONE_COUNTRY_SUCCESS,
  payload,
});
const updateOneCountryFailed = (payload) => ({
  type: UPDATE_ONE_COUNTRY_FAILED,
  payload,
});
const updateOneCountryReset = () => ({ type: UPDATE_ONE_COUNTRY_RESET });

// Action Creators: Delete One Country.
const deleteOneCountryStart = (payload) => ({
  type: DELETE_ONE_COUNTRY_START,
  payload,
});
const deleteOneCountrySuccess = (payload) => ({
  type: DELETE_ONE_COUNTRY_SUCCESS,
  payload,
});
const deleteOneCountryFailed = (payload) => ({
  type: DELETE_ONE_COUNTRY_FAILED,
  payload,
});
const deleteOneCountryReset = () => ({ type: DELETE_ONE_COUNTRY_RESET });

// Actions: Get All Countries.
export const getAllCountries = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllCountriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination } = payload;
      const result = await ApiClient.get('countries', {
        params: { page, limit, pagination },
      });
      if (result.data?.success) {
        const { countries } = result.data.data;
        dispatch(
          getAllCountriesSuccess({
            loading: false,
            success: true,
            data: { countries },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllCountriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllCountriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Countries.
export const clearGetAllCountries = () => getAllCountriesReset();

// Actions: Get One Country.
export const getOneCountry = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneCountryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { countryId } = payload;
      const result = await ApiClient.get(`countries/${countryId}`);
      if (result.data?.success) {
        const { country } = result.data.data;
        dispatch(
          getOneCountrySuccess({
            loading: false,
            success: true,
            data: { country },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneCountryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneCountryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Country.
export const clearGetOneCountry = () => getOneCountryReset();

// Actions: Create New Country.
export const createCountry = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createCountryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('countries', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { country } = result.data.data;
        dispatch(
          createCountrySuccess({
            loading: false,
            success: true,
            data: { country },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createCountryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createCountryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Country.
export const clearCreateNewCountry = () => createCountryReset();

// Actions: Update One Country.
export const updateOneCountry = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneCountryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { countryId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`countries/${countryId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { country } = result.data.data;
        dispatch(
          updateOneCountrySuccess({
            loading: false,
            success: true,
            data: { country },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneCountryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneCountryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Country.
export const clearUpdateOneCountry = () => updateOneCountryReset();

// Actions: Delete One Country.
export const deleteOneCountry = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneCountryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { countryId } = payload;
      const result = await ApiClient.delete(`countries/${countryId}`);
      if (result.data?.success) {
        const { country } = result.data.data;
        dispatch(
          deleteOneCountrySuccess({
            loading: false,
            success: true,
            data: { country },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneCountryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneCountryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Country.
export const clearDeleteOneCountry = () => deleteOneCountryReset();
