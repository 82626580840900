import React, { useEffect, useState } from 'react';
import {
  engagementsByMunicipality,
  engagementsByBusiness,
  engagementsByGender,
  engagementsByAge,
  engagementsBySkills,
  engagementsByCategories,
  engagementsByEthnicity,
  engagementsByHealthCondition,
} from '../../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Grid } from '@mui/material';
import { toastNotification } from '../../../utils/toastNotification';
import APIClient from '../../../services/ApiClient';
import { saveAs } from 'file-saver';
import MainCard from '../../../utils/MainCard';
import { InternFilter } from './Filters/Intern';
import SubCard from '../../../utils/SubCard';
import { StatsChart } from './Stats';

export const EngagementsReports = ({ startDate, endDate }) => {
  const engagementsByMunicipalityResponse = useSelector(({ reports }) => reports.engagementsByMunicipality);
  const engagementsByBusinessResponse = useSelector(({ reports }) => reports.engagementsByBusiness);
  const engagementsByGenderResponse = useSelector(({ reports }) => reports.engagementsByGender);
  const engagementsByAgeResponse = useSelector(({ reports }) => reports.engagementsByAge);
  const engagementsBySkillsResponse = useSelector(({ reports }) => reports.engagementsBySkills);
  const engagementsByCategoriesResponse = useSelector(({ reports }) => reports.engagementsByCategories);
  const engagementsByEthnicityResponse = useSelector(({ reports }) => reports.engagementsByEthnicity);
  const engagementsByHealthConditionResponse = useSelector(({ reports }) => reports.engagementsByHealthCondition);

  const [loadingButton, setLoadingButton] = useState(false);
  const [municipalityChips, setMunicipalityChips] = useState([]);
  const [genderChips, setGenderChips] = useState([]);
  const [ethnicityChips, setEthnicityChips] = useState([]);
  const [healthConditionChips, setHealthConditionChips] = useState([]);
  const [skillChips, setSkillChips] = useState([]);
  const [categoryChips, setCategoryChips] = useState([]);
  const [selectedYear, setSelectedYear] = useState('choose');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate || endDate) {
      let payload = { startDate: startDate ? startDate : undefined, endDate: endDate ? endDate : undefined };
      if (skillChips?.length) payload['skills'] = skillChips.map((skill) => skill._id);
      if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
      if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
      if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
      if (selectedYear !== 'choose') payload['birthYear'] = String(selectedYear);
      if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
      if (healthConditionChips?.length) payload['healthConditions'] = healthConditionChips.map((e) => e._id);
      dispatch(engagementsByMunicipality(payload));
      dispatch(engagementsByBusiness(payload));
      dispatch(engagementsByGender(payload));
      dispatch(engagementsByAge(payload));
      dispatch(engagementsBySkills(payload));
      dispatch(engagementsByCategories(payload));
      dispatch(engagementsByEthnicity(payload));
      dispatch(engagementsByHealthCondition(payload));
    }
  }, [municipalityChips, genderChips, ethnicityChips, healthConditionChips, skillChips, categoryChips, selectedYear]);

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (skillChips?.length) payload['skills'] = skillChips.map((skill) => skill._id);
    if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
    if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
    if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
    if (selectedYear !== 'choose') payload['birthYear'] = String(selectedYear);
    if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
    if (healthConditionChips?.length) payload['healthConditions'] = healthConditionChips.map((e) => e._id);
    await APIClient.post(`/reports/engagements/export`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Engagements_Stats.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };

  if (
    engagementsByMunicipalityResponse.loading ||
    engagementsByBusinessResponse.loading ||
    engagementsByGenderResponse.loading ||
    engagementsByAgeResponse.loading ||
    engagementsBySkillsResponse.loading ||
    engagementsByCategoriesResponse.loading ||
    engagementsByEthnicityResponse.loading ||
    engagementsByHealthConditionResponse.loading
  ) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <MainCard title={t('generalStatistics')} sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InternFilter
                  categoryChips={categoryChips}
                  skillChips={skillChips}
                  municipalityChips={municipalityChips}
                  genderChips={genderChips}
                  ethnicityChips={ethnicityChips}
                  healthConditionChips={healthConditionChips}
                  setCategoryChips={setCategoryChips}
                  setSkillChips={setSkillChips}
                  setMunicipalityChips={setMunicipalityChips}
                  setGenderChips={setGenderChips}
                  setEthnicityChips={setEthnicityChips}
                  setHealthConditionChips={setHealthConditionChips}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                />
              </Grid>
              <Grid item xs={12}>
                <SubCard
                  title={t('charts')}
                  sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}
                  secondary={
                    <Button
                      variant="contained"
                      disabled={loadingButton === true ? true : false}
                      sx={{
                        backgroundColor: '#01486B !important',
                        '&:hover': { backgroundColor: '#012b40 !important' },
                        width: '160px',
                        fontFamily: 'Mark-Regular',
                        fontSize: '14px',
                        textTransform: 'none !important',
                      }}
                      onClick={(e) => {
                        onDocumentDownload(e);
                      }}
                    >
                      {loadingButton ? (
                        <>
                          <CircularProgress size={24} style={{ color: '#fff' }} />
                        </>
                      ) : (
                        <>
                          <FileDownloadOutlined /> {t('exportExcel')}
                        </>
                      )}
                    </Button>
                  }
                >
                  <Grid container spacing={3}>
                    <StatsChart
                      response={engagementsByMunicipalityResponse}
                      label={t('engagementsByMunicipality')}
                      data={
                        engagementsByMunicipalityResponse?.data?.applications
                          ? engagementsByMunicipalityResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsByBusinessResponse}
                      label={t('engagementsByBusiness')}
                      data={
                        engagementsByBusinessResponse?.data?.applications
                          ? engagementsByBusinessResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsByGenderResponse}
                      label={t('engagementsByGender')}
                      data={
                        engagementsByGenderResponse?.data?.applications
                          ? engagementsByGenderResponse.data.applications
                          : []
                      }
                      chart={'Pie'}
                    />
                    <StatsChart
                      response={engagementsByAgeResponse}
                      label={t('engagementsByAge')}
                      data={
                        engagementsByAgeResponse?.data?.applications ? engagementsByAgeResponse.data.applications : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsByEthnicityResponse}
                      label={t('engagementsByEthnicity')}
                      data={
                        engagementsByEthnicityResponse?.data?.applications
                          ? engagementsByEthnicityResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsByHealthConditionResponse}
                      label={t('applicationsByHealthCondition')}
                      data={
                        engagementsByHealthConditionResponse?.data?.applications
                          ? engagementsByHealthConditionResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsBySkillsResponse}
                      label={t('engagementsBySkills')}
                      data={
                        engagementsBySkillsResponse?.data?.applications
                          ? engagementsBySkillsResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={engagementsByCategoriesResponse}
                      label={t('engagementsByCategories')}
                      data={
                        engagementsByCategoriesResponse?.data?.applications
                          ? engagementsByCategoriesResponse.data.applications
                          : []
                      }
                      chart={'Bar'}
                    />
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
