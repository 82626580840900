import { combineReducers } from 'redux';
import businessesReducer from './businesses.reducer';
import categoriesReducer from './categories.reducer';
import skillsReducer from './skills.reducer';
import articlesReducer from './articles.reducer';
import tokenReducer from './token.reducer';
import municipalitiesReducer from './municipalities.reducer';
import businessTypesReducer from './businesstypes.reducer';
import businessCategoriesReducer from './businesscategories.reducer';
import mentorsReducer from './mentors.reducer';
import jobsReducer from './jobs.reducer';
import internsReducer from './interns.reducer';
import applicationsReducer from './applications.reducer';
import countriesReducer from './countries.reducer';
import institutionsReducer from './institutions.reducer';
import mentorshipsReducer from './mentorships.reducer';
import adminsReducer from './admins.reducer';
import surveysReducer from './surveys.reducer';
import meetingsReducer from './meetings.reducer';
import reportsReducer from './reports.reducer';
import cancellationsReducer from './cancellations.reducer';
import languageReducer from './language.reducer';
import ethnicitiesReducer from './ethnicities.reducer';
import redflagsReducer from './redflags.reducer';
import healthconditionsReducer from './healthconditions.reducer';
import hearaboutReducer from './hearabout.reducer';
import facilitationGuidesReducer from './facilitationguides.reducer';
import trainingsReducer from './trainings.reducer';
import tagsReducer from './tags.reducer';
import subcategoriesReducer from './subcategories.reducer';
import resourcesReducer from './resources.reducer';
import customizationReducer from './customization.reducer';
import questionsReducer from './questions.reducer';
import answersReducer from './answers.reducer';
import documentsReducer from './documents.reducer';
import publicMentorsReducer from './publicmentors.reducer';
import bannerReducer from './banner.reducer';

export default combineReducers({
  businesses: businessesReducer,
  categories: categoriesReducer,
  skills: skillsReducer,
  articles: articlesReducer,
  token: tokenReducer,
  municipalities: municipalitiesReducer,
  businessTypes: businessTypesReducer,
  businessCategories: businessCategoriesReducer,
  mentors: mentorsReducer,
  jobs: jobsReducer,
  interns: internsReducer,
  applications: applicationsReducer,
  countries: countriesReducer,
  institutions: institutionsReducer,
  mentorships: mentorshipsReducer,
  admins: adminsReducer,
  surveys: surveysReducer,
  meetings: meetingsReducer,
  reports: reportsReducer,
  cancellations: cancellationsReducer,
  language: languageReducer,
  ethnicities: ethnicitiesReducer,
  redFlags: redflagsReducer,
  healthConditions: healthconditionsReducer,
  hearAbout: hearaboutReducer,
  facilitationGuides: facilitationGuidesReducer,
  trainings: trainingsReducer,
  tags: tagsReducer,
  subcategories: subcategoriesReducer,
  resources: resourcesReducer,
  customization: customizationReducer,
  questions: questionsReducer,
  answers: answersReducer,
  documents: documentsReducer,
  publicMentors: publicMentorsReducer,
  banner: bannerReducer,
});
