import React, { useEffect, useState } from 'react';
import {
  trainingsByCategories,
  trainingsBySpots,
  trainingsByApplications,
  trainingsByEngagements,
  trainingsByOpenings,
  trainingsByEngagementsGender,
} from '../../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Grid } from '@mui/material';
import { toastNotification } from '../../../utils/toastNotification';
import APIClient from '../../../services/ApiClient';
import { saveAs } from 'file-saver';
import MainCard from '../../../utils/MainCard';
import { StatsChart } from './Stats';
import SubCard from '../../../utils/SubCard';
import { TrainingFilter } from './Filters/Training';

export const TrainingsReports = ({ startDate, endDate }) => {
  const trainingsByApplicationsResponse = useSelector(({ reports }) => reports.trainingsByApplications);
  const trainingsBySpotsResponse = useSelector(({ reports }) => reports.trainingsBySpots);
  const trainingsByCategoriesResponse = useSelector(({ reports }) => reports.trainingsByCategories);
  const trainingsByEngagementsResponse = useSelector(({ reports }) => reports.trainingsByEngagements);
  const trainingsByOpeningsResponse = useSelector(({ reports }) => reports.trainingsByOpenings);
  const trainingsByEngagementsGenderResponse = useSelector(({ reports }) => reports.trainingsByEngagementsGender);

  const [loadingButton, setLoadingButton] = useState(false);
  const [categoryChips, setCategoryChips] = useState([]);
  const [municipalityChips, setMunicipalityChips] = useState([]);
  const [organisation, setOrganisation] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate || endDate) {
      let payload = {
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
      };
      if (organisation) payload['organisation'] = organisation.id;
      if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
      if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
      dispatch(trainingsByApplications(payload));
      dispatch(trainingsByOpenings(payload));
      dispatch(trainingsByCategories(payload));
      dispatch(trainingsByEngagements(payload));
      dispatch(trainingsBySpots(payload));
      dispatch(trainingsByEngagementsGender(payload));
    }
  }, [municipalityChips, organisation, categoryChips]);

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (organisation) payload['organisation'] = organisation.id;
    if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
    if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
    await APIClient.post(`/reports/trainings/export`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Training-Opportunities_Stats.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };

  if (
    trainingsByApplicationsResponse.loading ||
    trainingsByEngagementsResponse.loading ||
    trainingsByCategoriesResponse.loading ||
    trainingsByOpeningsResponse.loading ||
    trainingsBySpotsResponse.loading ||
    trainingsByEngagementsGenderResponse.loading
  ) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <MainCard title={t('generalStatistics')} sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TrainingFilter
                  categoryChips={categoryChips}
                  organisation={organisation}
                  municipalityChips={municipalityChips}
                  setCategoryChips={setCategoryChips}
                  setOrganisation={setOrganisation}
                  setMunicipalityChips={setMunicipalityChips}
                />
              </Grid>
              <Grid item xs={12}>
                <SubCard
                  title={t('charts')}
                  sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}
                  secondary={
                    <Button
                      variant="contained"
                      disabled={loadingButton === true ? true : false}
                      sx={{
                        backgroundColor: '#01486B !important',
                        '&:hover': { backgroundColor: '#012b40 !important' },
                        width: '160px',
                        fontFamily: 'Mark-Regular',
                        fontSize: '14px',
                        textTransform: 'none !important',
                      }}
                      onClick={(e) => {
                        onDocumentDownload(e);
                      }}
                    >
                      {loadingButton ? (
                        <>
                          <CircularProgress size={24} style={{ color: '#fff' }} />
                        </>
                      ) : (
                        <>
                          <FileDownloadOutlined /> {t('exportExcel')}
                        </>
                      )}
                    </Button>
                  }
                >
                  <Grid container spacing={3}>
                    <StatsChart
                      response={trainingsByApplicationsResponse}
                      label={t('trainingsByApplications')}
                      data={
                        trainingsByApplicationsResponse?.data?.trainings
                          ? trainingsByApplicationsResponse.data.trainings
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={trainingsBySpotsResponse}
                      label={t('trainingsBySpots')}
                      data={trainingsBySpotsResponse?.data?.trainings ? trainingsBySpotsResponse.data.trainings : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={trainingsByCategoriesResponse}
                      label={t('trainingsByCategories')}
                      data={
                        trainingsByCategoriesResponse?.data?.trainings
                          ? trainingsByCategoriesResponse.data.trainings
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={trainingsByEngagementsResponse}
                      label={t('trainingsByEngagements')}
                      data={
                        trainingsByEngagementsResponse?.data?.trainings
                          ? trainingsByEngagementsResponse.data.trainings
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={trainingsByEngagementsGenderResponse}
                      label={t('trainingsByEngagementsGender')}
                      data={
                        trainingsByEngagementsGenderResponse?.data?.trainings
                          ? trainingsByEngagementsGenderResponse.data.trainings
                          : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={trainingsByOpeningsResponse}
                      label={t('trainingsByOpenings')}
                      data={
                        trainingsByOpeningsResponse?.data?.trainings ? trainingsByOpeningsResponse.data.trainings : []
                      }
                      chart={'Bar'}
                    />
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
