// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Categories.
export const GET_ALL_CATEGORIES_START = 'GET_ALL_CATEGORIES_START';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAILED = 'GET_ALL_CATEGORIES_FAILED';
export const GET_ALL_CATEGORIES_RESET = 'GET_ALL_CATEGORIES_RESET';

// Action Types: Get One Category.
export const GET_ONE_CATEGORY_START = 'GET_ONE_CATEGORY_START';
export const GET_ONE_CATEGORY_SUCCESS = 'GET_ONE_CATEGORY_SUCCESS';
export const GET_ONE_CATEGORY_FAILED = 'GET_ONE_CATEGORY_FAILED';
export const GET_ONE_CATEGORY_RESET = 'GET_ONE_CATEGORY_RESET';

// Action Types: Create New Category.
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export const CREATE_CATEGORY_RESET = 'CREATE_CATEGORY_RESET';

// Action Types: Update One Category.
export const UPDATE_ONE_CATEGORY_START = 'UPDATE_ONE_CATEGORY_START';
export const UPDATE_ONE_CATEGORY_SUCCESS = 'UPDATE_ONE_CATEGORY_SUCCESS';
export const UPDATE_ONE_CATEGORY_FAILED = 'UPDATE_ONE_CATEGORY_FAILED';
export const UPDATE_ONE_CATEGORY_RESET = 'UPDATE_ONE_CATEGORY_RESET';

// Action Types: Delete One Category.
export const DELETE_ONE_CATEGORY_START = 'DELETE_ONE_CATEGORY_START';
export const DELETE_ONE_CATEGORY_SUCCESS = 'DELETE_ONE_CATEGORY_SUCCESS';
export const DELETE_ONE_CATEGORY_FAILED = 'DELETE_ONE_CATEGORY_FAILED';
export const DELETE_ONE_CATEGORY_RESET = 'DELETE_ONE_CATEGORY_RESET';

// Action Creators: Get All Categories.
const getAllCategoriesStart = (payload) => ({
  type: GET_ALL_CATEGORIES_START,
  payload,
});
const getAllCategoriesSuccess = (payload) => ({
  type: GET_ALL_CATEGORIES_SUCCESS,
  payload,
});
const getAllCategoriesFailed = (payload) => ({
  type: GET_ALL_CATEGORIES_FAILED,
  payload,
});
const getAllCategoriesReset = () => ({ type: GET_ALL_CATEGORIES_RESET });

// Action Creators: Get One Category.
const getOneCategoryStart = (payload) => ({
  type: GET_ONE_CATEGORY_START,
  payload,
});
const getOneCategorySuccess = (payload) => ({
  type: GET_ONE_CATEGORY_SUCCESS,
  payload,
});
const getOneCategoryFailed = (payload) => ({
  type: GET_ONE_CATEGORY_FAILED,
  payload,
});
const getOneCategoryReset = () => ({ type: GET_ONE_CATEGORY_RESET });

// Action Creators: Create New Category.
const createCategoryStart = (payload) => ({
  type: CREATE_CATEGORY_START,
  payload,
});
const createCategorySuccess = (payload) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload,
});
const createCategoryFailed = (payload) => ({
  type: CREATE_CATEGORY_FAILED,
  payload,
});
const createCategoryReset = () => ({ type: CREATE_CATEGORY_RESET });

// Action Creators: Update One Category.
const updateOneCategoryStart = (payload) => ({
  type: UPDATE_ONE_CATEGORY_START,
  payload,
});
const updateOneCategorySuccess = (payload) => ({
  type: UPDATE_ONE_CATEGORY_SUCCESS,
  payload,
});
const updateOneCategoryFailed = (payload) => ({
  type: UPDATE_ONE_CATEGORY_FAILED,
  payload,
});
const updateOneCategoryReset = () => ({ type: UPDATE_ONE_CATEGORY_RESET });

// Action Creators: Delete One Category.
const deleteOneCategoryStart = (payload) => ({
  type: DELETE_ONE_CATEGORY_START,
  payload,
});
const deleteOneCategorySuccess = (payload) => ({
  type: DELETE_ONE_CATEGORY_SUCCESS,
  payload,
});
const deleteOneCategoryFailed = (payload) => ({
  type: DELETE_ONE_CATEGORY_FAILED,
  payload,
});
const deleteOneCategoryReset = () => ({ type: DELETE_ONE_CATEGORY_RESET });

// Actions: Get All Categories.
export const getAllCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, categories, language } = payload;
      const result = await ApiClient.get('categories', {
        params: { page, limit, pagination, categories, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { categories } = result.data.data;
        dispatch(
          getAllCategoriesSuccess({
            loading: false,
            success: true,
            data: { categories },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Categories.
export const clearGetAllCategories = () => getAllCategoriesReset();

// Actions: Get One Category.
export const getOneCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { categoryId } = payload;
      const result = await ApiClient.get(`categories/${categoryId}`);
      if (result.data?.success) {
        const { category } = result.data.data;
        dispatch(
          getOneCategorySuccess({
            loading: false,
            success: true,
            data: { category },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Category.
export const clearGetOneCategory = () => getOneCategoryReset();

// Actions: Create New Category.
export const createCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onCategoryExistsMessage } = options;

    try {
      const result = await ApiClient.post('categories', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { category } = result.data.data;
        dispatch(
          createCategorySuccess({
            loading: false,
            success: true,
            data: { category },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const categoryExists = 'CATEGORY_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === categoryExists
      ) {
        toastNotification('error', onCategoryExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Category.
export const clearCreateNewCategory = () => createCategoryReset();

// Actions: Update One Category.
export const updateOneCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { categoryId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`categories/${categoryId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { category } = result.data.data;
        dispatch(
          updateOneCategorySuccess({
            loading: false,
            success: true,
            data: { category },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Category.
export const clearUpdateOneCategory = () => updateOneCategoryReset();

// Actions: Delete One Category.
export const deleteOneCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { categoryId } = payload;
      const result = await ApiClient.delete(`categories/${categoryId}`);
      if (result.data?.success) {
        const { category } = result.data.data;
        dispatch(
          deleteOneCategorySuccess({
            loading: false,
            success: true,
            data: { category },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Category.
export const clearDeleteOneCategory = () => deleteOneCategoryReset();
