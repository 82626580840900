import Chart from 'react-apexcharts';

export const BarChart = ({ labels, values, title }) => {
  const chartData = {
    height: 480,
    type: 'bar',
    options: {
      chart: {
        id: 'dropdown-chart',
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
        },
      },
      xaxis: {
        type: 'category',
        categories: labels,
        title: {
          text: title,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: `'Mark-Regular', sans-serif`,
        position: 'bottom',
        offsetX: 20,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      fill: {
        type: 'solid',
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        show: true,
      },
    },
    series: [
      {
        name: 'Value',
        data: values,
      },
    ],
  };
  return <Chart {...chartData} />;
};
