import React from 'react';
import { GlobalOutlined, TeamOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import '../../style/partners.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export const Partners = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  return (
    <div id="aboutus" className="container-about-us">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="partner-single">
              <div className="icon">
                {/* <GlobalOutlined style={{ fontSize: '90px' }} /> */}
                <img src={'/images/Ministry.png'} alt="Ministry of Finance" />
              </div>
              {/* <div className="cont">
                <h4>Generation Unlimited</h4>
                <p>{t('gen-unlimited-txt')}</p>
              </div> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="partner-single">
              <div className="icon">
                {/* <TeamOutlined style={{ fontSize: '90px' }} /> */}
                <img src={'/images/GenU.png'} alt="Kosovo Generation Unlimited" />
              </div>
              {/* <div className="cont">
                <h4>Kosovo CSR Network</h4>
                <p>{t('ks-csr-txt')}</p>
              </div> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="partner-single">
              <div className="icon">
                {/* <SafetyCertificateOutlined style={{ fontSize: '90px' }} /> */}

                <img src={'/images/CSR.png'} alt="CSR Kosovo" />
              </div>
              {/* <div className="cont">
                <h4>{t('intern-no')}</h4>
                <p>{t('intern-no-txt')}</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="text-center" style={{ color: '#01486B', fontFamily: 'Mark-Light', fontSize: '20px' }}>
              {t('supportedUnicef')}
            </p>
            <p
              className="text-center"
              style={{ color: '#01486B', fontFamily: 'Mark-Bold', fontSize: '20px', marginLeft: '6px' }}
            >
              {t('unicefKosova')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
