// Imports: local files.
import {
    GET_ALL_INSTITUTIONS_START,
    GET_ALL_INSTITUTIONS_SUCCESS,
    GET_ALL_INSTITUTIONS_FAILED,
    GET_ALL_INSTITUTIONS_RESET,
    GET_ONE_INSTITUTION_START,
    GET_ONE_INSTITUTION_SUCCESS,
    GET_ONE_INSTITUTION_FAILED,
    GET_ONE_INSTITUTION_RESET,
    CREATE_INSTITUTION_START,
    CREATE_INSTITUTION_SUCCESS,
    CREATE_INSTITUTION_FAILED,
    CREATE_INSTITUTION_RESET,
    UPDATE_ONE_INSTITUTION_START,
    UPDATE_ONE_INSTITUTION_SUCCESS,
    UPDATE_ONE_INSTITUTION_FAILED,
    UPDATE_ONE_INSTITUTION_RESET,
    DELETE_ONE_INSTITUTION_START,
    DELETE_ONE_INSTITUTION_SUCCESS,
    DELETE_ONE_INSTITUTION_FAILED,
    DELETE_ONE_INSTITUTION_RESET,
  } from '../actions/actions';
  
  const initialLoadingState = {
    loading: false,
    success: false,
    data: null,
    error: false,
    errorMessage: null,
  };
  const initialState = {
    getAll: initialLoadingState,
    getOne: initialLoadingState,
    create: initialLoadingState,
    updateOne: initialLoadingState,
    deleteOne: initialLoadingState,
  };
  
  const institutionsReducer = (state = initialState, action) => {
    switch (action.type) {
      /**
       * =======================================================================
       * Get All Institutions
       * =======================================================================
       */
      case GET_ALL_INSTITUTIONS_START:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_INSTITUTIONS_SUCCESS:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_INSTITUTIONS_FAILED:
        return { ...state, getAll: { ...action.payload } };
      case GET_ALL_INSTITUTIONS_RESET:
        return { ...state, getAll: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Get One Institution
       * =======================================================================
       */
      case GET_ONE_INSTITUTION_START:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_INSTITUTION_SUCCESS:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_INSTITUTION_FAILED:
        return { ...state, getOne: { ...action.payload } };
      case GET_ONE_INSTITUTION_RESET:
        return { ...state, getOne: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Create New Institution
       * =======================================================================
       */
      case CREATE_INSTITUTION_START:
        return { ...state, create: { ...action.payload } };
      case CREATE_INSTITUTION_SUCCESS:
        return { ...state, create: { ...action.payload } };
      case CREATE_INSTITUTION_FAILED:
        return { ...state, create: { ...action.payload } };
      case CREATE_INSTITUTION_RESET:
        return { ...state, create: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Update One Institution
       * =======================================================================
       */
      case UPDATE_ONE_INSTITUTION_START:
        return { ...state, updateOne: { ...action.payload } };
      case UPDATE_ONE_INSTITUTION_SUCCESS:
        return { ...state, updateOne: { ...action.payload } };
      case UPDATE_ONE_INSTITUTION_FAILED:
        return { ...state, updateOne: { ...action.payload } };
      case UPDATE_ONE_INSTITUTION_RESET:
        return { ...state, updateOne: { ...initialLoadingState } };
      /**
       * =======================================================================
       * Delete One Institution
       * =======================================================================
       */
      case DELETE_ONE_INSTITUTION_START:
        return { ...state, deleteOne: { ...action.payload } };
      case DELETE_ONE_INSTITUTION_SUCCESS:
        return { ...state, deleteOne: { ...action.payload } };
      case DELETE_ONE_INSTITUTION_FAILED:
        return { ...state, deleteOne: { ...action.payload } };
      case DELETE_ONE_INSTITUTION_RESET:
        return { ...state, deleteOne: { ...initialLoadingState } };
      default:
        return state;
    }
  };
  
  export default institutionsReducer;
  