import React, { useEffect, useState } from 'react';
import { internships } from '../../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, Grid, CircularProgress } from '@mui/material';
import { toastNotification } from '../../../utils/toastNotification';
import APIClient from '../../../services/ApiClient';
import { saveAs } from 'file-saver';
import MainCard from '../../../utils/MainCard';
import SubCard from '../../../utils/SubCard';
import { StatsChart } from './Stats';
import { InternshipFilter } from './Filters/Internship';

export const InternshipReports = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const internshipResponse = useSelector(({ reports }) => reports.internships);

  const [loadingButton, setLoadingButton] = useState(false);
  const [municipalityChips, setMunicipalityChips] = useState([]);
  const [genderChips, setGenderChips] = useState([]);
  const [ethnicityChips, setEthnicityChips] = useState([]);
  const [healthConditionChips, setHealthConditionChips] = useState([]);
  const [organisation, setOrganisation] = useState('');
  const [categoryChips, setCategoryChips] = useState([]);
  const [selectedYear, setSelectedYear] = useState('choose');

  useEffect(() => {
    if (startDate || endDate) {
      let payload = {
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
      };
      if (organisation) payload['organisation'] = organisation.id;
      if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
      if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
      if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
      if (selectedYear !== 'choose') payload['birthYear'] = String(selectedYear);
      if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
      if (healthConditionChips?.length) payload['functionalConstrains'] = healthConditionChips.map((e) => e._id);
      dispatch(internships(payload));
    }
  }, [municipalityChips, genderChips, categoryChips, ethnicityChips, healthConditionChips, organisation, selectedYear]);

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (organisation) payload['organisation'] = organisation.id;
    if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
    if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
    if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
    if (selectedYear !== 'choose') payload['birthYear'] = String(selectedYear);
    if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
    if (healthConditionChips?.length) payload['functionalConstrains'] = healthConditionChips.map((e) => e._id);
    await APIClient.post(`/reports/internships/export`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Internship_Stats.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };

  if (internshipResponse.loading) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <MainCard title={t('generalStatistics')} sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InternshipFilter
                  categoryChips={categoryChips}
                  organisation={organisation}
                  municipalityChips={municipalityChips}
                  genderChips={genderChips}
                  ethnicityChips={ethnicityChips}
                  healthConditionChips={healthConditionChips}
                  setCategoryChips={setCategoryChips}
                  setOrganisation={setOrganisation}
                  setMunicipalityChips={setMunicipalityChips}
                  setGenderChips={setGenderChips}
                  setEthnicityChips={setEthnicityChips}
                  setHealthConditionChips={setHealthConditionChips}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                />
              </Grid>
              <Grid item xs={12}>
                <SubCard
                  title={t('charts')}
                  sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}
                  secondary={
                    <Button
                      variant="contained"
                      disabled={loadingButton === true ? true : false}
                      sx={{
                        backgroundColor: '#01486B !important',
                        '&:hover': { backgroundColor: '#012b40 !important' },
                        width: '160px',
                        fontFamily: 'Mark-Regular',
                        fontSize: '14px',
                        textTransform: 'none !important',
                      }}
                      onClick={(e) => {
                        onDocumentDownload(e);
                      }}
                    >
                      {loadingButton ? (
                        <>
                          <CircularProgress size={24} style={{ color: '#fff' }} />
                        </>
                      ) : (
                        <>
                          <FileDownloadOutlined /> {t('exportExcel')}
                        </>
                      )}
                    </Button>
                  }
                >
                  <Grid container spacing={3}>
                    <StatsChart
                      response={internshipResponse}
                      label={t('internships')}
                      data={internshipResponse?.data?.applications ? internshipResponse.data.applications : []}
                      chart={'Bar'}
                    />
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
