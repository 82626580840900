import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Spin } from 'antd';
import 'antd/dist/antd.css';
import DOMPurify from 'dompurify';
import moment from 'moment';
import '../../../style/jobPostings.scss';
import { EyeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography, Divider } from '@mui/material';
import InternsButton from '../../../components/buttons/InternsButton';

export const JobCard = ({ job, goToJob, fullWidth }) => {
  const language = useSelector((state) => state.language);

  const { t } = useTranslation();

  // let title = job?.title;
  // if (title.length > 10) {
  //   title = title.substring(0, 10) + '...';
  // }

  return (
    <Card
      // className="jobCards"
      id={t('includesTraining')}
      className={`jobCards ${job.training ? 'jobCardsLabel' : null}`}
      cover={
        <img
          className="companyLogo"
          style={{
            borderRadius: '13px',
            width: 61,
            height: 64,
            marginLeft: 20,
            marginTop: 20,
            objectFit: 'cover',
          }}
          src={job?.business?.businessLogo || './images/shpallje.png'}
        />
      }
      onClick={() => goToJob(job?._id)}
    >
      <Grid container>
        <Grid item xs={12} lg={12} sx={{ height: '80px' }}>
          <Typography variant="subtitle2" className="titlePosting">
            {job?.title || 'Ska titull'}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Typography variant="subtitle2" className="companyName">
              {' '}
              {job?.business?.businessName || 'Ska emer te biznesit'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ pt: 1 }}>
          <Grid item xs={12} lg={12}>
            <div id="dTag">
              <p
                className="valuePosting"
                id="pTag"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job?.body, {
                    ALLOWED_TAGS: [],
                    KEEP_TEXT: true,
                    REMOVE_TEXT: ['script'],
                  }),
                }}
              ></p>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            height: {
              md: '480px',
              sm: '525px',
              lg: '315px',
            },
          }}
        >
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  Email
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <a className="valueEmail" href={`mailTo:${job?.business?.email}`}>
                  {job?.business?.email}
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('vendi')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting">
                  {job && job?.municipality ? job?.municipality?.localisations[language].name : 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('categories')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting" id="pfield">
                  {job?.categories?.map((category) => category.localisations[language].name).join(', ') ||
                    'Ska fusha interesi'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('skills')}
                </Typography>
              </Grid>{' '}
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting" id="pfield">
                  {job?.skills?.map((skill) => skill.localisations[language].name).join(', ') || 'Ska fusha interesi'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('engagementLength')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting">
                  {job?.lengthOfEngagement
                    ? job?.lengthOfEngagement === '1'
                      ? `${job.lengthOfEngagement}`
                      : `${job.lengthOfEngagement}`
                    : t('3months')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {job.training === true && (
            <Grid item xs={12} lg={6}>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle2" className="labelPosting">
                    {t('trainingLength')}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography variant="subtitle2" className="valuePosting">
                    {/* {job?.trainingLength ? (job?.trainingLength === '1' ? `${job.trainingLength}` : null) : 'NA'} */}
                    {job?.trainingLength}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('publishedAt')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting">
                  {moment(job?.createdAt).format('DD/MM/YYYY') || '00/00/0000'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="labelPosting">
                  {t('jobDeadline')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="subtitle2" className="valuePosting">
                  {moment(job?.deadline).format('DD/MM/YYYY') || '00/00/0000'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Grid item xs={12} lg={9}>
            <InternsButton label={t('viewDetails')} icon={<EyeOutlined />} backgroundColor={'#01486B'} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
