// Imports: local files.
import {
  GET_ALL_RESOURCES_START,
  GET_ALL_RESOURCES_SUCCESS,
  GET_ALL_RESOURCES_FAILED,
  GET_ALL_RESOURCES_RESET,
  GET_ONE_RESOURCE_START,
  GET_ONE_RESOURCE_SUCCESS,
  GET_ONE_RESOURCE_FAILED,
  GET_ONE_RESOURCE_RESET,
  CREATE_RESOURCE_START,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_FAILED,
  CREATE_RESOURCE_RESET,
  UPDATE_ONE_RESOURCE_START,
  UPDATE_ONE_RESOURCE_SUCCESS,
  UPDATE_ONE_RESOURCE_FAILED,
  UPDATE_ONE_RESOURCE_RESET,
  DELETE_ONE_RESOURCE_START,
  DELETE_ONE_RESOURCE_SUCCESS,
  DELETE_ONE_RESOURCE_FAILED,
  DELETE_ONE_RESOURCE_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Resources
     * =======================================================================
     */
    case GET_ALL_RESOURCES_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_RESOURCES_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_RESOURCES_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_RESOURCES_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Resource
     * =======================================================================
     */
    case GET_ONE_RESOURCE_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_RESOURCE_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_RESOURCE_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_RESOURCE_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Resource
     * =======================================================================
     */
    case CREATE_RESOURCE_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_RESOURCE_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_RESOURCE_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_RESOURCE_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Resource
     * =======================================================================
     */
    case UPDATE_ONE_RESOURCE_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_RESOURCE_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_RESOURCE_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_RESOURCE_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Resource
     * =======================================================================
     */
    case DELETE_ONE_RESOURCE_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_RESOURCE_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_RESOURCE_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_RESOURCE_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default resourcesReducer;
