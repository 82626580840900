import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const InnerHeader = ({ icon, color, title, text }) => {
  const theme = useTheme();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={icon}
        style={{
          height: matchesXs ? '60px' : '80px',
          width: matchesXs ? '60px' : '80px',
        }}
      />
      <Box sx={{ pl: 4 }}>
        <Typography
          style={{ fontFamily: 'Mark-Medium', fontSize: '34px', color: '#01486B', textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        <Typography style={{ fontFamily: 'Mark-Medium', fontSize: '20px', lineHeight: '30px', color: '#01486B' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default InnerHeader;
