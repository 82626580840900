import { Autocomplete, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AllRoleButton from '../../../../components/buttons/AllRoleButton';
import { useStyles } from '../SurveyStatistics/style/SurveyStatistics';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from 'react';
import { ChipSelect } from '../../../../components/inputs/ChipSelect';
import {
  getAllCategories,
  getAllMunicipalities,
  getAllEthnicities,
  getAllHealthConditions,
  getAllBusinesses,
} from '../../../../store/actions/actions';
import { v4 } from 'uuid';

export const InternshipFilter = ({
  categoryChips,
  setCategoryChips,
  organisation,
  setOrganisation,
  municipalityChips,
  setMunicipalityChips,
  genderChips,
  setGenderChips,
  ethnicityChips,
  setEthnicityChips,
  healthConditionChips,
  setHealthConditionChips,
  selectedYear,
  setSelectedYear,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedMunicipality, setSelectedMunicipality] = useState('choose');
  const [selectedGender, setSelectedGender] = useState('choose');
  const [selectedEthnicity, setSelectedEthnicity] = useState('choose');
  const [selectedHealthCondition, setSelectedHealthCondition] = useState('choose');
  const [selectedCategory, setSelectedCategory] = useState('choose');
  const [options, setOptions] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState(v4());

  const categoriesResponse = useSelector(({ categories }) => categories.getAll);
  const businessesResponse = useSelector(({ businesses }) => businesses.getAll);
  const municipalitiesResponse = useSelector(({ municipalities }) => municipalities.getAll);
  const ethnicitiesResponse = useSelector(({ ethnicities }) => ethnicities.getAll);
  const healthConditionsResponse = useSelector(({ healthConditions }) => healthConditions.getAll);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getAllCategories({ pagination: false, language }));
    dispatch(getAllBusinesses({ pagination: false, language }));
    dispatch(getAllMunicipalities({ pagination: false, language }));
    dispatch(getAllEthnicities({ pagination: false, language }));
    dispatch(getAllHealthConditions({ pagination: false, language }));
  }, []);

  const date = new Date();
  const currentYear = date.getFullYear();

  const first = currentYear - 25;
  const last = currentYear - 16;

  let newOptions = [];
  for (let i = first; i <= last; i++) {
    newOptions.push(i);
  }

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  const onCategoryChange = (category, type) => {
    switch (type) {
      case 'add':
        const exists = categoryChips.find((chip) => chip._id === category._id);
        if (exists) return;

        const addedArray = [...categoryChips, category];
        setCategoryChips(addedArray);
        setSelectedCategory(category._id);
        break;
      case 'delete':
        const filteredArray = categoryChips.filter((chip) => chip._id !== category._id);
        setCategoryChips(filteredArray);
        setSelectedCategory('choose');
        break;
      default:
        break;
    }
  };
  const onOrganisationChange = (org) => {
    setOrganisation(org);
  };
  const onMunicipalityChange = (municipality, type) => {
    switch (type) {
      case 'add':
        const exists = municipalityChips.find((chip) => chip._id === municipality._id);
        if (exists) return;

        const addedArray = [...municipalityChips, municipality];
        setMunicipalityChips(addedArray);
        setSelectedMunicipality(municipality._id);
        break;
      case 'delete':
        const filteredArray = municipalityChips.filter((chip) => chip._id !== municipality._id);
        setMunicipalityChips(filteredArray);
        setSelectedMunicipality('choose');
        break;
      default:
        break;
    }
  };
  const onGenderChange = (gender, type) => {
    switch (type) {
      case 'add':
        const exists = genderChips.find((chip) => chip._id === gender._id);
        if (exists) return;

        const addedArray = [...genderChips, gender];
        setGenderChips(addedArray);
        setSelectedGender(gender._id);
        break;
      case 'delete':
        const filteredArray = genderChips.filter((chip) => chip._id !== gender._id);
        setGenderChips(filteredArray);
        setSelectedGender('choose');
        break;
      default:
        break;
    }
  };

  const onEthnicityChange = (ethnicity, type) => {
    switch (type) {
      case 'add':
        const exists = ethnicityChips.find((chip) => chip === ethnicity);
        if (exists) return;

        const addedArray = [...ethnicityChips, ethnicity];
        setEthnicityChips(addedArray);
        setSelectedEthnicity(ethnicity);
        break;
      case 'delete':
        const filteredArray = ethnicityChips.filter((chip) => chip !== ethnicity);
        setEthnicityChips(filteredArray);
        setSelectedEthnicity('choose');
        break;
      default:
        break;
    }
  };

  const onHealthConditionChange = (healthCondition, type) => {
    switch (type) {
      case 'add':
        const exists = healthConditionChips.find((chip) => chip === healthCondition);
        if (exists) return;

        const addedArray = [...healthConditionChips, healthCondition];
        setHealthConditionChips(addedArray);
        setSelectedHealthCondition(healthCondition);
        break;
      case 'delete':
        const filteredArray = healthConditionChips.filter((chip) => chip !== healthCondition);
        setHealthConditionChips(filteredArray);
        setSelectedHealthCondition('choose');
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    setSelectedMunicipality('choose');
    setSelectedGender('choose');
    setSelectedEthnicity('choose');
    setSelectedHealthCondition('choose');
    setSelectedCategory('choose');
    setMunicipalityChips([]);
    setCategoryChips([]);
    setEthnicityChips([]);
    setGenderChips([]);
    setHealthConditionChips([]);
    setSelectedYear('choose');
    setOrganisation('');
    setAutocompleteKey(v4());
  };

  useEffect(() => {
    if (businessesResponse) {
      switch (true) {
        case businessesResponse.loading:
          break;
        case businessesResponse.success:
          const { docs } = businessesResponse.data.businesses;
          let newOptions = [];
          if (docs) docs.map((e) => newOptions.push({ title: `${e.businessName}`, id: e._id }));
          setOptions(newOptions);
          break;
        case businessesResponse.error:
          break;
        default:
          break;
      }
    }
  }, [businessesResponse]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="ethnicity"
              label={t('ethnicity')}
              selectLabel={t('chooseEthnicity')}
              handleChange={(e) => onEthnicityChange(JSON.parse(e.target.value), 'add')}
              value={selectedEthnicity}
              data={
                ethnicitiesResponse?.success && ethnicitiesResponse.data?.ethnicities?.docs
                  ? ethnicitiesResponse.data.ethnicities.docs
                  : []
              }
              valueChips={ethnicityChips}
              handleDelete={onEthnicityChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="healthCondition"
              label={t('healthConditions')}
              selectLabel={t('chooseHealthCondition')}
              handleChange={(e) => onHealthConditionChange(JSON.parse(e.target.value), 'add')}
              value={selectedHealthCondition}
              data={
                healthConditionsResponse?.success && healthConditionsResponse.data?.healthconditions?.docs
                  ? healthConditionsResponse.data.healthconditions.docs
                  : []
              }
              valueChips={healthConditionChips}
              handleDelete={onHealthConditionChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="gender"
              label={t('gender')}
              selectLabel={t('gender')}
              handleChange={(e) => onGenderChange(JSON.parse(e.target.value), 'add')}
              value={selectedGender}
              data={[
                { _id: 'M', label: t('gender1') },
                { _id: 'F', label: t('gender2') },
                { _id: 'O', label: t('gender3') },
              ]}
              valueChips={genderChips}
              handleDelete={onGenderChange}
              ignoreLoc={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="categories"
              label={t('filter-interest')}
              selectLabel={t('chooseCategories')}
              handleChange={(e) => onCategoryChange(JSON.parse(e.target.value), 'add')}
              value={selectedCategory}
              data={
                categoriesResponse?.success && categoriesResponse.data?.categories?.docs
                  ? categoriesResponse.data.categories.docs
                  : []
              }
              valueChips={categoryChips}
              handleDelete={onCategoryChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="municipalities"
              label={t('Komuna')}
              selectLabel={t('ChooseMunicipality')}
              handleChange={(e) => onMunicipalityChange(JSON.parse(e.target.value), 'add')}
              value={selectedMunicipality}
              data={
                municipalitiesResponse?.success && municipalitiesResponse.data?.municipalities?.docs
                  ? municipalitiesResponse.data.municipalities.docs
                  : []
              }
              valueChips={municipalityChips}
              handleDelete={onMunicipalityChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('yearOfBirth')}</InputLabel>
            <Select
              fullWidth
              id="type"
              name="age"
              value={selectedYear}
              className={classes.selectInput}
              onChange={(e) => onYearChange(e.target.value)}
            >
              <MenuItem value="choose" disabled className={classes.menuItem}>
                {t('yearOfBirth')}
              </MenuItem>
              {newOptions.map((e, index) => (
                <MenuItem key={index} value={e} className={classes.menuItem}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('Business')}</InputLabel>
            <Autocomplete
              id="tags-standard"
              options={options}
              getOptionLabel={(option) => option.title}
              defaultValue={organisation ? organisation : null}
              key={autocompleteKey}
              ListboxProps={{
                sx: {
                  minHeight: '34px',
                  fontFamily: 'Mark-Regular !important',
                  color: 'rgb(112, 112, 112) !important',
                  fontSize: '14px !important',
                },
              }}
              onChange={(e, value) => {
                if (!value) onOrganisationChange('');
                else onOrganisationChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className={`${classes.defaultInput}`}
                  variant="outlined"
                  placeholder={t('chooseOrg')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: { md: 3.5, lg: 3.6, xl: 3.6 } }}>
            <AllRoleButton
              label={t('resetFilters')}
              handleClick={() => resetFilters()}
              backgroundColor={'#F36E21'}
              icon={<RemoveCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
