// Imports: local files.
import ApiClient from '../../services/ApiClient';
import Swal from 'sweetalert2';

// Action Types: Get All Meetings.
export const GET_ALL_MEETINGS_START = 'GET_ALL_MEETINGS_START';
export const GET_ALL_MEETINGS_SUCCESS = 'GET_ALL_MEETINGS_SUCCESS';
export const GET_ALL_MEETINGS_FAILED = 'GET_ALL_MEETINGS_FAILED';
export const GET_ALL_MEETINGS_RESET = 'GET_ALL_MEETINGS_RESET';

// Action Types: Get One Meeting.
export const GET_ONE_MEETING_START = 'GET_ONE_MEETING_START';
export const GET_ONE_MEETING_SUCCESS = 'GET_ONE_MEETING_SUCCESS';
export const GET_ONE_MEETING_FAILED = 'GET_ONE_MEETING_FAILED';
export const GET_ONE_MEETING_RESET = 'GET_ONE_MEETING_RESET';

// Action Types: Create New Meeting.
export const CREATE_MEETING_START = 'CREATE_MEETING_START';
export const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_FAILED = 'CREATE_MEETING_FAILED';
export const CREATE_MEETING_RESET = 'CREATE_MEETING_RESET';

// Action Types: Update One Meeting.
export const UPDATE_ONE_MEETING_START = 'UPDATE_ONE_MEETING_START';
export const UPDATE_ONE_MEETING_SUCCESS = 'UPDATE_ONE_MEETING_SUCCESS';
export const UPDATE_ONE_MEETING_FAILED = 'UPDATE_ONE_MEETING_FAILED';
export const UPDATE_ONE_MEETING_RESET = 'UPDATE_ONE_MEETING_RESET';

// Action Types: Cancel One Meeting.
export const CANCEL_ONE_MEETING_START = 'CANCEL_ONE_MEETING_START';
export const CANCEL_ONE_MEETING_SUCCESS = 'CANCEL_ONE_MEETING_SUCCESS';
export const CANCEL_ONE_MEETING_FAILED = 'CANCEL_ONE_MEETING_FAILED';
export const CANCEL_ONE_MEETING_RESET = 'CANCEL_ONE_MEETING_RESET';

// Action Types: Delete One Meeting.
export const DELETE_ONE_MEETING_START = 'DELETE_ONE_MEETING_START';
export const DELETE_ONE_MEETING_SUCCESS = 'DELETE_ONE_MEETING_SUCCESS';
export const DELETE_ONE_MEETING_FAILED = 'DELETE_ONE_MEETING_FAILED';
export const DELETE_ONE_MEETING_RESET = 'DELETE_ONE_MEETING_RESET';

// Action Creators: Get All Meetings.
const getAllMeetingsStart = (payload) => ({
  type: GET_ALL_MEETINGS_START,
  payload,
});
const getAllMeetingsSuccess = (payload) => ({
  type: GET_ALL_MEETINGS_SUCCESS,
  payload,
});
const getAllMeetingsFailed = (payload) => ({
  type: GET_ALL_MEETINGS_FAILED,
  payload,
});
const getAllMeetingsReset = () => ({ type: GET_ALL_MEETINGS_RESET });

// Action Creators: Get One Meeting.
const getOneMeetingStart = (payload) => ({
  type: GET_ONE_MEETING_START,
  payload,
});
const getOneMeetingSuccess = (payload) => ({
  type: GET_ONE_MEETING_SUCCESS,
  payload,
});
const getOneMeetingFailed = (payload) => ({
  type: GET_ONE_MEETING_FAILED,
  payload,
});
const getOneMeetingReset = () => ({ type: GET_ONE_MEETING_RESET });

// Action Creators: Create New Meeting.
const createMeetingStart = (payload) => ({
  type: CREATE_MEETING_START,
  payload,
});
const createMeetingSuccess = (payload) => ({
  type: CREATE_MEETING_SUCCESS,
  payload,
});
const createMeetingFailed = (payload) => ({
  type: CREATE_MEETING_FAILED,
  payload,
});
const createMeetingReset = () => ({ type: CREATE_MEETING_RESET });

// Action Creators: Update One Meeting.
const updateOneMeetingStart = (payload) => ({
  type: UPDATE_ONE_MEETING_START,
  payload,
});
const updateOneMeetingSuccess = (payload) => ({
  type: UPDATE_ONE_MEETING_SUCCESS,
  payload,
});
const updateOneMeetingFailed = (payload) => ({
  type: UPDATE_ONE_MEETING_FAILED,
  payload,
});
const updateOneMeetingReset = () => ({ type: UPDATE_ONE_MEETING_RESET });

// Action Creators: Cancel One Meeting.
const cancelOneMeetingStart = (payload) => ({
  type: CANCEL_ONE_MEETING_START,
  payload,
});
const cancelOneMeetingSuccess = (payload) => ({
  type: CANCEL_ONE_MEETING_SUCCESS,
  payload,
});
const cancelOneMeetingFailed = (payload) => ({
  type: CANCEL_ONE_MEETING_FAILED,
  payload,
});
const cancelOneMeetingReset = () => ({ type: CANCEL_ONE_MEETING_RESET });

// Action Creators: Delete One Meeting.
const deleteOneMeetingStart = (payload) => ({
  type: DELETE_ONE_MEETING_START,
  payload,
});
const deleteOneMeetingSuccess = (payload) => ({
  type: DELETE_ONE_MEETING_SUCCESS,
  payload,
});
const deleteOneMeetingFailed = (payload) => ({
  type: DELETE_ONE_MEETING_FAILED,
  payload,
});
const deleteOneMeetingReset = () => ({ type: DELETE_ONE_MEETING_RESET });

// Actions: Get All Meetings.
export const getAllMeetings = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMeetingsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, interns, mentors, availability, startDate, endDate } = payload;
      const result = await ApiClient.get('meetings', {
        params: {
          page,
          limit,
          interns,
          mentors,
          availability,
          startDate,
          endDate,
        },
      });
      if (result.data?.success) {
        const { meetings } = result.data.data;
        dispatch(
          getAllMeetingsSuccess({
            loading: false,
            success: true,
            data: { meetings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMeetingsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMeetingsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Meetings.
export const clearGetAllMeetings = () => getAllMeetingsReset();

// Actions: Get One Meeting.
export const getOneMeeting = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneMeetingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { meetingId } = payload;
      const result = await ApiClient.get(`meetings/${meetingId}`);
      if (result.data?.success) {
        const { meeting } = result.data.data;
        dispatch(
          getOneMeetingSuccess({
            loading: false,
            success: true,
            data: { meeting },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneMeetingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneMeetingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Meeting.
export const clearGetOneMeeting = () => getOneMeetingReset();

// Actions: Create New Meeting.
export const createMeeting = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createMeetingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { attendees, title, description, link, startDate, emailLanguage } = payload;
    const { toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('meetings', {
        attendees,
        title,
        description,
        link,
        startDate,
        emailLanguage,
      });
      if (result.data?.success) {
        const { meeting } = result.data.data;
        dispatch(
          createMeetingSuccess({
            loading: false,
            success: true,
            data: { meeting },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        shouldRedirect && history.push(pathname);
      } else {
        dispatch(
          createMeetingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createMeetingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
      //   if (
      //     error &&
      //     error.response &&
      //     error.response.data &&
      //     error.response.data.errorType &&
      //     error.response.data.errorType === 'ALREADY_WORKING'
      //   ) {
      //     toastNotification('error', 'You are already working!');
      //   } else {
      //     toastNotification('error', onFailMessage);
      //   }
    }
  };
};

// Actions: Clear Create Meeting.
export const clearCreateMeeting = () => createMeetingReset();

// Actions: Update One Meeting.
export const updateOneMeeting = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      updateOneMeetingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );
    const { meetingId, attendees, title, description, link, startDate, emailLanguage } = payload;
    const { toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`meetings/${meetingId}`, {
        attendees,
        title,
        description,
        link,
        startDate,
        emailLanguage,
      });
      if (result.data?.success) {
        const { meeting } = result.data.data;
        dispatch(
          updateOneMeetingSuccess({
            loading: false,
            success: true,
            data: { meeting },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        shouldRedirect && history.push(pathname);
        // if (showSwal) {
        //   const swalOptions = {
        //     title: 'Intern hired successfully!',
        //     text: 'This intern now works in your company!',
        //     icon: 'success',
        //     showCancelButton: false,
        //     confirmButtonColor: '#023142',
        //     cancelButtonColor: '#ff4d4f',
        //     confirmButtonText: 'OK',
        //   };
        //   Swal.fire(swalOptions).then((p) => {});
        //   shouldRedirect && history.push(pathname);
        // } else {
        //   toastNotification('success', onSuccessMessage);
        //   shouldRedirect && history.push(pathname);
        // }
      } else {
        dispatch(
          updateOneMeetingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneMeetingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
      //   if (
      //     error &&
      //     error.response &&
      //     error.response.data &&
      //     error.response.data.errorType &&
      //     error.response.data.errorType === 'ALREADY_WORKING'
      //   ) {
      //     toastNotification('error', 'Intern already working!');
      //   } else {
      //     toastNotification('error', onFailMessage);
      //   }
    }
  };
};

// Actions: Clear Update One Meeting.
export const clearUpdateOneMeeting = () => updateOneMeetingReset();

// Actions: Cancel One Meeting.
export const cancelOneMeeting = (payload, options, showSwal = false) => {
  return async (dispatch) => {
    dispatch(
      cancelOneMeetingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );
    const { meetingId, emailLanguage } = payload;
    const { toastNotification, shouldRedirect, history, pathname, onSuccessMessage, onFailMessage } = options;
    try {
      const result = await ApiClient.put(`meetings/${meetingId}/cancel`, {
        emailLanguage,
      });
      if (result.data?.success) {
        const { meeting } = result.data.data;
        dispatch(
          cancelOneMeetingSuccess({
            loading: false,
            success: true,
            data: { meeting },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
        shouldRedirect && history.push(pathname);
      } else {
        dispatch(
          cancelOneMeetingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        cancelOneMeetingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Cancel One Meeting.
export const clearCancelOneMeeting = () => cancelOneMeetingReset();

// Actions: Delete One Meeting.
export const deleteOneMeeting = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneMeetingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { meetingId } = payload;
      const result = await ApiClient.delete(`meetings/${meetingId}`);
      if (result.data?.success) {
        const { meeting } = result.data.data;
        dispatch(
          deleteOneMeetingSuccess({
            loading: false,
            success: true,
            data: { meeting },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneMeetingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneMeetingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Meeting.
export const clearDeleteOneMeeting = () => deleteOneMeetingReset();
