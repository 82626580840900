import React from 'react';
import { toastNotification } from '../../../utils/toastNotification';
import { Header } from '../../../components/Header';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetPassBusiness, resetPassMentor, resetPassIntern } from '../../../store/actions/actions';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { OutlinedInput, IconButton } from '@mui/material';
import { Form } from 'antd';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Label from '../../../components/inputs/Label';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '35px !important',
    borderRadius: '7px !important',
    fontSize: '14px !important',
    fontFamily: 'Mark-Light !important',
    color: '#1E1E1E !important',
    boxShadow: '0px 3px 6px #00000029 !important',
    opacity: 1,
    marginTop: '7px',
    '& input::placeholder': {
      fontFamily: 'Mark-Light !important',
      fontSize: '13px',
      color: '#1E1E1E !important',
      alignItems: 'center',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white',
    },
    '&.Mui-focused': {
      border: '1px solid #01486B',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
});

const schema = Joi.object({
  newPassword: Joi.string().min(8).required(),
  passwordConfirm: Joi.string().min(8).required(),
}).required();

const ResetBusinessPassword = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const token = localStorage.getItem('kgen-token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const base64 = new URLSearchParams(location.search).get('q');
  if (!base64) {
    history.push('/admin/login');
    return null;
  }
  const information = new URLSearchParams(Buffer.from(base64, 'base64').toString('utf-8'));
  const resetToken = information.get('resetToken');
  const email = information.get('email');
  const type = information.get('type');

  const onSubmit = (data) => {
    const { newPassword, passwordConfirm } = data;

    if (newPassword !== passwordConfirm) {
      toastNotification('error', t('confirmNewPassword'));
      return;
    }
    const payload = { email, newPassword, passwordConfirm, resetToken, type };

    const options = {
      toastNotification,
      history,
      onSuccessMessage: t('passwordResetSuccessfully'),
      onFailMessage: t('newPass'),
      onFailMessageExpired: t('linkExpired'),
    };
    if (type === 'mentor') {
      dispatch(resetPassMentor(payload, { ...options, pathname: '/admin/login' }));
    } else if (type === 'business' || type === 'admin') {
      dispatch(resetPassBusiness(payload, { ...options, pathname: '/admin/login' }));
    } else {
      dispatch(resetPassIntern(payload, { ...options, pathname: '/login' }));
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickConfrimShowPassword = () => setShowConfirmPassword(!showConfirmPassword);

  if (token) return <Redirect to="/" />;
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Header {...props} />
      <div id="resetPassword" className="page-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="title">{t('password-qs1')}</h1>
              <h3 className="subtitle">
                {t('password-qs1-tx')}
                <br />
                {t('password-qs1-tx1')}
                <a className="secondary" href="mailto:info@kosovogenu.com">
                  info@kosovogenu.com
                </a>
              </h3>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} id="org-form" layout="vertical" name="basic">
            <div className="row">
              <div className="col-sm-12 offset-md-4 col-md-4">
                <Label label={'Email'} />
                <OutlinedInput
                  fullWidth
                  className={classes.root}
                  placeholder={'Email'}
                  id="email"
                  value={email}
                  disabled
                  sx={{ backgroundColor: '#e9ecef', marginBottom: '10px' }}
                />
              </div>

              <div className="col-sm-12 offset-md-4 col-md-4">
                <Label label={t('new-pass')} />
                <OutlinedInput
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  className={classes.root}
                  placeholder={t('new-pass')}
                  id="password"
                  onChange={(e) => setValue('newPassword', e.target.value)}
                  endAdornment={
                    <IconButton position="start" onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  sx={{
                    border: errors.newPassword ? '1px solid red' : '1px solid #d9d9d9',
                    marginBottom: '10px',
                    '&:hover fieldset': {
                      borderColor: errors.newPassword ? 'red !important' : '#01486B !important',
                    },
                  }}
                />

                {errors.newPassword && <span style={{ color: 'red' }}>{t('newPasswordError')}</span>}
              </div>

              <div className="col-sm-12 offset-md-4 col-md-4">
                <Label label={t('new-pass-confirm')} />
                <OutlinedInput
                  fullWidth
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={classes.root}
                  placeholder={t('new-pass-confirm')}
                  id="password"
                  onChange={(e) => setValue('passwordConfirm', e.target.value)}
                  endAdornment={
                    <IconButton position="start" onClick={handleClickConfrimShowPassword}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  sx={{
                    border: errors.passwordConfirm ? '1px solid red' : '1px solid #d9d9d9',
                    marginBottom: '15px',
                    '&:hover fieldset': {
                      borderColor: errors.passwordConfirm ? 'red !important' : '#01486B !important',
                    },
                  }}
                />
                {errors.passwordConfirm && <span style={{ color: 'red' }}>{t('confirmPasswordError')}</span>}
              </div>
              <div className="col-sm-8 text-right">
                <Form.Item>
                  <button type="submit" className="btn btn-kgen-secondary btn-lg pl-5 pr-5 pt-2 pb-2">
                    {t('submit')}
                  </button>
                </Form.Item>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('translations')(ResetBusinessPassword);
