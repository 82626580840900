import '../../../style/jobPostings.scss';
import { useTranslation } from 'react-i18next';

export const Pagination = ({
  page,
  jobPagination,
  prevPage,
  nextPage,
  limit,
  defaultLimit,
  defaultPage,
  setLimit,
  setPage,
  allPostingsLimit,
  setAllPostingsLimit,
}) => {
  const { t } = useTranslation();
  return (
    <div className="kgen-pagination">
      <nav aria-label="Page navigation">
        <ul className="pagination" style={{ marginTop: '60px' }}>
          {jobPagination?.hasPrevPage && page > 1 ? (
            <li className="page-item ml-2">
              <button className="page-link" aria-label="Previous" onClick={() => prevPage()}>
                <span aria-hidden="true"> &lt;</span>
              </button>
            </li>
          ) : (
            <li className="page-item ml-2">
              <button className="page-link" aria-label="Previous" disabled>
                <span aria-hidden="true"> &lt;</span>
              </button>
            </li>
          )}
          {jobPagination?.prevPage ? (
            <li className="page-item ml-2">
              <button className="page-link" onClick={() => prevPage()}>
                {jobPagination.prevPage}
              </button>
            </li>
          ) : null}
          <li className="page-item ml-2 active">
            <a className="page-link" href="#">
              {jobPagination?.page}
            </a>
          </li>
          {jobPagination?.nextPage ? (
            <li className="page-item ml-2">
              <button className="page-link" onClick={() => nextPage()}>
                {jobPagination.nextPage}
              </button>
            </li>
          ) : null}
          {jobPagination?.hasNextPage && page < jobPagination?.totalPages ? (
            <li className="page-item ml-2 mr-2">
              <button className="page-link" aria-label="Next" onClick={() => nextPage()}>
                <span aria-hidden="true">&gt;</span>
              </button>
            </li>
          ) : (
            <li className="page-item ml-2 mr-2">
              <button className="page-link" aria-label="Next" disabled>
                <span aria-hidden="true">&gt;</span>
              </button>
            </li>
          )}
          <li>
            <select
              className="ml-2 jobLimit"
              defaultValue={allPostingsLimit ? allPostingsLimit : defaultLimit}
              value={allPostingsLimit ? allPostingsLimit : limit}
              onChange={(e) => {
                allPostingsLimit ? setAllPostingsLimit(e.target.value) : setLimit(e.target.value);
                setPage(defaultPage);
              }}
            >
              <option value={3}>{t('opportunitiesOption1')}</option>
              <option value={6}>{t('opportunitiesOption2')}</option>
            </select>
          </li>
        </ul>
      </nav>
    </div>
  );
};
