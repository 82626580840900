// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Municipalities.
export const GET_ALL_MUNICIPALITIES_START = 'GET_ALL_MUNICIPALITIES_START';
export const GET_ALL_MUNICIPALITIES_SUCCESS = 'GET_ALL_MUNICIPALITIES_SUCCESS';
export const GET_ALL_MUNICIPALITIES_FAILED = 'GET_ALL_MUNICIPALITIES_FAILED';
export const GET_ALL_MUNICIPALITIES_RESET = 'GET_ALL_MUNICIPALITIES_RESET';

// Action Types: Get One Municipality.
export const GET_ONE_MUNICIPALITY_START = 'GET_ONE_MUNICIPALITY_START';
export const GET_ONE_MUNICIPALITY_SUCCESS = 'GET_ONE_MUNICIPALITY_SUCCESS';
export const GET_ONE_MUNICIPALITY_FAILED = 'GET_ONE_MUNICIPALITY_FAILED';
export const GET_ONE_MUNICIPALITY_RESET = 'GET_ONE_MUNICIPALITY_RESET';

// Action Types: Create New Municipality.
export const CREATE_MUNICIPALITY_START = 'CREATE_MUNICIPALITY_START';
export const CREATE_MUNICIPALITY_SUCCESS = 'CREATE_MUNICIPALITY_SUCCESS';
export const CREATE_MUNICIPALITY_FAILED = 'CREATE_MUNICIPALITY_FAILED';
export const CREATE_MUNICIPALITY_RESET = 'CREATE_MUNICIPALITY_RESET';

// Action Types: Update One Municipality.
export const UPDATE_ONE_MUNICIPALITY_START = 'UPDATE_ONE_MUNICIPALITY_START';
export const UPDATE_ONE_MUNICIPALITY_SUCCESS = 'UPDATE_ONE_MUNICIPALITY_SUCCESS';
export const UPDATE_ONE_MUNICIPALITY_FAILED = 'UPDATE_ONE_MUNICIPALITY_FAILED';
export const UPDATE_ONE_MUNICIPALITY_RESET = 'UPDATE_ONE_MUNICIPALITY_RESET';

// Action Types: Delete One Municipality.
export const DELETE_ONE_MUNICIPALITY_START = 'DELETE_ONE_MUNICIPALITY_START';
export const DELETE_ONE_MUNICIPALITY_SUCCESS = 'DELETE_ONE_MUNICIPALITY_SUCCESS';
export const DELETE_ONE_MUNICIPALITY_FAILED = 'DELETE_ONE_MUNICIPALITY_FAILED';
export const DELETE_ONE_MUNICIPALITY_RESET = 'DELETE_ONE_MUNICIPALITY_RESET';

// Action Creators: Get All Municipalities.
const getAllMunicipalitiesStart = (payload) => ({
  type: GET_ALL_MUNICIPALITIES_START,
  payload,
});
const getAllMunicipalitiesSuccess = (payload) => ({
  type: GET_ALL_MUNICIPALITIES_SUCCESS,
  payload,
});
const getAllMunicipalitiesFailed = (payload) => ({
  type: GET_ALL_MUNICIPALITIES_FAILED,
  payload,
});
const getAllMunicipalitiesReset = () => ({ type: GET_ALL_MUNICIPALITIES_RESET });

// Action Creators: Get One Municipality.
const getOneMunicipalityStart = (payload) => ({
  type: GET_ONE_MUNICIPALITY_START,
  payload,
});
const getOneMunicipalitySuccess = (payload) => ({
  type: GET_ONE_MUNICIPALITY_SUCCESS,
  payload,
});
const getOneMunicipalityFailed = (payload) => ({
  type: GET_ONE_MUNICIPALITY_FAILED,
  payload,
});
const getOneMunicipalityReset = () => ({ type: GET_ONE_MUNICIPALITY_RESET });

// Action Creators: Create New Municipality.
const createMunicipalityStart = (payload) => ({
  type: CREATE_MUNICIPALITY_START,
  payload,
});
const createMunicipalitySuccess = (payload) => ({
  type: CREATE_MUNICIPALITY_SUCCESS,
  payload,
});
const createMunicipalityFailed = (payload) => ({
  type: CREATE_MUNICIPALITY_FAILED,
  payload,
});
const createMunicipalityReset = () => ({ type: CREATE_MUNICIPALITY_RESET });

// Action Creators: Update One Municipality.
const updateOneMunicipalityStart = (payload) => ({
  type: UPDATE_ONE_MUNICIPALITY_START,
  payload,
});
const updateOneMunicipalitySuccess = (payload) => ({
  type: UPDATE_ONE_MUNICIPALITY_SUCCESS,
  payload,
});
const updateOneMunicipalityFailed = (payload) => ({
  type: UPDATE_ONE_MUNICIPALITY_FAILED,
  payload,
});
const updateOneMunicipalityReset = () => ({ type: UPDATE_ONE_MUNICIPALITY_RESET });

// Action Creators: Delete One Municipality.
const deleteOneMunicipalityStart = (payload) => ({
  type: DELETE_ONE_MUNICIPALITY_START,
  payload,
});
const deleteOneMunicipalitySuccess = (payload) => ({
  type: DELETE_ONE_MUNICIPALITY_SUCCESS,
  payload,
});
const deleteOneMunicipalityFailed = (payload) => ({
  type: DELETE_ONE_MUNICIPALITY_FAILED,
  payload,
});
const deleteOneMunicipalityReset = () => ({ type: DELETE_ONE_MUNICIPALITY_RESET });

// Actions: Get All Municipalities.
export const getAllMunicipalities = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMunicipalitiesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('municipalities', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { municipalities } = result.data.data;
        dispatch(
          getAllMunicipalitiesSuccess({
            loading: false,
            success: true,
            data: { municipalities },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMunicipalitiesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMunicipalitiesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Municipalities.
export const clearGetAllMunicipalities = () => getAllMunicipalitiesReset();

// Actions: Get One Municipality.
export const getOneMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { municipalityId } = payload;
      const result = await ApiClient.get(`municipalities/${municipalityId}`);
      if (result.data?.success) {
        const { municipality } = result.data.data;
        dispatch(
          getOneMunicipalitySuccess({
            loading: false,
            success: true,
            data: { municipality },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Municipality.
export const clearGetOneMunicipality = () => getOneMunicipalityReset();

// Actions: Create New Municipality.
export const createMunicipality = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onMunicipalityExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('municipalities', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { municipality } = result.data.data;
        dispatch(
          createMunicipalitySuccess({
            loading: false,
            success: true,
            data: { municipality },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const municipalityExists = 'MUNICIPALITY_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === municipalityExists
      ) {
        toastNotification('error', onMunicipalityExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Municipality.
export const clearCreateNewMunicipality = () => createMunicipalityReset();

// Actions: Update One Municipality.
export const updateOneMunicipality = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { municipalityId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`municipalities/${municipalityId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { municipality } = result.data.data;
        dispatch(
          updateOneMunicipalitySuccess({
            loading: false,
            success: true,
            data: { municipality },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Municipality.
export const clearUpdateOneMunicipality = () => updateOneMunicipalityReset();

// Actions: Delete One Municipality.
export const deleteOneMunicipality = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneMunicipalityStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { municipalityId } = payload;
      const result = await ApiClient.delete(`municipalities/${municipalityId}`);
      if (result.data?.success) {
        const { municipality } = result.data.data;
        dispatch(
          deleteOneMunicipalitySuccess({
            loading: false,
            success: true,
            data: { municipality },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneMunicipalityFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneMunicipalityFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Municipality.
export const clearDeleteOneMunicipality = () => deleteOneMunicipalityReset();
