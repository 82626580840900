// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Trainings.
export const GET_ALL_TRAININGS_START = 'GET_ALL_TRAININGS_START';
export const GET_ALL_TRAININGS_SUCCESS = 'GET_ALL_TRAININGS_SUCCESS';
export const GET_ALL_TRAININGS_FAILED = 'GET_ALL_TRAININGS_FAILED';
export const GET_ALL_TRAININGS_RESET = 'GET_ALL_TRAININGS_RESET';

// Action Types: Get One Training.
export const GET_ONE_TRAINING_START = 'GET_ONE_TRAINING_START';
export const GET_ONE_TRAINING_SUCCESS = 'GET_ONE_TRAINING_SUCCESS';
export const GET_ONE_TRAINING_FAILED = 'GET_ONE_TRAINING_FAILED';
export const GET_ONE_TRAINING_RESET = 'GET_ONE_TRAINING_RESET';

// Action Types: Create New Training.
export const CREATE_TRAINING_START = 'CREATE_TRAINING_START';
export const CREATE_TRAINING_SUCCESS = 'CREATE_TRAINING_SUCCESS';
export const CREATE_TRAINING_FAILED = 'CREATE_TRAINING_FAILED';
export const CREATE_TRAINING_RESET = 'CREATE_TRAINING_RESET';

// Action Types: Update One Training.
export const UPDATE_ONE_TRAINING_START = 'UPDATE_ONE_TRAINING_START';
export const UPDATE_ONE_TRAINING_SUCCESS = 'UPDATE_ONE_TRAINING_SUCCESS';
export const UPDATE_ONE_TRAINING_FAILED = 'UPDATE_ONE_TRAINING_FAILED';
export const UPDATE_ONE_TRAINING_RESET = 'UPDATE_ONE_TRAINING_RESET';

// Action Types: Delete One Training.
export const DELETE_ONE_TRAINING_START = 'DELETE_ONE_TRAINING_START';
export const DELETE_ONE_TRAINING_SUCCESS = 'DELETE_ONE_TRAINING_SUCCESS';
export const DELETE_ONE_TRAINING_FAILED = 'DELETE_ONE_TRAINING_FAILED';
export const DELETE_ONE_TRAINING_RESET = 'DELETE_ONE_TRAINING_RESET';

// Action Types: Get All Matched Interns
export const GET_ALL_MATCHED_INTERNS_START = 'GET_ALL_MATCHED_INTERNS_START';
export const GET_ALL_MATCHED_INTERNS_SUCCESS = 'GET_ALL_MATCHED_INTERNS_SUCCESS';
export const GET_ALL_MATCHED_INTERNS_FAILED = 'GET_ALL_MATCHED_INTERNS_FAILED';
export const GET_ALL_MATCHED_INTERNS_RESET = 'GET_ALL_MATCHED_INTERNS_RESET';

// Action Types: Invite One Intern
export const INVITE_ONE_INTERN_START = 'INVITE_ONE_INTERN_START';
export const INVITE_ONE_INTERN_SUCCESS = 'INVITE_ONE_INTERN_SUCCESS';
export const INVITE_ONE_INTERN_FAILED = 'INVITE_ONE_INTERN_FAILED';
export const INVITE_ONE_INTERN_RESET = 'INVITE_ONE_INTERN_RESET';

// Action Creators: Get All Trainings.
const getAllTrainingsStart = (payload) => ({
  type: GET_ALL_TRAININGS_START,
  payload,
});
const getAllTrainingsSuccess = (payload) => ({
  type: GET_ALL_TRAININGS_SUCCESS,
  payload,
});
const getAllTrainingsFailed = (payload) => ({
  type: GET_ALL_TRAININGS_FAILED,
  payload,
});
const getAllTrainingsReset = () => ({ type: GET_ALL_TRAININGS_RESET });

// Action Creators: Get One Training.
const getOneTrainingStart = (payload) => ({
  type: GET_ONE_TRAINING_START,
  payload,
});
const getOneTrainingSuccess = (payload) => ({
  type: GET_ONE_TRAINING_SUCCESS,
  payload,
});
const getOneTrainingFailed = (payload) => ({
  type: GET_ONE_TRAINING_FAILED,
  payload,
});
const getOneTrainingReset = () => ({ type: GET_ONE_TRAINING_RESET });

// Action Creators: Create New Training.
const createTrainingStart = (payload) => ({
  type: CREATE_TRAINING_START,
  payload,
});
const createTrainingSuccess = (payload) => ({
  type: CREATE_TRAINING_SUCCESS,
  payload,
});
const createTrainingFailed = (payload) => ({
  type: CREATE_TRAINING_FAILED,
  payload,
});
const createTrainingReset = () => ({ type: CREATE_TRAINING_RESET });

// Action Creators: Update One Training.
const updateOneTrainingStart = (payload) => ({
  type: UPDATE_ONE_TRAINING_START,
  payload,
});
const updateOneTrainingSuccess = (payload) => ({
  type: UPDATE_ONE_TRAINING_SUCCESS,
  payload,
});
const updateOneTrainingFailed = (payload) => ({
  type: UPDATE_ONE_TRAINING_FAILED,
  payload,
});
const updateOneTrainingReset = () => ({ type: UPDATE_ONE_TRAINING_RESET });

// Action Creators: Delete One Training.
const deleteOneTrainingStart = (payload) => ({
  type: DELETE_ONE_TRAINING_START,
  payload,
});
const deleteOneTrainingSuccess = (payload) => ({
  type: DELETE_ONE_TRAINING_SUCCESS,
  payload,
});
const deleteOneTrainingFailed = (payload) => ({
  type: DELETE_ONE_TRAINING_FAILED,
  payload,
});
const deleteOneTrainingReset = () => ({ type: DELETE_ONE_TRAINING_RESET });

// Action Creators: Get All Matched Interns
const getAllMatchedInternsStart = (payload) => ({
  type: GET_ALL_MATCHED_INTERNS_START,
  payload,
});
const getAllMatchedInternsSuccess = (payload) => ({
  type: GET_ALL_MATCHED_INTERNS_SUCCESS,
  payload,
});
const getAllMatchedInternsFailed = (payload) => ({
  type: GET_ALL_MATCHED_INTERNS_FAILED,
  payload,
});
const getAllMatchedInternsReset = () => ({ type: GET_ALL_MATCHED_INTERNS_RESET });

// Action Creators: Invite One Intern
const inviteOneInternStart = (payload) => ({
  type: INVITE_ONE_INTERN_START,
  payload,
});
const inviteOneInternSuccess = (payload) => ({
  type: INVITE_ONE_INTERN_SUCCESS,
  payload,
});
const inviteOneInternFailed = (payload) => ({
  type: INVITE_ONE_INTERN_FAILED,
  payload,
});
const inviteOneInternReset = () => ({ type: INVITE_ONE_INTERN_RESET });

// Actions: Get All Trainings.
export const getAllTrainings = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllTrainingsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const {
        page,
        limit,
        pagination,
        categories,
        municipality,
        business,
        name,
        language,
        createdSort,
        deadlineSort,
        incomingTrainings,
      } = payload;
      const result = await ApiClient.get('trainings', {
        params: {
          page,
          limit,
          pagination,
          categories,
          municipality,
          business,
          name,
          createdSort,
          deadlineSort,
          incomingTrainings,
          language,
        },
      });
      if (result.data?.success) {
        const { trainings } = result.data.data;

        dispatch(
          getAllTrainingsSuccess({
            loading: false,
            success: true,
            data: { trainings },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllTrainingsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllTrainingsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Trainings
export const clearGetAllTrainings = () => getAllTrainingsReset();

// Actions: Get One Training.
export const getOneTraining = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneTrainingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { trainingId } = payload;
      const result = await ApiClient.get(`trainings/${trainingId}`);
      if (result.data?.success) {
        const { training } = result.data.data;
        dispatch(
          getOneTrainingSuccess({
            loading: false,
            success: true,
            data: { training },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneTrainingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneTrainingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Training.
export const clearGetOneTraining = () => getOneTrainingReset();

// Actions: Create New Training.
export const createTraining = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createTrainingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { name, description, spots, startDate, endDate, deadline, language, categories, municipality, business } =
      payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.post('trainings', {
        name,
        description,
        spots,
        startDate,
        endDate,
        deadline,
        language,
        categories,
        municipality,
        business,
      });
      if (result.data?.success) {
        const { training } = result.data.data;

        dispatch(
          createTrainingSuccess({
            loading: false,
            success: true,
            data: { training },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createTrainingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createTrainingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Training
export const clearCreateNewTraining = () => createTrainingReset();

// Actions: Update One Training.
export const updateOneTraining = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneTrainingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const {
      trainingId,
      name,
      description,
      spots,
      startDate,
      endDate,
      deadline,
      language,
      categories,
      municipality,
      business,
    } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`trainings/${trainingId}`, {
        name,
        description,
        spots,
        startDate,
        endDate,
        deadline,
        language,
        categories,
        municipality,
        business,
      });
      if (result.data?.success) {
        const { training } = result.data.data;
        dispatch(
          updateOneTrainingSuccess({
            loading: false,
            success: true,
            data: { training },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneTrainingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneTrainingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Job.
export const clearUpdateOneTraining = () => updateOneTrainingReset();

// Actions: Delete One Training.
export const deleteOneTraining = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneTrainingStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { trainingId } = payload;
      const result = await ApiClient.delete(`trainings/${trainingId}`);
      if (result.data?.success) {
        const { training } = result.data.data;
        dispatch(
          deleteOneTrainingSuccess({
            loading: false,
            success: true,
            data: { training },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneTrainingFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneTrainingFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Training
export const clearDeleteOneTraining = () => deleteOneTrainingReset();

// Actions: Get All Matched Interns.
export const getAllMatchedInterns = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllMatchedInternsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { trainingId, page, limit, pagination, sort, internName } = payload;
      const result = await ApiClient.get(`trainings/${trainingId}/matches`, {
        params: { page, limit, pagination, sort, internName },
      });
      if (result.data?.success) {
        const { matches } = result.data.data;
        dispatch(
          getAllMatchedInternsSuccess({
            loading: false,
            success: true,
            data: { matches },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllMatchedInternsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllMatchedInternsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Matched Interns.
export const clearGetAllMatchedInterns = () => getAllMatchedInternsReset();

// Actions: Invite One Intern.
export const inviteOneInternTraining = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      inviteOneInternStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onInviteFailMessage } = options;

    try {
      const { trainingId, inviteeId, emailLanguage } = payload;
      const result = await ApiClient.put(`trainings/${trainingId}/invite/${inviteeId}`, { emailLanguage });
      if (result.data?.success) {
        const { application } = result.data.data;
        dispatch(
          inviteOneInternSuccess({
            loading: false,
            success: true,
            data: { application },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          inviteOneInternFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        inviteOneInternFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === 'JOB_EXPIRED'
      ) {
        toastNotification('error', onInviteFailMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Invite One Intern.
export const clearInviteOneInternTraining = () => inviteOneInternReset();
