import { Grid, Skeleton } from '@mui/material';
import { Card } from 'antd';

export const CardSkeleton = ({ training }) => {
  return (
    <>
      <Card
        className={training && training === true ? 'trainingCards' : 'jobCards'}
        cover={
          <Skeleton
            varaint="rounded"
            animation="wave"
            width={61}
            height={64}
            sx={{
              borderRadius: '13px',
              marginLeft: '20px',
              marginTop: '24px',
            }}
          />
        }
      >
        <Grid container spacing={training && training === true ? 0 : 3}>
          <Grid item xs={12}>
            <Skeleton varaint="rounded" animation="wave" width={'100%'} height={40} />
            <Skeleton variant="text" animation="wave" width="100%" height={92.1} />
          </Grid>
          {!training ? (
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Skeleton varaint="rounded" animation="wave" width={'100%'} height={42} />
            </Grid>
          ) : null}

          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', my: training ? 1 : null }}>
            <Skeleton varaint="rounded" animation="wave" width={'100%'} height={42} />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', my: training ? 1 : null }}>
            <Skeleton varaint="rounded" animation="wave" width={'100%'} height={42} />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', my: training ? 1 : null }}>
            <Skeleton varaint="rounded" animation="wave" width={'45%'} height={42} />
            <Skeleton
              varaint="rounded"
              animation="wave"
              width={'45%'}
              height={42}
              sx={{
                pl: 3,
                backgroundColor: '#ffff',
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', my: training ? 0.88 : null }}>
            <Skeleton
              varaint="rounded"
              animation="wave"
              width={'50%'}
              height={63}
              sx={{
                m: 3,
                borderRadius: '7px',
                backgroundColor: '#ffff',
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
