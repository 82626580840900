import React from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import i18n from '../i18n';

export const Loading = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="loading-container">
      <div className="middle-loading">
        <p className="pb-1" style={{ fontFamily: 'Mark-Medium', color: '#01486B' }}>
          {t('loading')}
        </p>
        <Spin size="large" />
      </div>
    </div>
  );
};
