// Imports: local files.
import {
  GET_ALL_TRAININGS_START,
  GET_ALL_TRAININGS_SUCCESS,
  GET_ALL_TRAININGS_FAILED,
  GET_ALL_TRAININGS_RESET,
  GET_ONE_TRAINING_START,
  GET_ONE_TRAINING_SUCCESS,
  GET_ONE_TRAINING_FAILED,
  GET_ONE_TRAINING_RESET,
  CREATE_TRAINING_START,
  CREATE_TRAINING_SUCCESS,
  CREATE_TRAINING_FAILED,
  CREATE_TRAINING_RESET,
  UPDATE_ONE_TRAINING_START,
  UPDATE_ONE_TRAINING_SUCCESS,
  UPDATE_ONE_TRAINING_FAILED,
  UPDATE_ONE_TRAINING_RESET,
  DELETE_ONE_TRAINING_START,
  DELETE_ONE_TRAINING_SUCCESS,
  DELETE_ONE_TRAINING_FAILED,
  DELETE_ONE_TRAINING_RESET,
  GET_ALL_MATCHED_INTERNS_START,
  GET_ALL_MATCHED_INTERNS_SUCCESS,
  GET_ALL_MATCHED_INTERNS_FAILED,
  GET_ALL_MATCHED_INTERNS_RESET,
  INVITE_ONE_INTERN_START,
  INVITE_ONE_INTERN_SUCCESS,
  INVITE_ONE_INTERN_FAILED,
  INVITE_ONE_INTERN_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  updateOne: initialLoadingState,
  deleteOne: initialLoadingState,
  getAllMatches: initialLoadingState,
  inviteOneIntern: initialLoadingState,
};

const trainingsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Trainings
     * =======================================================================
     */
    case GET_ALL_TRAININGS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TRAININGS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TRAININGS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_TRAININGS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Training
     * =======================================================================
     */
    case GET_ONE_TRAINING_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TRAINING_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TRAINING_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_TRAINING_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Training
     * =======================================================================
     */
    case CREATE_TRAINING_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_TRAINING_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_TRAINING_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_TRAINING_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Training
     * =======================================================================
     */
    case UPDATE_ONE_TRAINING_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TRAINING_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TRAINING_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_ONE_TRAINING_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Delete One Training
     * =======================================================================
     */
    case DELETE_ONE_TRAINING_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TRAINING_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TRAINING_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_ONE_TRAINING_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get All Matches
     * =======================================================================
     */
    case GET_ALL_MATCHED_INTERNS_START:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHED_INTERNS_SUCCESS:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHED_INTERNS_FAILED:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_ALL_MATCHED_INTERNS_RESET:
      return { ...state, getAllMatches: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Invite One Intern
     * =======================================================================
     */
    case INVITE_ONE_INTERN_START:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_SUCCESS:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_FAILED:
      return { ...state, inviteOneIntern: { ...action.payload } };
    case INVITE_ONE_INTERN_RESET:
      return { ...state, inviteOneIntern: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default trainingsReducer;
