// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Tags.
export const GET_ALL_TAGS_START = 'GET_ALL_TAGS_START';
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS';
export const GET_ALL_TAGS_FAILED = 'GET_ALL_TAGS_FAILED';
export const GET_ALL_TAGS_RESET = 'GET_ALL_TAGS_RESET';

// Action Types: Get One Tag.
export const GET_ONE_TAG_START = 'GET_ONE_TAG_START';
export const GET_ONE_TAG_SUCCESS = 'GET_ONE_TAG_SUCCESS';
export const GET_ONE_TAG_FAILED = 'GET_ONE_TAG_FAILED';
export const GET_ONE_TAG_RESET = 'GET_ONE_TAG_RESET';

// Action Types: Create New Tag.
export const CREATE_TAG_START = 'CREATE_TAG_START';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILED = 'CREATE_TAG_FAILED';
export const CREATE_TAG_RESET = 'CREATE_TAG_RESET';

// Action Types: Update One Tag.
export const UPDATE_ONE_TAG_START = 'UPDATE_ONE_TAG_START';
export const UPDATE_ONE_TAG_SUCCESS = 'UPDATE_ONE_TAG_SUCCESS';
export const UPDATE_ONE_TAG_FAILED = 'UPDATE_ONE_TAG_FAILED';
export const UPDATE_ONE_TAG_RESET = 'UPDATE_ONE_TAG_RESET';

// Action Types: Delete One Tag.
export const DELETE_ONE_TAG_START = 'DELETE_ONE_TAG_START';
export const DELETE_ONE_TAG_SUCCESS = 'DELETE_ONE_TAG_SUCCESS';
export const DELETE_ONE_TAG_FAILED = 'DELETE_ONE_TAG_FAILED';
export const DELETE_ONE_TAG_RESET = 'DELETE_ONE_TAG_RESET';

// Action Creators: Get All Tags.
const getAllTagsStart = (payload) => ({
  type: GET_ALL_TAGS_START,
  payload,
});
const getAllTagsSuccess = (payload) => ({
  type: GET_ALL_TAGS_SUCCESS,
  payload,
});
const getAllTagsFailed = (payload) => ({
  type: GET_ALL_TAGS_FAILED,
  payload,
});
const getAllTagsReset = () => ({ type: GET_ALL_TAGS_RESET });

// Action Creators: Get One Tag.
const getOneTagStart = (payload) => ({
  type: GET_ONE_TAG_START,
  payload,
});
const getOneTagSuccess = (payload) => ({
  type: GET_ONE_TAG_SUCCESS,
  payload,
});
const getOneTagFailed = (payload) => ({
  type: GET_ONE_TAG_FAILED,
  payload,
});
const getOneTagReset = () => ({ type: GET_ONE_TAG_RESET });

// Action Creators: Create New Tag.
const createTagStart = (payload) => ({
  type: CREATE_TAG_START,
  payload,
});
const createTagSuccess = (payload) => ({
  type: CREATE_TAG_SUCCESS,
  payload,
});
const createTagFailed = (payload) => ({
  type: CREATE_TAG_FAILED,
  payload,
});
const createTagReset = () => ({ type: CREATE_TAG_RESET });

// Action Creators: Update One Tag.
const updateOneTagStart = (payload) => ({
  type: UPDATE_ONE_TAG_START,
  payload,
});
const updateOneTagSuccess = (payload) => ({
  type: UPDATE_ONE_TAG_SUCCESS,
  payload,
});
const updateOneTagFailed = (payload) => ({
  type: UPDATE_ONE_TAG_FAILED,
  payload,
});
const updateOneTagReset = () => ({ type: UPDATE_ONE_TAG_RESET });

// Action Creators: Delete One Tag.
const deleteOneTagStart = (payload) => ({
  type: DELETE_ONE_TAG_START,
  payload,
});
const deleteOneTagSuccess = (payload) => ({
  type: DELETE_ONE_TAG_SUCCESS,
  payload,
});
const deleteOneTagFailed = (payload) => ({
  type: DELETE_ONE_TAG_FAILED,
  payload,
});
const deleteOneTagReset = () => ({ type: DELETE_ONE_TAG_RESET });

// Actions: Get All Tags.
export const getAllTags = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllTagsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, name, language } = payload;
      const result = await ApiClient.get('tags', {
        params: { page, limit, pagination, name, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { tags } = result.data.data;
        dispatch(
          getAllTagsSuccess({
            loading: false,
            success: true,
            data: { tags },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllTagsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllTagsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Tags.
export const clearGetAllTags = () => getAllTagsReset();

// Actions: Get One Tag.
export const getOneTag = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneTagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { tagId } = payload;
      const result = await ApiClient.get(`tags/${tagId}`);
      if (result.data?.success) {
        const { tag } = result.data.data;
        dispatch(
          getOneTagSuccess({
            loading: false,
            success: true,
            data: { tag },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneTagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneTagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Tag.
export const clearGetOneTag = () => getOneTagReset();

// Actions: Create New Tag.
export const createTag = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createTagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onTagExistsMessage } = options;

    try {
      const result = await ApiClient.post('tags', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { tag } = result.data.data;
        dispatch(
          createTagSuccess({
            loading: false,
            success: true,
            data: { tag },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createTagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createTagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'TAG_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onTagExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Tag.
export const clearCreateNewTag = () => createTagReset();

// Actions: Update One Tag.
export const updateOneTag = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneTagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { tagId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`tags/${tagId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { tag } = result.data.data;
        dispatch(
          updateOneTagSuccess({
            loading: false,
            success: true,
            data: { tag },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneTagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneTagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Tag.
export const clearUpdateOneTag = () => updateOneTagReset();

// Actions: Delete One Tag.
export const deleteOneTag = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneTagStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { tagId } = payload;
      const result = await ApiClient.delete(`tags/${tagId}`);
      if (result.data?.success) {
        const { tag } = result.data.data;
        dispatch(
          deleteOneTagSuccess({
            loading: false,
            success: true,
            data: { tag },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneTagFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneTagFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Tag.
export const cleareDeleteOneTag = () => deleteOneTagReset();
