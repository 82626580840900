import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import '../../../style/intershipsDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jwt from 'jsonwebtoken';
import '../../../style/jobPostings.scss';
import SearchBar from '../../../components/inputs/SearchBar';
import FilterInput from '../../../components/inputs/FilterInput';
import InnerHeader from './InnerHeader';
import { getAllJobs, getInternMatches } from '../../../store/actions/jobs.actions';
import { Pagination } from '../Pagination/Pagination';
import { JobCard } from '../Jobs/JobCard';
import { getAllSkills, getAllMunicipalities, getAllCategories } from '../../../store/actions/actions';
import { CardSkeleton } from '../../../components/sceletons/CardSceleton';
import Button from '../../../components/buttons/Button';
import useDebounce from '../../../utils/useDebounce';

const defaultLimit = 3;
const defaultPage = 1;
export const OpportunitiesList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const searchTerm = location?.state?.title;
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get('page')) || defaultPage;

  const [jobs, setJobs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('choose');
  const [selectedSkill, setSelectedSkill] = useState('choose');
  const [selectedMunicipality, setSelectedMunicipality] = useState('choose');
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(defaultLimit);
  const [loading, setLoading] = useState(true);
  const [jobPagination, setJobPagination] = useState('');
  const [title, setTitle] = useState(searchTerm);
  const [selectedTrainingFilter, setSelectedTrainingFilter] = useState('choose');
  const [selectedLanguageFilter, setSelectedLanguageFilter] = useState('choose');
  const [allPostingsLimit, setAllPostingsLimit] = useState(props?.allPostingsLimit ? props.allPostingsLimit : null);
  const [noResults, setNoResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const jobsResponse = useSelector(({ jobs }) => (allPostingsLimit ? jobs.getInternMatches : jobs.getAll));

  const token = localStorage.getItem('kgen-token');

  const debouncedTitle = useDebounce(title, 500);

  let intern;
  if (token) {
    const { id } = jwt.decode(token);
    intern = id;
  }

  const skillsResponse = useSelector(({ skills }) => skills.getAll);
  const categoriesResponse = useSelector(({ categories }) => categories.getAll);
  const municipalitiesResponse = useSelector(({ municipalities }) => municipalities.getAll);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getAllSkills({ pagination: false, language }));
    dispatch(getAllCategories({ pagination: false, language }));
    dispatch(getAllMunicipalities({ pagination: false, language }));
  }, []);

  useEffect(() => {
    if (allPostingsLimit) {
      dispatch(
        getInternMatches({
          page: page,
          limit: allPostingsLimit,
          pagination: true,
          internId: intern ? intern : null,
          categories: selectedCategory !== 'choose' ? [selectedCategory] : undefined,
          skills: selectedSkill !== 'choose' ? [selectedSkill] : undefined,
          municipality: selectedMunicipality !== 'choose' ? selectedMunicipality : undefined,
          title: debouncedTitle ? debouncedTitle : undefined,
          training:
            selectedTrainingFilter === 0 && selectedTrainingFilter !== 'choose'
              ? 0
              : selectedTrainingFilter === 1
              ? 1
              : undefined,
          language: selectedLanguageFilter !== 'choose' ? selectedLanguageFilter : undefined,
          incomingJobs: true,
        })
      );
    } else {
      dispatch(
        getAllJobs({
          page: page,
          limit: limit,
          pagination: true,
          categories: selectedCategory !== 'choose' ? [selectedCategory] : undefined,
          skills: selectedSkill !== 'choose' ? [selectedSkill] : undefined,
          municipality: selectedMunicipality !== 'choose' ? selectedMunicipality : undefined,
          title: debouncedTitle ? debouncedTitle : undefined,
          training:
            selectedTrainingFilter === 0 && selectedTrainingFilter !== 'choose'
              ? 0
              : selectedTrainingFilter === 1
              ? 1
              : undefined,
          language: selectedLanguageFilter !== 'choose' ? selectedLanguageFilter : undefined,
          incomingJobs: 1,
        })
      );
    }
  }, [
    intern,
    page,
    limit,
    allPostingsLimit,
    selectedCategory,
    selectedSkill,
    selectedMunicipality,
    debouncedTitle,
    selectedTrainingFilter,
    selectedLanguageFilter,
  ]);

  useEffect(() => {
    if (jobsResponse && jobsResponse.loading) {
      setLoading(true);
      setNoResults(false);
    } else if (jobsResponse && jobsResponse.success) {
      setLoading(false);
      setIsSearching(false);
      setJobs(jobsResponse?.data?.jobPostings?.docs);
      setJobPagination(jobsResponse?.data?.jobPostings);
      setNoResults(!jobsResponse || jobsResponse?.length === 0);
    } else if (jobsResponse && jobsResponse.error) {
      setLoading(false);
      setIsSearching(false);
    }
  }, [jobsResponse]);

  const updateURLWithPage = (pageNumber) => {
    history.replace(`/opportunitiesinterships?page=${pageNumber}`);
  };

  const nextPage = () => {
    const nextPageNumber = page + 1;
    setPage(nextPageNumber);
    updateURLWithPage(nextPageNumber);
  };

  const prevPage = () => {
    const prevPageNumber = page - 1;
    setPage(prevPageNumber);
    updateURLWithPage(prevPageNumber);
  };

  const goToJob = (id) => {
    history.push(`/jobs/${id}?page=${page}`);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const pageParam = query.get('page');
    if (pageParam) {
      setPage(parseInt(pageParam, 10));
    }
  }, [location.search]);

  const resetPagination = () => {
    setPage(defaultPage);
    setLimit(defaultLimit);
  };

  const handleTitleChange = (event) => {
    if (page !== defaultPage || limit !== defaultLimit) {
      resetPagination();
    }
    const newValue = event?.target?.value;
    setTitle(newValue);
    setIsSearching(true);
  };

  const resetFilters = () => {
    resetPagination();
    setTitle('');
    setSelectedCategory('choose');
    setSelectedLanguageFilter('choose');
    setSelectedMunicipality('choose');
    setSelectedSkill('choose');
    setSelectedTrainingFilter('choose');
  };

  const handleMunicipalityChange = (e) => {
    resetPagination();
    if (e.target.value === 'all') setSelectedMunicipality('choose');
    else {
      setSelectedMunicipality(e.target.value);
    }
  };

  const handleSkillChange = (e) => {
    resetPagination();
    if (e.target.value === 'all') setSelectedSkill('choose');
    else {
      setSelectedSkill(e.target.value);
    }
  };

  const handleCategoryChange = (e) => {
    resetPagination();
    if (e.target.value === 'all') setSelectedCategory('choose');
    else {
      setSelectedCategory(e.target.value);
    }
  };

  const handleLanguageFilterChange = (e) => {
    resetPagination();
    if (e.target.value === 'all') setSelectedLanguageFilter('choose');
    else {
      setSelectedLanguageFilter(e.target.value);
    }
  };
  const handleTrainingFilterChange = (e) => {
    resetPagination();
    if (e.target.value === 'all') setSelectedTrainingFilter('choose');
    else {
      const value = parseInt(e.target.value);
      setSelectedTrainingFilter(value);
    }
  };

  const languages = [
    { value: 'sq', option: t('shqip') },
    { value: 'en', option: t('anglisht') },
    { value: 'sr', option: t('serbisht') },
  ];

  const training = [
    { value: 1, option: t('includesTraining') },
    { value: 0, option: t('noTraining') },
  ];

  const text = allPostingsLimit ? t('filterMatchIntership') : t('filterintership');

  return (
    <>
      <Grid container xs={12} lg={12} md={12} className={allPostingsLimit ? 'allIntershipDetails' : 'intershipDetails'}>
        <InnerHeader icon={'./images/JobsIcon.png'} title={t('INTERSHIP')} text={text} />
        <Grid container xs={12} lg={12} sx={{ marginTop: '60px' }}>
          <SearchBar handleChange={handleTitleChange} inputValue={title} />
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            pt: 2,
          }}
        >
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <FilterInput
              data={municipalitiesResponse?.data?.municipalities?.docs}
              inputValue={selectedMunicipality}
              handleChange={(e) => handleMunicipalityChange(e)}
              fieldId="municipality"
              icon={'./images/location.svg'}
              includesAll={true}
              allLabel={t('allMunicipalities')}
              label={t('ChooseMunicipality')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <FilterInput
              label={t('filter-skills')}
              data={skillsResponse?.data?.skills?.docs || []}
              inputValue={selectedSkill}
              handleChange={(e) => handleSkillChange(e)}
              fieldId="skills"
              icon={'./images/VectorS.svg'}
              includesAll={true}
              allLabel={t('allSkills')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <FilterInput
              data={categoriesResponse?.data?.categories?.docs || []}
              inputValue={selectedCategory}
              includesAll={true}
              handleChange={(e) => handleCategoryChange(e)}
              fieldId="category"
              label={t('categories')}
              icon={'./images/Vector.svg'}
              allLabel={t('allCategories')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <FilterInput
              fieldId="language"
              label={t('languageFilter')}
              handleChange={(e) => handleLanguageFilterChange(e)}
              inputValue={selectedLanguageFilter}
              data={languages || []}
              icon={'./images/VectorL.svg'}
              includesAll={true}
              allLabel={t('allLanguages')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <FilterInput
              fieldId="trainings"
              handleChange={(e) => handleTrainingFilterChange(e)}
              inputValue={selectedTrainingFilter}
              data={training || []}
              icon={'./images/light.svg'}
              label={t('filterTraining')}
              includesAll={true}
              allLabel={t('allTrainings')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={4}>
            <Button color="#F36E21" label={t('resetFilters')} handleClick={resetFilters} />
          </Grid>
        </Grid>
        {loading && !isSearching ? (
          <Grid spacing={2} container sx={{ marginTop: '20px' }}>
            {[1, 2, 3].map((job, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                <CardSkeleton training={false} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            {jobs?.length ? (
              <Grid spacing={2} container sx={{ marginTop: '20px' }}>
                {jobs.map((job) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    key={job._id}
                    // className={job?.training ? 'jobCardsLabel' : null}
                  >
                    <JobCard job={job} goToJob={goToJob} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  height: '50vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '20px', fontFamily: 'Mark-Light', color: '#01486B', textAlign: 'center' }}
                >
                  {t('noOpportunitiesFound')}
                </Typography>
              </div>
            )}
          </>
        )}
        <div
          className="col-sm-12"
          style={{
            paddingTop: !allPostingsLimit ? '0px' : null,
            paddingBottom: !allPostingsLimit ? '20px' : null,
          }}
        >
          <Pagination
            page={page}
            jobPagination={jobPagination}
            prevPage={prevPage}
            nextPage={nextPage}
            defaultLimit={defaultLimit}
            defaultPage={defaultPage}
            allPostingsLimit={allPostingsLimit}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            setAllPostingsLimit={setAllPostingsLimit}
          />
        </div>
      </Grid>
    </>
  );
};
