import { Autocomplete, Grid, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AllRoleButton from '../../../../components/buttons/AllRoleButton';
import { useStyles } from '../SurveyStatistics/style/SurveyStatistics';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from 'react';
import { ChipSelect } from '../../../../components/inputs/ChipSelect';
import { getAllCategories, getAllMunicipalities, getAllBusinesses } from '../../../../store/actions/actions';
import { v4 } from 'uuid';

export const TrainingFilter = ({
  categoryChips,
  setCategoryChips,
  organisation,
  setOrganisation,
  municipalityChips,
  setMunicipalityChips,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedMunicipality, setSelectedMunicipality] = useState('choose');
  const [selectedCategory, setSelectedCategory] = useState('choose');
  const [options, setOptions] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState(v4());

  const categoriesResponse = useSelector(({ categories }) => categories.getAll);
  const businessesResponse = useSelector(({ businesses }) => businesses.getAll);
  const municipalitiesResponse = useSelector(({ municipalities }) => municipalities.getAll);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getAllCategories({ pagination: false, language }));
    dispatch(getAllBusinesses({ pagination: false, language }));
    dispatch(getAllMunicipalities({ pagination: false, language }));
  }, []);

  const onCategoryChange = (category, type) => {
    switch (type) {
      case 'add':
        const exists = categoryChips.find((chip) => chip._id === category._id);
        if (exists) return;

        const addedArray = [...categoryChips, category];
        setCategoryChips(addedArray);
        setSelectedCategory(category._id);
        break;
      case 'delete':
        const filteredArray = categoryChips.filter((chip) => chip._id !== category._id);
        setCategoryChips(filteredArray);
        setSelectedCategory('choose');
        break;
      default:
        break;
    }
  };
  const onOrganisationChange = (org) => {
    setOrganisation(org);
  };
  const onMunicipalityChange = (municipality, type) => {
    switch (type) {
      case 'add':
        const exists = municipalityChips.find((chip) => chip._id === municipality._id);
        if (exists) return;

        const addedArray = [...municipalityChips, municipality];
        setMunicipalityChips(addedArray);
        setSelectedMunicipality(municipality._id);
        break;
      case 'delete':
        const filteredArray = municipalityChips.filter((chip) => chip._id !== municipality._id);
        setMunicipalityChips(filteredArray);
        setSelectedMunicipality('choose');
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    setSelectedMunicipality('choose');
    setSelectedCategory('choose');
    setMunicipalityChips([]);
    setCategoryChips([]);
    setOrganisation('');
    setAutocompleteKey(v4());
  };

  useEffect(() => {
    if (businessesResponse) {
      switch (true) {
        case businessesResponse.loading:
          break;
        case businessesResponse.success:
          const { docs } = businessesResponse.data.businesses;
          let newOptions = [];
          if (docs) docs.map((e) => newOptions.push({ title: `${e.businessName}`, id: e._id }));
          setOptions(newOptions);
          break;
        case businessesResponse.error:
          break;
        default:
          break;
      }
    }
  }, [businessesResponse]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="categories"
              label={t('filter-interest')}
              selectLabel={t('chooseCategories')}
              handleChange={(e) => onCategoryChange(JSON.parse(e.target.value), 'add')}
              value={selectedCategory}
              data={
                categoriesResponse?.success && categoriesResponse.data?.categories?.docs
                  ? categoriesResponse.data.categories.docs
                  : []
              }
              valueChips={categoryChips}
              handleDelete={onCategoryChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="municipalities"
              label={t('Komuna')}
              selectLabel={t('ChooseMunicipality')}
              handleChange={(e) => onMunicipalityChange(JSON.parse(e.target.value), 'add')}
              value={selectedMunicipality}
              data={
                municipalitiesResponse?.success && municipalitiesResponse.data?.municipalities?.docs
                  ? municipalitiesResponse.data.municipalities.docs
                  : []
              }
              valueChips={municipalityChips}
              handleDelete={onMunicipalityChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('Business')}</InputLabel>
            <Autocomplete
              id="tags-standard"
              options={options}
              getOptionLabel={(option) => option.title}
              defaultValue={organisation ? organisation : null}
              key={autocompleteKey}
              ListboxProps={{
                sx: {
                  minHeight: '41px',
                  fontFamily: 'Mark-Regular !important',
                  color: 'rgb(112, 112, 112) !important',
                  fontSize: '14px !important',
                },
              }}
              onChange={(e, value) => {
                if (!value) onOrganisationChange('');
                else onOrganisationChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className={`${classes.defaultInput}`}
                  variant="outlined"
                  placeholder={t('chooseOrg')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: { md: 3.5, lg: 3.6, xl: 3.6 } }}>
            <AllRoleButton
              label={t('resetFilters')}
              handleClick={() => resetFilters()}
              backgroundColor={'#F36E21'}
              icon={<RemoveCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
