// const URL = 'http://localhost:5000/api/';
const URL = 'https://kosovogenu.com/api/';
// const URL = 'https://kosovogenu.appbit.es/api/';

const ApiConstants = {
  TOKEN: localStorage.getItem('kgen-token'),
  BASE_URL: URL,
};

export default ApiConstants;
