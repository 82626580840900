import React from 'react';
import { Button as MUIButton } from '@mui/material';
import { useStyles } from './ButtonStyle';

const Button = ({ color, label, icon, handleClick }) => {
  const classes = useStyles();
  return (
    <MUIButton
      variant="contained"
      style={{
        backgroundColor: color,
      }}
      className={classes.button}
      onClick={handleClick}
    >
      {label} {icon ? icon : null}
    </MUIButton>
  );
};

export default Button;
