// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Business Categories.
export const GET_ALL_BUSINESS_CATEGORIES_START = 'GET_ALL_BUSINESS_CATEGORIES_START';
export const GET_ALL_BUSINESS_CATEGORIES_SUCCESS = 'GET_ALL_BUSINESS_CATEGORIES_SUCCESS';
export const GET_ALL_BUSINESS_CATEGORIES_FAILED = 'GET_ALL_BUSINESS_CATEGORIES_FAILED';
export const GET_ALL_BUSINESS_CATEGORIES_RESET = 'GET_ALL_BUSINESS_CATEGORIES_RESET';

// Action Types: Get One Business Category.
export const GET_ONE_BUSINESS_CATEGORY_START = 'GET_ONE_BUSINESS_CATEGORY_START';
export const GET_ONE_BUSINESS_CATEGORY_SUCCESS = 'GET_ONE_BUSINESS_CATEGORY_SUCCESS';
export const GET_ONE_BUSINESS_CATEGORY_FAILED = 'GET_ONE_BUSINESS_CATEGORY_FAILED';
export const GET_ONE_BUSINESS_CATEGORY_RESET = 'GET_ONE_BUSINESS_CATEGORY_RESET';

// Action Types: Create New Business Category.
export const CREATE_BUSINESS_CATEGORY_START = 'CREATE_BUSINESS_CATEGORY_START';
export const CREATE_BUSINESS_CATEGORY_SUCCESS = 'CREATE_BUSINESS_CATEGORY_SUCCESS';
export const CREATE_BUSINESS_CATEGORY_FAILED = 'CREATE_BUSINESS_CATEGORY_FAILED';
export const CREATE_BUSINESS_CATEGORY_RESET = 'CREATE_BUSINESS_CATEGORY_RESET';

// Action Types: Update One Business Category.
export const UPDATE_ONE_BUSINESS_CATEGORY_START = 'UPDATE_ONE_BUSINESS_CATEGORY_START';
export const UPDATE_ONE_BUSINESS_CATEGORY_SUCCESS = 'UPDATE_ONE_BUSINESS_CATEGORY_SUCCESS';
export const UPDATE_ONE_BUSINESS_CATEGORY_FAILED = 'UPDATE_ONE_BUSINESS_CATEGORY_FAILED';
export const UPDATE_ONE_BUSINESS_CATEGORY_RESET = 'UPDATE_ONE_BUSINESS_CATEGORY_RESET';

// Action Types: Delete One Business Category.
export const DELETE_ONE_BUSINESS_CATEGORY_START = 'DELETE_ONE_BUSINESS_CATEGORY_START';
export const DELETE_ONE_BUSINESS_CATEGORY_SUCCESS = 'DELETE_ONE_BUSINESS_CATEGORY_SUCCESS';
export const DELETE_ONE_BUSINESS_CATEGORY_FAILED = 'DELETE_ONE_BUSINESS_CATEGORY_FAILED';
export const DELETE_ONE_BUSINESS_CATEGORY_RESET = 'DELETE_ONE_BUSINESS_CATEGORY_RESET';

// Action Creators: Get All Business Categories.
const getAllBusinessCategoriesStart = (payload) => ({
  type: GET_ALL_BUSINESS_CATEGORIES_START,
  payload,
});
const getAllBusinessCategoriesSuccess = (payload) => ({
  type: GET_ALL_BUSINESS_CATEGORIES_SUCCESS,
  payload,
});
const getAllBusinessCategoriesFailed = (payload) => ({
  type: GET_ALL_BUSINESS_CATEGORIES_FAILED,
  payload,
});
const getAllBusinessCategoriesReset = () => ({ type: GET_ALL_BUSINESS_CATEGORIES_RESET });

// Action Creators: Get One Business Category.
const getOneBusinessCategoryStart = (payload) => ({
  type: GET_ONE_BUSINESS_CATEGORY_START,
  payload,
});
const getOneBusinessCategorySuccess = (payload) => ({
  type: GET_ONE_BUSINESS_CATEGORY_SUCCESS,
  payload,
});
const getOneBusinessCategoryFailed = (payload) => ({
  type: GET_ONE_BUSINESS_CATEGORY_FAILED,
  payload,
});
const getOneBusinessCategoryReset = () => ({ type: GET_ONE_BUSINESS_CATEGORY_RESET });

// Action Creators: Create New Business Category.
const createBusinessCategoryStart = (payload) => ({
  type: CREATE_BUSINESS_CATEGORY_START,
  payload,
});
const createBusinessCategorySuccess = (payload) => ({
  type: CREATE_BUSINESS_CATEGORY_SUCCESS,
  payload,
});
const createBusinessCategoryFailed = (payload) => ({
  type: CREATE_BUSINESS_CATEGORY_FAILED,
  payload,
});
const createBusinessCategoryReset = () => ({ type: CREATE_BUSINESS_CATEGORY_RESET });

// Action Creators: Update One Business Category.
const updateOneBusinessCategoryStart = (payload) => ({
  type: UPDATE_ONE_BUSINESS_CATEGORY_START,
  payload,
});
const updateOneBusinessCategorySuccess = (payload) => ({
  type: UPDATE_ONE_BUSINESS_CATEGORY_SUCCESS,
  payload,
});
const updateOneBusinessCategoryFailed = (payload) => ({
  type: UPDATE_ONE_BUSINESS_CATEGORY_FAILED,
  payload,
});
const updateOneBusinessCategoryReset = () => ({ type: UPDATE_ONE_BUSINESS_CATEGORY_RESET });

// Action Creators: Delete One Business Category.
const deleteOneBusinessCategoryStart = (payload) => ({
  type: DELETE_ONE_BUSINESS_CATEGORY_START,
  payload,
});
const deleteOneBusinessCategorySuccess = (payload) => ({
  type: DELETE_ONE_BUSINESS_CATEGORY_SUCCESS,
  payload,
});
const deleteOneBusinessCategoryFailed = (payload) => ({
  type: DELETE_ONE_BUSINESS_CATEGORY_FAILED,
  payload,
});
const deleteOneBusinessCategoryReset = () => ({ type: DELETE_ONE_BUSINESS_CATEGORY_RESET });

// Actions: Get All Business Categories.
export const getAllBusinessCategories = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllBusinessCategoriesStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('businesscategories', {
        params: { page, limit, pagination, selectedLanguage: language },
      });
      if (result.data?.success) {
        const { businesscategories } = result.data.data;
        dispatch(
          getAllBusinessCategoriesSuccess({
            loading: false,
            success: true,
            data: { businesscategories },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllBusinessCategoriesFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllBusinessCategoriesFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Business Categories.
export const clearGetAllBusinessCategories = () => getAllBusinessCategoriesReset();

// Actions: Get One Business Category.
export const getOneBusinessCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOneBusinessCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { businessCategoryId } = payload;
      const result = await ApiClient.get(`businesscategories/${businessCategoryId}`);
      if (result.data?.success) {
        const { businessCategory } = result.data.data;
        dispatch(
          getOneBusinessCategorySuccess({
            loading: false,
            success: true,
            data: { businessCategory },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOneBusinessCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOneBusinessCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Business Category.
export const clearGetOneBusinessCategory = () => getOneBusinessCategoryReset();

// Actions: Create New Business Category.
export const createBusinessCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createBusinessCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onBusinessCategoryExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('businesscategories', {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { businessCategory } = result.data.data;
        dispatch(
          createBusinessCategorySuccess({
            loading: false,
            success: true,
            data: { businessCategory },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createBusinessCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createBusinessCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      const businessCateogryExists = 'BUSINESS_CATEGORY_EXISTS';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorType &&
        error.response.data.errorType === businessCateogryExists
      ) {
        toastNotification('error', onBusinessCategoryExistsMessage);
      } else {
        toastNotification('error', onFailMessage);
      }
    }
  };
};

// Actions: Clear Create New Business Category.
export const clearCreateNewBusinessCategory = () => createBusinessCategoryReset();

// Actions: Update One Business Category.
export const updateOneBusinessCategory = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOneBusinessCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { businessCategoryId, enName, enDescription, sqName, sqDescription, srName, srDescription } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`businesscategories/${businessCategoryId}`, {
        enName,
        enDescription,
        sqName,
        sqDescription,
        srName,
        srDescription,
      });
      if (result.data?.success) {
        const { businessCategory } = result.data.data;
        dispatch(
          updateOneBusinessCategorySuccess({
            loading: false,
            success: true,
            data: { businessCategory },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOneBusinessCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOneBusinessCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Business Category.
export const clearUpdateOneBusinessCategory = () => updateOneBusinessCategoryReset();

// Actions: Delete One Business Category.
export const deleteOneBusinessCategory = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOneBusinessCategoryStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { businessCategoryId } = payload;
      const result = await ApiClient.delete(`businesscategories/${businessCategoryId}`);
      if (result.data?.success) {
        const { businessCategory } = result.data.data;
        dispatch(
          deleteOneBusinessCategorySuccess({
            loading: false,
            success: true,
            data: { businessCategory },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOneBusinessCategoryFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOneBusinessCategoryFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Business Category.
export const cleareDeleteOneBusinessCategory = () => deleteOneBusinessCategoryReset();
