import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { toastNotification } from '../../../utils/toastNotification';
import { Header } from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { loginBusiness, clearLoginBusiness, loginMentor, clearLoginMentor } from '../../../store/actions/actions';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Grid, Box, Typography, Divider, OutlinedInput, IconButton } from '@mui/material';
import InternsButton from '../../../components/buttons/InternsButton';
import Label from '../../../components/inputs/Label';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '35px !important',
    borderRadius: '7px !important',
    fontSize: '14px !important',
    fontFamily: 'Mark-Light !important',
    color: '#1E1E1E !important',
    boxShadow: '0px 3px 6px #00000029 !important',
    opacity: 1,
    marginTop: '7px',
    '& input::placeholder': {
      fontFamily: 'Mark-Light !important',
      fontSize: '13px',
      color: '#1E1E1E !important',
      alignItems: 'center',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white',
    },
    '&.Mui-focused': {
      border: '1px solid #01486B',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
});

const schema = Joi.object({
  email: Joi.string().required(),
  password: Joi.string().min(8).required(),
  remember: Joi.boolean().optional().default(false),
}).required();

export const AdminBusinessLogin = (props) => {
  let defaultUser = 'business';
  if (props.location && props.location.state && props.location.state.type === 'mentor') defaultUser = 'mentor';

  const token = localStorage.getItem('kgen-token');

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const forgotPassword = (param, options = {}) => {
    if (options && options.shouldUseState) {
      props.history.push({ pathname: param, state: options.state });
    } else {
      props.history.push(param);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = (data) => {
    const { email, password, remember } = data;
    if (defaultUser === 'mentor') {
      dispatch(
        loginMentor(
          { email, password, remember },
          {
            toastNotification,
            history,
            pathname: '/dashboard',
            onSuccessMessage: t('loggedInSuccessfully'),
            onFailMessage: t('loginFailed'),
            onFailConfirmMessage: t('checkEmailToConfirm'),
            onFailCredentialMessage: t('invalidCredentials'),
          }
        )
      );
    } else {
      dispatch(
        loginBusiness(
          { email, password, remember },
          {
            toastNotification,
            history,
            pathname: '/dashboard/jobs',
            onSuccessMessage: t('loggedInSuccessfully'),
            onFailMessage: t('loginFailed'),
            onFailConfirmMessage: t('checkEmailToConfirm'),
            onFailCredentialMessage: t('invalidCredentials'),
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(clearLoginBusiness());
    dispatch(clearLoginMentor());
  }, []);

  const checkKeyDown = (e) => {
    const allValues = getValues();
    if (e.code === 'Enter') onSubmit(allValues);
  };

  const showText = () => {
    if (defaultUser === 'mentor') {
      return (
        <Grid item xs={12} lg={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: '#01486B !important', fontFamily: 'Mark-Bold !important', fontSize: '20px !important' }}
          >
            {t('kycu')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: '#01486B !important', fontFamily: 'Mark-Light !important', fontSize: '16px !important' }}
          >
            {t('mentor-login')} <br />
            {t('mentor-txt')}
          </Typography>
        </Grid>
      );
    } else if (defaultUser === 'business') {
      return (
        <Grid item xs={12} lg={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: '#01486B !important', fontFamily: 'Mark-Bold !important', fontSize: '20px !important' }}
          >
            {t('kycu')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: '#01486B !important', fontFamily: 'Mark-Light !important', fontSize: '16px !important' }}
          >
            {t('password-sntc-business')} <br />
            {t('kycu-txt1')}
          </Typography>
        </Grid>
      );
    }
  };

  if (token) return <Redirect to="/" />;
  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <Header {...props} role="business_admin" />
      <form onSubmit={handleSubmit(onSubmit)} id="org-form" onKeyDown={(e) => checkKeyDown(e)}>
        <Grid container className="container-Login">
          <Grid item xs={12} lg={6} md={9} sm={9} xl={6}>
            <img src="/images/Frame.png" width="100px" />
            <Grid container sx={{ pt: 3 }}>
              {showText()}
              <Grid container sx={{ pt: 2 }}>
                <Grid item xs={12} lg={12}>
                  <Label label={'Email'} />
                  <OutlinedInput
                    fullWidth
                    className={classes.root}
                    placeholder={'Email'}
                    id="email"
                    onChange={(e) => setValue('email', e.target.value)}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    sx={{
                      border: errors.email ? '1px solid red' : '1px solid #d9d9d9',
                      '&:hover fieldset': {
                        borderColor: errors.email ? 'red !important' : '#01486B !important',
                      },
                    }}
                  />
                  {errors.email && <span style={{ color: 'red', fontSize: '14px' }}>{t('emailError')}</span>}
                </Grid>
                <Grid item xs={12} lg={12} sx={{ pt: 2 }}>
                  <Label label={t('password')} />
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    className={classes.root}
                    placeholder={t('password')}
                    id="password"
                    onChange={(e) => setValue('password', e.target.value)}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    endAdornment={
                      <IconButton position="start" onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    sx={{
                      border: errors.email ? '1px solid red' : '1px solid #d9d9d9',
                      '&:hover fieldset': {
                        borderColor: errors.email ? 'red !important' : '#01486B !important',
                      },
                    }}
                  />
                  {errors.password && (
                    <span style={{ color: 'red', fontSize: '14px', fontFamily: 'Mark-Medium !important' }}>
                      {t('passwordError')}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
                  <Typography sx={{ color: '#707070', fontFamily: 'Mark-Medium !important', fontSize: '14px' }}>
                    {t('rememberMe')}
                  </Typography>
                  <input name="remember" {...register('remember')} type="checkbox" className="ml-2" />
                </Grid>
              </Grid>
              <Grid container sx={{ pt: 2 }}>
                <Grid item xs={12} lg={6}>
                  <InternsButton label={t('kycu')} handleClick={handleSubmit(onSubmit)} backgroundColor={'#01486B'} />
                </Grid>
                <Grid item xs={12} lg={12} sx={{ pt: 2 }}>
                  <Typography
                    onClick={() =>
                      forgotPassword('/forgot-user-password', { shouldUseState: true, state: { type: defaultUser } })
                    }
                    variant="subtitle2"
                    sx={{
                      fontSize: '14px !important',
                      color: '#707070',
                      fontFamily: 'Mark-Medium !important',
                      cursor: 'pointer',
                    }}
                  >
                    {t('password-qs')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={6} md={6} sm={6} xl={6}>
            <img
              className="img-Login"
              src="/images/kgenu_logo.png"
              style={{ objectFit: 'contain', height: '60%', width: '60%' }}
            />
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};
