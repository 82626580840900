import React, { useEffect, useState } from 'react';
import {
  mentorsByMunicipality,
  mentorsByCategories,
  mentorsBySkills,
  mentorsByGender,
  mentorsByInterns,
  mentorsByEthnicity,
  mentorsByHealthCondition,
} from '../../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Grid } from '@mui/material';
import { toastNotification } from '../../../utils/toastNotification';
import APIClient from '../../../services/ApiClient';
import { saveAs } from 'file-saver';
import MainCard from '../../../utils/MainCard';
import { MentorFilter } from './Filters/Mentor';
import { StatsChart } from './Stats';
import SubCard from '../../../utils/SubCard';

export const MentorsReports = ({ startDate, endDate }) => {
  const mentorsByMunicipalityResponse = useSelector(({ reports }) => reports.mentorsByMunicipality);
  const mentorsBySkillsResponse = useSelector(({ reports }) => reports.mentorsBySkills);
  const mentorsByCategoriesResponse = useSelector(({ reports }) => reports.mentorsByCategories);
  const mentorsByGenderResponse = useSelector(({ reports }) => reports.mentorsByGender);
  const mentorsByInternsResponse = useSelector(({ reports }) => reports.mentorsByInterns);
  const mentorsByEthnicityResponse = useSelector(({ reports }) => reports.mentorsByEthnicity);
  const mentorsByHealthConditionResponse = useSelector(({ reports }) => reports.mentorsByHealthCondition);

  const [loadingButton, setLoadingButton] = useState(false);
  const [municipalityChips, setMunicipalityChips] = useState([]);
  const [genderChips, setGenderChips] = useState([]);
  const [ethnicityChips, setEthnicityChips] = useState([]);
  const [healthConditionChips, setHealthConditionChips] = useState([]);
  const [skillChips, setSkillChips] = useState([]);
  const [categoryChips, setCategoryChips] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate || endDate) {
      let payload = { startDate: startDate ? startDate : undefined, endDate: endDate ? endDate : undefined };
      if (skillChips?.length) payload['skills'] = skillChips.map((skill) => skill._id);
      if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
      if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
      if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
      if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
      if (healthConditionChips?.length) payload['healthConditions'] = healthConditionChips.map((e) => e._id);
      dispatch(mentorsByMunicipality(payload));
      dispatch(mentorsBySkills(payload));
      dispatch(mentorsByCategories(payload));
      dispatch(mentorsByGender(payload));
      dispatch(mentorsByInterns(payload));
      dispatch(mentorsByEthnicity(payload));
      dispatch(mentorsByHealthCondition(payload));
    }
  }, [municipalityChips, genderChips, ethnicityChips, healthConditionChips, skillChips, categoryChips]);

  const onDocumentDownload = async (event) => {
    setLoadingButton(true);
    event.preventDefault();
    let payload = {};
    if (endDate) payload['endDate'] = endDate;
    if (startDate) payload['startDate'] = startDate;
    if (skillChips?.length) payload['skills'] = skillChips.map((skill) => skill._id);
    if (categoryChips?.length) payload['categories'] = categoryChips.map((cat) => cat._id);
    if (municipalityChips?.length) payload['municipalities'] = municipalityChips.map((mun) => mun._id);
    if (genderChips?.length) payload['genders'] = genderChips.map((e) => e._id);
    if (ethnicityChips?.length) payload['ethnicities'] = ethnicityChips.map((e) => e._id);
    if (healthConditionChips?.length) payload['healthConditions'] = healthConditionChips.map((e) => e._id);
    await APIClient.post(`/reports/mentors/export`, payload, {
      responseType: 'blob',
    })
      .then((res) => {
        setLoadingButton(false);
        if (res.data) {
          if (res.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(res.data, 'Mentors_Stats.csv');
          }
        } else {
          return toastNotification('error', 'Something went wrong!');
        }
      })
      .catch((err) => {
        setLoadingButton(false);
        return toastNotification('error', 'Something went wrong!');
      });
  };

  if (
    mentorsByMunicipalityResponse.loading ||
    mentorsBySkillsResponse.loading ||
    mentorsByCategoriesResponse.loading ||
    mentorsByGenderResponse.loading ||
    mentorsByInternsResponse.loading ||
    mentorsByEthnicityResponse.loading ||
    mentorsByHealthConditionResponse.loading
  ) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <MainCard title={t('generalStatistics')} sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MentorFilter
                  categoryChips={categoryChips}
                  skillChips={skillChips}
                  municipalityChips={municipalityChips}
                  genderChips={genderChips}
                  ethnicityChips={ethnicityChips}
                  healthConditionChips={healthConditionChips}
                  setCategoryChips={setCategoryChips}
                  setSkillChips={setSkillChips}
                  setMunicipalityChips={setMunicipalityChips}
                  setGenderChips={setGenderChips}
                  setEthnicityChips={setEthnicityChips}
                  setHealthConditionChips={setHealthConditionChips}
                />
              </Grid>
              <Grid item xs={12}>
                <SubCard
                  title={t('charts')}
                  sx={{ color: '#01486B', fontFamily: 'Mark-Bold !important' }}
                  secondary={
                    <Button
                      variant="contained"
                      disabled={loadingButton === true ? true : false}
                      sx={{
                        backgroundColor: '#01486B !important',
                        '&:hover': { backgroundColor: '#012b40 !important' },
                        width: '160px',
                        fontFamily: 'Mark-Regular',
                        fontSize: '14px',
                        textTransform: 'none !important',
                      }}
                      onClick={(e) => {
                        onDocumentDownload(e);
                      }}
                    >
                      {loadingButton ? (
                        <>
                          <CircularProgress size={24} style={{ color: '#fff' }} />
                        </>
                      ) : (
                        <>
                          <FileDownloadOutlined /> {t('exportExcel')}
                        </>
                      )}
                    </Button>
                  }
                >
                  <Grid container spacing={3}>
                    <StatsChart
                      response={mentorsByMunicipalityResponse}
                      label={t('mentorsByMunicipality')}
                      data={
                        mentorsByMunicipalityResponse?.data?.mentors ? mentorsByMunicipalityResponse.data.mentors : []
                      }
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={mentorsBySkillsResponse}
                      label={t('mentorsBySkills')}
                      data={mentorsBySkillsResponse?.data?.mentors ? mentorsBySkillsResponse.data.mentors : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={mentorsByCategoriesResponse}
                      label={t('mentorsByCategories')}
                      data={mentorsByCategoriesResponse?.data?.mentors ? mentorsByCategoriesResponse.data.mentors : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={mentorsByGenderResponse}
                      label={t('mentorsByGender')}
                      data={mentorsByGenderResponse?.data?.mentors ? mentorsByGenderResponse.data.mentors : []}
                      chart={'Pie'}
                    />
                    <StatsChart
                      response={mentorsByInternsResponse}
                      label={t('mentorsByInternsAssigned')}
                      data={mentorsByInternsResponse?.data?.mentors ? mentorsByInternsResponse.data.mentors : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={mentorsByEthnicityResponse}
                      label={t('mentorsByEthnicity')}
                      data={mentorsByEthnicityResponse?.data?.mentors ? mentorsByEthnicityResponse.data.mentors : []}
                      chart={'Bar'}
                    />
                    <StatsChart
                      response={mentorsByHealthConditionResponse}
                      label={t('mentorsByHealthCondition')}
                      data={
                        mentorsByHealthConditionResponse?.data?.mentors
                          ? mentorsByHealthConditionResponse.data.mentors
                          : []
                      }
                      chart={'Bar'}
                    />
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
