import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import {
  PersonPlusFill,
  Diagram3Fill,
  LightbulbFill,
  MegaphoneFill,
  UiChecks,
  BriefcaseFill,
} from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import { counts } from '../../store/actions/actions';
import { toastNotification } from '../../utils/toastNotification';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import '../../style/stats.scss';
import { InternsReports } from './Reports/InternsReports';
import { BusinessesReports } from './Reports/BusinessesReports';
import { MentorsReports } from './Reports/MentorsReports';
import { ApplicationsReports } from './Reports/ApplicationsReports';
import { EngagementsReports } from './Reports/EngagementsReports';
import { JobsReports } from './Reports/JobsReports';
import { useTranslation } from 'react-i18next';
import { InternshipReports } from './Reports/InternshipReports';
import { ProgrammeInfoReports } from './Reports/ProgrammeInfoReports';
import { TrainingsReports } from './Reports/TrainingsReports';

const { TabPane } = Tabs;

export const StatisticPage = () => {
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(0, 0, 0, 0));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countsResponse = useSelector(({ reports }) => reports.counts);

  useEffect(() => {
    if (startDate || endDate) dispatch(counts({ startDate, endDate }));
  }, [startDate, endDate]);

  const options = {
    print: false,
    filter: false,
    search: true,
    download: false,
    pagination: true,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: 5,
  };

  const handleStartDateChange = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    if (newDate > endDate) {
      toastNotification('error', t('validStartDate'));
      return;
    }
    setStartDate(newDate);
  };

  const handleEndDateChange = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    if (newDate < startDate) {
      toastNotification('error', t('validEndDate'));
      return;
    }
    setEndDate(newDate);
  };

  if (countsResponse.loading) {
    return <div class="Stats-loader">Loading...</div>;
  }
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className="content-heading">
          <div>{t('statistics')}</div>
        </div>
        <hr style={{ width: '100%', borderTop: '1px solid lightgray' }} className="mb-4 mt-3" />
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="mb-4"
        >
          <Col xs={24} sm={24} md={12} lg={12}>
            <Row xs={24} sm={24} md={24} lg={24}>
              <label
                style={{
                  fontSize: '17.5px',
                  color: '#707070',
                  fontFamily: 'Mark-Medium !important',
                  fontWeight: 'inherit',
                }}
              >
                {t('startDate')}:
              </label>
            </Row>
            <Row xs={24} sm={24} md={24} lg={24}>
              <Datetime
                timeFormat={false}
                value={startDate}
                onChange={handleStartDateChange}
                inputProps={{ className: 'dates form-control ms-3' }}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Row xs={24} sm={24} md={24} lg={24}>
              <label
                style={{
                  fontSize: '17.5px',
                  fontWeight: 'inherit',
                  color: '#707070',
                  fontFamily: 'Mark-Medium !important',
                }}
              >
                {t('endDate')}:
              </label>
            </Row>
            <Row xs={24} sm={24} md={24} lg={24}>
              <Datetime
                timeFormat={false}
                value={endDate}
                onChange={handleEndDateChange}
                inputProps={{ className: 'dates form-control ms-3' }}
              />
            </Row>
          </Col>
        </Row>
        <hr style={{ width: '100%', borderTop: '1px solid lightgray' }} className="mb-3" />
        <Row>
          <Col xl={12} md={12} sm={24} xs={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: '#01486B', color: '#fafafa' }}
              >
                <PersonPlusFill size={'3em'} />
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{
                  backgroundColor: '#01486B',
                  color: '#fafafa',
                  opacity: '0.9',
                  fontFamily: 'Mark-Medium',
                }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.internCount
                    : null}
                </div>
                <div className="text-uppercase">{t('Interns')}</div>
              </div>
            </div>
          </Col>
          <Col xl={12} md={12} sm={24} xs={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: '#FFD600', color: '#fafafa' }}
              >
                <BriefcaseFill size={'3em'} />
                <em className="fa-3x fas fa-handshake"></em>
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{ backgroundColor: '#FFD600', color: '#fafafa', opacity: '0.9', fontFamily: 'Mark-Medium' }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.businessCount
                    : null}
                </div>
                <div className="text-uppercase">{t('businesses')}</div>
              </div>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: '#00AEEF', color: '#fafafa' }}
              >
                <em className="fa-3x fas fa-handshake"></em>
                <MegaphoneFill size={'3em'} />
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{ backgroundColor: '#00AEEF', color: '#fafafa', opacity: '0.9', fontFamily: 'Mark-Medium' }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.mentorCount
                    : null}
                </div>
                <div className="text-uppercase">{t('mentors')}</div>
              </div>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: '#001726', color: '#fafafa' }}
              >
                <em className="fa-3x fas fa-handshake"></em>
                <UiChecks size={'3em'} />
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{ backgroundColor: '#001726', color: '#fafafa', opacity: '0.9', fontFamily: 'Mark-Medium' }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.applicationCount
                    : null}
                </div>
                <div className="text-uppercase">{t('applicationsS')}</div>
              </div>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: 'rgb(243, 110, 33)', color: '#fafafa' }}
              >
                <em className="fa-3x fas fa-handshake"></em>
                <Diagram3Fill size={'3em'} />
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{
                  backgroundColor: 'rgb(243, 110, 33)',
                  color: '#fafafa',
                  opacity: '0.9',
                  fontFamily: 'Mark-Medium',
                }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.jobCount
                    : null}
                </div>
                <div className="text-uppercase">{t('jobs')}</div>
              </div>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24} className="pr-3 pb-3">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div
                className="col-4 d-flex align-items-center justify-content-center rounded-left"
                style={{ backgroundColor: 'rgb(194, 32, 51)', color: '#fafafa' }}
              >
                <em className="fa-3x fas fa-handshake"></em>
                <LightbulbFill size={'3em'} />
              </div>
              <div
                className="col-8 py-3 rounded-right"
                style={{
                  backgroundColor: 'rgb(194, 32, 51)',
                  color: '#fafafa',
                  opacity: '0.9',
                  fontFamily: 'Mark-Medium',
                }}
              >
                <div className="h2 mt-0">
                  {countsResponse && countsResponse.success && countsResponse.data && countsResponse.data.counts
                    ? countsResponse.data.counts.trainingCount
                    : null}
                </div>
                <div className="text-uppercase">{t('trajnimeS')}</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '0px !important' }}>
          <div role="tabpanel" className="w-100">
            <Tabs type="card" destroyInactiveTabPane>
              <TabPane tab={t('internships')} key="internship">
                <InternshipReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('programmeInfo')} key="programmeInfo">
                <ProgrammeInfoReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('Interns')} key="interns">
                <InternsReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('businesses')} key="businesses">
                <BusinessesReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('mentors')} key="mentors">
                <MentorsReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('applications')} key="applications">
                <ApplicationsReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('engagements')} key="engagements">
                <EngagementsReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('jobs')} key="jobs">
                <JobsReports startDate={startDate} endDate={endDate} />
              </TabPane>
              <TabPane tab={t('trajnime')} key="trainings">
                <TrainingsReports startDate={startDate} endDate={endDate} />
              </TabPane>
            </Tabs>
          </div>
        </Row>
      </div>
    </>
  );
};
