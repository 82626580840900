// Imports: local files.
import {
  GET_ALL_QUESTIONS_START,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_FAILED,
  GET_ALL_QUESTIONS_RESET,
  GET_STATISTICS_QUESTIONS_START,
  GET_STATISTICS_QUESTIONS_SUCCESS,
  GET_STATISTICS_QUESTIONS_FAILED,
  GET_STATISTICS_QUESTIONS_RESET,
  CREATE_QUESTIONS_START,
  CREATE_QUESTIONS_SUCCESS,
  CREATE_QUESTIONS_FAILED,
  CREATE_QUESTIONS_RESET,
  UPDATE_QUESTIONS_START,
  UPDATE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_FAILED,
  UPDATE_QUESTIONS_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  create: initialLoadingState,
  update: initialLoadingState,
  statistics: {
    loading: false,
    success: false,
    data: null,
    error: false,
    errorMessage: null,
    dataDocs: [],
  },
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Questions
     * =======================================================================
     */
    case GET_ALL_QUESTIONS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_QUESTIONS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_QUESTIONS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_QUESTIONS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get Statistics Questions
     * =======================================================================
     */
    case GET_STATISTICS_QUESTIONS_START:
      return {
        ...state,
        statistics: { ...action.payload, dataDocs: state.statistics.dataDocs },
      };
    case GET_STATISTICS_QUESTIONS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...action.payload,
          dataDocs: state.statistics.dataDocs.concat(action?.payload?.data?.questions?.docs),
        },
      };
    case GET_STATISTICS_QUESTIONS_FAILED:
      return { ...state, statistics: { ...action.payload, dataDocs: [] } };
    case GET_STATISTICS_QUESTIONS_RESET:
      return {
        ...state,
        statistics: {
          loading: false,
          success: false,
          data: null,
          error: false,
          errorMessage: null,
          dataDocs: [],
        },
      };
    /**
     * =======================================================================
     * Create Questions
     * =======================================================================
     */
    case CREATE_QUESTIONS_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_QUESTIONS_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_QUESTIONS_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_QUESTIONS_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update Questions
     * =======================================================================
     */
    case UPDATE_QUESTIONS_START:
      return { ...state, update: { ...action.payload } };
    case UPDATE_QUESTIONS_SUCCESS:
      return { ...state, update: { ...action.payload } };
    case UPDATE_QUESTIONS_FAILED:
      return { ...state, update: { ...action.payload } };
    case UPDATE_QUESTIONS_RESET:
      return { ...state, update: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default questionsReducer;
