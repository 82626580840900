import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useStyles } from './ButtonStyle';

const AllRoleButton = ({
  backgroundColor,
  label,
  icon,
  handleClick,
  disabled,
  customWidth,
  customColor,
  customMargin,
  dateBefore,
  title,
  href,
  target,
  loadingButtonExcel,
}) => {
  const classes = useStyles();
  return (
    <Button
      type="button"
      title={title}
      href={href}
      target={target}
      variant="contained"
      style={{ backgroundColor }}
      className={classes.allRoleButton}
      onClick={handleClick}
      disabled={disabled}
      startIcon={loadingButtonExcel ? <CircularProgress size={24} color="inherit" /> : icon}
      sx={{
        width: customWidth ? `${customWidth} !important` : '100% !important',
        color: customColor ? dateBefore && `${customColor} !important` : 'inherit',
        marginLeft: customMargin ? `${customMargin} !important` : 'none',
      }}
    >
      {loadingButtonExcel ? '' : label} {/* Empty label space for consistent button size */}
    </Button>
  );
};

export default AllRoleButton;
