// Imports: local files.
import {
  GET_ALL_REDFLAGS_START,
  GET_ALL_REDFLAGS_SUCCESS,
  GET_ALL_REDFLAGS_FAILED,
  GET_ALL_REDFLAGS_RESET,
  GET_ONE_REDFLAG_START,
  GET_ONE_REDFLAG_SUCCESS,
  GET_ONE_REDFLAG_FAILED,
  GET_ONE_REDFLAG_RESET,
  CREATE_REDFLAG_START,
  CREATE_REDFLAG_SUCCESS,
  CREATE_REDFLAG_FAILED,
  CREATE_REDFLAG_RESET,
} from '../actions/actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
};

const redFlagsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Red Flags
     * =======================================================================
     */
    case GET_ALL_REDFLAGS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_REDFLAGS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_REDFLAGS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_REDFLAGS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Red Flag
     * =======================================================================
     */
    case GET_ONE_REDFLAG_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_REDFLAG_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_REDFLAG_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_REDFLAG_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create New Red Flag
     * =======================================================================
     */
    case CREATE_REDFLAG_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_REDFLAG_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_REDFLAG_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_REDFLAG_RESET:
      return { ...state, create: { ...initialLoadingState } };

    default:
      return state;
  }
};

export default redFlagsReducer;
