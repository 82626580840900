import { Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AllRoleButton from '../../../../components/buttons/AllRoleButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from 'react';
import { ChipSelect } from '../../../../components/inputs/ChipSelect';
import { getAllMunicipalities } from '../../../../store/actions/actions';
import { useStyles } from '../SurveyStatistics/style/SurveyStatistics';

export const ProgrammeInfoFilter = ({
  genderChips,
  setGenderChips,
  selectedYear,
  setSelectedYear,
  municipalityChips,
  setMunicipalityChips,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedMunicipality, setSelectedMunicipality] = useState('choose');
  const [selectedGender, setSelectedGender] = useState('choose');

  const municipalitiesResponse = useSelector(({ municipalities }) => municipalities.getAll);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getAllMunicipalities({ pagination: false, language }));
  }, []);

  const onMunicipalityChange = (municipality, type) => {
    switch (type) {
      case 'add':
        const exists = municipalityChips.find((chip) => chip._id === municipality._id);
        if (exists) return;

        const addedArray = [...municipalityChips, municipality];
        setMunicipalityChips(addedArray);
        setSelectedMunicipality(municipality._id);
        break;
      case 'delete':
        const filteredArray = municipalityChips.filter((chip) => chip._id !== municipality._id);
        setMunicipalityChips(filteredArray);
        setSelectedMunicipality('choose');
        break;
      default:
        break;
    }
  };

  const onGenderChange = (gender, type) => {
    switch (type) {
      case 'add':
        const exists = genderChips.find((chip) => chip._id === gender._id);
        if (exists) return;

        const addedArray = [...genderChips, gender];
        setGenderChips(addedArray);
        setSelectedGender(gender._id);
        break;
      case 'delete':
        const filteredArray = genderChips.filter((chip) => chip._id !== gender._id);
        setGenderChips(filteredArray);
        setSelectedGender('choose');
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    setSelectedMunicipality('choose');
    setSelectedGender('choose');
    setSelectedYear('choose');
    setMunicipalityChips([]);
    setGenderChips([]);
  };

  const date = new Date();
  const currentYear = date.getFullYear();

  const first = currentYear - 25;
  const last = currentYear - 16;

  let newOptions = [];
  for (let i = first; i <= last; i++) {
    newOptions.push(i);
  }

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="municipalities"
              label={t('Komuna')}
              selectLabel={t('ChooseMunicipality')}
              handleChange={(e) => onMunicipalityChange(JSON.parse(e.target.value), 'add')}
              value={selectedMunicipality}
              data={
                municipalitiesResponse?.success && municipalitiesResponse.data?.municipalities?.docs
                  ? municipalitiesResponse.data.municipalities.docs
                  : []
              }
              valueChips={municipalityChips}
              handleDelete={onMunicipalityChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ChipSelect
              name="gender"
              label={t('gender')}
              selectLabel={t('gender')}
              handleChange={(e) => onGenderChange(JSON.parse(e.target.value), 'add')}
              value={selectedGender}
              data={[
                { _id: 'M', label: t('gender1') },
                { _id: 'F', label: t('gender2') },
                { _id: 'O', label: t('gender3') },
              ]}
              valueChips={genderChips}
              handleDelete={onGenderChange}
              ignoreLoc={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputLabel sx={{ fontFamily: 'Mark-Regular', fontSize: '14px' }}>{t('yearOfBirth')}</InputLabel>
            <Select
              fullWidth
              id="type"
              name="age"
              value={selectedYear}
              className={classes.selectInput}
              onChange={(e) => onYearChange(e.target.value)}
            >
              <MenuItem value="choose" disabled className={classes.menuItem}>
                {t('yearOfBirth')}
              </MenuItem>
              {newOptions.map((e, index) => (
                <MenuItem key={index} value={e} className={classes.menuItem}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: { md: 3.5, lg: 3.6, xl: 3.6 } }}>
            <AllRoleButton
              label={t('resetFilters')}
              handleClick={() => resetFilters()}
              backgroundColor={'#F36E21'}
              icon={<RemoveCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
