// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Action Types: Get All Public Mentors.
export const GET_ALL_PUBLIC_MENTORS_START = 'GET_ALL_PUBLIC_MENTORS_START';
export const GET_ALL_PUBLIC_MENTORS_SUCCESS = 'GET_ALL_PUBLIC_MENTORS_SUCCESS';
export const GET_ALL_PUBLIC_MENTORS_FAILED = 'GET_ALL_PUBLIC_MENTORS_FAILED';
export const GET_ALL_PUBLIC_MENTORS_RESET = 'GET_ALL_PUBLIC_MENTORS_RESET';

// Action Types: Get One Public Mentor.
export const GET_ONE_PUBLIC_MENTOR_START = 'GET_ONE_PUBLIC_MENTOR_START';
export const GET_ONE_PUBLIC_MENTOR_SUCCESS = 'GET_ONE_PUBLIC_MENTOR_SUCCESS';
export const GET_ONE_PUBLIC_MENTOR_FAILED = 'GET_ONE_PUBLIC_MENTOR_FAILED';
export const GET_ONE_PUBLIC_MENTOR_RESET = 'GET_ONE_PUBLIC_MENTOR_RESET';

// Action Types: Create New Public Mentor.
export const CREATE_PUBLIC_MENTOR_START = 'CREATE_PUBLIC_MENTOR_START';
export const CREATE_PUBLIC_MENTOR_SUCCESS = 'CREATE_PUBLIC_MENTOR_SUCCESS';
export const CREATE_PUBLIC_MENTOR_FAILED = 'CREATE_PUBLIC_MENTOR_FAILED';
export const CREATE_PUBLIC_MENTOR_RESET = 'CREATE_PUBLIC_MENTOR_RESET';

// Action Types: Update One Public Mentor.
export const UPDATE_ONE_PUBLIC_MENTOR_START = 'UPDATE_ONE_PUBLIC_MENTOR_START';
export const UPDATE_ONE_PUBLIC_MENTOR_SUCCESS = 'UPDATE_ONE_PUBLIC_MENTOR_SUCCESS';
export const UPDATE_ONE_PUBLIC_MENTOR_FAILED = 'UPDATE_ONE_PUBLIC_MENTOR_FAILED';
export const UPDATE_ONE_PUBLIC_MENTOR_RESET = 'UPDATE_ONE_PUBLIC_MENTOR_RESET';

// Action Types: Delete One Public Mentor.
export const DELETE_ONE_PUBLIC_MENTOR_START = 'DELETE_ONE_PUBLIC_MENTOR_START';
export const DELETE_ONE_PUBLIC_MENTOR_SUCCESS = 'DELETE_ONE_PUBLIC_MENTOR_SUCCESS';
export const DELETE_ONE_PUBLIC_MENTOR_FAILED = 'DELETE_ONE_PUBLIC_MENTOR_FAILED';
export const DELETE_ONE_PUBLIC_MENTOR_RESET = 'DELETE_ONE_PUBLIC_MENTOR_RESET';

// Action Creators: Get All Public Mentors.
const getAllPublicMentorsStart = (payload) => ({
  type: GET_ALL_PUBLIC_MENTORS_START,
  payload,
});
const getAllPublicMentorsSuccess = (payload) => ({
  type: GET_ALL_PUBLIC_MENTORS_SUCCESS,
  payload,
});
const getAllPublicMentorsFailed = (payload) => ({
  type: GET_ALL_PUBLIC_MENTORS_FAILED,
  payload,
});
const getAllPublicMentorsReset = () => ({ type: GET_ALL_PUBLIC_MENTORS_RESET });

// Action Creators: Get One Public Mentor.
const getOnePublicMentorStart = (payload) => ({
  type: GET_ONE_PUBLIC_MENTOR_START,
  payload,
});
const getOnePublicMentorSuccess = (payload) => ({
  type: GET_ONE_PUBLIC_MENTOR_SUCCESS,
  payload,
});
const getOnePublicMentorFailed = (payload) => ({
  type: GET_ONE_PUBLIC_MENTOR_FAILED,
  payload,
});
const getOnePublicMentorReset = () => ({ type: GET_ONE_PUBLIC_MENTOR_RESET });

// Action Creators: Create New Public Mentor.
const createPublicMentorStart = (payload) => ({
  type: CREATE_PUBLIC_MENTOR_START,
  payload,
});
const createPublicMentorSuccess = (payload) => ({
  type: CREATE_PUBLIC_MENTOR_SUCCESS,
  payload,
});
const createPublicMentorFailed = (payload) => ({
  type: CREATE_PUBLIC_MENTOR_FAILED,
  payload,
});
const createPublicMentorReset = () => ({ type: CREATE_PUBLIC_MENTOR_RESET });

// Action Creators: Update One Public Mentor.
const updateOnePublicMentorStart = (payload) => ({
  type: UPDATE_ONE_PUBLIC_MENTOR_START,
  payload,
});
const updateOnePublicMentorSuccess = (payload) => ({
  type: UPDATE_ONE_PUBLIC_MENTOR_SUCCESS,
  payload,
});
const updateOnePublicMentorFailed = (payload) => ({
  type: UPDATE_ONE_PUBLIC_MENTOR_FAILED,
  payload,
});
const updateOnePublicMentorReset = () => ({ type: UPDATE_ONE_PUBLIC_MENTOR_RESET });

// Action Creators: Delete One Public Mentor.
const deleteOnePublicMentorStart = (payload) => ({
  type: DELETE_ONE_PUBLIC_MENTOR_START,
  payload,
});
const deleteOnePublicMentorSuccess = (payload) => ({
  type: DELETE_ONE_PUBLIC_MENTOR_SUCCESS,
  payload,
});
const deleteOnePublicMentorFailed = (payload) => ({
  type: DELETE_ONE_PUBLIC_MENTOR_FAILED,
  payload,
});
const deleteOnePublicMentorReset = () => ({ type: DELETE_ONE_PUBLIC_MENTOR_RESET });

// Actions: Get All Public Mentors.
export const getAllPublicMentors = (payload) => {
  return async (dispatch) => {
    dispatch(
      getAllPublicMentorsStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { page, limit, pagination, language } = payload;
      const result = await ApiClient.get('publicmentors', {
        params: { page, limit, pagination },
      });
      if (result.data?.success) {
        const { publicMentors } = result.data.data;
        dispatch(
          getAllPublicMentorsSuccess({
            loading: false,
            success: true,
            data: { publicMentors },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getAllPublicMentorsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getAllPublicMentorsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Get All Public Mentors.
export const clearGetAllPublicMentors = () => getAllPublicMentorsReset();

// Actions: Get One Public Mentor.
export const getOnePublicMentor = (payload) => {
  return async (dispatch) => {
    dispatch(
      getOnePublicMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { publicMentorId } = payload;
      const result = await ApiClient.get(`publicmentors/${publicMentorId}`);
      if (result.data?.success) {
        const { publicMentor } = result.data.data;
        dispatch(
          getOnePublicMentorSuccess({
            loading: false,
            success: true,
            data: { publicMentor },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          getOnePublicMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server  Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getOnePublicMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server  Error!',
        })
      );
    }
  };
};

// Actions: Clear Get One Public Mentor.
export const clearGetOnePublicMentor = () => getOnePublicMentorReset();

// Actions: Create New Public Mentor.
export const createPublicMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      createPublicMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage, onPublicMentorExistsMessage } =
      options;

    try {
      const result = await ApiClient.post('publicmentors', formData);
      if (result.data?.success) {
        const { publicMentor } = result.data.data;
        dispatch(
          createPublicMentorSuccess({
            loading: false,
            success: true,
            data: { publicMentor },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          createPublicMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        createPublicMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Create New Public Mentor.
export const clearCreateNewPublicMentor = () => createPublicMentorReset();

// Actions: Update One Public Mentor.
export const updateOnePublicMentor = (payload, options) => {
  return async (dispatch) => {
    dispatch(
      updateOnePublicMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    const { publicMentorId, formData } = payload;
    const { toastNotification, history, pathname, onSuccessMessage, onFailMessage } = options;

    try {
      const result = await ApiClient.put(`publicmentors/${publicMentorId}`, formData);
      if (result.data?.success) {
        const { publicMentor } = result.data.data;
        dispatch(
          updateOnePublicMentorSuccess({
            loading: false,
            success: true,
            data: { publicMentor },
            error: false,
            errorMessage: null,
          })
        );
        toastNotification('success', onSuccessMessage);
      } else {
        dispatch(
          updateOnePublicMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
        toastNotification('error', onFailMessage);
      }
    } catch (error) {
      dispatch(
        updateOnePublicMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
      toastNotification('error', onFailMessage);
    }
  };
};

// Actions: Clear Update One Public Mentor.
export const clearUpdateOnePublicMentor = () => updateOnePublicMentorReset();

// Actions: Delete One Public Mentor.
export const deleteOnePublicMentor = (payload) => {
  return async (dispatch) => {
    dispatch(
      deleteOnePublicMentorStart({
        loading: true,
        success: false,
        data: null,
        error: false,
        errorMessage: null,
      })
    );

    try {
      const { publicMentorId } = payload;
      const result = await ApiClient.delete(`publicmentors/${publicMentorId}`);
      if (result.data?.success) {
        const { publicMentor } = result.data.data;
        dispatch(
          deleteOnePublicMentorSuccess({
            loading: false,
            success: true,
            data: { publicMentor },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        dispatch(
          deleteOnePublicMentorFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteOnePublicMentorFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: error.message || 'Internal Server Error!',
        })
      );
    }
  };
};

// Actions: Clear Delete One Public Mentor.
export const cleareDeleteOnePublicMentor = () => deleteOnePublicMentorReset();
