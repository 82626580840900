import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  button: {
    color: '#FFFFFF !important',
    width: '100% !important',
    height: '41px !important',
    borderRadius: '6px !important',
    color: '#FFFFFF !important',
    fontFamily: 'Mark-Medium !important',
    fontSize: '16px !important',
    lineHeight: '20px !important',
    boxShadow: 'none !important',
    textTransform: 'none !important',
  },
  readMoreButton: {
    width: '100% !important',
    height: '25px !important',
    borderRadius: '10px !important',
    fontFamily: 'Mark-Medium !important',
    fontSize: '13.8px !important',
    lineHeight: '20px !important',
    boxShadow: 'none !important',
    textTransform: 'none !important',
    border: 'none !important',
  },
  internsButton: {
    // marginBottom: '10px !important',
    borderRadius: '7px !important',
    width: '100% !important',
    padding: '10px 20px !important',
    letterSpacing: '0px !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    lineHeight: '20px !important',
    fontSize: '14px !important',
    opacity: '1',
    color: '#ffffff !important',
    fontFamily: 'Mark-Medium !important',
  },
  allRoleButton: {
    marginBottom: '10px !important',
    borderRadius: '7px !important',
    // width: '100% !important',
    padding: '10px 20px !important',
    letterSpacing: '0px !important',
    textTransform: 'none !important',
    boxShadow: 'none !important',
    lineHeight: '20px !important',
    fontSize: '14px !important',
    opacity: '1',
    color: '#ffffff !important',
    fontFamily: 'Mark-Medium !important',
  },
});
